import React from 'react';

const Child = ({ width, height }) => {
    const viewBoxValues = '0 0 164 164';

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox={viewBoxValues}
        >
            <defs>
                <filter
                    id="Rectangle_1662"
                    x="0"
                    y="0"
                    width="164"
                    height="164"
                    filterUnits="userSpaceOnUse"
                >
                    <feOffset dy="1" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="1" result="blur" />
                    <feFlood floodOpacity="0.157" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g
                id="Квадрат_с_картинка_детето"
                data-name="Квадрат с картинка детето"
                transform="translate(-106 -166)"
            >
                <g
                    transform="matrix(1, 0, 0, 1, 106, 166)"
                    filter="url(#Rectangle_1662)"
                >
                    <g
                        id="Rectangle_1662-2"
                        data-name="Rectangle 1662"
                        transform="translate(3 2)"
                        fill="#fff"
                        stroke="#cacaca"
                        strokeWidth="2"
                    >
                        <rect width="158" height="158" rx="11" stroke="none" />
                        <rect
                            x="1"
                            y="1"
                            width="156"
                            height="156"
                            rx="10"
                            fill="none"
                        />
                    </g>
                </g>
                <g id="Бебе" transform="translate(137.648 200.097)">
                    <ellipse
                        id="Ellipse_100"
                        data-name="Ellipse 100"
                        cx="39.37"
                        cy="44.083"
                        rx="39.37"
                        ry="44.083"
                        transform="translate(5.558 84.774) rotate(-88.029)"
                        fill="#fdc1a1"
                    />
                    <ellipse
                        id="Ellipse_101"
                        data-name="Ellipse 101"
                        cx="9.568"
                        cy="7.287"
                        rx="9.568"
                        ry="7.287"
                        transform="translate(86.482 57.739) rotate(-88.029)"
                        fill="#fdc1a1"
                    />
                    <path
                        id="Path_457"
                        data-name="Path 457"
                        d="M347.963,456.1a2.99,2.99,0,0,1,2.785,1.65,1.79,1.79,0,0,0,.184-.714c.029-1.25-1.279-2.294-2.922-2.333s-3,.945-3.025,2.2a1.769,1.769,0,0,0,.138.685A3,3,0,0,1,347.963,456.1Z"
                        transform="translate(-295.805 -405.52)"
                        fill="#e99e7f"
                    />
                    <g
                        id="Group_348"
                        data-name="Group 348"
                        transform="translate(48.354)"
                    >
                        <path
                            id="Path_458"
                            data-name="Path 458"
                            d="M352.6,442.429c-1.9,2.627-4.81,3.87-7.165,3.315a4.344,4.344,0,0,0,1.181,1.355c2.473,1.788,6.379.607,8.727-2.64s2.244-7.327-.228-9.115a4.315,4.315,0,0,0-1.559-.671C354.765,436.738,354.478,439.841,352.6,442.429Z"
                            transform="translate(-343.497 -434.675)"
                            fill="#f9ad71"
                        />
                        <path
                            id="Path_459"
                            data-name="Path 459"
                            d="M349.166,441.246a4.586,4.586,0,0,1-4.518,2.09,2.709,2.709,0,0,0,.744.857c1.559,1.127,4.025.383,5.5-1.665s1.417-4.621-.142-5.751a2.7,2.7,0,0,0-.985-.422A4.617,4.617,0,0,1,349.166,441.246Z"
                            transform="translate(-344.648 -432.23)"
                            fill="#f9ad71"
                        />
                    </g>
                    <ellipse
                        id="Ellipse_102"
                        data-name="Ellipse 102"
                        cx="8.733"
                        cy="6.651"
                        rx="8.733"
                        ry="6.651"
                        transform="translate(0 52.895) rotate(-88.029)"
                        fill="#fdc1a1"
                    />
                    <circle
                        id="Ellipse_103"
                        data-name="Ellipse 103"
                        cx="4.084"
                        cy="4.084"
                        r="4.084"
                        transform="translate(64.283 42.542)"
                        fill="#fff"
                    />
                    <path
                        id="Path_460"
                        data-name="Path 460"
                        d="M356.794,461.56c-8.6-.287-14.637-2.853-16.884-3.241a.765.765,0,0,0-.879.936c.656,2.8,4.776,11.838,12.346,14.645a13.338,13.338,0,0,0,4.634.842c12.145.034,18.2-14.532,17.029-15.2C371.94,458.655,364.723,461.825,356.794,461.56Z"
                        transform="translate(-304.501 -400.278)"
                        fill="#970d34"
                    />
                    <circle
                        id="Ellipse_104"
                        data-name="Ellipse 104"
                        cx="4.084"
                        cy="4.084"
                        r="4.084"
                        transform="translate(29.912 40.538)"
                        fill="#fff"
                    />
                    <circle
                        id="Ellipse_105"
                        data-name="Ellipse 105"
                        cx="2.623"
                        cy="2.623"
                        r="2.623"
                        transform="translate(31.372 42.972)"
                        fill="#0b545d"
                    />
                    <circle
                        id="Ellipse_106"
                        data-name="Ellipse 106"
                        cx="1.394"
                        cy="1.394"
                        r="1.394"
                        transform="translate(32.602 44.201)"
                        fill="#1a1818"
                    />
                    <circle
                        id="Ellipse_107"
                        data-name="Ellipse 107"
                        cx="2.623"
                        cy="2.623"
                        r="2.623"
                        transform="translate(65.743 44.972)"
                        fill="#0b545d"
                    />
                    <path
                        id="Path_461"
                        data-name="Path 461"
                        d="M350.766,462.531c-3.882,0-7.114,1.478-7.985,3.585a13.9,13.9,0,0,0,7.769,2.478,14.089,14.089,0,0,0,8.2-2.522C357.877,463.963,354.646,462.531,350.766,462.531Z"
                        transform="translate(-299.012 -394.13)"
                        fill="#c74b5d"
                    />
                    <path
                        id="Path_462"
                        data-name="Path 462"
                        d="M328.733,455.592c1.957,1.6,1.031,3.177,0,3.177s-1.866-1.626-1.866-3.629.837-3.629,1.866-3.629S327.181,454.323,328.733,455.592Z"
                        transform="translate(-322.175 -410.17)"
                        fill="#e99e7f"
                    />
                    <path
                        id="Path_463"
                        data-name="Path 463"
                        d="M364.569,457.112c-1.957,1.6-1.031,3.18,0,3.18s1.866-1.626,1.866-3.629-.837-3.629-1.866-3.629S366.12,455.845,364.569,457.112Z"
                        transform="translate(-268.968 -407.955)"
                        fill="#e99e7f"
                    />
                    <g
                        id="Group_349"
                        data-name="Group 349"
                        transform="translate(25.075 30.954)"
                    >
                        <path
                            id="Path_464"
                            data-name="Path 464"
                            d="M341.617,447.691c-3.332.832-6.662,3.47-6.438,4.373a.413.413,0,0,0,.26.3.437.437,0,0,0,.14.022c.211,0,.44-.169.828-.457a11.052,11.052,0,0,1,4.106-2.036,22.338,22.338,0,0,1,5.272-.442c1.608,0,2.4.01,2.519-.53a.907.907,0,0,0-.282-.854C347.117,447.19,344.305,447.021,341.617,447.691Z"
                            transform="translate(-335.168 -447.281)"
                            fill="#e99e7f"
                        />
                        <path
                            id="Path_465"
                            data-name="Path 465"
                            d="M364.3,453.379c-.393-1-2.662-3.539-5.594-4.81-2.534-1.105-5.333-1.4-6.377-.68a.9.9,0,0,0-.42.8c.032.555.823.685,2.4.938a21.861,21.861,0,0,1,5.125,1.3,11.027,11.027,0,0,1,3.718,2.681c.368.386.57.594.808.594a.354.354,0,0,0,.074-.005.408.408,0,0,0,.3-.258A.711.711,0,0,0,364.3,453.379Z"
                            transform="translate(-310.807 -446.968)"
                            fill="#e99e7f"
                        />
                    </g>
                    <circle
                        id="Ellipse_108"
                        data-name="Ellipse 108"
                        cx="1.393"
                        cy="1.393"
                        r="1.393"
                        transform="translate(67.044 46.23)"
                        fill="#1a1818"
                    />
                    <circle
                        id="Ellipse_109"
                        data-name="Ellipse 109"
                        cx="0.544"
                        cy="0.544"
                        r="0.544"
                        transform="translate(68.862 46.335)"
                        fill="#fff"
                    />
                    <circle
                        id="Ellipse_110"
                        data-name="Ellipse 110"
                        cx="0.544"
                        cy="0.544"
                        r="0.544"
                        transform="translate(34.508 44.427)"
                        fill="#fff"
                    />
                </g>
            </g>
        </svg>
    );
};

export default Child;
