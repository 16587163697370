import React from 'react';

const LogoSmall = ({ width, height }) => {
    const viewBoxValues = '0 0 73 25';

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox={viewBoxValues}
        >
            <g
                id="Лого_Дневникът_с_надпис"
                data-name="Лого Дневникът с надпис"
                transform="translate(-26.252 -21.434)"
            >
                <path
                    id="Path_274"
                    data-name="Path 274"
                    d="M246.069,37.188v2.648h-2.648v2.648h-2.648v-5.3h5.3Z"
                    transform="translate(-163.511 -12.007)"
                    fill="#ffd300"
                />
                <path
                    id="Path_275"
                    data-name="Path 275"
                    d="M246.069,96.286V93.638h-2.648V90.989h-2.648v5.3h5.3Z"
                    transform="translate(-163.511 -53.016)"
                    fill="#fbae17"
                />
                <path
                    id="Path_276"
                    data-name="Path 276"
                    d="M294.571,37.187v2.648h2.648v2.648h2.648v-5.3h-5.3Z"
                    transform="translate(-204.517 -12.007)"
                    fill="#fbae17"
                />
                <path
                    id="Path_277"
                    data-name="Path 277"
                    d="M310.86,66.954l-1.872-1.873,1.872-1.873-1.872-1.873,1.872-1.873,1.873,1.873,1.873,1.873-1.873,1.873-1.873,1.873Z"
                    transform="translate(-215.505 -28.986)"
                    fill="#f37920"
                />
                <path
                    id="Path_278"
                    data-name="Path 278"
                    d="M228.759,59.488l1.873,1.873-1.873,1.873,1.873,1.873-1.873,1.873-1.873-1.873-1.873-1.873,1.873-1.873,1.873-1.873Z"
                    transform="translate(-151.5 -29.005)"
                    fill="#ffd300"
                />
                <path
                    id="Path_279"
                    data-name="Path 279"
                    d="M263.054,107.253l1.873-1.873,1.873,1.873,1.873-1.873,1.873,1.873-1.873,1.873L266.8,111l-1.873-1.873-1.873-1.873Z"
                    transform="translate(-180.494 -63.985)"
                    fill="#f37920"
                />
                <path
                    id="Path_280"
                    data-name="Path 280"
                    d="M270.552,25.18l-1.872,1.873-1.873-1.873-1.873,1.873-1.873-1.873,1.873-1.873,1.873-1.873,1.873,1.873,1.872,1.873Z"
                    transform="translate(-180.499)"
                    fill="#ffd300"
                />
                <path
                    id="Path_281"
                    data-name="Path 281"
                    d="M294.57,96.271V93.62h2.65v-2.65h2.651l0,5.3Z"
                    transform="translate(-204.516 -53)"
                    fill="#f37920"
                />
                <path
                    id="Path_282"
                    data-name="Path 282"
                    d="M270.477,65.537v.674H269.8v.674h-.674V65.537h1.348Z"
                    transform="translate(-185.124 -33.616)"
                    fill="#ffd300"
                />
                <path
                    id="Path_283"
                    data-name="Path 283"
                    d="M270.477,80.584V79.91H269.8v-.674h-.674v1.348h1.348Z"
                    transform="translate(-185.124 -44.057)"
                    fill="#fbae17"
                />
                <path
                    id="Path_284"
                    data-name="Path 284"
                    d="M282.825,65.537v.674h.674v.674h.674V65.537h-1.349Z"
                    transform="translate(-195.564 -33.616)"
                    fill="#fbae17"
                />
                <path
                    id="Path_285"
                    data-name="Path 285"
                    d="M286.973,73.115l-.477-.477.477-.477-.477-.477.477-.477.477.477.477.477-.477.477-.477.477Z"
                    transform="translate(-198.362 -37.938)"
                    fill="#f37920"
                />
                <path
                    id="Path_286"
                    data-name="Path 286"
                    d="M266.07,71.215l.477.477-.477.477.477.477-.477.477-.477-.477-.477-.477.477-.477.477-.477Z"
                    transform="translate(-182.066 -37.943)"
                    fill="#ffd300"
                />
                <path
                    id="Path_287"
                    data-name="Path 287"
                    d="M274.8,83.375l.477-.477.477.477.477-.477.477.477-.477.477-.477.477-.477-.477-.477-.477Z"
                    transform="translate(-189.448 -46.849)"
                    fill="#f37920"
                />
                <path
                    id="Path_288"
                    data-name="Path 288"
                    d="M276.71,62.479l-.477.477-.477-.477-.477.477-.477-.477.477-.477.477-.477.477.477.477.477Z"
                    transform="translate(-189.449 -30.558)"
                    fill="#ffd300"
                />
                <path
                    id="Path_289"
                    data-name="Path 289"
                    d="M282.825,80.579V79.9h.675v-.675h.675v1.35Z"
                    transform="translate(-195.564 -44.052)"
                    fill="#f37920"
                />
                <path
                    id="Path_290"
                    data-name="Path 290"
                    d="M252.428,58.113l.6,1.439-1.439.6.6,1.439-1.439.6-.6-1.439-.6-1.439,1.439-.6,1.439-.6Z"
                    transform="translate(-170.201 -27.957)"
                    fill="#ffd300"
                />
                <path
                    id="Path_291"
                    data-name="Path 291"
                    d="M265.778,92.147l-.6-1.439-1.439.6-.6-1.439-1.439.6.6,1.439.6,1.439,1.439-.6,1.439-.6Z"
                    transform="translate(-179.465 -52.16)"
                    fill="#fbae17"
                />
                <path
                    id="Path_292"
                    data-name="Path 292"
                    d="M278.786,47.157l.6,1.439,1.439-.6.6,1.439,1.439-.6-.6-1.439-.6-1.439-1.439.6-1.439.6Z"
                    transform="translate(-192.485 -18.694)"
                    fill="#fbae17"
                />
                <path
                    id="Path_293"
                    data-name="Path 293"
                    d="M295.718,62.133l-1.441-.595.595-1.441-1.441-.595.595-1.441,1.44.595,1.441.595-.595,1.441-.595,1.441Z"
                    transform="translate(-203.648 -27.918)"
                    fill="#f37920"
                />
                <path
                    id="Path_294"
                    data-name="Path 294"
                    d="M250.771,75.266l1.441.595-.595,1.441,1.441.594-.595,1.441-1.441-.594-1.441-.595.594-1.441.595-1.44Z"
                    transform="translate(-170.225 -41.031)"
                    fill="#fbae17"
                />
                <path
                    id="Path_295"
                    data-name="Path 295"
                    d="M278.84,92.119l.595-1.441,1.441.595.594-1.441,1.441.595-.595,1.44-.595,1.441-1.441-.594-1.441-.595Z"
                    transform="translate(-192.527 -52.134)"
                    fill="#f37920"
                />
                <path
                    id="Path_296"
                    data-name="Path 296"
                    d="M265.732,47.18l-.595,1.441-1.441-.595-.594,1.441-1.441-.595.595-1.441.595-1.441,1.441.595,1.44.595Z"
                    transform="translate(-179.433 -18.717)"
                    fill="#ffd300"
                />
                <path
                    id="Path_297"
                    data-name="Path 297"
                    d="M294.05,79.261l-.6-1.44,1.441-.6-.6-1.441,1.441-.6,1.2,2.882-2.88,1.2Z"
                    transform="translate(-203.663 -40.968)"
                    fill="#f37920"
                />
                <path
                    id="Path_298"
                    data-name="Path 298"
                    d="M63.954,37.318h.229V36.235h3.673v1.083h.228v-1.29h-.31l-1.626-4.755h-.262l-1.633,4.755h-.3v1.29Zm3.578-1.29H64.493l1.523-4.479,1.516,4.479Z"
                    transform="translate(-28.737 -7.499)"
                    fill="#231f20"
                />
                <path
                    id="Path_299"
                    data-name="Path 299"
                    d="M83.84,40.652h.22V38.868h2.212v1.785h.22V37.069h-.22v1.6H84.061v-1.6h-.22v3.583Z"
                    transform="translate(-43.895 -11.917)"
                    fill="#231f20"
                />
                <path
                    id="Path_300"
                    data-name="Path 300"
                    d="M99.271,38.624c0,1.172.6,1.882,1.516,1.882a1.436,1.436,0,0,0,1.434-1.152h-.214a1.241,1.241,0,0,1-1.22.958c-.744,0-1.289-.627-1.289-1.619v-.055h2.791v-.131c0-1.116-.579-1.757-1.5-1.757s-1.516.7-1.516,1.874Zm.234-.186c.055-.937.572-1.5,1.323-1.5.71,0,1.227.565,1.227,1.5Z"
                    transform="translate(-55.656 -11.674)"
                    fill="#231f20"
                />
                <path
                    id="Path_301"
                    data-name="Path 301"
                    d="M115.445,34.42c0,1.013.441,1.558,1.475,1.558a1.338,1.338,0,0,0,1.426-1.469,1.29,1.29,0,0,0-1.082-1.392,1.223,1.223,0,0,0,.882-1.144,1.161,1.161,0,0,0-1.282-1.165c-.772,0-1.42.51-1.42,1.682V34.42Zm.22-1.991c0-1.006.558-1.426,1.185-1.426a.981.981,0,0,1,1.082.951,1.166,1.166,0,0,1-1.282,1.11h-.607a3.3,3.3,0,0,1-.379-.014v-.62Zm0,.847c.117-.007.228-.014.372-.014h.641c.861,0,1.454.379,1.454,1.213a1.149,1.149,0,0,1-1.261,1.309c-.689,0-1.206-.379-1.206-1.309v-1.2Z"
                    transform="translate(-67.984 -7.145)"
                    fill="#231f20"
                />
                <path
                    id="Path_302"
                    data-name="Path 302"
                    d="M131.814,40.652h.221V38.868h2.212v1.785h.221V37.069h-.221v1.6h-2.212v-1.6h-.221v3.583Z"
                    transform="translate(-80.461 -11.917)"
                    fill="#231f20"
                />
                <path
                    id="Path_303"
                    data-name="Path 303"
                    d="M150.385,40.652h.214V37.069h-.214v1.943a1.624,1.624,0,0,1-.289,1.178,1.17,1.17,0,0,1-.923.358c-.724,0-1.006-.358-1.006-1.151V37.069h-.214v2.4c0,.861.4,1.283,1.227,1.283a1.262,1.262,0,0,0,1.206-.656v.558Z"
                    transform="translate(-92.762 -11.917)"
                    fill="#231f20"
                />
                <path
                    id="Path_304"
                    data-name="Path 304"
                    d="M163.905,36.234h.22V34.96l.875-.855,1.412,2.129h.248l-1.509-2.281,1.3-1.3h-.269l-2.06,2.047V31.273h-.22v4.962Z"
                    transform="translate(-104.921 -7.499)"
                    fill="#231f20"
                />
                <path
                    id="Path_305"
                    data-name="Path 305"
                    d="M177.117,37.269h.634v2.2a1.153,1.153,0,0,0,1.282,1.283,1.137,1.137,0,0,0,1.233-1.207,1.168,1.168,0,0,0-1.233-1.234,2.692,2.692,0,0,0-1.061.193V37.069h-.854v.2Zm.854,1.441a2.31,2.31,0,0,1,1.034-.2.963.963,0,0,1,1.034,1.047.906.906,0,0,1-1.034,1,.952.952,0,0,1-1.034-1.075v-.772Z"
                    transform="translate(-114.992 -11.917)"
                    fill="#231f20"
                />
                <path
                    id="Path_306"
                    data-name="Path 306"
                    d="M193.587,40.409h.22V38.473a1.694,1.694,0,0,1,.269-1.164,1.047,1.047,0,0,1,.834-.358.827.827,0,0,1,.813.462,2.194,2.194,0,0,1,.1.841v2.157h.214V38.473a1.694,1.694,0,0,1,.269-1.164,1.048,1.048,0,0,1,.834-.358.827.827,0,0,1,.813.462,2.194,2.194,0,0,1,.1.841v2.157h.221V38.032c0-.854-.386-1.282-1.13-1.282a1.232,1.232,0,0,0-1.164.73,1,1,0,0,0-1.061-.73,1.2,1.2,0,0,0-1.116.662v-.586h-.22v3.583Z"
                    transform="translate(-127.545 -11.674)"
                    fill="#231f20"
                />
                <path
                    id="Path_307"
                    data-name="Path 307"
                    d="M26.252,67.98h.767V64.835h3.707V67.98h.767V61.345h-.767V64.2H27.019V61.345h-.767V67.98Z"
                    transform="translate(0 -30.421)"
                    fill="#231f20"
                />
                <path
                    id="Path_308"
                    data-name="Path 308"
                    d="M57.23,64.964c0,1.342-1.2,1.93-2.416,1.93-.729,0-1.432-.384-1.432-1.189,0-.9.7-1.317,1.867-1.407a6.312,6.312,0,0,0,1.981-.37v1.035Zm-2.352-1.278c-1.547.2-2.314.882-2.314,1.994a1.969,1.969,0,0,0,2.212,1.907,3.252,3.252,0,0,0,2.467-1.1c.013.46.1.907.613.986a1.5,1.5,0,0,0,.345.039,2.041,2.041,0,0,0,.6-.13v-.6c-.652.23-.806.089-.806-.511V62.663c0-1.534-1.061-2.071-2.4-2.071-1.445,0-2.608.639-2.723,2.2h.78c.141-1.15.869-1.509,1.93-1.509,1.189,0,1.649.575,1.649,1.355,0,.23-.026.575-.281.691a3.861,3.861,0,0,1-1.01.217l-1.061.141Z"
                    transform="translate(-20.056 -29.846)"
                    fill="#231f20"
                />
                <path
                    id="Path_309"
                    data-name="Path 309"
                    d="M96.086,67.98h.767V62.445h.026l2.237,5.535h.793l2.186-5.535h.026V67.98h.767V61.345h-1.1l-2.263,5.714H99.5l-2.314-5.714h-1.1V67.98Z"
                    transform="translate(-53.228 -30.421)"
                    fill="#231f20"
                />
                <path
                    id="Path_310"
                    data-name="Path 310"
                    d="M132.2,64.964c0,1.342-1.2,1.93-2.416,1.93-.729,0-1.432-.384-1.432-1.189,0-.9.7-1.317,1.866-1.407a6.312,6.312,0,0,0,1.982-.37v1.035Zm-2.352-1.278c-1.547.2-2.314.882-2.314,1.994a1.969,1.969,0,0,0,2.212,1.907,3.25,3.25,0,0,0,2.467-1.1c.013.461.1.908.614.987a1.5,1.5,0,0,0,.345.039,2.038,2.038,0,0,0,.6-.13v-.6c-.652.23-.805.089-.805-.511V62.663c0-1.534-1.061-2.071-2.4-2.071-1.445,0-2.608.639-2.723,2.2h.78c.141-1.15.869-1.509,1.93-1.509,1.189,0,1.649.575,1.649,1.355,0,.23-.026.575-.281.691a3.874,3.874,0,0,1-1.01.217l-1.061.141Z"
                    transform="translate(-77.2 -29.846)"
                    fill="#231f20"
                />
                <path
                    id="Path_311"
                    data-name="Path 311"
                    d="M155.939,67.98h.767V62.445h.026l2.237,5.535h.793l2.186-5.535h.026V67.98h.767V61.345h-1.1l-2.263,5.714h-.026l-2.314-5.714h-1.1V67.98Z"
                    transform="translate(-98.85 -30.421)"
                    fill="#231f20"
                />
                <path
                    id="Path_312"
                    data-name="Path 312"
                    d="M191.753,64.964c0,1.342-1.2,1.93-2.416,1.93-.729,0-1.432-.384-1.432-1.189,0-.9.7-1.317,1.867-1.407a6.315,6.315,0,0,0,1.982-.37v1.035ZM189.4,63.686c-1.547.2-2.314.882-2.314,1.994a1.969,1.969,0,0,0,2.212,1.907,3.25,3.25,0,0,0,2.467-1.1c.013.461.1.908.613.987a1.5,1.5,0,0,0,.346.039,2.041,2.041,0,0,0,.6-.13v-.6c-.652.23-.806.089-.806-.511V62.663c0-1.534-1.061-2.071-2.4-2.071-1.445,0-2.608.639-2.723,2.2h.78c.141-1.15.869-1.509,1.93-1.509,1.189,0,1.65.575,1.65,1.355,0,.23-.026.575-.282.691a3.869,3.869,0,0,1-1.01.217l-1.061.141Z"
                    transform="translate(-122.59 -29.846)"
                    fill="#231f20"
                />
                <path
                    id="Path_313"
                    data-name="Path 313"
                    d="M186.807,98.159h.221v-1.28l.879-.858,1.419,2.138h.249l-1.516-2.291,1.308-1.308h-.27l-2.069,2.055V93.176h-.221v4.983Z"
                    transform="translate(-122.378 -54.683)"
                    fill="#231f20"
                />
                <path
                    id="Path_314"
                    data-name="Path 314"
                    d="M147.765,100.575a1.181,1.181,0,0,1-.36,1.045,1.593,1.593,0,0,1-1.024.353.791.791,0,0,1-.907-.831c0-.567.443-.768,1.363-.892.519-.062.831-.083.927-.214v.54Zm-2.519.6a.988.988,0,0,0,1.073,1.011,1.64,1.64,0,0,0,1.446-.7c0,.45.131.652.471.652.048,0,.111-.014.194-.02v-.181a.942.942,0,0,1-.131.014c-.277,0-.318-.18-.318-.623V99.572a1,1,0,0,0-1.2-1.156c-.83,0-1.343.422-1.37,1.086h.208c.034-.561.457-.892,1.183-.892.63,0,.969.27.969.754v.305c0,.352-.353.311-1.086.415-.969.138-1.432.415-1.432,1.094Z"
                    transform="translate(-90.699 -58.676)"
                    fill="#231f20"
                />
                <path
                    id="Path_315"
                    data-name="Path 315"
                    d="M98.53,102.6h.215V99H98.53v1.952a1.629,1.629,0,0,1-.291,1.183,1.174,1.174,0,0,1-.927.36c-.727,0-1.01-.36-1.01-1.156V99h-.214v2.408c0,.865.4,1.288,1.232,1.288a1.266,1.266,0,0,0,1.211-.659v.561Z"
                    transform="translate(-53.229 -59.119)"
                    fill="#231f20"
                />
                <path
                    id="Path_316"
                    data-name="Path 316"
                    d="M122.158,102.35h.221v-1.945a1.7,1.7,0,0,1,.27-1.17,1.054,1.054,0,0,1,.837-.36.83.83,0,0,1,.816.464,2.207,2.207,0,0,1,.1.844v2.166h.214v-1.945a1.7,1.7,0,0,1,.27-1.17,1.054,1.054,0,0,1,.837-.36.829.829,0,0,1,.816.464,2.2,2.2,0,0,1,.1.844v2.166h.221V99.962c0-.858-.388-1.287-1.135-1.287a1.238,1.238,0,0,0-1.169.734,1,1,0,0,0-1.066-.734,1.206,1.206,0,0,0-1.121.665v-.588h-.221v3.6Z"
                    transform="translate(-73.101 -58.874)"
                    fill="#231f20"
                />
                <path
                    id="Path_317"
                    data-name="Path 317"
                    d="M162.245,102.35h.221v-1.945a1.7,1.7,0,0,1,.27-1.17,1.053,1.053,0,0,1,.837-.36.83.83,0,0,1,.817.464,2.208,2.208,0,0,1,.1.844v2.166h.215v-1.945a1.7,1.7,0,0,1,.27-1.17,1.053,1.053,0,0,1,.837-.36.83.83,0,0,1,.816.464,2.212,2.212,0,0,1,.1.844v2.166h.221V99.962c0-.858-.388-1.287-1.135-1.287a1.237,1.237,0,0,0-1.169.734,1,1,0,0,0-1.066-.734,1.206,1.206,0,0,0-1.121.665v-.588h-.221v3.6Z"
                    transform="translate(-103.656 -58.874)"
                    fill="#231f20"
                />
                <path
                    id="Path_318"
                    data-name="Path 318"
                    d="M200.245,100.559c0-1.066.532-1.689,1.356-1.689s1.363.623,1.363,1.689-.54,1.7-1.363,1.7a1.4,1.4,0,0,1-.431-.065,1.539,1.539,0,0,1-.925-1.631Zm-.221,0c0,1.183.609,1.89,1.577,1.89s1.585-.707,1.585-1.89-.616-1.882-1.585-1.882-1.577.706-1.577,1.882Z"
                    transform="translate(-132.452 -58.875)"
                    fill="#231f20"
                />
            </g>
        </svg>
    );
};

export default LogoSmall;
