import type { SVGProps } from 'react';
export const SleepTrackerSleepIconIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 129 129"
    {...props}
  >
    <path
      fill="#FFF"
      d="M13.85 1.6h101c6.8 0 12.3 5.5 12.3 12.3v101c0 6.8-5.5 12.3-12.3 12.3h-101c-6.8 0-12.3-5.5-12.3-12.3v-101c0-6.8 5.5-12.3 12.3-12.3z"
    />
    <path
      fill="#87BA3B"
      d="M114.85 127.2h-101c-6.8 0-12.3-5.5-12.3-12.3v-101c0-6.8 5.5-12.3 12.3-12.3h101c6.8 0 12.3 5.5 12.3 12.3v101c0 6.8-5.5 12.3-12.3 12.3zM13.85 3.8c-5.6 0-10.1 4.5-10.1 10.1v101c0 5.6 4.5 10.1 10.1 10.1h101c5.6 0 10.1-4.5 10.1-10.1v-101c0-5.6-4.5-10.1-10.1-10.1h-101z"
    />
    <g fill="#F2F3C4">
      <path d="M110.5 53.8c-.3 29.9-21.2 33.6-46.9 33.4C37.9 86.9 17 82.3 17.3 53c.2-26.4 21.2-33.6 46.9-33.4 25.7.2 46.6 4.9 46.3 34.2z" />
      <ellipse
        cx={102.1}
        cy={34.5}
        rx={10.4}
        ry={7.6}
        transform="rotate(-14.85 102.055 34.477)"
      />
      <ellipse
        cx={100.4}
        cy={73.9}
        rx={7.6}
        ry={10.4}
        transform="rotate(-62.303 100.44 73.943)"
      />
      <ellipse
        cx={25}
        cy={32.8}
        rx={7.6}
        ry={10.4}
        transform="rotate(-74.181 25.02 32.813)"
      />
      <ellipse
        cx={26}
        cy={72.3}
        rx={10.4}
        ry={7.6}
        transform="rotate(-26.722 25.98 72.302)"
      />
    </g>
    <path
      fill="#EEEBB2"
      d="M17.7 35.5c-1.5-1.8-2-4.2-1.3-5 1-1.1 1.4 1.3 3 3 1.7 1.7 4 2.1 2.4 3.6-1.3 1.3-2.6.3-4.1-1.6z"
    />
    <path
      fill="#F9F7D0"
      d="M105.2 28c2.3.3 4.4 1.7 4.3 2.8 0 1.5-1.9 0-4.3-.2-2.4-.1-4.4 1.1-4.2-1.1.2-1.8 1.8-1.9 4.2-1.5z"
    />
    <path
      fill="#EEEBB2"
      d="M19.1 62.1c.6 9.7.4 5.2.4 5.2 1.4.6 10.1 16.4 12.7 13.9 0 0-6.6-11-8.8-23.3-2.2-12.3 4-21.2 1.5-20.3-6.9 2.2-6.4 14.2-5.8 24.5z"
    />
    <circle cx={21.6} cy={53.8} r={1.2} fill="#E8EC9E" />
    <g fill="#E8EC9E">
      <circle cx={34.2} cy={30.2} r={1.2} />
      <circle cx={21.3} cy={28.9} r={1.2} />
      <circle cx={26.2} cy={46.3} r={1.2} />
      <circle cx={50.2} cy={23.7} r={1.2} />
      <circle cx={29.8} cy={63.2} r={1.2} />
      <circle cx={32} cy={37} r={1.2} />
      <circle cx={82.9} cy={25.2} r={1.2} />
      <circle cx={93.5} cy={33.4} r={1.2} />
      <circle cx={104.7} cy={32.6} r={1.2} />
      <circle cx={100.6} cy={37.6} r={1.2} />
      <circle cx={99.3} cy={46.3} r={1.2} />
      <circle cx={103.4} cy={56} r={1.2} />
      <circle cx={97.5} cy={65.7} r={1.2} />
      <circle cx={101} cy={72.4} r={1.2} />
      <circle cx={93.9} cy={73.5} r={1.2} />
      <circle cx={27.7} cy={71.4} r={1.2} />
      <circle cx={33.8} cy={74} r={1.2} />
    </g>
    <path
      fill="#F9F7D0"
      d="M109 49.4c-.5-4.4-2.2-9-3.6-9.8-1.9-1.1.1 3.4.3 7.7.2 4.3-1.3 6.9 1.4 8.3 2.3 1.1 2.4-1.8 1.9-6.2z"
    />
    <path
      fill="#F2F3C4"
      d="M38.7 113.1c-3.8-.6-1.9-8.6-1-14.5 1-5.8 6.8-11.2 10.6-10.6 3.8.6 2 7.1 1 13-.8 5.9-6.7 12.8-10.6 12.1z"
    />
    <path
      fill="#EEEBB2"
      d="M85.8 73.4c-4.2.2-.8 4.6-.8 4.6-3 1.2-11-.1-11.3-2.9 0 0 5.9-2.4 9.8-5.4 3.9-3-1.3-33.5.6-32.2 2.7 2 6.5 7.6 7.3 11.2.8 4 4.2 7 1.7 10.2-2.3 2.9-4.1 8.6-5.7 10.2-4 3.8 7.4 4-1.6 4.3zM42.4 70c.7.6.5.7.1.7-1.6-.1-2.4 2.1-1.1 3.1 3 2.3 10.3 2.2 10.6.2 0 0-4.7-6.1-8.5-9.2-3.8-3.1 4-29.7 2.2-28.4-2.7 1.9-7.1 7.9-8.3 8.5-6 2.8-6 9.8-.1 13.5 3 1.9-1.4 6.1 5.1 11.6z"
    />
    <path
      fill="#E1E18B"
      d="M45 70.7c1.9 1.4-1.9 1.4-1.9 1.4-2.3 2.5 10.9 5 11.3 2.2 0 0-4.7-6.1-8.5-9.2-3.8-3.1 4-29.7 2.2-28.4-2.7 1.9-7.1 7.9-8.3 8.5-6 2.8-6.3 8.8-1.7 12.8 2 1.7 0 7.5 6.9 12.7zM82.8 71.7c-2 1.1 1.8 1.6 1.8 1.6 2.1 2.6-11.1 3.2-11.2.5 0 0 5.1-5.1 9.1-7.4 4-2.3-1.6-28.1.1-26.6 2.5 2.1 6.3 8.3 7.5 9 5.7 3.4 5.5 9 .6 12-2.3 1.4-.8 7-7.9 10.9z"
    />
    <path
      fill="#FDD7BB"
      d="M17.1 53.8c1.3-1.1 3 .8 4.3 3.4-.6-2-1.1-3-.1-3.4.9-.3 2.3.6 3.5 3.2.7 1.4 1.9 3.4 1.9 3.4 0-2.2 1-3.7 2.5-2.7 1 .7.1 1.8.9 5.7.5 2.4-.2 5.1-3.4 7.5-4.1 2.5-6.5.5-8.5-1.4-2.1-2.1-2-3.3-2.5-4.2-1-1.9-3.5-3.5-2.5-4.4.5-.5 2.3.1 3.8 1.3-.1-.2-3.8-4-2.7-5 .8-.7 3.3.8 4.5 2.4-1.5-3.1-2.7-4.9-1.7-5.8z"
    />
    <path
      fill="#E8B399"
      d="M26.5 65.8c.5.4-1.5-.9-1.4-1.7.2-1.9 1.2-3.3 1.2-3.3s-.7 2.5-.5 3.4c.1.4.5 1.4.7 1.6zM23.5 59.4c.5.1-1.3 0-1.5-.4-.2-.4-1.1-3.5-1.1-3.5s1 2.6 1.6 3.3c.2.3.8.5 1 .6z"
    />
    <path
      fill="#F2C2A8"
      d="M18.3 66.8c-.2.6-2.1-.8 0-3.1 2.2-2.4 5-3.6 5-3.6s-2.5 1.9-3.5 3.2c-.8 1.1-1.4 3.2-1.5 3.5z"
    />
    <path
      fill="#E8B399"
      d="M19.7 60.2s-.2.5-.4.2-1.2-2.6-1.2-2.6l1.6 2.4zM17.1 63.8c-.4.3.7-.4.6-.7-.2-.3-1.8-2.4-1.8-2.4s1.9 2.4 1.2 3.1z"
    />
    <path
      fill="#FDD7BB"
      d="M40.7 93.9c-1.5 1.2-8.9-4.2-13.3-11.5-4.5-7.3-11-16-1.5-16.7 5.5-.4 10.3 9.7 12.9 14.1 3 5.1 8 9.1 1.9 14.1zM109.8 55.6c-1.3-1.1-3 .9-4.2 3.4.6-1.9 1-3 .1-3.4-.9-.3-2.3.7-3.4 3.2-.6 1.4-1.8 3.4-1.9 3.4-.1-2.2-1.1-3.6-2.6-2.6-1 .7-.1 1.8-.8 5.7-.5 2.4.4 5.1 3.6 7.4 4.2 2.4 6.5.3 8.5-1.6 2.1-2.1 2-3.3 2.4-4.2 1-1.9 3.5-3.5 2.4-4.4-.6-.5-2.3.2-3.7 1.4.1-.2 3.7-4.1 2.5-5.1-.8-.6-3.3.8-4.4 2.5 1.3-3 2.5-4.8 1.5-5.7z"
    />
    <path
      fill="#E8B399"
      d="M100.6 67.7c-.5.4 1.5-1 1.4-1.7-.2-1.8-1.3-3.2-1.3-3.2s.8 2.5.6 3.3c-.1.4-.4 1.4-.7 1.6zM103.4 61.3c-.5.1 1.3 0 1.5-.4.2-.4 1.1-3.5 1.1-3.5s-1 2.6-1.5 3.3c-.3.3-.8.5-1.1.6z"
    />
    <path
      fill="#F2C2A8"
      d="M108.8 68.5c.2.6 2.1-.8-.1-3.1-2.2-2.4-5.1-3.5-5.1-3.5s2.5 1.8 3.5 3.1c1 1.1 1.6 3.2 1.7 3.5z"
    />
    <path
      fill="#E8B399"
      d="M107.3 62.1s.2.5.4.2 1.1-2.6 1.1-2.6l-1.5 2.4zM110 65.5c.4.3-.7-.4-.6-.7s1.7-2.4 1.7-2.4-1.9 2.5-1.1 3.1z"
    />
    <path
      fill="#FDD7BB"
      d="M87.1 95.9c1.5 1.2 8.8-4.4 13-11.7 4.3-7.4 10.6-16.1 1.1-16.6-5.6-.3-10 9.9-12.6 14.2-2.8 5.2-7.7 9.2-1.5 14.1z"
    />
    <ellipse
      cx={66.1}
      cy={82.2}
      fill="#80C094"
      rx={7.4}
      ry={23.7}
      transform="matrix(.00852 -1 1 .00852 -16.656 147.532)"
    />
    <path
      fill="#80C094"
      d="M80.9 99.9c-4.1-3-9.7-21.2-1.5-22.7 20.3-3.6 13.8-9.3 18.4-7 11.6 6.1 7.8 9.6-.6 19.9S85 103 80.9 99.9zM46.2 98.5c4.2-3 10.1-21 1.9-22.6-20.3-3.9-13.7-9.6-18.2-7.3-11.7 5.9-8 9.5.2 19.9s11.9 12.9 16.1 10z"
    />
    <path
      fill="#79B78B"
      d="M44.7 95.5c.9-2.5 1.2-5.5.8-6.5-.6-1.3-1.3 1.6-1.8 4.1-1 4.6-6.2 3-4.7 4.4 2.4 2.1 4.8.5 5.7-2z"
    />
    <path
      fill="#F3CCB3"
      d="M73 76.7c-.2 1.7-3.7 3.1-8.2 3.1-4.5 0-8.1-1.5-8.1-3.2 0-4.8 3.7-3.1 8.2-3.1 4.5 0 8.8-2.2 8.1 3.2z"
    />
    <ellipse
      cx={64.4}
      cy={53.2}
      fill="#FDD7BB"
      rx={23.6}
      ry={26.5}
      transform="rotate(-87.541 64.426 53.151) scale(1.00002)"
    />
    <ellipse
      cx={90.3}
      cy={54.3}
      fill="#FDD7BB"
      rx={5.7}
      ry={4.4}
      transform="rotate(-87.541 90.31 54.263) scale(1.00002)"
    />
    <path
      fill="#EB9"
      d="M65.1 55.3c.8 0 1.5.4 1.7 1 .1-.1.1-.3.1-.4 0-.8-.8-1.4-1.7-1.4-1 0-1.8.6-1.8 1.3 0 .1 0 .3.1.4.2-.5.8-.9 1.6-.9z"
    />
    <g fill="#E5AC97">
      <path d="M68.5 29.7c-1.2 1.6-2.9 2.3-4.3 2 .2.3.4.6.7.8 1.5 1.1 3.8.4 5.3-1.5 1.4-1.9 1.4-4.4-.1-5.5-.3-.2-.6-.3-.9-.4.7 1.1.5 3-.7 4.6z" />
      <path d="M65.8 30.4c-.7 1-1.8 1.4-2.7 1.2.1.2.3.4.4.5.9.7 2.4.2 3.3-1 .9-1.2.9-2.8-.1-3.5l-.6-.3c.5.9.4 2.1-.3 3.1z" />
    </g>
    <ellipse
      cx={38}
      cy={51.4}
      fill="#FDD7BB"
      rx={5.2}
      ry={4}
      transform="rotate(-87.541 38.008 51.396) scale(1.00002)"
    />
    <path
      fill="#C64D59"
      d="M65.2 65.6c-1.4 0-2.7-.8-2.7-1.2 0-.9.7-1.9 1.9-2.3.2-.1.5-.1.7-.1 1.9.1 2.7 1.5 2.7 2.6 0 .7-1.3 1-2.6 1z"
    />
    <path
      fill="#E87880"
      d="M65.2 64.5c-.9 0-1.8.2-2 .6.5.3 1.2.5 1.9.5.8 0 1.5-.1 2.1-.3-.2-.4-1-.8-2-.8z"
    />
    <path
      fill="#E8B491"
      d="M37.8 52c1.2 1 .6 1.9 0 1.9s-1.1-1-1.1-2.2c0-1.2.5-2.2 1.1-2.2.6.1-1 1.7 0 2.5zM91.2 54.7c-1.2.9-.6 1.9 0 1.9s1.1-1 1.1-2.2-.5-2.2-1.1-2.2c-.6.1.9 1.8 0 2.5z"
    />
    <g fill="#E5AC97">
      <path d="M52.8 43.7c-2 .5-4 2-3.9 2.6 0 .1.1.2.2.2h.1c.1 0 .3-.1.5-.3.4-.3 1.2-.9 2.5-1.2 1.1-.3 2.3-.2 3.2-.2 1 0 1.4 0 1.5-.3 0-.2 0-.4-.2-.5-.6-.6-2.3-.7-3.9-.3zM81 47.5c-.2-.6-1.6-2.1-3.3-2.9-1.5-.7-3.2-.9-3.8-.4-.2.1-.3.3-.3.5 0 .3.5.4 1.4.6.9.1 2 .3 3.1.8 1.2.5 1.9 1.2 2.2 1.6.2.2.3.4.5.4 0 0 .1 0 .2-.2.1-.1.1-.2 0-.4z" />
    </g>
    <path
      fill="#A47362"
      d="M54.7 53.6c-1.7-.1-3.5-1-3.5-1.5 0-.1.1-.2.1-.2h.1c.1 0 .2 0 .4.1.4.2 1.1.5 2.2.6.9 0 1.9-.2 2.6-.3.8-.2 1.2-.2 1.3 0 .1.1 0 .3-.1.4-.4.6-1.7 1-3.1.9zM78.4 52.9c-.4.4-1.9 1-3.5.9-1.4-.1-2.6-.6-2.9-1.1-.1-.2-.1-.3 0-.4.1-.2.5-.1 1.3.1.7.2 1.6.5 2.5.6 1 .1 1.8-.2 2.2-.4.2-.1.4-.1.5-.1 0 0 .1.1.1.2l-.2.2z"
    />
    <path
      fill="#F2F3C4"
      d="M75 123.8c-9.6-2-18.3 1.4-16.5-6.8 1.5-6.7 8.3-6.1 17.6-3.2 7.5 2.3 13.8 6.9 15.6 10.8h3.2c-1-3-1.8-6-2.1-9.2-2.9-32-7-44.7-18.4-41.2.5 1 .5.8.8 1.9 3.8 11.1-4.6 12.1-10.3 12.3-8 .2-11.9-5.1-10.3-12.4.2-1.1.3-.9.8-1.9-4.7-1.3-12.4 0-10.6 1.1 2.3 1.5 2 7.9 1.1 14.7-.5 3.5-1 11.1-5.4 10.1-2.9-.7-5.2 9.3-6.1 16.4-.3 2.8-.9 5.5-1.5 8.2h45c-1-.3-1.9-.6-2.9-.8zm8-14.7c-1 0-1.8-.8-1.8-1.8s.8-1.8 1.8-1.8 1.8.8 1.8 1.8-.8 1.8-1.8 1.8zm-.3-21.2c1 0 1.8.8 1.8 1.8s-.8 1.8-1.8 1.8-1.8-.8-1.8-1.8.8-1.8 1.8-1.8zm-10.4 8c1 0 1.8.8 1.8 1.8s-.8 1.8-1.8 1.8-1.8-.8-1.8-1.8.8-1.8 1.8-1.8zm-8.4 11.6c1 0 1.8.8 1.8 1.8s-.8 1.8-1.8 1.8-1.8-.8-1.8-1.8.8-1.8 1.8-1.8zM44 108.8c-1 0-1.8-.8-1.8-1.8s.8-1.8 1.8-1.8 1.8.8 1.8 1.8-.8 1.8-1.8 1.8zm5.1-23.1c0-1 .8-1.8 1.8-1.8s1.8.8 1.8 1.8-.8 1.8-1.8 1.8c-1.1 0-1.8-.8-1.8-1.8zm1.5 9.6c0-1 .8-1.8 1.8-1.8s1.8.8 1.8 1.8-.8 1.8-1.8 1.8-1.8-.8-1.8-1.8zm2.5 21.8c-1 0-1.8-.8-1.8-1.8s.8-1.8 1.8-1.8 1.8.8 1.8 1.8c0 1.1-.8 1.9-1.8 1.8z"
    />
    <path
      fill="#F9F7D0"
      d="M76.1 113.8c-9.3-2.8-16.1-3.4-17.6 3.2-1.9 8.2 6.9 4.8 16.5 6.8 1 .2 2 .5 2.9.8h4.8c.3-.5.9-.8 1.5-.8s1.1.3 1.5.8h6.1c-1.9-3.9-8.2-8.5-15.7-10.8zm-3.1 5.7c-1 0-1.8-.8-1.8-1.8s.8-1.8 1.8-1.8 1.8.8 1.8 1.8-.8 1.8-1.8 1.8z"
    />
    <path
      fill="#E8EC9E"
      d="M53.2 113.6c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8 1.8-.8 1.8-1.8c-.1-1-.9-1.8-1.8-1.8zM44 105.2c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8 1.8-.8 1.8-1.8-.8-1.8-1.8-1.8zM54.1 95.4c0-1-.8-1.8-1.8-1.8s-1.8.8-1.8 1.8.8 1.8 1.8 1.8 1.8-.8 1.8-1.8zM73.1 115.9c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8 1.8-.8 1.8-1.8c-.1-1-.9-1.8-1.8-1.8zM63.9 111.1c1 0 1.8-.8 1.8-1.8s-.8-1.8-1.8-1.8-1.8.8-1.8 1.8.8 1.8 1.8 1.8zM72.2 99.5c1 0 1.8-.8 1.8-1.8s-.8-1.8-1.8-1.8-1.8.8-1.8 1.8c.1.9.9 1.7 1.8 1.8zM84.2 123.8c-.6 0-1.2.3-1.5.8h2.9c-.3-.5-.8-.8-1.4-.8zM82.6 91.5c1 0 1.8-.8 1.8-1.8s-.8-1.8-1.8-1.8-1.8.8-1.8 1.8c.1 1 .9 1.8 1.8 1.8zM83 105.6c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8 1.8-.8 1.8-1.8-.8-1.8-1.8-1.8zM52.6 85.7c0-1-.8-1.8-1.8-1.8s-1.8.8-1.8 1.8.8 1.8 1.8 1.8 1.8-.8 1.8-1.8z"
    />
  </svg>
);
