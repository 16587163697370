import { CLEAR_REDUCERS, LOAD_TRACKERS } from '../actions/actionTypes';

const initialState = [];

const trackersReducer = (state = [...initialState], action) => {
  switch (action.type) {
    case LOAD_TRACKERS:
      state = [...action.payload];
      return [...state];
    case CLEAR_REDUCERS:
      state = [...initialState];
      return state;
    default:
      return state;
  }
};

export default trackersReducer;
