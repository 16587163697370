import React from 'react';
import { Container, Col } from 'reactstrap';
import i18n from '../../i18next';
import ReturnToHomeScreen from './ReturnToHomeScreen';
import { isAuthenticated } from '../../actions';

const NotFoundErrorScreen = () => (
    <Container className="themed-container">
        <Col
            sm={12}
            md={4}
            lg={4}
            className={isAuthenticated() ? 'centred fix-padding' : 'centred'}
        >
            <div className="centredText">
                <br />
                <h3>{i18n.t('globalErrors:notFound.title')}</h3>
                <hr />
                <br />
                <p>{i18n.t('globalErrors:notFound.notFoundText')}</p>
                <ReturnToHomeScreen />
            </div>
        </Col>
    </Container>
);

export default NotFoundErrorScreen;
