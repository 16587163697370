import { history } from '../index';
import {
  RELATIVE_PAGE_PATHS,
  SYSTEM_IMAGES_RELATIVE_URL,
  USER_IMAGES_RELATIVE_URL,
} from './constants';

const getRoute = () => history.location.pathname;

const isCurrentPage = (relativePageUrl) => {
  if (relativePageUrl === RELATIVE_PAGE_PATHS.HOME) {
    return getRoute() === RELATIVE_PAGE_PATHS.HOME;
  }
  return getRoute().includes(relativePageUrl);
};

const navigate = (relativeUrl, data) =>
  history.push({
    pathname: relativeUrl,
    search: data,
  });

const openUrlInNewTab = (url) => {
  // TODO: Remove the logging command
  console.log('URL to navigate to: ' + url);

  window.open(url, '_blank').focus();
};

const goBack = () => history.goBack();

const getSystemImageUrl = (fileName) =>
  fileName ? SYSTEM_IMAGES_RELATIVE_URL + fileName : fileName;

const getUserImageUrl = (fileName) => USER_IMAGES_RELATIVE_URL + fileName;

const navigationMethods = {
  getRoute,
  isCurrentPage,
  navigate,
  openUrlInNewTab,
  goBack,
  getSystemImageUrl,
  getUserImageUrl,
};

export default navigationMethods;
