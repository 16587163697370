import * as i18n from 'i18next';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Col } from 'reactstrap';
import { clearFields, getFormValues, reduxForm } from 'redux-form';
import {
    addStickerImage, closeModal, deleteStickerPhoto,
    getAddedSticker, showConfirmModal, showErrorModal
} from '../../actions';
import {
    isExistingSticker,
    isImageBelowMaxSize,
    isReadOnlyRoute,
    isSupportedImageFormat
} from '../../utils/commonUtils';
import {
    FORMS,
    RELATIVE_PAGE_PATHS,
    STICKER_FILE_SIZE_LIMIT_IN_MB
} from '../../utils/constants';
import { formUtils } from '../../utils/formUtils';
import navigationUtils from '../../utils/navigationUtils';
import { Fields } from '../common/Fields';
import MenuDots from '../common/MenuDots';
import Camera from '../common/svgComponents/GoalsAndTagsIcons/Camera';

class StickerSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            file: '',
            imagePreviewUrl: '',
            photoMenuShown: false,
        };

        this.readURL = this.readURL.bind(this); // properly bound once
        this.deletePhotoPressed = this.deletePhotoPressed.bind(this);
        this.uploader = React.createRef();
    }

    closeMenuItems() {
        if (this.state.photoMenuShown) {
            this.setState({ photoMenuShown: !this.state.photoMenuShown });
        }
    }

    selectMenuItems() {
        this.setState({ photoMenuShown: !this.state.photoMenuShown });
    }

    deletePhotoPressed() {
        this.props.closeModal();
        const { selectedAddedSticker } = this.props;
        const stickerId = selectedAddedSticker.id;
        this.uploader.current.value = '';
        if (stickerId) {
            if (selectedAddedSticker.photo === '') {
                this.setState({ imagePreviewUrl: '' });
            } else {
                this.props.deleteStickerPhoto(stickerId, () => {
                    this.props.reloadData(stickerId);
                    this.setState({ imagePreviewUrl: '' });
                });
            }
        } else {
            this.setState({ imagePreviewUrl: '' });
        }
    }

    changePhotoPressed() {
        const uploader = document.getElementById('uploader');

        if (uploader) {
            uploader.click();
        }
    }

    readURL(setField, event) {
        let reader = new FileReader();
        let file = event.target.files[0];

        if (file && file.size) {
            let maxSizeExceeded = isImageBelowMaxSize(file.size);

            if (!maxSizeExceeded) {
                const message = i18n.t('addStickerScreen:maxSizeExceeded', {
                    mb: STICKER_FILE_SIZE_LIMIT_IN_MB,
                });
                this.props.showErrorModal(message);
                return;
            }
        }

        if (file && file.type) {
            let isImageFormatSupported = isSupportedImageFormat(file.type);

            if (!isImageFormatSupported) {
                const message = i18n.t(
                    'addStickerScreen:imageFormatNotSupported'
                );
                this.props.showErrorModal(message);
                return;
            }
        }

        reader.onload = () => {
            this.setState({
                file: file,
                imagePreviewUrl: reader.result,
                photoMenuShown: false,
            });
        };

        reader.readAsDataURL(file);

        setField();

        const stickerId = this.props.selectedAddedSticker
            ? this.props.selectedAddedSticker.id
            : null;

        // For UPDATE
        if (stickerId) {
            this.props.addStickerImage(event, stickerId);
        }
    }

    selectMenuDots() {
        let { imagePreviewUrl } = this.state;
        const { borderColor, selectedAddedSticker } = this.props;

        const photoDotsStyle = {
            position: 'absolute',
            top: 8,
            right: 8,
            zIndex: 3,
            background: 'rgb(201 201 201 / 50%)',
            justifyContent: 'center',
            alignItems: 'center',
            height: 30,
            width: 30,
            borderRadius: '50%',
            display: isReadOnlyRoute()
                ? 'none'
                : imagePreviewUrl ||
                  (selectedAddedSticker && selectedAddedSticker.photo)
                ? 'flex'
                : 'none',
        };

        return (
            <div>
                <MenuDots
                    styles={photoDotsStyle}
                    onClick={() => this.selectMenuItems()}
                    backgroundColor={borderColor}
                />
            </div>
        );
    }

    renderMenuItems() {
        return this.state.photoMenuShown ? (
            <ul className="itemMenu" style={{ zIndex: 3 }}>
                <li
                    onClick={() => {
                        this.props.showConfirmModal({
                            message: i18n.t(
                                'addStickerScreen:confirmDeletePhoto'
                            ),
                            onConfirm: this.deletePhotoPressed,
                        });
                    }}
                >
                    {i18n.t('addStickerScreen:delete')}
                </li>
                <li
                    onClick={() => {
                        this.changePhotoPressed();
                    }}
                >
                    {i18n.t('addStickerScreen:change')}
                </li>
            </ul>
        ) : null;
    }

    render() {
        const {
            unActiveTextColor,
            borderColor,
            backgroundColor,
            expiredBackgroundColor,
            expiredBorderColor,
        } = this.props;
        const { user, selectedStickerTemplate, selectedAddedSticker } =
            this.props;
        let { imagePreviewUrl } = this.state;
        const formValues = { ...this.props.formValues };

        const enteredDate = formValues.entered;
        const isStickerExpired = selectedStickerTemplate.isExpired;

        const stickerSectionBackground = enteredDate
            ? { backgroundColor: backgroundColor }
            : selectedStickerTemplate.isExpired
            ? { backgroundColor: expiredBackgroundColor }
            : {};

        const stickerSectionColor = enteredDate
            ? { color: borderColor }
            : selectedStickerTemplate.isExpired
            ? { color: expiredBorderColor }
            : { color: unActiveTextColor };

        const stickerSectionBorder = enteredDate
            ? { border: `1px solid ${borderColor}` }
            : selectedStickerTemplate.isExpired
            ? { border: `1px solid ${expiredBorderColor}` }
            : { border: `1px solid ${unActiveTextColor}` };

        let uploadImgContent = imagePreviewUrl ? (
            <img alt="Preview" src={imagePreviewUrl} className="image-preview" />
        ) : (
            <Camera
                fill={
                    enteredDate
                        ? backgroundColor
                        : isStickerExpired
                        ? expiredBackgroundColor
                        : '#ebebeb'
                }
            />
        );

        if (selectedAddedSticker && selectedAddedSticker.photo) {
            const photoThumbnail = selectedAddedSticker.photoThumbnail;

            uploadImgContent = (
                <img
                    alt="Added sticker thumbnail"
                    src={navigationUtils.getUserImageUrl(photoThumbnail)}
                    onClick={() => {
                        navigationUtils.navigate(
                            RELATIVE_PAGE_PATHS.PHOTO_PREVIEW +
                                selectedAddedSticker.photo
                        );
                    }}
                    className="image-preview"
                />
            );
        }

        const inputClass =
            selectedAddedSticker && selectedAddedSticker.photo ? 'd-none' : '';

        if (this.props.loader) {
            return <Fragment />;
        }

        return (
            <Col
                xs={12}
                className="stickerSection flexColumnCentered centred ph-5p"
                style={stickerSectionBackground}
                onClick={(e) => this.closeMenuItems(e)}
            >
                <p className="childName" style={stickerSectionColor}>
                    {user.childName}
                </p>
                <div className="flexContainer w-100">
                    <div
                        className="stickersContainer mr-5p"
                        style={stickerSectionBorder}
                    >
                        <img
                            alt="sticker"
                            src={navigationUtils.getSystemImageUrl(
                                selectedStickerTemplate.image
                            )}
                        />
                    </div>

                    <div
                        className="stickersContainer photo-container-holder"
                        style={stickerSectionBorder}
                    >
                        {this.selectMenuDots()}
                        {this.renderMenuItems()}
                        {Fields.commonFields.uploadPhoto({
                            onChange: this.readURL,
                            className: inputClass,
                            ref: this.uploader,
                        })}
                        {uploadImgContent}
                    </div>
                </div>
                <div className="mt-2 mb-3">
                    {Fields.commonFields.userTextArea({
                        className: 'input-container userText',
                        rows: 5,
                        style: stickerSectionBorder,
                    })}
                </div>
            </Col>
        );
    }
}

const stickerForm = reduxForm({
    form: FORMS.STICKER_FORM,
    enableReinitialize: true,
    validate: formUtils.validateAddSticker,
})(StickerSection);

const mapStateToProps = (state) => {
    return {
        formValues: getFormValues(FORMS.STICKER_FORM)(state),
        selectedStickerTemplate: isExistingSticker()
            ? state.selectedSticker.selectedAddedSticker.stickerTemplate
            : state.selectedSticker.selectedStickerTemplate,
        user: state.authentication.userData || {},
        loader: state.loader,
    };
};

export default connect(mapStateToProps, {
    addStickerImage,
    showErrorModal,
    deleteStickerPhoto,
    getAddedSticker,
    showConfirmModal,
    clearFields,
    closeModal,
})(stickerForm);
