import { TRACKERS_SUB_TYPE } from '../../../utils/constants';
import { BREASTS, BUTTON_SIDES, TIME_OF_THE_DAY } from './models';

export const TrackingConfig = {
  [TRACKERS_SUB_TYPE.SLEEP]: {
    [BUTTON_SIDES.LEFT]: { value: TIME_OF_THE_DAY.DAY },
    [BUTTON_SIDES.RIGHT]: { value: TIME_OF_THE_DAY.NIGHT },
  },
  [TRACKERS_SUB_TYPE.BREAST_FEEDING]: {
    [BUTTON_SIDES.LEFT]: { value: BREASTS.LEFT },
    [BUTTON_SIDES.RIGHT]: { value: BREASTS.RIGHT },
  },
};

export const getAdditionalInfo = (tracker?: string, side?: string) => {
  if (tracker && side) {
    const trackerConfig = TrackingConfig[tracker];
    if (trackerConfig[side]) {
      return trackerConfig[side].value;
    }
  }
  return '';
};
