import React from 'react';
import { Col, Container } from 'reactstrap';

const ScreenContainer = ({ children }) => {
  return (
    <Container className="limited-width-journal mb-5 breastfeeding">
      <Col xs={12} className="centred noPadding journal">
        <div className="flexContainerColumn flexContainerCentered">
          {children}
        </div>
      </Col>
    </Container>
  );
};

export default ScreenContainer;
