import React from 'react';

const TimerIcon = ({ width, height }) => {
    const viewBoxValues = '0 0 52 52';

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox={viewBoxValues}
        >
            <defs>
                <filter
                    id="Квадрат_бял_със_сянка"
                    x="0"
                    y="0"
                    width="51.833"
                    height="51.833"
                    filterUnits="userSpaceOnUse"
                >
                    <feOffset dy="1" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="1" result="blur" />
                    <feFlood floodOpacity="0.161" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g
                id="Икона_-_Засичате"
                data-name="Икона - Засичате"
                transform="translate(-44.125 -398.811)"
            >
                <g
                    transform="matrix(1, 0, 0, 1, 44.12, 398.81)"
                    filter="url(#Квадрат_бял_със_сянка)"
                >
                    <g
                        id="Квадрат_бял_със_сянка-2"
                        data-name="Квадрат бял със сянка"
                        transform="translate(3 2)"
                        fill="#fff"
                        stroke="#cacaca"
                        strokeWidth="1"
                    >
                        <rect
                            width="45.833"
                            height="45.833"
                            rx="6"
                            stroke="none"
                        />
                        <rect
                            x="0.5"
                            y="0.5"
                            width="44.833"
                            height="44.833"
                            rx="5.5"
                            fill="none"
                        />
                    </g>
                </g>
                <g
                    id="Икона_-_Измери"
                    data-name="Икона - Измери"
                    transform="translate(59.879 410.297)"
                >
                    <path
                        id="Path_435"
                        data-name="Path 435"
                        d="M53.283,45.895a2.017,2.017,0,0,1-1.941-1.171,1.911,1.911,0,0,1,.4-2.126.663.663,0,0,0,.208-.467c.066-1.229.141-2.458.211-3.686a.95.95,0,0,1,.589-.884.928.928,0,0,1,.991.128,1,1,0,0,1,.375.789c.065,1.2.14,2.392.2,3.588a.819.819,0,0,0,.233.558,1.829,1.829,0,0,1,.366,2.1A1.851,1.851,0,0,1,53.283,45.895Z"
                        transform="translate(-42.638 -30.564)"
                        fill="#87ba3b"
                    />
                    <g
                        id="Group_260"
                        data-name="Group 260"
                        transform="translate(0 3.306)"
                    >
                        <path
                            id="Path_436"
                            data-name="Path 436"
                            d="M37.987,48.48a10.5,10.5,0,1,1,10.5-10.5A10.512,10.512,0,0,1,37.987,48.48Zm0-19.2a8.7,8.7,0,1,0,8.7,8.7A8.707,8.707,0,0,0,37.987,29.284Z"
                            transform="translate(-27.487 -27.481)"
                            fill="#87ba3b"
                        />
                    </g>
                    <rect
                        id="Rectangle_1048"
                        data-name="Rectangle 1048"
                        width="6.309"
                        height="2.75"
                        rx="1.375"
                        transform="translate(7.345 0)"
                        fill="#87ba3b"
                    />
                    <rect
                        id="Rectangle_1049"
                        data-name="Rectangle 1049"
                        width="3.282"
                        height="2.554"
                        rx="1.277"
                        transform="matrix(0.817, 0.577, -0.577, 0.817, 17.847, 2.218)"
                        fill="#87ba3b"
                    />
                </g>
            </g>
        </svg>
    );
};

export default TimerIcon;
