import React from 'react';

const MenuDots = ({ onClick, backgroundColor, styles }) => {
    return (
        <div
            className="menuDots"
            onClick={onClick}
            style={styles ? styles : null}
        >
            <div style={{ backgroundColor }} />
            <div style={{ backgroundColor }} />
            <div style={{ backgroundColor }} />
        </div>
    );
};

export default MenuDots;
