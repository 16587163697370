import * as i18n from 'i18next';
import { clearReducers, startLoader, stopLoader } from '.';
import { store } from '../index';
import appUtils from '../utils/appUtils';
import {
    GOALS, PAID_FUNCTIONALITY_CREDENTIALS_IN_LOCAL_STORAGE, RELATIVE_PAGE_PATHS, SELECTED_GOAL_IN_LOCAL_STORAGE, TOKEN_IN_LOCAL_STORAGE,
    USER_DATA_IN_LOCAL_STORAGE
} from '../utils/constants';
import navigationUtils from '../utils/navigationUtils';
import { getAxiosWithoutToken, getAxiosWithToken } from '../utils/webApi';
import {
    CLEAR_REDUCERS,
    EDIT_PROFILE, LOCAL_STORAGE_DATA_LOADED,
    LOGIN_ATTEMPT,
    LOGOUT, PASSWORD_CHANGED, SELECT_GOAL_SUCCESS
} from './actionTypes';
import { closeModal, showSuccessModal } from './commonActions';

export const loadLocalStorageData = () => async (dispatch) => {
    const token = localStorage.getItem(TOKEN_IN_LOCAL_STORAGE);
    const userDataInJSON = localStorage.getItem(USER_DATA_IN_LOCAL_STORAGE);
    const paidFunctionalityCredentialsInJSON = localStorage.getItem(
        PAID_FUNCTIONALITY_CREDENTIALS_IN_LOCAL_STORAGE
    );
    const paidFunctionalityCredentials =
        paidFunctionalityCredentialsInJSON != null
            ? JSON.parse(paidFunctionalityCredentialsInJSON)
            : null;
    const userData = userDataInJSON != null ? JSON.parse(userDataInJSON) : null;

    const selectedGoal =
        JSON.parse(localStorage.getItem(SELECTED_GOAL_IN_LOCAL_STORAGE)) || {};

    if (token) {
        dispatch({
            type: LOCAL_STORAGE_DATA_LOADED,
            payload: {
                userData,
                paidFunctionalityCredentials,
                token,
            },
        });

        dispatch({ type: SELECT_GOAL_SUCCESS, payload: selectedGoal });

        // dispatch({ type: SELECT_TAG_SUCCESS, payload: selectedTag });
    }
};

export const setTokenInLocalStorage = (token) =>
    localStorage.setItem(TOKEN_IN_LOCAL_STORAGE, token);

export const isTokenInLocalStorage = () => {
    return localStorage.getItem(TOKEN_IN_LOCAL_STORAGE) != null;
};

export const setUserDataInLocalStorage = (currentUser) =>
    localStorage.setItem(
        USER_DATA_IN_LOCAL_STORAGE,
        JSON.stringify(currentUser)
    );

export const setPaidFunctionalityCredentialsInLocalStorage = (credentials) =>
    localStorage.setItem(
        PAID_FUNCTIONALITY_CREDENTIALS_IN_LOCAL_STORAGE,
        JSON.stringify(credentials)
    );

export const clearLocalStorageData = () => {
    localStorage.removeItem(USER_DATA_IN_LOCAL_STORAGE);
    localStorage.removeItem(TOKEN_IN_LOCAL_STORAGE);
    localStorage.removeItem(GOALS);

    // localStorage.clear();

    // IMPORTANT: making sure to send action type that will immediately remove the token value
    // in the global cache (Redux store). By doing this, we ensure that there will
    // be no weird case when we send a request to the server with the old token
    // after the user has re-logged in and received a new token
    // Additionally, we are clearing the reducers, so there are no race conditions which will
    // cause the use of "stale" data from the previously logged-in account
    // after re-login with different account
    return [
        {
            type: LOCAL_STORAGE_DATA_LOADED,
            payload: null,
        },
        {
            type: CLEAR_REDUCERS,
        },
    ];
};

export const isAuthenticated = () =>
    store.getState().authentication.token != null;

export const activateProfile = (token) => async (dispatch) => {
    dispatch(startLoader());

    const response = await getAxiosWithoutToken().post('user/activate', {
        userActionTokenText: token,
    });

    dispatch(stopLoader());

    const returnedData = response.data;
    if (returnedData.success) {
        const message = i18n.t('activateProfileScreen:successMessage');
        dispatch(showSuccessModal(message));
    }

    // Navigating to Login page regardless of the outcome of the request - success or fail
    navigationUtils.navigate(RELATIVE_PAGE_PATHS.LOGIN);
};

export const requestPasswordReset = (userEmail) => async (dispatch) => {
    dispatch(startLoader());

    const response = await getAxiosWithoutToken().post(
        'user/request-password-reset',
        { userEmail }
    );

    dispatch(stopLoader());

    const returnedData = response.data;
    if (returnedData.success) {
        const message = i18n.t('requestPasswordResetScreen:successMessage');
        dispatch(showSuccessModal(message));

        navigationUtils.navigate(RELATIVE_PAGE_PATHS.LOGIN);
    }
};

export const resetPassword =
    (newPassword, userActionTokenText) => async (dispatch) => {
        const data = { newPassword, userActionTokenText };

        dispatch(startLoader());

        const response = await getAxiosWithoutToken().post(
            'user/reset-password',
            data
        );

        dispatch(stopLoader());

        const returnedData = response.data;
        if (returnedData.success) {
            const message = i18n.t('resetPasswordScreen:successMessage');
            dispatch(showSuccessModal(message));

            navigationUtils.navigate(RELATIVE_PAGE_PATHS.LOGIN);
        }
    };

export const login = (email, password) => async (dispatch) => {
    const data = { email, password };

    dispatch(startLoader());

    const response = await getAxiosWithoutToken().post('user/login', data);

    dispatch(stopLoader());

    const returnedData = response.data;

    if (returnedData.success) {
        setTokenInLocalStorage(returnedData.data.token.value);
        setUserDataInLocalStorage(returnedData.data.user);

        dispatch({
            type: LOGIN_ATTEMPT,
            payload: returnedData.data,
        });

        navigationUtils.navigate(RELATIVE_PAGE_PATHS.HOME);
    }
};

export const editProfile =
    (data, userId, isChildBorn4WeeksBeforeDueDate) => async (dispatch) => {
        data.childDateOfBirth = appUtils.convertDateToBackendFormat(
            data.childDateOfBirth
        );

        dispatch(startLoader());

        const response = await getAxiosWithToken().put('user/' + userId, data);

        dispatch(stopLoader());

        const returnedData = response.data;
        if (returnedData.success) {
            setUserDataInLocalStorage(returnedData.data);

            let message;

            if (isChildBorn4WeeksBeforeDueDate) {
                message =
                    i18n.t('profileScreen:successfulEditedUser') +
                    '\n' +
                    i18n.t('profileScreen:birthInfoCalculation');
            } else {
                message = i18n.t('profileScreen:successfulEditedUser');
            }
            dispatch(showSuccessModal(message));

            dispatch({
                type: EDIT_PROFILE,
                payload: returnedData.data,
            });
        }
    };

export const deleteProfile = (id) => async (dispatch) => {
    dispatch(closeModal());
    dispatch(startLoader());

    const response = await getAxiosWithToken().delete('user/' + id, {
        data: { id },
    });

    dispatch(stopLoader());

    const returnedData = response.data;

    if (returnedData.success) {
        logoutToSpecificPage(RELATIVE_PAGE_PATHS.HOME, dispatch);
        dispatch(
            showSuccessModal(i18n.t('deleteProfileScreen:successfulDelete'))
        );
    }
};

export const changePassword =
    (oldPassword, newPassword) => async (dispatch) => {
        const data = { oldPassword, newPassword };

        dispatch(startLoader());

        const response = await getAxiosWithToken().post(
            'user/change-password',
            data
        );

        dispatch(stopLoader());

        const returnedData = response.data;
        if (returnedData.success) {
            var token = returnedData.data.token;

            setTokenInLocalStorage(token.value);

            dispatch({
                type: PASSWORD_CHANGED,
                payload: token,
            });

            const message = i18n.t('changePasswordScreen:successMessage');
            dispatch(showSuccessModal(message));

            navigationUtils.navigate(RELATIVE_PAGE_PATHS.PROFILE);
        }
    };

export const forceLogout = () => async (dispatch) =>
    logoutToSpecificPage(RELATIVE_PAGE_PATHS.LOGIN, dispatch);

export const logout = () => async (dispatch) =>
    logoutToSpecificPage(RELATIVE_PAGE_PATHS.HOME, dispatch);

const logoutToSpecificPage = (relativePageUrl, dispatch) => {
    clearLocalStorageData();

    dispatch({ type: LOGOUT });
    dispatch(clearReducers());

    navigationUtils.navigate(relativePageUrl);
};
