import {
  CLEAR_REDUCERS,
  EXPIRED_STICKERS_EXIST,
  GET_EXPIRED_STICKERS,
} from '../../actions/actionTypes';

const initialState = {
  expiredStickersExist: false,
  expiredStickerList: [],
};

const expiredStickersReducer = (state = initialState, action) => {
  const data = action.payload;
  switch (action.type) {
    case EXPIRED_STICKERS_EXIST:
      return {
        ...state,
        expiredStickersExist: data,
      };

    case GET_EXPIRED_STICKERS:
      return {
        ...state,
        expiredStickerList: data,
      };

    case CLEAR_REDUCERS:
      // We make sure we DON'T clear the value indicating if expired stickers exist
      // or not. Otherwise, we'll deal with a bug showing the light bulb in
      // the navigation bar as green when it should be yellow.
      const expiredStickersExist = state.expiredStickersExist;
      return {
        ...initialState,
        expiredStickersExist,
      };

    default:
      return state;
  }
};

export default expiredStickersReducer;
