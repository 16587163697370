import React, { Component } from 'react';
import { Col, Container } from 'reactstrap';
import * as i18n from 'i18next';

import LocalNavigationComponent from '../LocalNavigationComponent';
import InfoScreen from './InfoScreen';
import CredentialsScreen from './CredentialsScreen';
import { connect } from 'react-redux';
import { FORMS, USER_REGISTRATION_DISABLED } from '../../utils/constants';
import { getFormValues } from 'redux-form';
import FamilyScreen from './FamilyScreen';
import ChildInfoScreen from './ChildInfoScreen';
import ChildBirthScreen from './ChildBirthScreen';
import TermsAndConditionsScreen from './TermsAndConditionsScreen';
import {
    navigateToHomeScreen,
    registerUser,
    showErrorModal,
    showInfoModal,
} from '../../actions';
import { scrollToTop } from '../../utils/commonUtils';
import navigationMethods from '../../utils/navigationUtils';

class RegisterScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            childBornInfoShowed: false,
            captchaValue: null,
        };
    }

    componentDidMount() {
        if (USER_REGISTRATION_DISABLED) {
            this.props.showInfoModal(
                i18n.t('registerScreen:disabledRegistration')
            );
            navigationMethods.goBack();
        }
        scrollToTop();
    }

    onSubmit = () => {
        const formValues = { ...this.props.formValues };
        formValues.isChildGirl = !!formValues.isChildGirl;

        if (this.state.captchaValue === null && this.state.page === 6) {
            const captchaError = i18n.t('registerScreen:captchaError');
            this.props.showErrorModal(captchaError);
        } else {
            this.props.registerUser(formValues);
        }
    };

    nextPage = () => {
        const formValues = { ...this.props.formValues };

        if (
            formValues.isChildBorn4WeeksBeforeDueDate &&
            !this.state.childBornInfoShowed &&
            this.state.page === 5
        ) {
            const message = i18n.t(
                'registerScreen:childBirthScreen.birthInfoCalculation'
            );
            this.props.showInfoModal(message);
            this.setState({ childBornInfoShowed: true });
        } else {
            this.setState({ page: this.state.page + 1 });
        }
    };

    previousPage = () => {
        const formValues = { ...this.props.formValues };

        this.setState({ page: this.state.page - 1 });

        if (!formValues.isChildBorn4WeeksBeforeDueDate) {
            this.setState({ childBornInfoShowed: false });
        }
    };

    validateCaptcha = (value) => {
        this.setState({ captchaValue: value });
    };

    renderInfoScreen = () => {
        if (this.state.page === 1) {
            return (
                <InfoScreen
                    onSubmit={this.nextPage}
                    currentPage={this.state.page}
                />
            );
        }
    };

    renderCredentialsScreen = () => {
        if (this.state.page === 2) {
            return (
                <CredentialsScreen
                    previousPage={this.previousPage}
                    onSubmit={this.nextPage}
                    currentPage={this.state.page}
                />
            );
        }
    };

    renderFamilyScreen = () => {
        if (this.state.page === 3) {
            return (
                <FamilyScreen
                    previousPage={this.previousPage}
                    onSubmit={this.nextPage}
                    currentPage={this.state.page}
                />
            );
        }
    };

    renderChildInfoScreen = () => {
        if (this.state.page === 4) {
            return (
                <ChildInfoScreen
                    previousPage={this.previousPage}
                    onSubmit={this.nextPage}
                    currentPage={this.state.page}
                />
            );
        }
    };

    renderChildBirthScreen = () => {
        if (this.state.page === 5) {
            return (
                <ChildBirthScreen
                    previousPage={this.previousPage}
                    onSubmit={this.nextPage}
                    currentPage={this.state.page}
                />
            );
        }
    };

    renderTermsAndConditionsScreen = () => {
        if (this.state.page === 6) {
            return (
                <TermsAndConditionsScreen
                    previousPage={this.previousPage}
                    onSubmit={this.onSubmit}
                    currentPage={this.state.page}
                    captchaValue={this.validateCaptcha}
                />
            );
        }
    };

    render() {
        let screenHeader =
            this.state.page !== 1
                ? i18n.t('registerScreen:headerText.navigationHeader')
                : i18n.t('registerScreen:headerText.welcome');

        return (
            <Container className="noPadding">
                <Col
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    className="centred noPadding"
                >
                    <LocalNavigationComponent
                        header={screenHeader}
                        previousPage={
                            this.state.page !== 1
                                ? this.previousPage
                                : this.props.navigateToHomeScreen
                        }
                    />
                    <div>
                        {this.renderInfoScreen()}
                        {this.renderCredentialsScreen()}
                        {this.renderFamilyScreen()}
                        {this.renderChildInfoScreen()}
                        {this.renderChildBirthScreen()}
                        {this.renderTermsAndConditionsScreen()}
                    </div>
                </Col>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        formValues: getFormValues(FORMS.REGISTER_FORM)(state),
        modal: state.modal,
    };
};

export default connect(mapStateToProps, {
    registerUser,
    showInfoModal,
    navigateToHomeScreen,
    showErrorModal,
})(RegisterScreen);
