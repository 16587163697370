import React from 'react';

const BackButtonSVG = ({ width, height, svgClicked, color, className }) => {
    const viewBoxValues = '0 0 7 13';
    const svgColor = color ? color : '#4b4b4b';

    return (
        <svg
            className={className}
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox={viewBoxValues}
            onClick={svgClicked ? () => svgClicked() : null}
        >
            <g id="Стрелка_назад_-_сиво_поле_горе" fill={svgColor}>
                <path
                    className="st0"
                    d="M6.3,12.8c-0.1,0-0.3,0-0.4-0.1L0.1,6.9C0,6.7,0,6.4,0.1,6.2l5.8-5.8c0.2-0.2,0.5-0.2,0.7,0
		c0.2,0.2,0.2,0.5,0,0.7L1.2,6.5l5.4,5.4c0.2,0.2,0.2,0.5,0,0.7C6.5,12.7,6.4,12.8,6.3,12.8z"
                />
            </g>
        </svg>
    );
};

export default BackButtonSVG;
