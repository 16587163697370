import { createContext, useContext, useRef } from 'react';

// export interface AdContextType {
//   createAdSlot: (id: string, path: string, size: googletag.GeneralSize) => void;
//   display: (path: string) => void;
//   destroySlot: (path: string) => void;
//   initialize: () => void;
// }

const defaultValues = {
  initialize: () => {},
  createAdSlot: (id, path, size) => {},
  display: (path) => {},
  destroySlot: (path) => {},
};

const AdContext = createContext(defaultValues);

const onImpressionViewable = (event) => {
  // console.log('Impression Viewable');
};

export const AdProvider = ({ children }) => {
  const registeredSlots = useRef({});
  const initilized = useRef(false);
  const initialize = () => {
    if (initilized.current) return;
    initilized.current = true;
    window.googletag = window.googletag || { cmd: [] };
    const googletag = window.googletag;
    googletag.cmd.push(() => {
      const pubads = googletag.pubads();
      pubads.collapseEmptyDivs();
      pubads.disableInitialLoad();
      pubads.addEventListener('impressionViewable', onImpressionViewable);
      googletag.enableServices();
    });
  };

  const createAdSlot = (id, path, size) => {
    window.googletag = window.googletag || { cmd: [] };
    const googletag = window.googletag;

    if (!registeredSlots.current[path]) {
      googletag.cmd.push(() => {
        const slot = googletag.defineSlot(path, size, id);
        slot && slot.addService(googletag.pubads());
        registeredSlots.current[path] = slot;
      });
    }
  };

  const display = (path) => {
    window.googletag = window.googletag || { cmd: [] };
    const googletag = window.googletag;
    const slot = registeredSlots.current[path];
    if (slot) {
      googletag.cmd.push(function () {
        const slotsToRefresh = [];
        slotsToRefresh.push(slot);
        const pubads = googletag.pubads();
        pubads.refresh([slot]);
      });
    }
  };

  const destroy = (path) => {
    window.googletag = window.googletag || { cmd: [] };
    const googletag = window.googletag;
    let slot = registeredSlots.current[path];
    delete registeredSlots.current[path];
    if (slot) {
      const tmp = slot;
      googletag.cmd.push(() => {
        googletag.destroySlots([tmp]);
      });
    }
  };

  return (
    <AdContext.Provider
      value={{ createAdSlot, display, initialize, destroySlot: destroy }}
    >
      {children}
    </AdContext.Provider>
  );
};

export const useAdManager = () => {
  const context = useContext(AdContext);
  context.initialize();
  return context;
};
