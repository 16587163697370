import {
  CLEAR_REDUCERS,
  SELECT_GOAL_SUCCESS,
  CLEAR_SELECTED_GOAL,
} from '../../actions/actionTypes';

const initialState = {};

const selectedGoalReducer = (state = initialState, action) => {
  let updatedState = {};
  switch (action.type) {
    case SELECT_GOAL_SUCCESS:
      updatedState = { ...initialState };
      updatedState = action.payload;

      return updatedState;
    case CLEAR_SELECTED_GOAL:
      updatedState = { ...initialState };
      return updatedState;
    case CLEAR_REDUCERS:
      updatedState = { ...initialState };
      return updatedState;
    default:
      return state;
  }
};

export default selectedGoalReducer;
