import { CLEAR_REDUCERS, SELECT_TRACKER } from '../actions/actionTypes';

const initialState = null;

const trackersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_TRACKER:
      if (action.payload) {
        return { ...action.payload };
      }
      return initialState;
    case CLEAR_REDUCERS:
      const newState = initialState;
      return newState;
    default:
      return state;
  }
};

export default trackersReducer;
