import { SvgIcon } from '@mui/material';
import React from 'react';

const PoopDiaperLow = (props) => (
  <SvgIcon {...props} viewBox="0 0 128 128">
    <path
      d="M71.85,35.31s-6.19-1.41-11.46-2.99-6.32-3.16-10.14-1.98-8.56,6.32-7.9,9.62,7.65,5.95,7.65,5.95l22.67-10.62-.81,.02Z"
      fill="#c5e27f"
    />
    <path
      d="M12.23,60.98c-.22-2.74-1.08-3.57,.27-5.04s5.42-2.5,7.46-1.48,2.69,4.78,2.69,4.78l-9.36,8.7s-.84-4.22-1.06-6.96Z"
      fill="#c5e27f"
    />
    <path
      d="M49.99,97.67s2.06-.25,5.4,0,5.36,1.22,7.7,.43,5.12-3.89,4.63-5.77-4.9-3.2-4.9-3.2l-13.73,6.78,.91,1.76Z"
      fill="#c5e27f"
    />
    <path
      d="M91.1,68.03s11.44,2.37,14.87,1.91,10.54-5.07,12.13-9.95-1.71-6.59-7.25-8.96-14.07-7.54-14.07-7.54l-5.68,24.53Z"
      fill="#c5e27f"
    />
    <path
      d="M69.02,35.55s-9.62,2.63-17.52,9.09-9.35,10.93-15.81,12.65-13.42,.4-17.51,0-3.83,2.11-4.69,2.83c-2.84,2.41,2.5,10.41,3.82,12.78s11.13,9.95,19.83,15.74,15.07,7.61,18.57,6.85c4.54-.99,6.32-6.19,10.93-11.46s7.9-8.17,16.73-11.99,15.46-9.14,17.92-12.91c2.7-4.15,2.83-9.55-1.19-13.04-4.71-4.09-16.99-12.38-31.09-10.54Z"
      fill="#ebf9ca"
    />
    <path
      d="M15.29,57.34s-.09,.8,1.23,3.83,6.46,10.28,14.89,15.94c6.28,4.22,12.78,8.17,19.37,10.54,5.21,1.88,12.12,1.3,12.12,1.3,0,0-1.28,4.84-5.4,6.74-4.72,2.16-12.76,4.92-26.35-5.8s-19.91-20.58-18.84-27.4c.92-5.84,2.98-5.15,2.98-5.15Z"
      fill="#c5e27f"
    />
    <ellipse cx="74.56" cy="36.21" rx="3.62" ry="2.04" fill="#ebf9ca" />
    <ellipse
      cx="81.09"
      cy="37.25"
      rx="2.04"
      ry="3.05"
      transform="translate(38.81 115.83) rotate(-86.39)"
      fill="#ebf9ca"
    />
    <ellipse
      cx="86.74"
      cy="39.37"
      rx="2.9"
      ry="2.04"
      transform="translate(-4.02 10.44) rotate(-6.73)"
      fill="#ebf9ca"
    />
    <ellipse
      cx="92.73"
      cy="41.9"
      rx="2.04"
      ry="3.1"
      transform="translate(40.59 129.28) rotate(-83.49)"
      fill="#ebf9ca"
    />
    <path
      d="M76.86,34.7s.57,2.69,.46,3.52,.85-1.32,1.82-1.96,.75-.91,.75-.91l-1.68,.28c-.69-.8-1.35-.93-1.35-.93Z"
      fill="#dfeda6"
    />
    <path
      d="M83.45,36.01s-.09,2.79-.38,3.58,1.11-1.1,2.2-1.53,.93-.73,.93-.73l-1.71-.08c-.38-.71-1.04-1.24-1.04-1.24Z"
      fill="#dfeda6"
    />
    <path
      d="M89.38,38.38s-.26,2.74-.59,3.51,1.18-1.03,2.29-1.39,.97-.67,.97-.67l-1.7-.19c-.31-.73-.96-1.26-.96-1.26Z"
      fill="#dfeda6"
    />
    <path
      d="M95.74,41.55s-1.49,2.49-2.1,3.06,1.49-.48,2.65-.37,1.12-.2,1.12-.2l-1.45-1.19c-.17-.54-.22-1.31-.22-1.31Z"
      fill="#dfeda6"
    />
    <ellipse
      cx="97.61"
      cy="45.98"
      rx="2.04"
      ry="3.1"
      transform="translate(40.85 137.74) rotate(-83.49)"
      fill="#ebf9ca"
    />
    <path
      d="M100.71,45.7s-1.18,1.98-1.68,2.38,1.24-.14,2.21,.19,.94,.05,.94,.05l-1.25-1.37c-.16-.53-.22-1.24-.22-1.24Z"
      fill="#dfeda6"
    />
    <path
      d="M53.76,53.36c-6.42,4.94-11.09,10.14-11.51,9.59s4.04-6.01,10.46-10.95c6.42-4.94,12.38-7.48,12.8-6.93s-5.33,3.35-11.76,8.29Z"
      fill="#c5e27f"
    />
    <path
      d="M69.96,65.74c-6.42,4.94-11.09,10.14-11.51,9.59s4.04-6.01,10.46-10.95c6.42-4.94,12.38-7.48,12.8-6.93s-5.33,3.35-11.76,8.29Z"
      fill="#c5e27f"
    />
    <g>
      <path
        d="M66.34,59.4c-.28,.09-.72,.35-.91-.1-.21-.51,.31-.51,.58-.68,.36-.23,.74-.37,1.19-.25,.12,.03,.27,.07,.3,.25,.03,.15-.1,.2-.18,.25-.31,.18-.64,.35-.97,.53Z"
        fill="#f2c86b"
      />
      <path
        d="M67.61,56.21c-.36-.14-.74-.22-1.1-.33-.37-.12-.69-.02-.99,.17-.58,.37-1.07,.89-1.71,1.17-.45,.2-.65,.69-.52,1.33-.04,.35,.21,.43,.4,.5,.43,.15,.88,.18,1.23-.06,.89-.63,1.84-1.11,2.87-1.43,.29-.09,.58-.26,.54-.72-.1-.44-.44-.51-.72-.62Zm-.74,.35c0,.13-.08,.16-.17,.16-.73,.03-1.29,.44-1.87,.84-.09,.06-.18,.1-.28,.13-.09,.03-.2,.12-.29-.02-.08-.13,0-.25,.08-.31,.45-.35,.82-.84,1.47-1.09,.21-.13,.54-.08,.88,.08,.09,.04,.18,.08,.18,.21Z"
        fill="#e5c676"
      />
      <path
        d="M57.49,59.26c.42-.84,.84-1.48,1.61-1.71,.08-.02,.18-.07,.24,.06,.07,.14-.01,.23-.1,.28-.1,.06-.21,.13-.33,.15-.45,.08-.54,.63-.85,.88-.14,.11-.25,.29-.57,.34Z"
        fill="#ffd797"
      />
      <path
        d="M63.48,55.12c-.54,.11-.85,.65-1.38,.78,.21-.46,.66-.6,.99-.89,.11-.1,.27-.03,.39,.11Z"
        fill="#f7cf8f"
      />
      <path
        d="M59.3,61.37c-.75,.27-1.47,.1-2.05,.46,.28-.68,1.11-.88,2.05-.46Z"
        fill="#efce8e"
      />
      <path
        d="M60.7,60.98c-.3,0-.6-.09-.9-.13-.91-.11-1.84-.18-2.61,.29-.66,.4-1.19,1.03-1.85,1.45-.47,.3-.51,.95-.08,1.26,0,0,.37,.31,.73,.37,.7,.12,1.38,.14,2.03-.07,.85-.28,1.62-.65,2.55-.86,.46-.1,.94-.89,.98-1.01,.29-.87,0-1.3-.84-1.3Zm-5.34,2.37c.28-.69,.77-.8,1.27-.89-.4,.24-.66,.74-1.27,.89Zm1.89-1.52c.28-.68,1.11-.88,2.05-.46-.75,.27-1.47,.1-2.05,.46Z"
        fill="#e5c676"
      />
      <path
        d="M56.63,62.46c-.4,.24-.66,.74-1.27,.89,.28-.69,.77-.8,1.27-.89Z"
        fill="#efce8e"
      />
      <path
        d="M65.8,56.27c.21-.13,.54-.08,.88,.08,.09,.04,.18,.08,.18,.21,0,.13-.08,.16-.17,.16-.73,.03-1.29,.44-1.87,.84-.09,.06-.18,.1-.28,.13-.09,.03-.2,.12-.29-.02-.08-.13,0-.25,.08-.31,.45-.35,.82-.84,1.47-1.09Z"
        fill="#efce8e"
      />
      <path
        d="M65.81,59.89c-.83-.12-1.68-.45-2.5-.11-.38,.19-.78,.38-1.18,.6-.32,.17-.36,.47-.17,.86,.28,.6,.97,.97,1.63,.99,1.1,.02,1.62-1.02,2.44-1.49,.32-.18,.15-.78-.22-.84Zm-1.34,1.57c-.25,.11-.5,.28-.93,.19,.32-.21,.54-.36,.77-.5,.07-.04,.17-.09,.24,.02,.09,.13,.02,.25-.08,.29Z"
        fill="#ffcc71"
      />
      <path
        d="M63.53,61.65c.32-.21,.54-.36,.77-.5,.07-.04,.17-.09,.24,.02,.09,.13,.02,.25-.08,.29-.25,.11-.5,.28-.93,.19Z"
        fill="#ffd797"
      />
      <path
        d="M64.88,55.2c-.25-.13-.54-.16-.77-.33-.78-.56-1.37-.26-1.88,.28-.88,.93-1.94,1.47-3.09,1.87-.69,.24-1.3,.65-1.74,1.31-.67,1.01-1.41,1.94-2.39,2.58-.17,.11-.37,.23-.31,.54h0c.17,.46,.48,.32,.73,.28,.5-.09,.91-.4,1.35-.65,1.01-.57,2.29-.53,3.16-1.43,0,0,.2-.26,.21-.35,.08-.3,.34-.31,.55-.32,.78-.04,1.36-.47,1.86-1.05,.62-.73,1.3-1.36,2.19-1.68,.18-.06,.39-.31,.42-.53,.07-.28-.1-.44-.28-.53Zm-5.65,2.69c-.1,.06-.21,.13-.33,.15-.45,.08-.54,.63-.85,.88-.14,.11-.25,.29-.57,.34,.42-.84,.84-1.48,1.61-1.71,.08-.02,.18-.07,.24,.06,.07,.14-.01,.23-.1,.28Zm2.87-1.98c.21-.46,.66-.6,.99-.89,.11-.1,.27-.03,.39,.11-.54,.11-.85,.65-1.38,.78"
        fill="#ffcc71"
      />
      <g>
        <polygon
          points="59.56 59.44 59.56 59.44 59.56 59.44 59.56 59.44"
          fill="#f2c86b"
        />
        <path
          d="M62.45,58.84c-.54-.03-1.02,.31-1.58,.12-.38-.13-.73-.08-1.06,.06-.37,.18-.46,.45-.47,.68-.1,.96,.3,1.04,.92,.92,.88-.17,1.61-.71,2.43-1.05,.16-.07,.25-.27,.2-.48-.06-.25-.26-.23-.44-.24Z"
          fill="#f2c86b"
        />
        <polygon
          points="59.81 59.02 59.81 59.02 59.81 59.01 59.81 59.02"
          fill="#f2c86b"
        />
      </g>
      <path
        d="M60.22,59.21c.08-.05,.2-.03,.33,.03,.03,.02,.07,.03,.07,.08,0,.05-.03,.06-.06,.06-.27,.01-.48,.17-.7,.31-.03,.02-.07,.04-.11,.05-.03,.01-.07,.05-.11,0-.03-.05,0-.09,.03-.11,.17-.13,.31-.31,.55-.41Z"
        fill="#f7ce7c"
      />
    </g>
  </SvgIcon>
);
export default PoopDiaperLow;
