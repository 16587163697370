import { START_LOADER, STOP_LOADER } from '../actions/actionTypes';

const initialState = false;

const loaderReducer = (state = initialState, action) => {
    switch (action.type) {
        case START_LOADER:
            state = true;
            return state;
        case STOP_LOADER:
            state = false;
            return state;
        default:
            return state;
    }
};


export default loaderReducer;