import React from 'react';
import { Alert } from 'reactstrap';

const MsgAlert = (props) => {
    const { isOpen, text, alertClass, dismiss, backgroundColor } = props;

    if (text) {
        return (
            <Alert
                isOpen={isOpen}
                toggle={dismiss}
                className={alertClass}
                style={{ backgroundColor: backgroundColor }}
            >
                <p className="alert-message">{text}</p>
            </Alert>
        );
    } else {
        return null;
    }
};

export default MsgAlert;
