import React from 'react';

const Add = ({ width, height }) => {
    const viewBox = '0 0 20 20';

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox={viewBox}
        >
            <g
                id="Икона_-_ДОБАВИ"
                data-name="Икона - ДОБАВИ"
                transform="translate(-441 -1163)"
            >
                <g
                    id="Ellipse_905"
                    data-name="Ellipse 905"
                    transform="translate(441 1163)"
                    fill="none"
                    stroke="#87ba3b"
                    strokeWidth="1.5"
                >
                    <circle cx="10" cy="10" r="10" stroke="none" />
                    <circle cx="10" cy="10" r="9.25" fill="none" />
                </g>
                <g
                    id="Group_1408"
                    data-name="Group 1408"
                    transform="translate(-23.706 2.794)"
                >
                    <line
                        id="Line_66"
                        data-name="Line 66"
                        y2="5.413"
                        transform="translate(474.706 1167.5)"
                        fill="none"
                        stroke="#87ba3b"
                        strokeLinecap="round"
                        strokeWidth="1.5"
                    />
                    <line
                        id="Line_67"
                        data-name="Line 67"
                        y2="5.413"
                        transform="translate(477.413 1170.206) rotate(90)"
                        fill="none"
                        stroke="#87ba3b"
                        strokeLinecap="round"
                        strokeWidth="1.5"
                    />
                </g>
            </g>
        </svg>
    );
};

export default Add;
