import { GET_USEFUL_INFO } from '../actions/actionTypes';

const initialState = [];

const usefulInfoReducer = (state = [...initialState], action) => {
  switch (action.type) {
    case GET_USEFUL_INFO:
      state = [...action.payload];
      return state;

    default:
      return state;
  }
};

export default usefulInfoReducer;
