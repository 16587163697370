import React, { Fragment } from 'react';
import * as i18n from 'i18next';
import { FacebookShareButton } from 'react-share';
import FacebookIcon from './svgComponents/FacebookIcon';
import { getColor } from '../../utils/commonUtils';
import { COLOR_SCHEME } from '../../utils/constants';
import { connect } from 'react-redux';
import { showInfoModal } from '../../actions';
import navigationMethods from '../../utils/navigationUtils';

class FacebookButton extends React.Component {
    render() {
        if (
            !this.props.selectedAddedSticker.stickerTemplate ||
            !this.props.selectedAddedSticker.stickerTemplate.area
        ) {
            return <Fragment />;
        }
        const disabled = this.props.disabled;
        return (
            <div
                className="facebookButton"
                onClick={() => {
                    if (disabled) {
                        this.props.showInfoModal(
                            i18n.t(
                                'addStickerScreen:shareNotAllowedDescription'
                            )
                        );
                    }
                }}
            >
                <FacebookShareButton
                    disabled={disabled}
                    style={{
                        backgroundColor: disabled
                            ? COLOR_SCHEME.WHITE
                            : getColor(
                                  this.props.selectedAddedSticker
                                      .stickerTemplate.area.tag.goal.borderColor
                              ),
                        color: disabled
                            ? COLOR_SCHEME.GRAY
                            : COLOR_SCHEME.WHITE,
                        border: disabled
                            ? '1px solid ' + COLOR_SCHEME.GRAY
                            : 'none',
                    }}
                    quote={this.getFacebookPostText()}
                    url={navigationMethods.getUserImageUrl(
                        this.props.selectedAddedSticker.photo
                    )}
                >
                    {i18n.t('addStickerScreen:buttons.share')}
                    <FacebookIcon
                        color={
                            disabled ? COLOR_SCHEME.GRAY : COLOR_SCHEME.WHITE
                        }
                    />
                </FacebookShareButton>
            </div>
        );
    }

    getFacebookPostText = () =>
        i18n.t('addStickerScreen:facebookAdditionalDescription') +
        '\n\n' +
        this.props.selectedAddedSticker.stickerTemplate.description;
}

export default connect(null, { showInfoModal })(FacebookButton);
