import * as i18n from 'i18next';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import {
  checkForNewVersion,
  checkIfExpiredStickersExist,
  clearSelectTag,
} from '../../actions';
import { loadAllGoals } from '../../actions/index';
import { RELATIVE_PAGE_PATHS } from '../../utils/constants';
import navigationUtils from '../../utils/navigationUtils';
import AddToJournalTitle from '../common/svgComponents/GoalsAndTagsIcons/AddToJournalTitle';
import ForwardButton from '../common/svgComponents/GoalsAndTagsIcons/ForwardButton';

class Home extends Component {
  componentDidMount() {
    this.props.clearSelectTag();
    this.props.loadAllGoals();
    this.props.checkIfExpiredStickersExist();

    this.props.checkForNewVersion();
  }

  renderHeader() {
    const { user } = this.props;

    return (
      <div className="flexContainerCentered journalHeader">
        <AddToJournalTitle width={307} height={91} />
        <p className="mainTextColor header-small">
          {i18n.t('goalsAndTagsScreen:for')}
          <span className="uppercase">{user.childName}</span>
        </p>
      </div>
    );
  }

  renderGoals() {
    const { goals } = this.props;

    if (goals !== undefined && goals !== null && goals.length > 0) {
      return goals.map((goal, index) => {
        return (
          <Row key={index} className="goalsRow">
            <Col
              xs={4}
              sm={4}
              md={4}
              lg={4}
              className="goalContainer noMargin"
              style={{
                backgroundColor: `#${goal.backgroundColor}`,
              }}
            >
              <p
                className="noMargin headerUppercase mainTextColor flexContainerCentered flexContainerColumn"
                style={{ color: `#${goal.borderColor}` }}
              >
                <img
                  alt="goal"
                  src={navigationUtils.getSystemImageUrl(goal.image)}
                  style={{ marginBottom: 12 }}
                />
                <span className="uppercase" style={{ padding: 6 }}>
                  {goal.name}
                </span>
              </p>
            </Col>
            <Col xs={8} sm={8} md={8} lg={8} className="tagsContainer">
              {this.renderTags(goal)}
            </Col>
          </Row>
        );
      });
    }
  }

  openTagOfSelectedGoal(tag) {
    navigationUtils.navigate(RELATIVE_PAGE_PATHS.SELECTED_TAG + tag.id);
  }

  renderTags(goal) {
    return goal.tags.map((tag, index) => {
      if (goal.id === tag.goalId) {
        return (
          <div
            key={index}
            className="tag"
            onClick={() => this.openTagOfSelectedGoal(tag, goal)}
            style={{
              borderBottom: `1px solid #${goal.backgroundColor}`,
            }}
          >
            <div className="tagTitle">
              <p className="noMargin" style={{ color: `#${goal.borderColor}` }}>
                {tag.name}
              </p>
            </div>
            <div>
              <ForwardButton
                width={20}
                height={20}
                fill={`#${goal.borderColor}`}
              />
            </div>
          </div>
        );
      }
      return null;
    });
  }

  render() {
    return (
      <Container className="noPadding">
        <Col xs={12} sm={12} md={4} lg={4} className="centred noPadding">
          <div className="flexContainerColumn flexContainerCentered profile-form fix-padding">
            {this.renderHeader()}
            {this.renderGoals()}
          </div>
        </Col>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authentication.userData,
    goals: state.goals,
  };
};

export default connect(mapStateToProps, {
  clearSelectTag,
  loadAllGoals,
  checkIfExpiredStickersExist,
  checkForNewVersion,
})(Home);
