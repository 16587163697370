import { SvgIcon } from '@mui/material';
import React from 'react';

const PoopDiaperMedium = (props) => (
  <SvgIcon {...props} viewBox="0 0 128 128">
    <path
      d="M71.36,35.4s-6.19-1.41-11.46-2.99-6.32-3.16-10.14-1.98-8.56,6.32-7.9,9.62,7.65,5.95,7.65,5.95l22.67-10.62-.81,.02Z"
      fill="#c5e27f"
    />
    <path
      d="M11.73,61.07c-.22-2.74-1.08-3.57,.27-5.04s5.42-2.5,7.46-1.48,2.69,4.78,2.69,4.78l-9.36,8.7s-.84-4.22-1.06-6.96Z"
      fill="#c5e27f"
    />
    <path
      d="M49.5,97.75s2.06-.25,5.4,0,5.36,1.22,7.7,.43,5.12-3.89,4.63-5.77-4.9-3.2-4.9-3.2l-13.73,6.78,.91,1.76Z"
      fill="#c5e27f"
    />
    <path
      d="M90.61,68.11s11.44,2.37,14.87,1.91,10.54-5.07,12.13-9.95-1.71-6.59-7.25-8.96-14.07-7.54-14.07-7.54l-5.68,24.53Z"
      fill="#c5e27f"
    />
    <path
      d="M68.53,35.64s-9.62,2.63-17.52,9.09-9.35,10.93-15.81,12.65-13.42,.4-17.51,0-3.83,2.11-4.69,2.83c-2.84,2.41,2.5,10.41,3.82,12.78s11.13,9.95,19.83,15.74,15.07,7.61,18.57,6.85c4.54-.99,6.32-6.19,10.93-11.46s7.9-8.17,16.73-11.99,15.46-9.14,17.92-12.91c2.7-4.15,2.83-9.55-1.19-13.04-4.71-4.09-16.99-12.38-31.09-10.54Z"
      fill="#ebf9ca"
    />
    <path
      d="M14.8,57.43s-.09,.8,1.23,3.83,6.46,10.28,14.89,15.94c6.28,4.22,12.78,8.17,19.37,10.54,5.21,1.88,12.12,1.3,12.12,1.3,0,0-1.28,4.84-5.4,6.74-4.72,2.16-12.76,4.92-26.35-5.8-13.58-10.71-19.91-20.58-18.84-27.4,.92-5.84,2.98-5.15,2.98-5.15Z"
      fill="#c5e27f"
    />
    <ellipse cx="74.06" cy="36.29" rx="3.62" ry="2.04" fill="#ebf9ca" />
    <ellipse
      cx="80.6"
      cy="37.33"
      rx="2.04"
      ry="3.05"
      transform="translate(38.27 115.42) rotate(-86.39)"
      fill="#ebf9ca"
    />
    <ellipse
      cx="86.25"
      cy="39.46"
      rx="2.9"
      ry="2.04"
      transform="translate(-4.03 10.38) rotate(-6.73)"
      fill="#ebf9ca"
    />
    <ellipse
      cx="92.24"
      cy="41.98"
      rx="2.04"
      ry="3.1"
      transform="translate(40.07 128.87) rotate(-83.49)"
      fill="#ebf9ca"
    />
    <path
      d="M76.37,34.78s.57,2.69,.46,3.52,.85-1.32,1.82-1.96,.75-.91,.75-.91l-1.68,.28c-.69-.8-1.35-.93-1.35-.93Z"
      fill="#dfeda6"
    />
    <path
      d="M82.96,36.1s-.09,2.79-.38,3.58,1.11-1.1,2.2-1.53,.93-.73,.93-.73l-1.71-.08c-.38-.71-1.04-1.24-1.04-1.24Z"
      fill="#dfeda6"
    />
    <path
      d="M88.88,38.47s-.26,2.74-.59,3.51,1.18-1.03,2.29-1.39,.97-.67,.97-.67l-1.7-.19c-.31-.73-.96-1.26-.96-1.26Z"
      fill="#dfeda6"
    />
    <path
      d="M95.25,41.63s-1.49,2.49-2.1,3.06,1.49-.48,2.65-.37,1.12-.2,1.12-.2l-1.45-1.19c-.17-.54-.22-1.31-.22-1.31Z"
      fill="#dfeda6"
    />
    <ellipse
      cx="97.12"
      cy="46.07"
      rx="2.04"
      ry="3.1"
      transform="translate(40.33 137.33) rotate(-83.49)"
      fill="#ebf9ca"
    />
    <path
      d="M100.22,45.78s-1.18,1.98-1.68,2.38,1.24-.14,2.21,.19,.94,.05,.94,.05l-1.25-1.37c-.16-.53-.22-1.24-.22-1.24Z"
      fill="#dfeda6"
    />
    <path
      d="M53.27,53.44c-6.42,4.94-11.09,10.14-11.51,9.59s4.04-6.01,10.46-10.95c6.42-4.94,12.38-7.48,12.8-6.93s-5.33,3.35-11.76,8.29Z"
      fill="#c5e27f"
    />
    <path
      d="M69.47,65.82c-6.42,4.94-11.09,10.14-11.51,9.59s4.04-6.01,10.46-10.95,12.38-7.48,12.8-6.93-5.33,3.35-11.76,8.29Z"
      fill="#c5e27f"
    />
    <g>
      <path
        d="M65.85,59.48c-.28,.09-.72,.35-.91-.1-.21-.51,.31-.51,.58-.68,.36-.23,.74-.37,1.19-.25,.12,.03,.27,.07,.3,.25,.03,.15-.1,.2-.18,.25-.31,.18-.64,.35-.97,.53Z"
        fill="#f2c86b"
      />
      <path
        d="M67.12,56.29c-.36-.14-.74-.22-1.1-.33-.37-.12-.69-.02-.99,.17-.58,.37-1.07,.89-1.71,1.17-.45,.2-.65,.69-.52,1.33-.04,.35,.21,.43,.4,.5,.43,.15,.88,.18,1.23-.06,.89-.63,1.84-1.11,2.87-1.43,.29-.09,.58-.26,.54-.72-.1-.44-.44-.51-.72-.62Zm-.74,.35c0,.13-.08,.16-.17,.16-.73,.03-1.29,.44-1.87,.84-.09,.06-.18,.1-.28,.13-.09,.03-.2,.12-.29-.02-.08-.13,0-.25,.08-.31,.45-.35,.82-.84,1.47-1.09,.21-.13,.54-.08,.88,.08,.09,.04,.18,.08,.18,.21Z"
        fill="#e5c676"
      />
      <path
        d="M57,59.34c.42-.84,.84-1.48,1.61-1.71,.08-.02,.18-.07,.24,.06,.07,.14-.01,.23-.1,.28-.1,.06-.21,.13-.33,.15-.45,.08-.54,.63-.85,.88-.14,.11-.25,.29-.57,.34Z"
        fill="#ffd797"
      />
      <path
        d="M62.99,55.21c-.54,.11-.85,.65-1.38,.78,.21-.46,.66-.6,.99-.89,.11-.1,.27-.03,.39,.11Z"
        fill="#f7cf8f"
      />
      <path
        d="M58.81,61.46c-.75,.27-1.47,.1-2.05,.46,.28-.68,1.11-.88,2.05-.46Z"
        fill="#efce8e"
      />
      <path
        d="M60.21,61.07c-.3,0-.6-.09-.9-.13-.91-.11-1.84-.18-2.61,.29-.66,.4-1.19,1.03-1.85,1.45-.47,.3-.51,.95-.08,1.26,0,0,.37,.31,.73,.37,.7,.12,1.38,.14,2.03-.07,.85-.28,1.62-.65,2.55-.86,.46-.1,.94-.89,.98-1.01,.29-.87,0-1.3-.84-1.3Zm-5.34,2.37c.28-.69,.77-.8,1.27-.89-.4,.24-.66,.74-1.27,.89Zm1.89-1.52c.28-.68,1.11-.88,2.05-.46-.75,.27-1.47,.1-2.05,.46Z"
        fill="#e5c676"
      />
      <path
        d="M56.14,62.54c-.4,.24-.66,.74-1.27,.89,.28-.69,.77-.8,1.27-.89Z"
        fill="#efce8e"
      />
      <path
        d="M65.31,56.35c.21-.13,.54-.08,.88,.08,.09,.04,.18,.08,.18,.21,0,.13-.08,.16-.17,.16-.73,.03-1.29,.44-1.87,.84-.09,.06-.18,.1-.28,.13-.09,.03-.2,.12-.29-.02-.08-.13,0-.25,.08-.31,.45-.35,.82-.84,1.47-1.09Z"
        fill="#efce8e"
      />
      <path
        d="M65.32,59.97c-.83-.12-1.68-.45-2.5-.11-.38,.19-.78,.38-1.18,.6-.32,.17-.36,.47-.17,.86,.28,.6,.97,.97,1.63,.99,1.1,.02,1.62-1.02,2.44-1.49,.32-.18,.15-.78-.22-.84Zm-1.34,1.57c-.25,.11-.5,.28-.93,.19,.32-.21,.54-.36,.77-.5,.07-.04,.17-.09,.24,.02,.09,.13,.02,.25-.08,.29Z"
        fill="#ffcc71"
      />
      <path
        d="M63.04,61.74c.32-.21,.54-.36,.77-.5,.07-.04,.17-.09,.24,.02,.09,.13,.02,.25-.08,.29-.25,.11-.5,.28-.93,.19Z"
        fill="#ffd797"
      />
      <path
        d="M64.39,55.29c-.25-.13-.54-.16-.77-.33-.78-.56-1.37-.26-1.88,.28-.88,.93-1.94,1.47-3.09,1.87-.69,.24-1.3,.65-1.74,1.31-.67,1.01-1.41,1.94-2.39,2.58-.17,.11-.37,.23-.31,.54h0c.17,.46,.48,.32,.73,.28,.5-.09,.91-.4,1.35-.65,1.01-.57,2.29-.53,3.16-1.43,0,0,.2-.26,.21-.35,.08-.3,.34-.31,.55-.32,.78-.04,1.36-.47,1.86-1.05,.62-.73,1.3-1.36,2.19-1.68,.18-.06,.39-.31,.42-.53,.07-.28-.1-.44-.28-.53Zm-5.65,2.69c-.1,.06-.21,.13-.33,.15-.45,.08-.54,.63-.85,.88-.14,.11-.25,.29-.57,.34,.42-.84,.84-1.48,1.61-1.71,.08-.02,.18-.07,.24,.06,.07,.14-.01,.23-.1,.28Zm2.87-1.98c.21-.46,.66-.6,.99-.89,.11-.1,.27-.03,.39,.11-.54,.11-.85,.65-1.38,.78"
        fill="#ffcc71"
      />
      <g>
        <polygon
          points="59.06 59.52 59.07 59.52 59.07 59.52 59.06 59.52"
          fill="#f2c86b"
        />
        <path
          d="M61.96,58.92c-.54-.03-1.02,.31-1.58,.12-.38-.13-.73-.08-1.06,.06-.37,.18-.46,.45-.47,.68-.1,.96,.3,1.04,.92,.92,.88-.17,1.61-.71,2.43-1.05,.16-.07,.25-.27,.2-.48-.06-.25-.26-.23-.44-.24Z"
          fill="#f2c86b"
        />
        <polygon
          points="59.32 59.1 59.32 59.1 59.32 59.1 59.32 59.1"
          fill="#f2c86b"
        />
      </g>
      <path
        d="M59.73,59.29c.08-.05,.2-.03,.33,.03,.03,.02,.07,.03,.07,.08,0,.05-.03,.06-.06,.06-.27,.01-.48,.17-.7,.31-.03,.02-.07,.04-.11,.05-.03,.01-.07,.05-.11,0-.03-.05,0-.09,.03-.11,.17-.13,.31-.31,.55-.41Z"
        fill="#f7ce7c"
      />
    </g>
    <g>
      <path
        d="M71.37,56.61c-.44,.06-1.16,.31-1.31-.41-.17-.81,.61-.66,1.05-.83,.6-.23,1.2-.34,1.83-.02,.16,.08,.37,.18,.37,.45,0,.23-.2,.27-.34,.32-.52,.18-1.04,.33-1.59,.5Z"
        fill="#f2c86b"
      />
      <path
        d="M74.16,52.25c-.49-.31-1.03-.53-1.54-.81-.52-.28-1.01-.23-1.52-.04-.97,.38-1.84,1-2.88,1.24-.73,.17-1.16,.84-1.16,1.81-.16,.51,.19,.7,.45,.86,.59,.35,1.25,.53,1.84,.26,1.5-.68,3.04-1.11,4.66-1.28,.45-.05,.93-.22,1.01-.92-.02-.67-.5-.88-.88-1.13Zm-1.2,.3c-.03,.2-.16,.21-.3,.19-1.08-.16-2.04,.28-3,.7-.14,.06-.3,.09-.46,.11-.14,.02-.33,.13-.42-.11-.08-.22,.07-.36,.2-.43,.77-.38,1.46-1.01,2.49-1.19,.35-.13,.82,.04,1.28,.37,.12,.09,.24,.17,.21,.36Z"
        fill="#e5c676"
      />
      <path
        d="M58.31,53.84c.86-1.12,1.67-1.95,2.88-2.06,.12-.01,.28-.05,.33,.15,.06,.23-.08,.33-.24,.39-.17,.06-.35,.13-.53,.13-.7,0-.98,.77-1.51,1.05-.24,.13-.45,.35-.93,.33Z"
        fill="#ffd797"
      />
      <path
        d="M68.37,49.45c-.82,0-1.45,.72-2.27,.76,.45-.63,1.15-.7,1.73-1.03,.19-.11,.4,.03,.54,.27Z"
        fill="#f7cf8f"
      />
      <path
        d="M60.38,57.49c-1.18,.19-2.2-.28-3.16,.08,.61-.93,1.9-.98,3.16-.08Z"
        fill="#efce8e"
      />
      <path
        d="M62.56,57.32c-.44-.08-.86-.31-1.3-.45-1.31-.43-2.67-.8-3.95-.33-1.09,.4-2.05,1.18-3.16,1.61-.78,.31-1.03,1.26-.49,1.84,0,0,.46,.56,.97,.75,1.01,.38,2,.61,3.02,.48,1.34-.17,2.58-.5,4.02-.54,.71-.02,1.65-1.05,1.74-1.22,.68-1.2,.38-1.92-.87-2.16Zm-8.58,1.96c.62-.94,1.37-.95,2.13-.95-.66,.24-1.19,.91-2.13,.95Zm3.24-1.7c.61-.93,1.9-.98,3.16-.08-1.18,.19-2.2-.28-3.16,.08Z"
        fill="#e5c676"
      />
      <path
        d="M56.11,58.32c-.66,.24-1.19,.91-2.13,.95,.62-.94,1.37-.95,2.13-.95Z"
        fill="#efce8e"
      />
      <path
        d="M71.48,51.82c.35-.13,.82,.04,1.28,.37,.12,.09,.24,.17,.21,.36-.03,.2-.16,.21-.3,.19-1.08-.16-2.04,.28-3,.7-.14,.06-.3,.09-.46,.11-.14,.02-.33,.13-.42-.11-.08-.22,.07-.36,.2-.43,.77-.38,1.46-1.01,2.49-1.19Z"
        fill="#efce8e"
      />
      <path
        d="M70.44,57.18c-1.19-.42-2.35-1.16-3.67-.89-.61,.17-1.27,.34-1.92,.54-.52,.16-.66,.59-.5,1.22,.24,.97,1.15,1.72,2.13,1.93,1.62,.35,2.68-1.04,4.05-1.5,.53-.18,.45-1.11-.09-1.3Zm-2.44,1.94c-.41,.1-.82,.27-1.44,0,.54-.22,.91-.38,1.28-.51,.12-.04,.28-.08,.35,.09,.09,.22-.05,.38-.2,.41Z"
        fill="#ffcc71"
      />
      <path
        d="M66.56,59.13c.54-.22,.91-.38,1.28-.51,.12-.04,.28-.08,.35,.09,.09,.22-.05,.38-.2,.41-.41,.1-.82,.27-1.44,0Z"
        fill="#ffd797"
      />
      <path
        d="M70.42,49.98c-.33-.27-.75-.4-1.05-.71-1-1.06-1.95-.78-2.87-.12-1.56,1.12-3.3,1.61-5.12,1.88-1.09,.16-2.1,.59-2.95,1.44-1.29,1.3-2.64,2.47-4.28,3.13-.28,.11-.61,.23-.61,.71h0c.12,.73,.61,.62,1,.62,.77,.01,1.46-.33,2.18-.57,1.66-.56,3.54-.12,5.09-1.2,0,0,.37-.32,.41-.46,.21-.42,.6-.36,.9-.32,1.17,.16,2.14-.3,3.06-1.02,1.13-.9,2.32-1.64,3.72-1.86,.28-.04,.67-.35,.77-.66,.18-.39-.02-.67-.26-.87Zm-9.14,2.34c-.17,.06-.35,.13-.53,.13-.7,0-.98,.77-1.51,1.05-.24,.13-.45,.35-.93,.33,.86-1.12,1.67-1.95,2.88-2.06,.12-.01,.28-.05,.33,.15,.06,.23-.08,.33-.24,.39Zm4.82-2.11c.45-.63,1.15-.7,1.73-1.03,.19-.11,.4,.03,.54,.27-.82,0-1.45,.72-2.27,.76"
        fill="#ffcc71"
      />
      <g>
        <polygon
          points="61.32 54.71 61.32 54.71 61.33 54.7 61.32 54.71"
          fill="#f2c86b"
        />
        <path
          d="M65.77,54.65c-.79-.21-1.59,.16-2.37-.28-.53-.3-1.05-.33-1.59-.21-.59,.17-.8,.53-.88,.87-.43,1.4,.14,1.62,1.1,1.62,1.35,0,2.59-.58,3.89-.85,.25-.05,.45-.33,.43-.66-.02-.39-.32-.42-.58-.49Z"
          fill="#f2c86b"
        />
        <polygon
          points="61.81 54.16 61.81 54.16 61.82 54.15 61.81 54.16"
          fill="#f2c86b"
        />
      </g>
      <path
        d="M62.36,54.55c.13-.05,.31,.02,.48,.14,.05,.03,.09,.06,.08,.13-.01,.07-.06,.08-.11,.07-.4-.06-.76,.11-1.12,.26-.05,.02-.11,.03-.17,.04-.05,0-.12,.05-.16-.04-.03-.08,.03-.14,.08-.16,.29-.14,.54-.38,.93-.44Z"
        fill="#f7ce7c"
      />
    </g>
    <g>
      <path
        d="M75.48,56.11c-.29,0-.79,.08-.82-.41-.02-.55,.47-.37,.77-.43,.42-.09,.82-.09,1.2,.18,.1,.07,.23,.16,.19,.33-.03,.15-.16,.16-.25,.17-.36,.06-.72,.1-1.09,.16Z"
        fill="#f2c86b"
      />
      <path
        d="M77.78,53.56c-.29-.26-.61-.46-.92-.7-.31-.24-.63-.26-.99-.19-.68,.14-1.32,.46-2.02,.5-.49,.03-.85,.42-.95,1.06-.16,.31,.05,.48,.21,.61,.35,.29,.76,.48,1.18,.37,1.05-.28,2.11-.4,3.19-.34,.3,.02,.63-.05,.76-.49,.06-.44-.23-.63-.46-.83Zm-.82,.07c-.04,.12-.13,.12-.22,.09-.69-.22-1.37-.03-2.04,.14-.1,.03-.21,.03-.31,.02-.1,0-.23,.05-.26-.12-.03-.15,.08-.23,.18-.26,.55-.17,1.06-.5,1.76-.51,.24-.05,.53,.11,.8,.38,.07,.07,.14,.14,.1,.26Z"
        fill="#e5c676"
      />
      <path
        d="M67.23,52.9c.68-.64,1.3-1.1,2.1-1.04,.08,0,.19,0,.2,.13,.01,.16-.09,.21-.2,.23-.12,.02-.24,.05-.36,.03-.46-.08-.72,.4-1.1,.53-.17,.06-.33,.18-.65,.12Z"
        fill="#ffd797"
      />
      <path
        d="M74.29,51.11c-.54-.08-1.03,.32-1.57,.26,.36-.36,.83-.34,1.24-.49,.14-.05,.26,.06,.32,.24Z"
        fill="#f7cf8f"
      />
      <path
        d="M68.2,55.52c-.79,0-1.41-.42-2.08-.28,.5-.54,1.35-.44,2.08,.28Z"
        fill="#efce8e"
      />
      <path
        d="M69.64,55.64c-.28-.1-.53-.29-.8-.43-.81-.42-1.66-.81-2.55-.64-.76,.15-1.47,.55-2.24,.72-.55,.12-.81,.71-.52,1.15,0,0,.24,.42,.55,.6,.62,.36,1.24,.61,1.93,.64,.9,.03,1.75-.05,2.69,.08,.47,.06,1.19-.51,1.27-.61,.58-.71,.45-1.22-.34-1.51Zm-5.83,.36c.51-.55,1-.48,1.5-.4-.46,.09-.87,.47-1.5,.4Zm2.3-.77c.5-.54,1.35-.44,2.08,.28-.79,0-1.41-.42-2.08-.28Z"
        fill="#e5c676"
      />
      <path
        d="M65.31,55.6c-.46,.09-.87,.47-1.5,.4,.51-.55,1-.48,1.5-.4Z"
        fill="#efce8e"
      />
      <path
        d="M76.07,52.99c.24-.05,.53,.11,.8,.38,.07,.07,.14,.14,.1,.26-.04,.12-.13,.12-.22,.09-.69-.22-1.37-.03-2.04,.14-.1,.03-.21,.03-.31,.02-.1,0-.23,.05-.26-.12-.03-.15,.08-.23,.18-.26,.55-.17,1.06-.5,1.76-.51Z"
        fill="#efce8e"
      />
      <path
        d="M74.81,56.39c-.73-.4-1.41-1.01-2.31-.98-.42,.05-.87,.09-1.31,.15-.36,.05-.5,.31-.46,.75,.06,.66,.57,1.25,1.19,1.49,1.02,.4,1.87-.4,2.81-.55,.37-.06,.41-.68,.08-.86Zm-1.81,1.01c-.28,.02-.57,.09-.94-.15,.38-.08,.64-.15,.9-.2,.08-.02,.19-.02,.22,.1,.04,.16-.07,.24-.17,.25Z"
        fill="#ffcc71"
      />
      <path
        d="M72.07,57.25c.38-.08,.64-.15,.9-.2,.08-.02,.19-.02,.22,.1,.04,.16-.07,.24-.17,.25-.28,.02-.57,.09-.94-.15Z"
        fill="#ffd797"
      />
      <path
        d="M75.57,51.67c-.19-.21-.45-.34-.61-.58-.54-.8-1.2-.72-1.87-.39-1.14,.57-2.33,.7-3.55,.68-.73-.01-1.44,.16-2.09,.63-.98,.71-1.99,1.33-3.14,1.59-.2,.04-.42,.09-.48,.4h0c0,.49,.33,.47,.59,.51,.5,.09,.99-.06,1.49-.14,1.15-.19,2.33,.3,3.46-.24,0,0,.28-.17,.32-.25,.18-.25,.43-.17,.63-.11,.75,.23,1.44,.03,2.11-.34,.84-.47,1.69-.82,2.64-.82,.19,0,.47-.16,.58-.35,.16-.24,.06-.44-.08-.6Zm-6.23,.55c-.12,.02-.24,.05-.36,.03-.46-.08-.72,.4-1.1,.53-.17,.06-.33,.18-.65,.12,.68-.64,1.3-1.1,2.1-1.04,.08,0,.19,0,.2,.13,.01,.16-.09,.21-.2,.23Zm3.38-.86c.36-.36,.83-.34,1.24-.49,.14-.05,.26,.06,.32,.24-.54-.08-1.03,.32-1.57,.26"
        fill="#ffcc71"
      />
      <g>
        <polygon
          points="69.11 53.79 69.11 53.79 69.11 53.79 69.11 53.79"
          fill="#f2c86b"
        />
        <path
          d="M72.03,54.23c-.49-.22-1.06-.06-1.52-.44-.31-.25-.65-.33-1.02-.31-.41,.04-.58,.26-.67,.47-.43,.87-.08,1.07,.54,1.18,.88,.15,1.76-.1,2.64-.14,.17,0,.33-.17,.35-.38,.03-.26-.17-.31-.33-.38Z"
          fill="#f2c86b"
        />
        <polygon
          points="69.49 53.49 69.49 53.49 69.49 53.48 69.49 53.49"
          fill="#f2c86b"
        />
      </g>
      <path
        d="M69.81,53.8c.09-.02,.2,.04,.3,.14,.03,.03,.05,.05,.04,.1-.01,.05-.05,.05-.08,.04-.26-.08-.51-.01-.76,.05-.04,0-.08,0-.12,0-.04,0-.09,.02-.1-.04-.01-.06,.03-.09,.07-.1,.2-.06,.4-.19,.66-.19Z"
        fill="#f7ce7c"
      />
    </g>
    <g>
      <path
        d="M58.47,60.27c-.28,.09-.72,.35-.91-.1-.21-.51,.31-.51,.58-.68,.36-.23,.74-.37,1.19-.25,.12,.03,.27,.07,.3,.25,.03,.15-.1,.2-.18,.25-.31,.18-.64,.35-.97,.53Z"
        fill="#f2c86b"
      />
      <path
        d="M59.74,57.08c-.36-.14-.74-.22-1.1-.33-.37-.12-.69-.02-.99,.17-.58,.37-1.07,.89-1.71,1.17-.45,.2-.65,.69-.52,1.33-.04,.35,.21,.43,.4,.5,.43,.15,.88,.18,1.23-.06,.89-.63,1.84-1.11,2.87-1.43,.29-.09,.58-.26,.54-.72-.1-.44-.44-.51-.72-.62Zm-.74,.35c0,.13-.08,.16-.17,.16-.73,.03-1.29,.44-1.87,.84-.09,.06-.18,.1-.28,.13-.09,.03-.2,.12-.29-.02-.08-.13,0-.25,.08-.31,.45-.35,.82-.84,1.47-1.09,.21-.13,.54-.08,.88,.08,.09,.04,.18,.08,.18,.21Z"
        fill="#e5c676"
      />
      <path
        d="M49.62,60.13c.42-.84,.84-1.48,1.61-1.71,.08-.02,.18-.07,.24,.06,.07,.14-.01,.23-.1,.28-.1,.06-.21,.13-.33,.15-.45,.08-.54,.63-.85,.88-.14,.11-.25,.29-.57,.34Z"
        fill="#ffd797"
      />
      <path
        d="M55.61,56c-.54,.11-.85,.65-1.38,.78,.21-.46,.66-.6,.99-.89,.11-.1,.27-.03,.39,.11Z"
        fill="#f7cf8f"
      />
      <path
        d="M51.43,62.25c-.75,.27-1.47,.1-2.05,.46,.28-.68,1.11-.88,2.05-.46Z"
        fill="#efce8e"
      />
      <path
        d="M52.83,61.86c-.3,0-.6-.09-.9-.13-.91-.11-1.84-.18-2.61,.29-.66,.4-1.19,1.03-1.85,1.45-.47,.3-.51,.95-.08,1.26,0,0,.37,.31,.73,.37,.7,.12,1.38,.14,2.03-.07,.85-.28,1.62-.65,2.55-.86,.46-.1,.94-.89,.98-1.01,.29-.87,0-1.3-.84-1.3Zm-5.34,2.37c.28-.69,.77-.8,1.27-.89-.4,.24-.66,.74-1.27,.89Zm1.89-1.52c.28-.68,1.11-.88,2.05-.46-.75,.27-1.47,.1-2.05,.46Z"
        fill="#e5c676"
      />
      <path
        d="M48.76,63.33c-.4,.24-.66,.74-1.27,.89,.28-.69,.77-.8,1.27-.89Z"
        fill="#efce8e"
      />
      <path
        d="M57.94,57.14c.21-.13,.54-.08,.88,.08,.09,.04,.18,.08,.18,.21,0,.13-.08,.16-.17,.16-.73,.03-1.29,.44-1.87,.84-.09,.06-.18,.1-.28,.13-.09,.03-.2,.12-.29-.02-.08-.13,0-.25,.08-.31,.45-.35,.82-.84,1.47-1.09Z"
        fill="#efce8e"
      />
      <path
        d="M57.94,60.76c-.83-.12-1.68-.45-2.5-.11-.38,.19-.78,.38-1.18,.6-.32,.17-.36,.47-.17,.86,.28,.6,.97,.97,1.63,.99,1.1,.02,1.62-1.02,2.44-1.49,.32-.18,.15-.78-.22-.84Zm-1.34,1.57c-.25,.11-.5,.28-.93,.19,.32-.21,.54-.36,.77-.5,.07-.04,.17-.09,.24,.02,.09,.13,.02,.25-.08,.29Z"
        fill="#ffcc71"
      />
      <path
        d="M55.66,62.53c.32-.21,.54-.36,.77-.5,.07-.04,.17-.09,.24,.02,.09,.13,.02,.25-.08,.29-.25,.11-.5,.28-.93,.19Z"
        fill="#ffd797"
      />
      <path
        d="M57.01,56.08c-.25-.13-.54-.16-.77-.33-.78-.56-1.37-.26-1.88,.28-.88,.93-1.94,1.47-3.09,1.87-.69,.24-1.3,.65-1.74,1.31-.67,1.01-1.41,1.94-2.39,2.58-.17,.11-.37,.23-.31,.54h0c.17,.46,.48,.32,.73,.28,.5-.09,.91-.4,1.35-.65,1.01-.57,2.29-.53,3.16-1.43,0,0,.2-.26,.21-.35,.08-.3,.34-.31,.55-.32,.78-.04,1.36-.47,1.86-1.05,.62-.73,1.3-1.36,2.19-1.68,.18-.06,.39-.31,.42-.53,.07-.28-.1-.44-.28-.53Zm-5.65,2.69c-.1,.06-.21,.13-.33,.15-.45,.08-.54,.63-.85,.88-.14,.11-.25,.29-.57,.34,.42-.84,.84-1.48,1.61-1.71,.08-.02,.18-.07,.24,.06,.07,.14-.01,.23-.1,.28Zm2.87-1.98c.21-.46,.66-.6,.99-.89,.11-.1,.27-.03,.39,.11-.54,.11-.85,.65-1.38,.78"
        fill="#ffcc71"
      />
      <g>
        <polygon
          points="51.69 60.32 51.69 60.31 51.69 60.31 51.69 60.32"
          fill="#f2c86b"
        />
        <path
          d="M54.58,59.71c-.54-.03-1.02,.31-1.58,.12-.38-.13-.73-.08-1.06,.06-.37,.18-.46,.45-.47,.68-.1,.96,.3,1.04,.92,.92,.88-.17,1.61-.71,2.43-1.05,.16-.07,.25-.27,.2-.48-.06-.25-.26-.23-.44-.24Z"
          fill="#f2c86b"
        />
        <polygon
          points="51.94 59.89 51.94 59.89 51.94 59.89 51.94 59.89"
          fill="#f2c86b"
        />
      </g>
      <path
        d="M52.35,60.08c.08-.05,.2-.03,.33,.03,.03,.02,.07,.03,.07,.08,0,.05-.03,.06-.06,.06-.27,.01-.48,.17-.7,.31-.03,.02-.07,.04-.11,.05-.03,.01-.07,.05-.11,0-.03-.05,0-.09,.03-.11,.17-.13,.31-.31,.55-.41Z"
        fill="#f7ce7c"
      />
    </g>
    <g>
      <path
        d="M69.14,60.93c-.28,.09-.72,.35-.91-.1-.21-.51,.31-.51,.58-.68,.36-.23,.74-.37,1.19-.25,.12,.03,.27,.07,.3,.25,.03,.15-.1,.2-.18,.25-.31,.18-.64,.35-.97,.53Z"
        fill="#f2c86b"
      />
      <path
        d="M70.41,57.74c-.36-.14-.74-.22-1.1-.33-.37-.12-.69-.02-.99,.17-.58,.37-1.07,.89-1.71,1.17-.45,.2-.65,.69-.52,1.33-.04,.35,.21,.43,.4,.5,.43,.15,.88,.18,1.23-.06,.89-.63,1.84-1.11,2.87-1.43,.29-.09,.58-.26,.54-.72-.1-.44-.44-.51-.72-.62Zm-.74,.35c0,.13-.08,.16-.17,.16-.73,.03-1.29,.44-1.87,.84-.09,.06-.18,.1-.28,.13-.09,.03-.2,.12-.29-.02-.08-.13,0-.25,.08-.31,.45-.35,.82-.84,1.47-1.09,.21-.13,.54-.08,.88,.08,.09,.04,.18,.08,.18,.21Z"
        fill="#e5c676"
      />
      <path
        d="M60.29,60.79c.42-.84,.84-1.48,1.61-1.71,.08-.02,.18-.07,.24,.06,.07,.14-.01,.23-.1,.28-.1,.06-.21,.13-.33,.15-.45,.08-.54,.63-.85,.88-.14,.11-.25,.29-.57,.34Z"
        fill="#ffd797"
      />
      <path
        d="M66.28,56.65c-.54,.11-.85,.65-1.38,.78,.21-.46,.66-.6,.99-.89,.11-.1,.27-.03,.39,.11Z"
        fill="#f7cf8f"
      />
      <path
        d="M62.1,62.91c-.75,.27-1.47,.1-2.05,.46,.28-.68,1.11-.88,2.05-.46Z"
        fill="#efce8e"
      />
      <path
        d="M63.5,62.52c-.3,0-.6-.09-.9-.13-.91-.11-1.84-.18-2.61,.29-.66,.4-1.19,1.03-1.85,1.45-.47,.3-.51,.95-.08,1.26,0,0,.37,.31,.73,.37,.7,.12,1.38,.14,2.03-.07,.85-.28,1.62-.65,2.55-.86,.46-.1,.94-.89,.98-1.01,.29-.87,0-1.3-.84-1.3Zm-5.34,2.37c.28-.69,.77-.8,1.27-.89-.4,.24-.66,.74-1.27,.89Zm1.89-1.52c.28-.68,1.11-.88,2.05-.46-.75,.27-1.47,.1-2.05,.46Z"
        fill="#e5c676"
      />
      <path
        d="M59.43,63.99c-.4,.24-.66,.74-1.27,.89,.28-.69,.77-.8,1.27-.89Z"
        fill="#efce8e"
      />
      <path
        d="M68.61,57.8c.21-.13,.54-.08,.88,.08,.09,.04,.18,.08,.18,.21,0,.13-.08,.16-.17,.16-.73,.03-1.29,.44-1.87,.84-.09,.06-.18,.1-.28,.13-.09,.03-.2,.12-.29-.02-.08-.13,0-.25,.08-.31,.45-.35,.82-.84,1.47-1.09Z"
        fill="#efce8e"
      />
      <path
        d="M68.61,61.42c-.83-.12-1.68-.45-2.5-.11-.38,.19-.78,.38-1.18,.6-.32,.17-.36,.47-.17,.86,.28,.6,.97,.97,1.63,.99,1.1,.02,1.62-1.02,2.44-1.49,.32-.18,.15-.78-.22-.84Zm-1.34,1.57c-.25,.11-.5,.28-.93,.19,.32-.21,.54-.36,.77-.5,.07-.04,.17-.09,.24,.02,.09,.13,.02,.25-.08,.29Z"
        fill="#ffcc71"
      />
      <path
        d="M66.33,63.19c.32-.21,.54-.36,.77-.5,.07-.04,.17-.09,.24,.02,.09,.13,.02,.25-.08,.29-.25,.11-.5,.28-.93,.19Z"
        fill="#ffd797"
      />
      <path
        d="M67.68,56.74c-.25-.13-.54-.16-.77-.33-.78-.56-1.37-.26-1.88,.28-.88,.93-1.94,1.47-3.09,1.87-.69,.24-1.3,.65-1.74,1.31-.67,1.01-1.41,1.94-2.39,2.58-.17,.11-.37,.23-.31,.54h0c.17,.46,.48,.32,.73,.28,.5-.09,.91-.4,1.35-.65,1.01-.57,2.29-.53,3.16-1.43,0,0,.2-.26,.21-.35,.08-.3,.34-.31,.55-.32,.78-.04,1.36-.47,1.86-1.05,.62-.73,1.3-1.36,2.19-1.68,.18-.06,.39-.31,.42-.53,.07-.28-.1-.44-.28-.53Zm-5.65,2.69c-.1,.06-.21,.13-.33,.15-.45,.08-.54,.63-.85,.88-.14,.11-.25,.29-.57,.34,.42-.84,.84-1.48,1.61-1.71,.08-.02,.18-.07,.24,.06,.07,.14-.01,.23-.1,.28Zm2.87-1.98c.21-.46,.66-.6,.99-.89,.11-.1,.27-.03,.39,.11-.54,.11-.85,.65-1.38,.78"
        fill="#ffcc71"
      />
      <g>
        <polygon
          points="62.36 60.97 62.36 60.97 62.36 60.97 62.36 60.97"
          fill="#f2c86b"
        />
        <path
          d="M65.25,60.37c-.54-.03-1.02,.31-1.58,.12-.38-.13-.73-.08-1.06,.06-.37,.18-.46,.45-.47,.68-.1,.96,.3,1.04,.92,.92,.88-.17,1.61-.71,2.43-1.05,.16-.07,.25-.27,.2-.48-.06-.25-.26-.23-.44-.24Z"
          fill="#f2c86b"
        />
        <polygon
          points="62.61 60.55 62.61 60.55 62.61 60.55 62.61 60.55"
          fill="#f2c86b"
        />
      </g>
      <path
        d="M63.02,60.74c.08-.05,.2-.03,.33,.03,.03,.02,.07,.03,.07,.08,0,.05-.03,.06-.06,.06-.27,.01-.48,.17-.7,.31-.03,.02-.07,.04-.11,.05-.03,.01-.07,.05-.11,0-.03-.05,0-.09,.03-.11,.17-.13,.31-.31,.55-.41Z"
        fill="#f7ce7c"
      />
    </g>
  </SvgIcon>
);
export default PoopDiaperMedium;
