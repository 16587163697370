import { IconButton, Menu, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { RELATIVE_PAGE_PATHS, TRACKERS_SUB_TYPE } from '../../utils/constants';
import RouterLink from '../common/RouterLink';
import { MoreVert } from '@mui/icons-material';
import { useCallback } from 'react';

const InnerLink = ({ record, children, disableLink }) => {
  if (disableLink) {
    return <div className="d-flex py-1">{children}</div>;
  }
  const getRelativePath = () => {
    switch (record.name) {
      case TRACKERS_SUB_TYPE.BREAST_FEEDING:
        return `${RELATIVE_PAGE_PATHS.BREAST_FEEDING}/${record.id}`;
      case TRACKERS_SUB_TYPE.SLEEP:
        return `${RELATIVE_PAGE_PATHS.SLEEP}/${record.id}`;
      default:
        return `${RELATIVE_PAGE_PATHS.TRACKING}/${record.subTypeId}/${record.id}`;
    }
  };

  const to = getRelativePath();
  return (
    <RouterLink to={to}>
      <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
    </RouterLink>
  );
};

const TrackerHistoryItem = ({
  record,
  children,
  onEdit,
  onDelete,
  disableEdit,
  hideEdit,
  disableLink,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleEdit = useCallback(() => {
    if (onEdit) {
      onEdit(record.id, record.subTypeId);
    }
    handleClose();
  }, [onEdit, handleClose, record]);

  const handleDelete = useCallback(() => {
    if (onDelete) {
      onDelete(record.id);
    }
    handleClose();
  }, [onDelete, handleClose, record]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        widht: '100%',
        alignItems: 'center',
      }}
    >
      <InnerLink disableLink={disableLink} record={record}>
        {children}
      </InnerLink>

      {!hideEdit && (
        <div>
          <IconButton aria-label="delete" onClick={handleClick}>
            <MoreVert />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
              dense: true,
            }}
          >
            <MenuItem disabled={disableEdit} onClick={handleEdit}>
              Промени
            </MenuItem>
            <MenuItem disabled={disableEdit} onClick={handleDelete}>
              Изтрий
            </MenuItem>
          </Menu>
        </div>
      )}
    </div>
  );
};

TrackerHistoryItem.propTypes = {
  record: PropTypes.object,
  onDelete: PropTypes.func,
};

export default TrackerHistoryItem;
