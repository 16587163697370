import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, Col } from 'reactstrap';
import * as i18n from 'i18next';

import {
  SHOW_INFO_MODAL,
  SHOW_AGREEMENT_MODAL,
  SHOW_ERROR_MODAL,
  SHOW_SUCCESS_MODAL,
  SHOW_SHARE_MODAL,
  SHOW_DELETE_MODAL,
  SHOW_CONFIRM_DIALOG,
} from '../../actions/actionTypes';
import FacebookButton from './FacebookButton';
import { store } from '../../index';

const ModalDialog = (props) => {
  const { isOpen, message, type, toggle, onDelete, onConfirm, onDeny } = props;

  const renderButton = () => {
    switch (type) {
      case SHOW_SUCCESS_MODAL:
        return (
          <Col
            xs={10}
            sm={10}
            md={10}
            lg={8}
            className="flexContainerColumn centred"
          >
            <Button className="greenBtn header uppercase" onClick={toggle}>
              {i18n.t('modalDialog:accept')}
            </Button>
          </Col>
        );
      case SHOW_INFO_MODAL:
        return (
          <Col
            xs={10}
            sm={10}
            md={10}
            lg={8}
            className="flexContainerColumn centred"
          >
            <Button className="greenBtn header uppercase" onClick={toggle}>
              {i18n.t('modalDialog:accept')}
            </Button>
          </Col>
        );
      case SHOW_AGREEMENT_MODAL:
        return (
          <Col
            xs={10}
            sm={10}
            md={10}
            lg={10}
            className="centred noPadding"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
            }}
          >
            <Button
              className="redBtnOutline header"
              onClick={toggle}
              style={{ width: '70%', margin: '10px 5px' }}
            >
              {i18n.t('modalDialog:cancel')}
            </Button>
            <Button
              className="redBtn header"
              onClick={console.log('agree')}
              style={{ width: '70%', margin: '10px 5px' }}
            >
              {i18n.t('modalDialog:agree')}
            </Button>
          </Col>
        );
      case SHOW_ERROR_MODAL:
        return (
          <Col
            xs={10}
            sm={10}
            md={10}
            lg={8}
            className="flexContainerColumn centred"
          >
            <Button className="redBtn header uppercase" onClick={toggle}>
              {i18n.t('modalDialog:accept')}
            </Button>
          </Col>
        );
      case SHOW_SHARE_MODAL:
        return (
          <Col
            xs={10}
            sm={10}
            md={10}
            lg={10}
            className="centred noPadding"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
            }}
          >
            <FacebookButton
              disabled={false}
              selectedAddedSticker={
                store.getState().selectedSticker.selectedAddedSticker
              }
            />
          </Col>
        );
      case SHOW_DELETE_MODAL:
        return (
          <Col
            xs={10}
            sm={10}
            md={10}
            lg={10}
            className="centred noPadding"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
            }}
          >
            <Button
              className="greenBtn header danger secondary"
              onClick={toggle}
              style={{ width: '70%', margin: '10px 5px' }}
            >
              {i18n.t('modalDialog:cancel')}
            </Button>
            <Button
              className="redBtn header"
              onClick={onDelete}
              style={{ width: '70%', margin: '10px 5px' }}
            >
              {i18n.t('modalDialog:continue')}
            </Button>
          </Col>
        );
      case SHOW_CONFIRM_DIALOG:
        return (
          <Col
            xs={10}
            sm={10}
            md={10}
            lg={10}
            className="centred noPadding"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
            }}
          >
            <Button
              className="greenBtn header"
              onClick={onConfirm}
              style={{ width: '70%', margin: '10px 5px' }}
            >
              {i18n.t('modalDialog:yes')}
            </Button>
            <Button
              className="redBtn header"
              onClick={() => {
                if (onDeny) onDeny();
                else toggle();
              }}
              style={{ width: '70%', margin: '10px 5px' }}
            >
              {i18n.t('modalDialog:no')}
            </Button>
          </Col>
        );
      default:
        return null;
    }
  };

  const renderHeaderText = () => {
    switch (type) {
      case SHOW_ERROR_MODAL:
        return (
          <div className="fullWidth centredText danger header errorModalHeader">
            {i18n.t('modalDialog:headers.error').toUpperCase()}
          </div>
        );
      default:
        return null;
    }
  };

  const renderContent = () => {
    return (
      <p className="flexContainerCentered centredText modalMsg">{message}</p>
    );
  };

  const renderModalId = () => {
    switch (type) {
      case SHOW_ERROR_MODAL:
        return 'errorModal';
      default:
        return null;
    }
  };

  return (
    <div className="flexContainerCentered modal">
      <Modal id={renderModalId()} isOpen={isOpen} toggle={toggle}>
        <ModalHeader className="modalHeader" toggle={toggle} />
        <ModalBody>
          {renderHeaderText()}
          {renderContent()}
          {renderButton()}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ModalDialog;
