import React from 'react';

const Family = ({ width, height }) => {
    const viewBoxValues = '0 0 164 164';

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox={viewBoxValues}
        >
            <defs>
                <filter
                    id="Rectangle_1731"
                    x="0"
                    y="0"
                    width="166"
                    height="166"
                    filterUnits="userSpaceOnUse"
                >
                    <feOffset dy="1" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="1.5" result="blur" />
                    <feFlood floodOpacity="0.161" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g
                id="Квадрат_с_картинка_-_Семейство"
                data-name="Квадрат с картинка - Семейство"
                transform="translate(-104.5 -117.5)"
            >
                <g
                    transform="matrix(1, 0, 0, 1, 104.5, 117.5)"
                    filter="url(#Rectangle_1731)"
                >
                    <g
                        id="Rectangle_1731-2"
                        data-name="Rectangle 1731"
                        transform="translate(4.5 3.5)"
                        fill="#fff"
                        stroke="#cacaca"
                        strokeWidth="2"
                    >
                        <rect width="157" height="157" rx="12" stroke="none" />
                        <rect
                            x="1"
                            y="1"
                            width="155"
                            height="155"
                            rx="11"
                            fill="none"
                        />
                    </g>
                </g>
                <g
                    id="Картинка_-_Семейство"
                    data-name="Картинка - Семейство"
                    transform="translate(104.34 118.601)"
                >
                    <path
                        id="Path_1663"
                        data-name="Path 1663"
                        d="M107.6,74.2c-11.4-1.5-1.1-5.4,2.6-12.6,4.8-9.6-7-23.5,4.4-24.3,6.1-.5,13.1,3.2,7.1,22.2C118.2,70.3,112.1,74.8,107.6,74.2Z"
                        fill="#3d2521"
                    />
                    <path
                        id="Path_1664"
                        data-name="Path 1664"
                        d="M70,90.5c-1.6,13.5-13.6,6.9-19.7,2-8.2-6.5-18.2-15-14.4-18.7,3.6-3.5,12.3-5.9,21.7-1.3S70.6,85.3,70,90.5Z"
                        fill="#442b27"
                    />
                    <path
                        id="Path_1665"
                        data-name="Path 1665"
                        d="M28.9,76.9C26.6,59.6,40,62.1,47.3,65.3c9.7,4.3,21.8,10,19.2,16.3C64,87.5,56.4,94.6,46,93.3A19.6,19.6,0,0,1,28.9,76.9Z"
                        fill="#492f2c"
                    />
                    <g id="Group_1364" data-name="Group 1364">
                        <path
                            id="Path_1666"
                            data-name="Path 1666"
                            d="M63.9,96.7c-2.4-1.5-3.6-2.5-4-3.8a20.137,20.137,0,0,1-4-2.9c-4.1-3.7-6.3-8-4.8-9.6.8-.8,2.3-.8,4.2,0-3.2-4.1-6.9-7.9-6.9-7.9s-.5,12.3-3.1,16.3-5,5.9-14.1,7.2-7.5,14-7.5,14,8.9,18.3,7.6,20.5c-1.2,2.2,40.9-25.8,40.9-25.8V97.5A9.479,9.479,0,0,1,63.9,96.7Z"
                            fill="#fac5b7"
                        />
                        <path
                            id="Path_1667"
                            data-name="Path 1667"
                            d="M51,80.4c-1.4,1.6.7,5.9,4.8,9.6a20.137,20.137,0,0,0,4,2.9,8.239,8.239,0,0,1,.1-3.8c.4-1.7-2-5.3-4.7-8.7C53.3,79.6,51.8,79.6,51,80.4Z"
                            fill="#efa7a1"
                        />
                    </g>
                    <path
                        id="Path_1668"
                        data-name="Path 1668"
                        d="M75.6,81.3C63,86.5,62.6,74,63.7,66.9c1.5-9.5,3.7-21.4,8.9-20.1,4.8,1.1,11.4,6.8,12.3,16.3C85.7,72.6,80.4,79.3,75.6,81.3Z"
                        fill="#442b27"
                    />
                    <path
                        id="Path_1669"
                        data-name="Path 1669"
                        d="M94.4,89.4c2.9-1.7,3.5-5.4,3.1-11.2,1.6-.8,5,4.8,6.6,3.4,4.9-4.3,7.6-9.3,5.9-11.3-.9-1-2.8-1-5-.1,3.9-4.8,11.4-14.4,11.4-14.4s.8,18.2,2.8,23.6c1.9,5.1,3.4,8,14.2,9.7,10.9,1.6,8.6,16.7,8.6,16.7s-10.8,21.6-9.4,24.2S84.5,98.7,84.5,98.7L83.7,94A48.631,48.631,0,0,0,94.4,89.4Z"
                        fill="#f3bba7"
                    />
                    <path
                        id="Path_1670"
                        data-name="Path 1670"
                        d="M63.9,96.4c-.5-.3-.9-.6-1.3-.8a33.368,33.368,0,0,1-11.5,1.9,30.5,30.5,0,0,1-14-3.1A46.931,46.931,0,0,1,31,95.6c-9.2,1.2-7.5,14-7.5,14s.7,7.4,7.6,20.5c3.8,7.3,40.9-25.8,40.9-25.8V97.1C72.1,97.2,68.2,99.1,63.9,96.4Z"
                        fill="#918ebd"
                    />
                    <path
                        id="Path_1671"
                        data-name="Path 1671"
                        d="M90.8,89.3c.6-.4,1.2-.7,1.7-1.1a44.522,44.522,0,0,0,15.3,2.6,41.367,41.367,0,0,0,18.7-4.1,72.383,72.383,0,0,0,8.1,1.6c12.3,1.7,10.4,15.5,10,18.7-.5,3.2-8.4,24.4-10,25.9-6.5,6.2-59.1-14.3-59.1-14.3l4.2-28.2A13,13,0,0,0,90.8,89.3Z"
                        fill="#9aa15d"
                    />
                    <path
                        id="Path_1672"
                        data-name="Path 1672"
                        d="M83.9,120.8c-7.9,11.2-19.2,8.5-28.6,6.9-8.4-1.5-6.2-11,1.7-22.2S76.1,85.1,84,90.6C91.8,96,91.8,109.6,83.9,120.8Z"
                        fill="#f1f4f3"
                    />
                    <circle
                        id="Ellipse_889"
                        data-name="Ellipse 889"
                        cx="11.2"
                        cy="11.2"
                        r="11.2"
                        transform="translate(66.8 84.7)"
                        fill="#fcd6c9"
                    />
                    <path
                        id="Path_1673"
                        data-name="Path 1673"
                        d="M71.8,88.8a1.5,1.5,0,0,0,.6,1.1,2.1,2.1,0,0,0,1.2.3c.1,0,.1,0,.1-.1s-.1-.2-.4-.3-.6,0-.8-.2a1.083,1.083,0,0,1-.7-.8Z"
                        fill="#3b2314"
                    />
                    <path
                        id="Path_1674"
                        data-name="Path 1674"
                        d="M83.5,94.4a2.708,2.708,0,0,1-1.3,0,2.351,2.351,0,0,1-.8-1v-.1a.619.619,0,0,1,.4.2c.2.2.2.5.5.7.7.3,1.2.1,1.2.2Z"
                        fill="#3b2314"
                    />
                    <path
                        id="Path_1675"
                        data-name="Path 1675"
                        d="M75.2,92.2a1.627,1.627,0,0,1,1.3-.1,1.912,1.912,0,0,1,.9.9v.1a.619.619,0,0,1-.4-.2c-.2-.2-.3-.5-.6-.6a2.745,2.745,0,0,0-1.2-.1Z"
                        fill="#efa7a1"
                    />
                    <path
                        id="Path_1676"
                        data-name="Path 1676"
                        d="M72,95.4a3.394,3.394,0,0,0,1.3,2.2,3.969,3.969,0,0,0,3.4.4,17.259,17.259,0,0,1-2.6-.5A4.79,4.79,0,0,1,72,95.4Z"
                        fill="#d85b73"
                    />
                    <path
                        id="Path_1677"
                        data-name="Path 1677"
                        d="M92.2,100.5c-3.6,4.8-1.3-8.4-11.3-13.4-8.3-4.2-13.2,3.6-9.9-1.3s10.6-6,16.2-2.2C92.8,87.2,98.5,92.3,92.2,100.5Z"
                        fill="#f1f4f3"
                    />
                    <ellipse
                        id="Ellipse_890"
                        data-name="Ellipse 890"
                        cx="4.1"
                        cy="2.9"
                        rx="4.1"
                        ry="2.9"
                        transform="translate(83.063 99.711) rotate(-26.129)"
                        fill="#fcd6c9"
                    />
                    <path
                        id="Path_1678"
                        data-name="Path 1678"
                        d="M74.9,83.4s2.3-3.4,9.5-3c3.7.2,7.9,2.1,9.7,5.3.9,1.7,3.1,5.5-.3,12.3-.9,1.9-5.2-6.5-5.2-6.5Z"
                        fill="#f1f4f3"
                    />
                    <ellipse
                        id="Ellipse_891"
                        data-name="Ellipse 891"
                        cx="20.601"
                        cy="16.1"
                        rx="20.601"
                        ry="16.1"
                        transform="translate(45.816 87.947) rotate(-80.154)"
                        fill="#fac5b7"
                    />
                    <path
                        id="Path_1679"
                        data-name="Path 1679"
                        d="M76,44.6c10.2,10.1-3.3,15.9-11.5,17.8-11,2.6-24.8,5.6-25.3,0-.5-5.2,3.3-14.1,13.5-19.1S72,40.7,76,44.6Z"
                        fill="#492f2c"
                    />
                    <ellipse
                        id="Ellipse_892"
                        data-name="Ellipse 892"
                        cx="4.3"
                        cy="6.5"
                        rx="4.3"
                        ry="6.5"
                        transform="matrix(0.95, -0.313, 0.313, 0.95, 41.085, 63.174)"
                        fill="#fac5b7"
                    />
                    <path
                        id="Path_1680"
                        data-name="Path 1680"
                        d="M81.6,72.4a3.509,3.509,0,0,0-.8-2.4,4.007,4.007,0,0,0-2.3-1c-.1,0-.2,0-.2.1-.1.1.1.5.6.7a5.228,5.228,0,0,1,1.6.6,3.871,3.871,0,0,1,1.1,2.2Z"
                        fill="#492f2c"
                    />
                    <path
                        id="Path_1681"
                        data-name="Path 1681"
                        d="M66.3,62.8a5.517,5.517,0,0,1,3.1-.2,5,5,0,0,1,2.4,2,.367.367,0,0,1,.1.3c-.1.1-.5.2-1.1-.3-.5-.5-.8-1.1-1.5-1.4-1.8-.6-3,0-3-.4C66.3,62.7,66.2,62.8,66.3,62.8Z"
                        fill="#492f2c"
                    />
                    <path
                        id="Path_1682"
                        data-name="Path 1682"
                        d="M63.1,68.4a4.439,4.439,0,0,0,1.4,2.8,5.611,5.611,0,0,0,3,.8.367.367,0,0,0,.3-.1c.1-.2-.2-.5-.9-.7a3.513,3.513,0,0,1-2-.5,4.793,4.793,0,0,1-1.7-2.5C63.1,68.3,63.1,68.3,63.1,68.4Z"
                        fill="#5f3d33"
                    />
                    <path
                        id="Path_1683"
                        data-name="Path 1683"
                        d="M75.6,75a3.263,3.263,0,0,0,1.6,1.8,2.7,2.7,0,0,0,2.1-.3c.1,0,.1-.1.1-.2a.594.594,0,0,0-.7-.3c-.5.1-.8.4-1.4.2a3.077,3.077,0,0,1-1.7-1.5Z"
                        fill="#5f3d33"
                    />
                    <path
                        id="Path_1684"
                        data-name="Path 1684"
                        d="M63.2,78.4A4.956,4.956,0,0,0,65,81.5a5.644,5.644,0,0,0,4.7.6c.4-.1-2,0-3.6-.7A6.779,6.779,0,0,1,63.2,78.4Z"
                        fill="#cb4a5a"
                    />
                    <path
                        id="Path_1685"
                        data-name="Path 1685"
                        d="M65.5,101.7s4.5,4.5,10.1,5.4c7.9,1.2,12.8-4,12-3.7-1,.4-.7-1-1.4-.5a12.957,12.957,0,0,1-8.4,2.8C71.6,105.6,66.5,102.1,65.5,101.7Z"
                        fill="#f3cbc1"
                    />
                    <path
                        id="Path_1686"
                        data-name="Path 1686"
                        d="M88.9,97.3c.4,1,2.3-5.6-4.5-10.6-3.9-2.9-7.5-3.1-9.6-.8-.4.5,3.6-.6,7.3,1.9C85.5,89.9,87.6,94,88.9,97.3Z"
                        fill="#fff"
                    />
                    <path
                        id="Path_1687"
                        data-name="Path 1687"
                        d="M79.1,108.5c.1.1.1.3-.1.3-1.4-.1-7.9-.8-10.6-3.1-3.7-3.3-4-7.3-1.9-9.7.4-.5.1,3.9,3.3,7.4C72.4,106.1,77.9,107.7,79.1,108.5Z"
                        fill="#fff"
                    />
                    <path
                        id="Path_1688"
                        data-name="Path 1688"
                        d="M68.1,118.2s-5.2-1.6-7.6-5.4c-3.4-5.4-3.1-8.7-1.1-10.7.6-.6-.2,4.4,1.4,8.3C62.9,115,67.5,117.6,68.1,118.2Z"
                        fill="#dfe6e9"
                    />
                    <path
                        id="Path_1689"
                        data-name="Path 1689"
                        d="M72.9,72.4s-.9.8-.3,3.1c.1.4-1.8.3-1.7.3,1,.5,2.5.3,2.3-.1A4.143,4.143,0,0,1,72.9,72.4Z"
                        fill="#efa7a1"
                        fillRule="evenodd"
                    />
                    <path
                        id="Path_1690"
                        data-name="Path 1690"
                        d="M42.6,111.1l7,10.8.9-4.2a4.906,4.906,0,0,1-2.5-1.2C46.7,115.4,42.6,111.1,42.6,111.1Z"
                        fill="#686fa5"
                    />
                    <path
                        id="Path_1691"
                        data-name="Path 1691"
                        d="M118.7,109.8l-8.7,9.6,4.3-.3Z"
                        fill="#838647"
                    />
                    <g id="Group_1365" data-name="Group 1365">
                        <path
                            id="Path_1692"
                            data-name="Path 1692"
                            d="M82.8,65.4c11.7,7,13.9-5.3,13.8-12.5-.1-9.6-.5-21.7-5.8-21.2-5,.4-12.3,5-14.5,14.3S78.3,62.7,82.8,65.4Z"
                            fill="#3d2521"
                        />
                        <path
                            id="Path_1693"
                            data-name="Path 1693"
                            d="M110.8,72c1.4,2.3-2.2,7.2-8.1,10.8a25.349,25.349,0,0,1-5.5,2.7,24.579,24.579,0,0,0,.3-5.6c-.1-2.2,4-5.3,8.1-8.9C108.1,70.5,110,70.8,110.8,72Z"
                            fill="#efa796"
                        />
                        <path
                            id="Path_1694"
                            data-name="Path 1694"
                            d="M78.4,61.3c2.2,12.2,7.4,20.5,22.7,18.9,11.7-1.2,16.9-13,14.7-25.1-2.2-12.2-12.4-20.6-22.7-18.9S76.2,49.1,78.4,61.3Z"
                            fill="#f3bba7"
                        />
                        <path
                            id="Path_1695"
                            data-name="Path 1695"
                            d="M85.3,31c-17.7,13.8,5.5,3.3,14.1,6,11.5,3.7,5.5,21.7,20.4,15.5,7-2.9,4.6-16.8-5.9-23S90.7,26.7,85.3,31Z"
                            fill="#3d2521"
                        />
                        <path
                            id="Path_1696"
                            data-name="Path 1696"
                            d="M110.9,51.8c-1.2,3.8-.2,7.5,2.3,8.3s5.6-1.6,6.8-5.3c1.2-3.8.2-7.4-2.3-8.3C112.6,44.4,112.1,48,110.9,51.8Z"
                            fill="#f3bba7"
                        />
                        <path
                            id="Path_1697"
                            data-name="Path 1697"
                            d="M99.1,57.5a3.1,3.1,0,0,1-2.5,1.9,6.633,6.633,0,0,1-3.1-.4c-.1-.1-.2-.1-.2-.2,0-.2.4-.4,1.1-.3s1,.6,2,.4a3.472,3.472,0,0,0,2.4-1.7C99,57.2,99.2,57.3,99.1,57.5Z"
                            fill="#603813"
                        />
                        <path
                            id="Path_1698"
                            data-name="Path 1698"
                            d="M79.8,60.9a3.451,3.451,0,0,0,2.4.8,5.393,5.393,0,0,0,2.4-1.3.31.31,0,0,0,.1-.2c-.1-.1-.4-.2-1,.1-.5.3-.8.8-1.5.9-1.6.2-2.1-.7-2.3-.5C79.8,60.7,79.7,60.8,79.8,60.9Z"
                            fill="#603813"
                        />
                        <path
                            id="Path_1699"
                            data-name="Path 1699"
                            d="M87.1,70.9a8.04,8.04,0,0,0,4.7,1,5.807,5.807,0,0,0,4.4-3.3c.2-.4-1.6,1.6-3.6,2.3A11.747,11.747,0,0,1,87.1,70.9Z"
                            fill="#a5515f"
                        />
                        <path
                            id="Path_1700"
                            data-name="Path 1700"
                            d="M86.1,56.5s2.2,2.3,1.5,7.7c-.1,1.1,3.8,1.2,3.5,1.3-1.9,1-5,0-4.8-.8C87.6,59.5,86.1,56.5,86.1,56.5Z"
                            fill="#efa796"
                            fillRule="evenodd"
                        />
                        <path
                            id="Path_1701"
                            data-name="Path 1701"
                            d="M90.2,52c.5,1.6,2.3-1.3,4.9-1.6s4.9,2.1,4.4.5-2.9-2.6-5.5-2.3C91.4,49,89.7,50.5,90.2,52Z"
                            fill="#3d2521"
                        />
                        <path
                            id="Path_1702"
                            data-name="Path 1702"
                            d="M83.7,53.8c.2,1.5-2-.3-3.8.3s-2.7,3.5-2.8,2c-.2-1.5,1.2-3.2,3-3.8C81.9,51.6,83.6,52.3,83.7,53.8Z"
                            fill="#3d2521"
                        />
                        <ellipse
                            id="Ellipse_893"
                            data-name="Ellipse 893"
                            cx="6.8"
                            cy="2.2"
                            rx="6.8"
                            ry="2.2"
                            transform="translate(84.427 39.907) rotate(-33.552)"
                            fill="#3d2521"
                        />
                        <ellipse
                            id="Ellipse_894"
                            data-name="Ellipse 894"
                            cx="6.8"
                            cy="2.2"
                            rx="6.8"
                            ry="2.2"
                            transform="translate(91.931 37.617) rotate(-33.552)"
                            fill="#3d2521"
                        />
                        <ellipse
                            id="Ellipse_895"
                            data-name="Ellipse 895"
                            cx="6.8"
                            cy="2.2"
                            rx="6.8"
                            ry="2.2"
                            transform="translate(79.327 39.906) rotate(-33.552)"
                            fill="#3d2521"
                        />
                    </g>
                    <g id="Group_1366" data-name="Group 1366">
                        <path
                            id="Path_1703"
                            data-name="Path 1703"
                            d="M80.1,87.4c.8-.2-.4-.9-.3-2s1.5-1.9.7-1.8a2.419,2.419,0,0,0-1.6,2.1C78.9,86.9,79.4,87.6,80.1,87.4Z"
                            fill="#ea9a78"
                        />
                        <path
                            id="Path_1704"
                            data-name="Path 1704"
                            d="M79.9,87.2c.7-.3-.9-.7-1.3-1.5-.4-.9.6-1.9-.1-1.6a1.722,1.722,0,0,0,1.4,3.1Z"
                            fill="#ea9a78"
                        />
                    </g>
                    <path
                        id="Path_1705"
                        data-name="Path 1705"
                        d="M85.8,124.9c2.7,1.1-12.8,6-26.8,6.7-14.1.7-31.7,2.5-25.7-6.3,3.5-5.2,18.9-4,27.3-3.7C68.9,121.9,76.6,121.2,85.8,124.9Z"
                        fill="#fac7ba"
                    />
                    <g id="Group_1368" data-name="Group 1368">
                        <path
                            id="Path_1706"
                            data-name="Path 1706"
                            d="M93,109.8c-.5-.6-2.8.9-5.1,2.4,1.5-1.3,2.7-3.1,2.4-3.6-.5-.8-2.6.8-4.9,2.4a53.036,53.036,0,0,1-5.9,3.5l-.1.1c.8-2.6,1.1-4.1.4-4.5-.9-.5-2.6,1.9-4.3,5.1-1.5,2.8-2.5,5.1-2,6v.1c2.2,5.1,6.9,2.8,10.4,1.4a19.016,19.016,0,0,0,5.8-3.5c1.6-1.3,3-2.7,3-3.2,0-.6-1.4,0-3.1.9.1-.1,4-3.2,3.7-3.9s-3.1.9-4.8,1.7C91.3,112.9,93.5,110.5,93,109.8Z"
                            fill="#fac7ba"
                        />
                        <g id="Group_1367" data-name="Group 1367">
                            <path
                                id="Path_1707"
                                data-name="Path 1707"
                                d="M85.1,114.1s-.1.5.3.2,2.9-2.5,2.9-2.5Z"
                                fill="#efb29f"
                            />
                            <path
                                id="Path_1708"
                                data-name="Path 1708"
                                d="M87.4,115.4s-.1.5.3.3c.3-.2,2.3-1.9,2.3-1.9Z"
                                fill="#efb29f"
                            />
                            <path
                                id="Path_1709"
                                data-name="Path 1709"
                                d="M88,117.9s-.1.5.3.3c.3-.2,2.3-1.9,2.3-1.9Z"
                                fill="#efb29f"
                            />
                        </g>
                    </g>
                    <path
                        id="Path_1710"
                        data-name="Path 1710"
                        d="M133.1,126.3c4.1,11.3-15.7,10-34.1,8.2-27.5-2.6-31.5-7-31.3-10.5s15.4-3.1,33.8-4.3C120,118.4,130.4,119,133.1,126.3Z"
                        fill="#f3bba7"
                    />
                    <g id="Group_1369" data-name="Group 1369">
                        <path
                            id="Path_1711"
                            data-name="Path 1711"
                            d="M52.6,117c.4-1,3.8,0,7.4.9-2.4-1-4.9-2.4-4.4-4.3.3-1.2,3.6-.1,7.1,1,1.9.5,3.7,1.1,8.2,2.5h0c-3.2-2.3-3.8-4.7-2.1-5.1,1.3-.3,4.2,1.5,7.8,4.4,3.2,2.5,5.2,3.7,5,6.7v.2c-.6,7.2-7.4,7.1-12.4,6.6a43.536,43.536,0,0,1-8.8-2.2c-2.5-1-5.4-2.1-5-2.9.4-1.1,1.8-.5,4.2-.2-.2-.1-6.7-1.9-6.2-3.3.5-1.5,4.2-.2,6.7.2C55.8,120.6,52,118.6,52.6,117Z"
                            fill="#f3bba7"
                        />
                        <path
                            id="Path_1712"
                            data-name="Path 1712"
                            d="M63.8,119s.3.6-.3.4-4.6-2.1-4.6-2.1Z"
                            fill="#efa796"
                        />
                        <path
                            id="Path_1713"
                            data-name="Path 1713"
                            d="M61.1,121.7s.3.5-.2.4-3.6-1.5-3.6-1.5Z"
                            fill="#efa796"
                        />
                        <path
                            id="Path_1714"
                            data-name="Path 1714"
                            d="M61.4,124.9s.3.5-.2.4-3.6-1.5-3.6-1.5Z"
                            fill="#efa796"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default Family;
