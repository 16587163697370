import React from 'react';

const BreastfeedingSticker = ({ width, height, svgClicked }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="112"
            height="112"
            viewBox="0 0 112 112"
        >
            <g
                id="Кърмеща_с_таймер_и_зелена_рамка"
                data-name="Кърмеща с таймер и зелена рамка"
                transform="translate(-44 -78)"
            >
                <g
                    id="Rectangle_1179"
                    data-name="Rectangle 1179"
                    transform="translate(44 78)"
                    fill="#fff"
                    stroke="#87ba3b"
                    strokeWidth="2"
                >
                    <rect width="112" height="112" rx="11" stroke="none" />
                    <rect
                        x="1"
                        y="1"
                        width="110"
                        height="110"
                        rx="10"
                        fill="none"
                    />
                </g>
                <g
                    id="Кърмеща_с_таймер"
                    data-name="Кърмеща с таймер"
                    transform="translate(-401.59 -528.084)"
                >
                    <g
                        id="Group_2000"
                        data-name="Group 2000"
                        transform="translate(505.393 618.851)"
                    >
                        <circle
                            id="Ellipse_1204"
                            data-name="Ellipse 1204"
                            cx="19.966"
                            cy="19.966"
                            r="19.966"
                            transform="translate(0 6.686)"
                            fill="#c7c9cb"
                        />
                        <rect
                            id="Rectangle_1902"
                            data-name="Rectangle 1902"
                            width="4.601"
                            height="3.944"
                            transform="translate(18 4.582)"
                            fill="#757b7b"
                        />
                        <rect
                            id="Rectangle_1903"
                            data-name="Rectangle 1903"
                            width="3.248"
                            height="2.108"
                            transform="matrix(0.733, 0.68, -0.68, 0.733, 33.677, 10.008)"
                            fill="#757b7b"
                        />
                        <circle
                            id="Ellipse_1205"
                            data-name="Ellipse 1205"
                            cx="19.966"
                            cy="19.966"
                            r="19.966"
                            transform="translate(0.265 6.285)"
                            fill="#9ac23e"
                        />
                        <circle
                            id="Ellipse_1206"
                            data-name="Ellipse 1206"
                            cx="17.198"
                            cy="17.198"
                            r="17.198"
                            transform="translate(3.033 9.053)"
                            fill="#fff"
                        />
                        <path
                            id="Path_2786"
                            data-name="Path 2786"
                            d="M536.577,629.578a17.433,17.433,0,1,0,17.433,17.433A17.453,17.453,0,0,0,536.577,629.578Zm0,34.631a17.2,17.2,0,1,1,17.2-17.2A17.218,17.218,0,0,1,536.577,664.209Z"
                            transform="translate(-516.346 -620.76)"
                            fill="#248332"
                        />
                        <path
                            id="Path_2787"
                            data-name="Path 2787"
                            d="M520.77,646.732a17.152,17.152,0,0,1,7.766-14.364A17.171,17.171,0,1,0,546,661.9a17.172,17.172,0,0,1-25.229-15.164Z"
                            transform="translate(-516.395 -621.256)"
                            fill="#e5ebb6"
                        />
                        <g
                            id="Group_1997"
                            data-name="Group 1997"
                            transform="translate(4.655 10.517)"
                        >
                            <g id="Group_1982" data-name="Group 1982">
                                <g
                                    id="Group_1978"
                                    data-name="Group 1978"
                                    transform="translate(15.281)"
                                >
                                    <g id="Group_1976" data-name="Group 1976">
                                        <path
                                            id="Path_2788"
                                            data-name="Path 2788"
                                            d="M540.3,634.025a.313.313,0,0,1-.313-.313v-1.754a.313.313,0,1,1,.626,0v1.754A.313.313,0,0,1,540.3,634.025Z"
                                            transform="translate(-539.991 -631.644)"
                                            fill="#6a6c6c"
                                        />
                                    </g>
                                    <g
                                        id="Group_1977"
                                        data-name="Group 1977"
                                        transform="translate(0 28.565)"
                                    >
                                        <path
                                            id="Path_2789"
                                            data-name="Path 2789"
                                            d="M540.3,669.014a.313.313,0,0,1-.313-.313v-2a.313.313,0,0,1,.626,0v2A.314.314,0,0,1,540.3,669.014Z"
                                            transform="translate(-539.991 -666.392)"
                                            fill="#6a6c6c"
                                        />
                                    </g>
                                </g>
                                <g
                                    id="Group_1981"
                                    data-name="Group 1981"
                                    transform="translate(0 15.281)"
                                >
                                    <g
                                        id="Group_1979"
                                        data-name="Group 1979"
                                        transform="translate(28.658)"
                                    >
                                        <path
                                            id="Path_2790"
                                            data-name="Path 2790"
                                            d="M558.479,650.858h-1.9a.313.313,0,1,1,0-.626h1.9a.313.313,0,1,1,0,.626Z"
                                            transform="translate(-556.264 -650.232)"
                                            fill="#6a6c6c"
                                        />
                                    </g>
                                    <g id="Group_1980" data-name="Group 1980">
                                        <path
                                            id="Path_2791"
                                            data-name="Path 2791"
                                            d="M523.643,650.858h-1.927a.313.313,0,0,1,0-.626h1.927a.313.313,0,0,1,0,.626Z"
                                            transform="translate(-521.403 -650.232)"
                                            fill="#6a6c6c"
                                        />
                                    </g>
                                </g>
                            </g>
                            <g
                                id="Group_1989"
                                data-name="Group 1989"
                                transform="translate(2.165 2.165)"
                            >
                                <g
                                    id="Group_1985"
                                    data-name="Group 1985"
                                    transform="translate(0 5.701)"
                                >
                                    <g id="Group_1983" data-name="Group 1983">
                                        <path
                                            id="Path_2792"
                                            data-name="Path 2792"
                                            d="M525.235,642.12a.159.159,0,0,1-.078-.021l-1.042-.594a.157.157,0,1,1,.155-.272l1.042.594a.157.157,0,0,1-.077.293Z"
                                            transform="translate(-524.036 -641.212)"
                                            fill="#6a6c6c"
                                        />
                                    </g>
                                    <g
                                        id="Group_1984"
                                        data-name="Group 1984"
                                        transform="translate(25.503 14.548)"
                                    >
                                        <path
                                            id="Path_2793"
                                            data-name="Path 2793"
                                            d="M556.258,659.817a.167.167,0,0,1-.078-.021l-1.042-.594a.157.157,0,1,1,.155-.272l1.042.594a.157.157,0,0,1-.077.293Z"
                                            transform="translate(-555.059 -658.909)"
                                            fill="#6a6c6c"
                                        />
                                    </g>
                                </g>
                                <g
                                    id="Group_1988"
                                    data-name="Group 1988"
                                    transform="translate(5.701)"
                                >
                                    <g
                                        id="Group_1986"
                                        data-name="Group 1986"
                                        transform="translate(14.548)"
                                    >
                                        <path
                                            id="Path_2794"
                                            data-name="Path 2794"
                                            d="M548.825,635.632a.159.159,0,0,1-.078-.021.157.157,0,0,1-.058-.214l.594-1.042a.157.157,0,0,1,.272.155l-.594,1.042A.156.156,0,0,1,548.825,635.632Z"
                                            transform="translate(-548.668 -634.277)"
                                            fill="#6a6c6c"
                                        />
                                    </g>
                                    <g
                                        id="Group_1987"
                                        data-name="Group 1987"
                                        transform="translate(0 25.503)"
                                    >
                                        <path
                                            id="Path_2795"
                                            data-name="Path 2795"
                                            d="M531.128,666.655a.157.157,0,0,1-.136-.234l.594-1.042a.157.157,0,0,1,.273.155l-.6,1.042A.157.157,0,0,1,531.128,666.655Z"
                                            transform="translate(-530.971 -665.3)"
                                            fill="#6a6c6c"
                                        />
                                    </g>
                                </g>
                            </g>
                            <g
                                id="Group_1996"
                                data-name="Group 1996"
                                transform="translate(2.295 2.295)"
                            >
                                <g
                                    id="Group_1992"
                                    data-name="Group 1992"
                                    transform="translate(5.346)"
                                >
                                    <g id="Group_1990" data-name="Group 1990">
                                        <path
                                            id="Path_2796"
                                            data-name="Path 2796"
                                            d="M531.467,635.78a.157.157,0,0,1-.135-.076l-.612-1.032a.156.156,0,0,1,.269-.159l.612,1.032a.156.156,0,0,1-.135.236Z"
                                            transform="translate(-530.698 -634.436)"
                                            fill="#6a6c6c"
                                        />
                                    </g>
                                    <g
                                        id="Group_1991"
                                        data-name="Group 1991"
                                        transform="translate(14.979 25.252)"
                                    >
                                        <path
                                            id="Path_2797"
                                            data-name="Path 2797"
                                            d="M549.688,666.5a.156.156,0,0,1-.134-.077l-.612-1.032a.157.157,0,0,1,.27-.16l.612,1.031a.156.156,0,0,1-.054.215A.159.159,0,0,1,549.688,666.5Z"
                                            transform="translate(-548.919 -665.154)"
                                            fill="#6a6c6c"
                                        />
                                    </g>
                                </g>
                                <g
                                    id="Group_1995"
                                    data-name="Group 1995"
                                    transform="translate(0 5.346)"
                                >
                                    <g
                                        id="Group_1993"
                                        data-name="Group 1993"
                                        transform="translate(25.252)"
                                    >
                                        <path
                                            id="Path_2798"
                                            data-name="Path 2798"
                                            d="M555.069,641.864a.156.156,0,0,1-.08-.291l1.032-.612a.156.156,0,0,1,.159.269l-1.032.612A.155.155,0,0,1,555.069,641.864Z"
                                            transform="translate(-554.912 -640.939)"
                                            fill="#6a6c6c"
                                        />
                                    </g>
                                    <g
                                        id="Group_1994"
                                        data-name="Group 1994"
                                        transform="translate(0 14.979)"
                                    >
                                        <path
                                            id="Path_2799"
                                            data-name="Path 2799"
                                            d="M524.351,660.086a.157.157,0,0,1-.08-.291l1.032-.612a.157.157,0,0,1,.159.27l-1.031.612A.159.159,0,0,1,524.351,660.086Z"
                                            transform="translate(-524.195 -659.16)"
                                            fill="#6a6c6c"
                                        />
                                    </g>
                                </g>
                            </g>
                        </g>
                        <path
                            id="Path_2800"
                            data-name="Path 2800"
                            d="M550.975,654.194a10.72,10.72,0,0,0-3.655-14.713c-.121-.073-.242-.141-.365-.207l-6.52,9.578,7.635,8.571A14.345,14.345,0,0,0,550.975,654.194Z"
                            transform="translate(-520.134 -622.485)"
                            fill="#b5ce55"
                        />
                        <rect
                            id="Rectangle_1904"
                            data-name="Rectangle 1904"
                            width="1.124"
                            height="12.553"
                            rx="0.562"
                            transform="translate(26.917 16.324) rotate(36.219)"
                            fill="#313133"
                            opacity="0.47"
                        />
                        <rect
                            id="Rectangle_1905"
                            data-name="Rectangle 1905"
                            width="1.693"
                            height="8.32"
                            rx="0.846"
                            transform="matrix(-0.801, 0.599, -0.599, -0.801, 25.795, 32.186)"
                            fill="#464749"
                            opacity="0.47"
                        />
                        <g
                            id="Group_1998"
                            data-name="Group 1998"
                            transform="translate(8.556 26.652)"
                        >
                            <path
                                id="Path_2801"
                                data-name="Path 2801"
                                d="M526.3,654.156a.157.157,0,0,1-.039-.308l10.149-2.57a.155.155,0,0,1,.19.113.157.157,0,0,1-.113.191l-10.149,2.57A.16.16,0,0,1,526.3,654.156Z"
                                transform="translate(-526.148 -651.272)"
                                fill="#237632"
                            />
                        </g>
                        <rect
                            id="Rectangle_1906"
                            data-name="Rectangle 1906"
                            width="1.387"
                            height="12.553"
                            rx="0.694"
                            transform="translate(26.822 14.982) rotate(33.372)"
                            fill="#248332"
                        />
                        <rect
                            id="Rectangle_1907"
                            data-name="Rectangle 1907"
                            width="1.606"
                            height="8.32"
                            rx="0.803"
                            transform="translate(26.638 32.377) rotate(139.045)"
                            fill="#248332"
                        />
                        <circle
                            id="Ellipse_1207"
                            data-name="Ellipse 1207"
                            cx="2.025"
                            cy="2.025"
                            r="2.025"
                            transform="translate(18.206 24.225)"
                            fill="#309b37"
                        />
                        <circle
                            id="Ellipse_1208"
                            data-name="Ellipse 1208"
                            cx="0.762"
                            cy="0.762"
                            r="0.762"
                            transform="translate(19.469 25.489)"
                            fill="#247d34"
                        />
                        <path
                            id="Path_2802"
                            data-name="Path 2802"
                            d="M537.636,624.425h4.6v1.1l-4.6-.347Z"
                            transform="translate(-519.636 -619.843)"
                            fill="#6a6c6c"
                        />
                        <path
                            id="Path_2803"
                            data-name="Path 2803"
                            d="M556.61,631.026l2.411,2.176-.522.578-2.246-2.358Z"
                            transform="translate(-522.949 -621.017)"
                            fill="#6a6c6c"
                        />
                        <path
                            id="Path_2804"
                            data-name="Path 2804"
                            d="M542.852,619.058h-7.777a1.882,1.882,0,0,0-1.882,1.882v2.109a.519.519,0,0,0,.519.52h.062a49.322,49.322,0,0,0,10.425,0h.016a.519.519,0,0,0,.519-.52V620.94A1.882,1.882,0,0,0,542.852,619.058Z"
                            transform="translate(-518.846 -618.888)"
                            fill="#898f8f"
                        />
                        <path
                            id="Path_2805"
                            data-name="Path 2805"
                            d="M546.136,620.9c-.169-.62-.59-1.167-.891-.956-.246.172.1.51.34,1.107.195.482.076,1.194.27,1.141S546.3,621.523,546.136,620.9Z"
                            transform="translate(-520.975 -619.038)"
                            fill="#a6adad"
                        />
                        <path
                            id="Path_2806"
                            data-name="Path 2806"
                            d="M540.847,650.4c-.082-.19-.242-.345-.329-.264-.071.067.055.158.159.337.085.145.08.378.14.352S540.93,650.591,540.847,650.4Z"
                            transform="translate(-520.145 -624.414)"
                            fill="#309b37"
                        />
                        <ellipse
                            id="Ellipse_1209"
                            data-name="Ellipse 1209"
                            cx="4.477"
                            cy="0.277"
                            rx="4.477"
                            ry="0.277"
                            transform="translate(15.641)"
                            fill="#757b7b"
                        />
                        <g
                            id="Group_1999"
                            data-name="Group 1999"
                            transform="translate(32.632 7.192)"
                        >
                            <path
                                id="Path_2807"
                                data-name="Path 2807"
                                d="M561.2,630.713l-3.1-2.8a1.164,1.164,0,0,0-1.6.267l-.955,1.058a.32.32,0,0,0-.028.446l.016.016a21.17,21.17,0,0,0,4.172,3.766.321.321,0,0,0,.439-.075l.955-1.058A1.163,1.163,0,0,0,561.2,630.713Z"
                                transform="translate(-555.435 -627.617)"
                                fill="#898f8f"
                            />
                            <ellipse
                                id="Ellipse_1210"
                                data-name="Ellipse 1210"
                                cx="0.187"
                                cy="2.404"
                                rx="0.187"
                                ry="2.404"
                                transform="matrix(0.67, -0.742, 0.742, 0.67, 2.255, 0.277)"
                                fill="#757b7b"
                            />
                        </g>
                        <path
                            id="Path_2808"
                            data-name="Path 2808"
                            d="M562.025,632.532c.169-.265.229-.6.054-.636-.143-.033-.152.2-.289.486-.111.229-.408.418-.325.472S561.855,632.8,562.025,632.532Z"
                            transform="translate(-523.874 -621.172)"
                            fill="#a6adad"
                        />
                    </g>
                    <g
                        id="Group_2010"
                        data-name="Group 2010"
                        transform="translate(457.59 619.327)"
                    >
                        <path
                            id="Path_2809"
                            data-name="Path 2809"
                            d="M501.367,667.051c-1.361,11.4-11.481,5.849-16.625,1.721-6.894-5.533-15.369-12.665-12.158-15.768,3.029-2.927,10.385-5,18.333-1.122S501.9,662.617,501.367,667.051Z"
                            transform="translate(-460.133 -624.826)"
                            fill="#442826"
                        />
                        <path
                            id="Path_2810"
                            data-name="Path 2810"
                            d="M465.542,654.272c-1.971-14.694,9.379-12.547,15.524-9.842,8.237,3.626,18.458,8.49,16.257,13.809a16.88,16.88,0,0,1-17.343,9.9A16.6,16.6,0,0,1,465.542,654.272Z"
                            transform="translate(-458.965 -623.532)"
                            fill="#4b2c2d"
                        />
                        <g
                            id="Group_2001"
                            data-name="Group 2001"
                            transform="translate(1.956 26.937)"
                        >
                            <path
                                id="Path_2811"
                                data-name="Path 2811"
                                d="M494.106,672.7c-2.06-1.251-3.048-2.132-3.4-3.218a19.11,19.11,0,0,1-3.407-2.456c-3.468-3.1-5.288-6.726-4.064-8.094.635-.711,1.971-.668,3.592-.032-2.735-3.458-5.856-6.7-5.856-6.7s-.395,10.38-2.628,13.8-4.2,4.993-11.956,6.044-6.307,11.825-6.307,11.825,7.489,15.5,6.438,17.343,46.418-5.853,46.418-5.853l-11.862-22A7.677,7.677,0,0,1,494.106,672.7Z"
                                transform="translate(-459.969 -652.198)"
                                fill="#fab6a6"
                            />
                            <path
                                id="Path_2812"
                                data-name="Path 2812"
                                d="M488.183,660.271c-1.224,1.368.6,4.992,4.064,8.094a19.1,19.1,0,0,0,3.407,2.456,6.3,6.3,0,0,1,.119-3.221c.3-1.435-1.716-4.475-4-7.362C490.155,659.6,488.819,659.56,488.183,660.271Z"
                                transform="translate(-464.922 -653.542)"
                                fill="#ea9590"
                            />
                        </g>
                        <path
                            id="Path_2813"
                            data-name="Path 2813"
                            d="M511.27,654.962c-10.609,4.385-10.967-6.166-10.011-12.153,1.281-8.024,3.141-18.047,7.485-17.024,4.1.966,9.67,5.715,10.367,13.772S515.4,653.256,511.27,654.962Z"
                            transform="translate(-465.299 -620.548)"
                            fill="#442826"
                        />
                        <path
                            id="Path_2814"
                            data-name="Path 2814"
                            d="M502.019,677.7c-2.687-1.183-8.7-1.678-9.023-1.9a27.83,27.83,0,0,1-9.681,1.638,25.87,25.87,0,0,1-11.848-2.607,37.684,37.684,0,0,1-5.128,1c-7.752,1.051-6.771,9.479-6.307,11.825s2.782,9.837,8.078,21.182c3.533,7.568,24.595-3.791,25.621-10.982.943-6.613,4.594-9.081,6.3-9.425,5.858-1.183,12.137,10.28,12.137,10.28S508.552,680.578,502.019,677.7Z"
                            transform="translate(-457.97 -629.289)"
                            fill="#75934d"
                        />
                        <ellipse
                            id="Ellipse_1211"
                            data-name="Ellipse 1211"
                            cx="17.387"
                            cy="13.637"
                            rx="17.387"
                            ry="13.637"
                            transform="matrix(0.171, -0.985, 0.985, 0.171, 20.808, 40.01)"
                            fill="#fab8a9"
                        />
                        <path
                            id="Path_2815"
                            data-name="Path 2815"
                            d="M507.2,622.856c8.646,8.508-2.828,13.411-9.757,15.057-9.287,2.206-20.981,4.706-21.421.007-.415-4.433,2.8-11.945,11.41-16.1S503.834,619.545,507.2,622.856Z"
                            transform="translate(-460.863 -619.43)"
                            fill="#4b2c2d"
                        />
                        <ellipse
                            id="Ellipse_1212"
                            data-name="Ellipse 1212"
                            cx="3.664"
                            cy="5.473"
                            rx="3.664"
                            ry="5.473"
                            transform="matrix(0.95, -0.313, 0.313, 0.95, 16.818, 19.112)"
                            fill="#fab8a9"
                        />
                        <path
                            id="Path_2816"
                            data-name="Path 2816"
                            d="M519.118,651.515a3.163,3.163,0,0,0-.659-2.059,3.611,3.611,0,0,0-1.975-.856c-.1-.014-.171.009-.2.065-.053.1.076.381.546.557s.927.127,1.335.515a3.017,3.017,0,0,1,.9,1.879A.1.1,0,0,0,519.118,651.515Z"
                            transform="translate(-468.032 -624.62)"
                            fill="#442826"
                        />
                        <path
                            id="Path_2817"
                            data-name="Path 2817"
                            d="M504,642.219a4.43,4.43,0,0,1,2.617-.128,4.477,4.477,0,0,1,2.035,1.678c.072.1.086.191.043.254-.076.111-.453.127-.908-.287s-.658-.938-1.307-1.167c-1.472-.522-2.506.016-2.479-.349C504,642.175,503.937,642.249,504,642.219Z"
                            transform="translate(-465.843 -623.437)"
                            fill="#442826"
                        />
                        <path
                            id="Path_2818"
                            data-name="Path 2818"
                            d="M500.662,648.01a3.822,3.822,0,0,0,1.171,2.35,4.467,4.467,0,0,0,2.548.674c.123,0,.206-.041.232-.113.046-.127-.161-.443-.762-.571s-1.145.012-1.711-.381a3.649,3.649,0,0,1-1.434-2.091C500.668,647.9,500.653,647.945,500.662,648.01Z"
                            transform="translate(-465.254 -624.492)"
                            fill="#4d2e25"
                        />
                        <path
                            id="Path_2819"
                            data-name="Path 2819"
                            d="M513.533,654.762a3.156,3.156,0,0,0,1.343,1.552,2.408,2.408,0,0,0,1.772-.22.177.177,0,0,0,.115-.162c0-.117-.215-.31-.626-.233s-.714.355-1.17.206a2.778,2.778,0,0,1-1.44-1.262A.124.124,0,0,0,513.533,654.762Z"
                            transform="translate(-467.541 -625.696)"
                            fill="#4d2e25"
                        />
                        <path
                            id="Path_2820"
                            data-name="Path 2820"
                            d="M500.8,658.361a4.087,4.087,0,0,0,1.5,2.6,4.669,4.669,0,0,0,3.977.47,12.552,12.552,0,0,1-3.03-.567A5.554,5.554,0,0,1,500.8,658.361Z"
                            transform="translate(-465.278 -626.357)"
                            fill="#bd3448"
                        />
                        <path
                            id="Path_2821"
                            data-name="Path 2821"
                            d="M510.418,652.2s-.8.7-.229,2.585c.113.374-1.524.215-1.428.266.871.452,2.1.252,1.973-.044A3.527,3.527,0,0,1,510.418,652.2Z"
                            transform="translate(-466.694 -625.261)"
                            fill="#ea9590"
                            fillRule="evenodd"
                        />
                        <path
                            id="Path_2822"
                            data-name="Path 2822"
                            d="M509.34,699.6c-.5-1.26-2.225.108-4.068-.738-2.635-1.21-1.465-4.556-2.675-2.04-.587,1.22-1.45,1.849,2.515,3.667C506.955,701.329,511.138,704.165,509.34,699.6Z"
                            transform="translate(-465.508 -633.037)"
                            fill="#ea9590"
                        />
                        <g
                            id="Group_2002"
                            data-name="Group 2002"
                            transform="translate(16.324 54.396)"
                        >
                            <path
                                id="Path_2823"
                                data-name="Path 2823"
                                d="M477.447,691.783s5.091,8.849,4.2,10.269,15.112,0,15.112,0-.563-8.364-3.236-4.72c-2.4,3.275-8.272,3.3-10.989,1.113C480.78,697.026,477.447,691.783,477.447,691.783Z"
                                transform="translate(-477.447 -686.7)"
                                fill="#597530"
                            />
                            <path
                                id="Path_2824"
                                data-name="Path 2824"
                                d="M502.246,692.582a25.137,25.137,0,0,0-1.712,3.381,6.146,6.146,0,0,1-4.99,3.948c-.083-.029,3.353-1.039,4.25-4.322A5.007,5.007,0,0,1,502.246,692.582Z"
                                transform="translate(-480.667 -686.842)"
                                fill="#597530"
                            />
                            <path
                                id="Path_2825"
                                data-name="Path 2825"
                                d="M500.372,693.71a14.967,14.967,0,0,0-.381,2.467,13.868,13.868,0,0,0,.263,2.432,4.194,4.194,0,0,1-.8-2.514A3.81,3.81,0,0,1,500.372,693.71Z"
                                transform="translate(-481.362 -687.043)"
                                fill="#597530"
                            />
                            <path
                                id="Path_2826"
                                data-name="Path 2826"
                                d="M512.7,691.932a1.732,1.732,0,0,0-.228-.552,3.648,3.648,0,0,0-.3-.715,20.085,20.085,0,0,1-2.185-4.719c-.315-1.088-.355.562.26,2.573-.117.4-.312.525-.494.534-.132-.044-.259-.081-.386-.114l-.012-.006.007,0a4.561,4.561,0,0,0-1.882-.142A17.082,17.082,0,0,1,510.268,690c.3.183.581.377.839.57.061.11.12.221.187.329a14.215,14.215,0,0,1,1.22,2.844,3.822,3.822,0,0,0,.081-1.888C512.652,691.909,512.694,691.943,512.7,691.932Z"
                                transform="translate(-482.791 -685.6)"
                                fill="#597530"
                            />
                        </g>
                        <g
                            id="Group_2005"
                            data-name="Group 2005"
                            transform="translate(23.8 58.06)"
                        >
                            <g id="Group_2004" data-name="Group 2004">
                                <path
                                    id="Path_2827"
                                    data-name="Path 2827"
                                    d="M487.02,690.724c.6-.33.964.215,1.853.973-.535-.637-.9-1.428-.506-1.585.528-.21.883.2,1.74.966.465.415,1.238.97,1.254.984-.324-.847-.19-1.54.522-1.391.495.1.312.645,1.232,2a2.942,2.942,0,0,1-.13,3.346c-1.156,1.574-2.383,1.182-3.408.772a6.42,6.42,0,0,1-2.257-1.7c-.57-.658-.815-.88-.677-1.249.1-.276.28-.283.933.13-.051-.048-1.318-1.125-.973-1.644.384-.577.784.023,1.477.453C487.052,691.852,486.486,691.015,487.02,690.724Z"
                                    transform="translate(-486.542 -690.057)"
                                    fill="#fcccbd"
                                />
                                <g
                                    id="Group_2003"
                                    data-name="Group 2003"
                                    transform="translate(0.614 1.446)"
                                >
                                    <path
                                        id="Path_2828"
                                        data-name="Path 2828"
                                        d="M490.39,692.932s0,.238-.17.106-1.047-1.222-1.047-1.222Z"
                                        transform="translate(-487.624 -691.816)"
                                        fill="#eba18e"
                                    />
                                    <path
                                        id="Path_2829"
                                        data-name="Path 2829"
                                        d="M488.83,693.53s.006.221-.135.139-.888-.875-.888-.875Z"
                                        transform="translate(-487.381 -691.99)"
                                        fill="#eba18e"
                                    />
                                    <path
                                        id="Path_2830"
                                        data-name="Path 2830"
                                        d="M488.273,695.131s.086.226-.05.137-.934-.926-.934-.926Z"
                                        transform="translate(-487.289 -692.266)"
                                        fill="#eba18e"
                                    />
                                </g>
                            </g>
                            <path
                                id="Path_2831"
                                data-name="Path 2831"
                                d="M502.609,703.417c-.367.686-3.976-.222-6.752-2.263-2.809-2.065-6.561-4.318-3.118-6.044,2.017-1.012,5.344,2.072,7,3.3C501.666,699.855,504.137,700.575,502.609,703.417Z"
                                transform="translate(-487.399 -690.92)"
                                fill="#fcccbd"
                            />
                        </g>
                        <path
                            id="Path_2832"
                            data-name="Path 2832"
                            d="M498.579,704.825c4.021,2.779,11.685,1.063,13.657.832,8.148-.955,1.1-2.388-5.4-4.526-2.212-.728-7.712-4.706-10.185-5.629-.995-.371-3.112,1.836-3.993,3.476-.385.719,3.508,3.018,3.227,3.288C495.7,702.442,496.526,703.405,498.579,704.825Z"
                            transform="translate(-463.826 -632.958)"
                            fill="#ece8b7"
                        />
                        <path
                            id="Path_2833"
                            data-name="Path 2833"
                            d="M521.948,697.33a10.764,10.764,0,0,0,1.091-2.35c.692-2.087.429-5.6-.934-6.128a4.687,4.687,0,0,1-1.65-.893,1.222,1.222,0,0,1-.27-.287c-.234-.021-.469-.039-.711-.039a9.183,9.183,0,0,0-6.118,2.489.861.861,0,0,0-1.522.529,5.219,5.219,0,0,0-4.354,5.144c0,2.028,1.989,4.389,4.173,5.615a8.686,8.686,0,0,0,7.325,4.017,8.778,8.778,0,0,0,1.693-.168c.284-.494.413-1.126-.536-1.67-3.494-2-3.142-2.164-1.675-3.033a2.814,2.814,0,0,0,2.723.483c1.422-.406,2.339-1.561,2.048-2.581A1.747,1.747,0,0,0,521.948,697.33Z"
                            transform="translate(-466.467 -631.566)"
                            fill="#fdd4c7"
                        />
                        <path
                            id="Path_2834"
                            data-name="Path 2834"
                            d="M534.206,696.519a1.639,1.639,0,0,0-1.727-.384.364.364,0,0,0-.132.084c0-.011,0-.022,0-.034a.872.872,0,0,1,.155-.27c.419-.465,1.332-.155.934-.514a.98.98,0,0,0-1.133-.079c-.5-7.043-7.295-7.938-8.281-8.166-.147-.026-.288-.044-.424-.055a1.085,1.085,0,0,1-.092-.536c.078-.679.963-1.266.456-1.161a1.639,1.639,0,0,0-1.056,1.419,1.325,1.325,0,0,0,.006.151.9.9,0,0,1-.2-.265c-.253-.572.4-1.279-.085-1.063a1.06,1.06,0,0,0-.43,1.428,1.291,1.291,0,0,0,.132.2,1.221,1.221,0,0,0,.27.287,4.686,4.686,0,0,0,1.65.893c1.363.532,1.626,4.04.934,6.128a10.764,10.764,0,0,1-1.091,2.35,1.747,1.747,0,0,1,1.282,1.129c.291,1.019-.626,2.174-2.048,2.581a2.814,2.814,0,0,1-2.723-.483c-1.467.869-1.819,1.033,1.675,3.033.949.543.82,1.176.536,1.67-.337.588-.893.979-.12.783a7.511,7.511,0,0,0,5.385-2.076,11.64,11.64,0,0,0,3.946-5.275.132.132,0,0,0,.034-.058c.4-1.106.8-1.268,1.041-1.372C533.732,696.59,534.508,696.938,534.206,696.519Z"
                            transform="translate(-468.615 -631.167)"
                            fill="#d0977e"
                        />
                        <ellipse
                            id="Ellipse_1213"
                            data-name="Ellipse 1213"
                            cx="5.378"
                            cy="11.611"
                            rx="5.378"
                            ry="11.611"
                            transform="translate(18.869 82.723) rotate(-82.496)"
                            fill="#ece8b7"
                        />
                        <path
                            id="Path_2835"
                            data-name="Path 2835"
                            d="M512.73,708.155c-1.028-5.7-8.569-6.044-18.267-5.147-.833.077-1.613.107-2.369.116a17.123,17.123,0,0,0-5.826-3.8,18.772,18.772,0,0,0-4.83-1.286c-2.029-.69-6.383.1-11.117,2.224a32.877,32.877,0,0,0-5.349,2.993,5.582,5.582,0,0,0-1.679-1.1c-2.252-.947-4.652-.347-5.362,1.341s-.547,2.5,2.792,4.771c.2.138.4.257.591.363,1.2,1.441,6.2.117,12.136-1.4,1.335-.34,2.576-.7,3.714-1.079,4.368,3.37,13.209,6.922,20.8,7.122C508.374,713.554,513.759,713.856,512.73,708.155Z"
                            transform="translate(-457.59 -633.375)"
                            fill="#ece8b7"
                        />
                        <path
                            id="Path_2836"
                            data-name="Path 2836"
                            d="M501.785,718.634c-.772,1.416,7.464,1.089,13.613-1.943,6.219-3.066,14.276-6.144,8.764-10.945-3.227-2.812-9.353,1.873-12.8,4.189C507.966,712.218,504.468,713.711,501.785,718.634Z"
                            transform="translate(-465.445 -634.633)"
                            fill="#fabbac"
                        />
                        <g
                            id="Group_2007"
                            data-name="Group 2007"
                            transform="translate(25 78.078)"
                        >
                            <path
                                id="Path_2837"
                                data-name="Path 2837"
                                d="M488,718.378c.086-.456,2.424-.38,4.791-.3-1.638-.21-3.3-.757-3.281-1.1.034-.538,2.276-.384,4.6-.276,1.261.058,2.871.1,5.778-.022.041,0,.084,0,.126.007-1.616-1.056-2.512-1.714-2.151-2.108.468-.51,2.7.206,5.235,1.278,2.232.945,3.955,1.818,3.965,2.423a.6.6,0,0,1,.028.082c.452,3.185-3.985,3.431-7.126,3.809a27.079,27.079,0,0,1-5.745,0,12.5,12.5,0,0,1-3.525-.7c-.247-.3,1.061-.405,2.631-.475-.141-.008-4.243-.4-4.314-.861-.067-.429,2.63-.478,4.285-.559C490.5,719.427,487.908,718.892,488,718.378Z"
                                transform="translate(-488.001 -714.407)"
                                fill="#fabbac"
                            />
                            <g
                                id="Group_2006"
                                data-name="Group 2006"
                                transform="translate(3.791 3.613)"
                            >
                                <path
                                    id="Path_2838"
                                    data-name="Path 2838"
                                    d="M496.55,718.983s.277.215-.157.21-3.177-.39-3.177-.39Z"
                                    transform="translate(-492.72 -718.802)"
                                    fill="#eba18e"
                                />
                                <path
                                    id="Path_2839"
                                    data-name="Path 2839"
                                    d="M495.174,720.611s.252.2-.078.214-2.483-.248-2.483-.248Z"
                                    transform="translate(-492.613 -719.118)"
                                    fill="#eba18e"
                                />
                                <path
                                    id="Path_2840"
                                    data-name="Path 2840"
                                    d="M495.9,722.351s.251.2-.079.214-2.481-.257-2.481-.257Z"
                                    transform="translate(-492.742 -719.426)"
                                    fill="#eba18e"
                                />
                            </g>
                        </g>
                        <path
                            id="Path_2841"
                            data-name="Path 2841"
                            d="M489.116,717.923c-.048-.237,8.1-.277,17.532-2.192s13.756-1.032,13.543-.919c-4.072,2.161-6.085,1.326-8.085,1.887C499.3,720.285,489.164,718.159,489.116,717.923Z"
                            transform="translate(-463.2 -636.348)"
                            fill="#eba18e"
                        />
                        <path
                            id="Path_2842"
                            data-name="Path 2842"
                            d="M517.349,710.816c2.256.852-10.772,5.26-22.577,6-11.94.752-26.741,2.543-21.769-4.991,2.913-4.413,15.915-3.634,22.992-3.486C502.968,708.486,509.512,707.852,517.349,710.816Z"
                            transform="translate(-460.157 -635.224)"
                            fill="#fabbac"
                        />
                        <g
                            id="Group_2009"
                            data-name="Group 2009"
                            transform="translate(46.822 67.534)"
                        >
                            <path
                                id="Path_2843"
                                data-name="Path 2843"
                                d="M532.379,703.241c-.3-.592-2.444.374-4.607,1.355,1.421-.889,2.7-2.25,2.506-2.718-.3-.722-2.308.314-4.414,1.314a42.117,42.117,0,0,1-5.389,2.1.919.919,0,0,0-.114.056c.981-2.036,1.488-3.266.957-3.671-.689-.525-2.414,1.276-4.239,3.671-1.608,2.112-2.779,3.939-2.488,4.77.007.04,0,.081.016.122,1.158,4.517,5.409,3.221,8.52,2.581a18.757,18.757,0,0,0,5.345-2.116c1.5-.913,2.875-1.887,2.935-2.251.082-.495-1.189-.164-2.684.319.127-.063,3.751-2.1,3.588-2.764-.151-.611-2.685.315-4.266.813C530.58,705.591,532.724,703.908,532.379,703.241Z"
                                transform="translate(-514.546 -701.582)"
                                fill="#fabbac"
                            />
                            <g
                                id="Group_2008"
                                data-name="Group 2008"
                                transform="translate(10.627 2.746)"
                            >
                                <path
                                    id="Path_2844"
                                    data-name="Path 2844"
                                    d="M527.5,706.4s-.151.4.25.229,2.764-1.7,2.764-1.7Z"
                                    transform="translate(-527.473 -704.922)"
                                    fill="#eba18e"
                                />
                                <path
                                    id="Path_2845"
                                    data-name="Path 2845"
                                    d="M529.73,708.113s-.136.367.178.264,2.187-1.253,2.187-1.253Z"
                                    transform="translate(-527.869 -705.314)"
                                    fill="#eba18e"
                                />
                                <path
                                    id="Path_2846"
                                    data-name="Path 2846"
                                    d="M529.921,710.756s-.134.367.179.263,2.181-1.264,2.181-1.264Z"
                                    transform="translate(-527.904 -705.782)"
                                    fill="#eba18e"
                                />
                            </g>
                        </g>
                        <path
                            id="Path_2847"
                            data-name="Path 2847"
                            d="M492.563,699.745a36.284,36.284,0,0,0,5.144,3.891c1.563.721,1.532.982,1.532.982a28.41,28.41,0,0,1-4.558-2.627c-1.55-1.239-3.32-2.788-3.32-2.788Z"
                            transform="translate(-463.599 -633.624)"
                            fill="#e0daa2"
                        />
                        <path
                            id="Path_2848"
                            data-name="Path 2848"
                            d="M517.554,693.462a1.476,1.476,0,0,1-.939-.367,1.683,1.683,0,0,1-.34-.944c0-.047.009-.079.035-.091.048-.021.175.047.243.27s.032.434.2.635a1.407,1.407,0,0,0,.848.475C517.6,693.457,517.579,693.463,517.554,693.462Z"
                            transform="translate(-468.032 -632.353)"
                            fill="#2d1a10"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default BreastfeedingSticker;
