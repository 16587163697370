import React from 'react';
import { reduxForm } from 'redux-form';
import { FORMS, RELATIVE_PAGE_PATHS } from '../../utils/constants';
import { formUtils } from '../../utils/formUtils';
import { Fields } from '../common/Fields';
import { connect } from 'react-redux';
import { Button, Col } from 'reactstrap';
import * as i18n from 'i18next';
import Password from '../common/svgComponents/Password';
import Dots from '../common/Dots';
import { getAxiosWithoutToken } from '../../utils/webApi';
import { scrollToTop } from '../../utils/commonUtils';

const asyncValidate = (values) => {
  return getAxiosWithoutToken()
    .post('user/is-unique-email', { email: values.email })
    .then((response) => {
      if (response.data.success) {
        if (!response.data.data) {
          return Promise.reject({
            email: i18n.t('auth:errors.emailAlreadyRegistered'),
          });
        }
      }
    });
};

class CredentialsScreen extends React.Component {
  componentDidMount() {
    scrollToTop();
  }

  render() {
    const { handleSubmit, currentPage } = this.props;

    return (
      <form
        onSubmit={handleSubmit}
        className="flexContainerColumn flexContainerCentered fixed-padding-register"
      >
        <div className="flexContainerCentered">
          <p className="header mainGreenColor uppercase register-section-title noMargin">
            {i18n.t('registerScreen:headerText.letsStart')}
          </p>
        </div>
        <div xs={10} sm={10} md={10} lg={8} className="flexContainerCentered">
          <Password width={166} height={168} />
        </div>
        <div
          className="flexContainerColumn flexContainerCentered"
          style={{ margin: '8px 0px' }}
        >
          {Fields.commonFields.email()}
          {Fields.commonFields.password()}
          {Fields.commonFields.repeatPassword()}
          <span
            style={{
              fontSize: 12,
              color: '#9f9f9f',
              paddingTop: 4,
            }}
          >
            {i18n.t('shared:labels.passwordLength')}
          </span>
        </div>
        <Col
          xs={10}
          sm={10}
          md={10}
          lg={8}
          className="flexContainerColumn centred"
          style={{ margin: '24px 0px 12px 0px' }}
        >
          <Button className="greenBtn header next uppercase">
            {i18n.t('buttons:next')}
          </Button>
        </Col>
        <p style={{ fontSize: 12 }}>
          {i18n.t('registerScreen:credentialsScreen.haveProfile')}
          <a href={RELATIVE_PAGE_PATHS.LOGIN} style={{ color: '#87ba3b' }}>
            {i18n.t('registerScreen:credentialsScreen.login')}
          </a>
        </p>
        <Dots page={currentPage} />
      </form>
    );
  }
}

const registerForm = reduxForm({
  form: FORMS.REGISTER_FORM,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: formUtils.validateSignUp,
  asyncValidate,
  asyncBlurFields: ['email'],
})(CredentialsScreen);

export default connect(null, {})(registerForm);
