import React from 'react';

const LogoDMT = ({ width, height }) => {
  const viewBoxValues = '0 0 129 129';

  return (
    <svg
      id="Layer_2"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBoxValues}
    >
      <g>
        <path
          d="M25.8,93.15h-1.03l-4.05,11.79h-.81v3.73h1.1v-2.68h8.57v2.68h1.1v-3.73h-.84l-4.03-11.79Zm-3.92,11.79l3.4-10,3.39,10h-6.79Z"
          fill="#211e1f"
        />
        <polygon
          points="37.66 100.53 32.71 100.53 32.71 96.57 31.63 96.57 31.63 105.99 32.71 105.99 32.71 101.56 37.66 101.56 37.66 105.99 38.74 105.99 38.74 96.57 37.66 96.57 37.66 100.53"
          fill="#211e1f"
        />
        <path
          d="M44.75,96.38c-2.44,0-4.02,1.93-4.02,4.91s1.58,4.93,4.02,4.93c1.89,0,3.39-1.2,3.81-3.06l.07-.33h-1.07l-.05,.2c-.38,1.34-1.44,2.18-2.77,2.18-1.72,0-2.88-1.45-2.93-3.62h6.92v-.59c0-2.85-1.53-4.62-3.99-4.62Zm-2.89,4.18c.21-1.96,1.33-3.17,2.99-3.17s2.68,1.24,2.77,3.17h-5.76Z"
          fill="#211e1f"
        />
        <path
          d="M55.8,98.85c.98-.55,1.68-1.67,1.68-2.81,0-1.57-1.06-3.15-3.44-3.15-1.83,0-3.79,1.16-3.79,4.43v4.78c0,2.74,1.32,4.13,3.92,4.13,2.35,0,3.8-1.5,3.8-3.91,0-1.74-.7-2.86-2.18-3.47Zm-1.8-4.95c1.66,0,2.41,1.08,2.41,2.09,0,1.23-1,2.48-2.91,2.48h-1.5c-.26,0-.47,0-.67-.01v-1.29c0-2.05,1-3.27,2.67-3.27Zm-2.94,5.37h0Zm.27,2.97v-2.72c.2-.01,.41-.02,.65-.02h1.59c1.25,0,3.34,.36,3.34,2.74,0,1.98-.96,2.98-2.86,2.98-1.24,0-2.72-.52-2.72-2.98Z"
          fill="#211e1f"
        />
        <polygon
          points="65.93 100.53 60.98 100.53 60.98 96.57 59.9 96.57 59.9 105.99 60.98 105.99 60.98 101.56 65.93 101.56 65.93 105.99 67.01 105.99 67.01 96.57 65.93 96.57 65.93 100.53"
          fill="#211e1f"
        />
        <path
          d="M75.44,101.65c0,1.25-.04,2.06-.65,2.74-.48,.54-1.16,.8-2.09,.8-1.6,0-2.23-.72-2.23-2.59v-6.04h-1.06v6.21c0,2.29,1.11,3.45,3.31,3.45,1.14,0,2.08-.37,2.72-1.05v.81h1.06v-9.42h-1.06v5.08Z"
          fill="#211e1f"
        />
        <polygon
          points="86.05 96.57 84.63 96.57 79.9 101.27 79.9 93.15 78.82 93.15 78.82 105.99 79.9 105.99 79.9 102.67 81.75 100.86 85.16 105.99 86.41 105.99 82.52 100.1 86.05 96.57"
          fill="#211e1f"
        />
        <path
          d="M91.62,99.65c-.9,0-1.61,.11-2.36,.36v-3.44h-2.65v1.03h1.57v5.18c0,2.22,1.22,3.45,3.44,3.45,2.02,0,3.32-1.28,3.32-3.26s-1.34-3.32-3.32-3.32Zm-2.36,1.44c.74-.3,1.35-.41,2.29-.41,1.44,0,2.3,.87,2.3,2.33s-.75,2.21-2.3,2.21c-1.9,0-2.29-1.3-2.29-2.4v-1.73Z"
          fill="#211e1f"
        />
        <path
          d="M105.41,96.38c-1.21,0-2.18,.5-2.84,1.46-.47-.95-1.4-1.46-2.68-1.46-1.01,0-1.88,.37-2.5,1.06v-.87h-1.08v9.42h1.08v-5.07c0-1.23,.04-2.03,.61-2.72,.42-.51,1.08-.79,1.86-.79,.86,0,1.5,.36,1.77,.98,.22,.51,.23,1.14,.23,1.98v5.61h1.06v-5.07c0-1.23,.04-2.03,.61-2.72,.42-.51,1.08-.79,1.86-.79,.86,0,1.5,.36,1.77,.98,.22,.51,.23,1.17,.23,1.98v5.61h1.08v-6.16c0-2.85-1.67-3.44-3.07-3.44Z"
          fill="#211e1f"
        />
      </g>
      <g>
        <polygon
          points="27.43 116.32 25.02 116.32 25.02 114.62 24.58 114.62 24.58 118.74 25.02 118.74 25.02 116.74 27.43 116.74 27.43 118.74 27.87 118.74 27.87 114.62 27.43 114.62 27.43 116.32"
          fill="#211e1f"
        />
        <path
          d="M31.61,118.29s-.03-.03-.03-.15v-2.31c0-.62-.27-1.36-1.56-1.36-.94,0-1.5,.48-1.59,1.38l-.02,.17h.45l.02-.14c.09-.67,.44-.98,1.14-.98,.81,0,1.12,.3,1.12,1.08,0,.23-.39,.28-.98,.31-.35,.02-.71,.05-1.01,.14-.55,.18-.87,.61-.87,1.18,0,.74,.59,1.22,1.49,1.22,.55,0,1.03-.23,1.36-.65,.02,.29,.13,.53,.58,.53h.36v-.45l-.28,.02c-.14,.01-.18-.01-.19-.02Zm-.47-1.68v.4c0,.79-.59,1.41-1.35,1.41-.49,0-1.06-.21-1.06-.79,0-.32,.13-.54,.41-.69,.19-.1,.41-.12,.64-.14l.27-.02c.41-.03,.8-.05,1.08-.17Z"
          fill="#211e1f"
        />
      </g>
      <g>
        <polygon
          points="40.18 112.99 37.88 118.07 35.52 112.99 34.94 112.99 34.94 118.88 35.47 118.88 35.47 114.08 37.68 118.88 38.06 118.88 40.28 114.08 40.28 118.88 40.81 118.88 40.81 112.99 40.18 112.99"
          fill="#211e1f"
        />
        <path
          d="M46.3,118.34s-.06-.12-.06-.26v-3.36c0-.88-.38-1.92-2.21-1.92-1.34,0-2.12,.68-2.26,1.96l-.02,.18h.53l.02-.14c.14-1.02,.67-1.48,1.73-1.48,1.21,0,1.7,.48,1.7,1.64,0,.4-.56,.46-1.48,.52-.5,.03-1.03,.07-1.46,.21-.77,.25-1.23,.87-1.23,1.65,0,1.26,1.14,1.71,2.12,1.71,.85,0,1.57-.38,2.05-1.06,0,.48,.09,.89,.79,.89h.46v-.52l-.35,.02c-.17,.01-.28,0-.32-.04Zm-2.62,.2c-.74,0-1.6-.32-1.6-1.21,0-.48,.21-.84,.64-1.06,.28-.15,.61-.19,.95-.21,.13-.01,.27-.02,.4-.03,.64-.04,1.25-.08,1.65-.29v.68c0,1.19-.89,2.12-2.03,2.12Z"
          fill="#211e1f"
        />
        <polygon
          points="52.71 112.99 50.41 118.07 48.05 112.99 47.47 112.99 47.47 118.88 48 118.88 48 114.08 50.21 118.88 50.59 118.88 52.81 114.08 52.81 118.88 53.34 118.88 53.34 112.99 52.71 112.99"
          fill="#211e1f"
        />
        <path
          d="M58.83,118.34s-.06-.12-.06-.26v-3.36c0-.88-.38-1.92-2.21-1.92-1.34,0-2.12,.68-2.26,1.96l-.02,.18h.53l.02-.14c.14-1.02,.67-1.48,1.73-1.48,1.21,0,1.7,.48,1.7,1.64,0,.4-.56,.46-1.48,.52-.5,.03-1.03,.07-1.46,.21-.77,.25-1.23,.87-1.23,1.65,0,1.26,1.14,1.71,2.12,1.71,.85,0,1.57-.38,2.05-1.06,0,.48,.09,.89,.79,.89h.46v-.52l-.35,.02c-.17,.01-.28,0-.32-.04Zm-2.62,.2c-.74,0-1.6-.32-1.6-1.21,0-.48,.21-.84,.64-1.06,.28-.15,.61-.19,.95-.21,.13-.01,.27-.02,.4-.03,.64-.04,1.25-.08,1.65-.29v.68c0,1.19-.89,2.12-2.03,2.12Z"
          fill="#211e1f"
        />
        <path
          d="M66.14,116.85c0,.82-.35,1.7-1.34,1.7-.85,0-1.2-.36-1.2-1.24v-2.76h-.46v2.85c0,1.06,.58,1.61,1.67,1.61,.54,0,1.01-.23,1.33-.64v.51h.46v-4.33h-.46v2.3Z"
          fill="#211e1f"
        />
        <path
          d="M76.86,112.8c-.88,0-1.54,.39-1.89,1.12-.28-.68-.92-1.12-1.69-1.12s-1.35,.3-1.74,.91v-.71h-.51v5.89h.51v-3.39c0-1.04,.53-2.17,1.69-2.17,1.28,0,1.47,1.25,1.47,2v3.56h.51v-3.78c0-1.08,.63-1.78,1.6-1.78s1.54,.64,1.54,1.63v3.93h.51v-3.94c0-1.58-1.08-2.14-2.01-2.14Z"
          fill="#211e1f"
        />
        <path
          d="M84.35,118.34s-.06-.12-.06-.26v-3.36c0-.88-.38-1.92-2.21-1.92-1.34,0-2.12,.68-2.26,1.96l-.02,.18h.53l.02-.14c.14-1.02,.67-1.48,1.73-1.48,1.21,0,1.7,.48,1.7,1.64,0,.4-.56,.46-1.48,.52-.5,.03-1.03,.07-1.46,.21-.77,.25-1.23,.87-1.23,1.65,0,1.26,1.14,1.71,2.12,1.71,.85,0,1.57-.38,2.05-1.06,0,.48,.09,.89,.79,.89h.46v-.52l-.35,.02c-.17,.01-.28,0-.32-.04Zm-.58-2.6v.68c0,1.19-.89,2.12-2.03,2.12-.74,0-1.6-.32-1.6-1.21,0-.48,.21-.84,.64-1.06,.28-.15,.61-.19,.95-.21,.13-.01,.27-.02,.4-.03,.64-.04,1.25-.08,1.65-.29Z"
          fill="#211e1f"
        />
        <path
          d="M91.32,112.8c-.88,0-1.54,.39-1.89,1.11-.28-.68-.91-1.11-1.69-1.11s-1.35,.31-1.74,.91v-.71h-.51v5.89h.51v-3.39c0-1.04,.53-2.17,1.69-2.17,1.28,0,1.47,1.25,1.47,2v3.56h.51v-3.78c0-1.08,.63-1.78,1.6-1.78s1.54,.64,1.54,1.63v3.93h.51v-3.94c0-1.58-1.08-2.14-2.01-2.14Z"
          fill="#211e1f"
        />
        <polygon
          points="99.24 112.99 98.48 112.99 95.04 115.95 95.04 110.7 94.53 110.7 94.53 118.88 95.04 118.88 95.04 116.62 96.29 115.56 98.76 118.88 99.4 118.88 96.7 115.22 99.24 112.99"
          fill="#211e1f"
        />
        <path
          d="M101.83,112.8c-1.88,0-2.72,1.56-2.72,3.1s.84,3.16,2.71,3.16,2.74-1.59,2.74-3.16-.84-3.1-2.73-3.1Zm0,.52c1.63,0,2.21,1.39,2.21,2.57s-.58,2.65-2.22,2.65-2.19-1.43-2.19-2.65,.58-2.57,2.21-2.57Z"
          fill="#211e1f"
        />
      </g>
      <g>
        <polygon
          points="52.5 19.56 52.5 27.66 44.39 27.66 44.39 35.77 36.29 35.77 36.29 27.66 36.29 19.56 44.39 19.56 52.5 19.56 52.5 19.56"
          fill="#fcd10a"
          fillRule="evenodd"
        />
        <polygon
          points="52.5 74.93 52.5 66.82 44.39 66.82 44.39 58.72 36.29 58.72 36.29 66.82 36.29 74.93 44.39 74.93 52.5 74.93 52.5 74.93"
          fill="#f6aa1c"
          fillRule="evenodd"
        />
        <polygon
          points="75.44 19.56 75.44 27.66 83.54 27.66 83.54 35.77 91.65 35.77 91.65 27.66 91.65 19.56 83.54 19.56 75.44 19.56 75.44 19.56"
          fill="#f6aa1c"
          fillRule="evenodd"
        />
        <polygon
          points="91.66 58.7 85.93 52.97 91.66 47.23 85.93 41.5 91.66 35.77 97.39 41.5 103.13 47.23 97.39 52.97 91.66 58.7 91.66 58.7"
          fill="#ef7922"
          fillRule="evenodd"
        />
        <polygon
          points="36.28 35.79 42.01 41.52 36.28 47.25 42.01 52.98 36.28 58.72 30.55 52.98 24.82 47.25 30.55 41.52 36.28 35.79 36.28 35.79"
          fill="#fcd10a"
          fillRule="evenodd"
        />
        <polygon
          points="52.5 74.92 58.23 69.19 63.97 74.92 69.7 69.19 75.43 74.92 69.7 80.65 63.97 86.38 58.23 80.65 52.5 74.92 52.5 74.92"
          fill="#ef7922"
          fillRule="evenodd"
        />
        <polygon
          points="75.43 19.56 69.7 25.29 63.97 19.56 58.24 25.29 52.51 19.56 58.24 13.83 63.97 8.09 69.7 13.83 75.43 19.56 75.43 19.56"
          fill="#fcd10a"
          fillRule="evenodd"
        />
        <polygon
          points="75.44 74.93 75.44 66.81 83.55 66.81 83.55 58.7 91.66 58.7 91.66 74.93 75.44 74.93 75.44 74.93"
          fill="#ef7922"
          fillRule="evenodd"
        />
        <polygon
          points="61.05 40.19 61.05 42.25 58.99 42.25 58.99 44.32 56.92 44.32 56.92 42.25 56.92 40.19 58.99 40.19 61.05 40.19 61.05 40.19"
          fill="#fcd10a"
          fillRule="evenodd"
        />
        <polygon
          points="61.05 54.29 61.05 52.22 58.99 52.22 58.99 50.16 56.92 50.16 56.92 52.22 56.92 54.29 58.99 54.29 61.05 54.29 61.05 54.29"
          fill="#f6aa1c"
          fillRule="evenodd"
        />
        <polygon
          points="66.89 40.19 66.89 42.25 68.95 42.25 68.95 44.32 71.02 44.32 71.02 42.25 71.02 40.19 68.95 40.19 66.89 40.19 66.89 40.19"
          fill="#f6aa1c"
          fillRule="evenodd"
        />
        <polygon
          points="71.02 50.16 69.56 48.7 71.02 47.24 69.56 45.78 71.02 44.32 72.48 45.78 73.94 47.24 72.48 48.7 71.02 50.16 71.02 50.16"
          fill="#ef7922"
          fillRule="evenodd"
        />
        <polygon
          points="56.92 44.32 58.38 45.78 56.92 47.24 58.38 48.7 56.92 50.16 55.46 48.7 54 47.24 55.46 45.78 56.92 44.32 56.92 44.32"
          fill="#fcd10a"
          fillRule="evenodd"
        />
        <polygon
          points="61.05 54.29 62.51 52.83 63.97 54.29 65.43 52.83 66.89 54.29 65.43 55.75 63.97 57.21 62.51 55.75 61.05 54.29 61.05 54.29"
          fill="#ef7922"
          fillRule="evenodd"
        />
        <polygon
          points="66.89 40.19 65.43 41.65 63.97 40.19 62.51 41.65 61.05 40.19 62.51 38.73 63.97 37.27 65.43 38.73 66.89 40.19 66.89 40.19"
          fill="#fcd10a"
          fillRule="evenodd"
        />
        <polygon
          points="66.89 54.29 66.89 52.22 68.96 52.22 68.96 50.16 71.02 50.16 71.02 54.29 66.89 54.29 66.89 54.29"
          fill="#ef7922"
          fillRule="evenodd"
        />
        <polygon
          points="51.48 34.79 53.31 39.19 48.91 41.02 50.74 45.43 46.34 47.26 44.5 42.85 42.67 38.45 47.08 36.62 51.48 34.79 51.48 34.79"
          fill="#fcd10a"
          fillRule="evenodd"
        />
        <polygon
          points="63.99 64.88 62.16 60.47 57.75 62.3 55.92 57.9 51.52 59.73 53.35 64.13 55.18 68.54 59.59 66.71 63.99 64.88 63.99 64.88"
          fill="#f6aa1c"
          fillRule="evenodd"
        />
        <polygon
          points="63.95 29.61 65.78 34.01 70.19 32.18 72.02 36.58 76.42 34.75 74.59 30.35 72.76 25.94 68.36 27.77 63.95 29.61 63.95 29.61"
          fill="#f6aa1c"
          fillRule="evenodd"
        />
        <polygon
          points="81.61 47.21 77.2 45.39 79.02 40.98 74.61 39.16 76.43 34.75 80.84 36.57 85.25 38.39 83.43 42.8 81.61 47.21 81.61 47.21"
          fill="#ef7922"
          fillRule="evenodd"
        />
        <polygon
          points="46.34 47.27 50.75 49.09 48.93 53.5 53.34 55.32 51.52 59.73 47.11 57.91 42.7 56.09 44.52 51.68 46.34 47.27 46.34 47.27"
          fill="#f6aa1c"
          fillRule="evenodd"
        />
        <polygon
          points="63.99 64.87 65.81 60.46 70.22 62.28 72.04 57.87 76.45 59.69 74.63 64.1 72.81 68.51 68.4 66.69 63.99 64.87 63.99 64.87"
          fill="#ef7922"
          fillRule="evenodd"
        />
        <polygon
          points="63.95 29.61 62.13 34.02 57.72 32.2 55.9 36.6 51.49 34.78 53.31 30.38 55.13 25.97 59.54 27.79 63.95 29.61 63.95 29.61"
          fill="#fcd10a"
          fillRule="evenodd"
        />
        <polygon
          points="76.46 59.69 74.62 55.29 79.03 53.45 77.2 49.04 81.61 47.21 85.27 56.03 76.46 59.69 76.46 59.69"
          fill="#ef7922"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
};

export default LogoDMT;
