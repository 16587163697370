import { SvgIcon } from '@mui/material';
import React from 'react';

const PoopDiaperFull = (props) => (
  <SvgIcon {...props} viewBox="0 0 128 128">
    <path
      d="M71.87,35.15s-6.19-1.41-11.46-2.99-6.32-3.16-10.14-1.98-8.56,6.32-7.9,9.62,7.65,5.95,7.65,5.95l22.67-10.62-.81,.02Z"
      fill="#c5e27f"
    />
    <path
      d="M12.24,60.82c-.22-2.74-1.08-3.57,.27-5.04s5.42-2.5,7.46-1.48,2.69,4.78,2.69,4.78l-9.36,8.7s-.84-4.22-1.06-6.96Z"
      fill="#c5e27f"
    />
    <path
      d="M50,97.5s2.06-.25,5.4,0,5.36,1.22,7.7,.43,5.12-3.89,4.63-5.77-4.9-3.2-4.9-3.2l-13.73,6.78,.91,1.76Z"
      fill="#c5e27f"
    />
    <path
      d="M91.11,67.86s11.44,2.37,14.87,1.91,10.54-5.07,12.13-9.95-1.71-6.59-7.25-8.96-14.07-7.54-14.07-7.54l-5.68,24.53Z"
      fill="#c5e27f"
    />
    <path
      d="M69.03,35.38s-9.62,2.63-17.52,9.09-9.35,10.93-15.81,12.65-13.42,.4-17.51,0-3.83,2.11-4.69,2.83c-2.84,2.41,2.5,10.41,3.82,12.78s11.13,9.95,19.83,15.74,15.07,7.61,18.57,6.85c4.54-.99,6.32-6.19,10.93-11.46s7.9-8.17,16.73-11.99c8.83-3.82,15.46-9.14,17.92-12.91,2.7-4.15,2.83-9.55-1.19-13.04-4.71-4.09-16.99-12.38-31.09-10.54Z"
      fill="#ebf9ca"
    />
    <path
      d="M15.31,57.18s-.09,.8,1.23,3.83,6.46,10.28,14.89,15.94c6.28,4.22,12.78,8.17,19.37,10.54,5.21,1.88,12.12,1.3,12.12,1.3,0,0-1.28,4.84-5.4,6.74-4.72,2.16-12.76,4.92-26.35-5.8s-19.91-20.58-18.84-27.4c.92-5.84,2.98-5.15,2.98-5.15Z"
      fill="#c5e27f"
    />
    <ellipse cx="74.57" cy="36.04" rx="3.62" ry="2.04" fill="#ebf9ca" />
    <ellipse
      cx="81.1"
      cy="37.08"
      rx="2.04"
      ry="3.05"
      transform="translate(38.99 115.69) rotate(-86.39)"
      fill="#ebf9ca"
    />
    <ellipse
      cx="86.75"
      cy="39.2"
      rx="2.9"
      ry="2.04"
      transform="translate(-4 10.44) rotate(-6.73)"
      fill="#ebf9ca"
    />
    <ellipse
      cx="92.75"
      cy="41.73"
      rx="2.04"
      ry="3.1"
      transform="translate(40.76 129.14) rotate(-83.49)"
      fill="#ebf9ca"
    />
    <path
      d="M76.87,34.53s.57,2.69,.46,3.52,.85-1.32,1.82-1.96,.75-.91,.75-.91l-1.68,.28c-.69-.8-1.35-.93-1.35-.93Z"
      fill="#dfeda6"
    />
    <path
      d="M83.46,35.85s-.09,2.79-.38,3.58,1.11-1.1,2.2-1.53,.93-.73,.93-.73l-1.71-.08c-.38-.71-1.04-1.24-1.04-1.24Z"
      fill="#dfeda6"
    />
    <path
      d="M89.39,38.22s-.26,2.74-.59,3.51,1.18-1.03,2.29-1.39,.97-.67,.97-.67l-1.7-.19c-.31-.73-.96-1.26-.96-1.26Z"
      fill="#dfeda6"
    />
    <path
      d="M95.75,41.38s-1.49,2.49-2.1,3.06,1.49-.48,2.65-.37,1.12-.2,1.12-.2l-1.45-1.19c-.17-.54-.22-1.31-.22-1.31Z"
      fill="#dfeda6"
    />
    <ellipse
      cx="97.62"
      cy="45.81"
      rx="2.04"
      ry="3.1"
      transform="translate(41.03 137.61) rotate(-83.49)"
      fill="#ebf9ca"
    />
    <path
      d="M100.72,45.53s-1.18,1.98-1.68,2.38,1.24-.14,2.21,.19,.94,.05,.94,.05l-1.25-1.37c-.16-.53-.22-1.24-.22-1.24Z"
      fill="#dfeda6"
    />
    <path
      d="M53.77,54.51c-6.42,4.94-11.09,10.14-11.51,9.59s4.04-6.01,10.46-10.95c6.42-4.94,12.38-7.48,12.8-6.93s-5.33,3.35-11.76,8.29Z"
      fill="#c5e27f"
    />
    <path
      d="M69.97,65.57c-6.42,4.94-11.09,10.14-11.51,9.59s4.04-6.01,10.46-10.95c6.42-4.94,12.38-7.48,12.8-6.93s-5.33,3.35-11.76,8.29Z"
      fill="#c5e27f"
    />
    <g>
      <g>
        <path
          d="M72.17,56.53c-.34,.11-.88,.43-1.12-.13-.26-.63,.39-.63,.71-.83,.44-.28,.91-.46,1.46-.3,.14,.04,.33,.08,.36,.3,.03,.18-.12,.25-.22,.31-.39,.23-.78,.43-1.2,.65Z"
          fill="#f2c86b"
        />
        <path
          d="M73.73,52.6c-.44-.17-.91-.27-1.36-.41-.46-.15-.84-.03-1.22,.21-.72,.45-1.32,1.09-2.11,1.44-.56,.25-.8,.85-.64,1.63-.05,.43,.26,.53,.5,.61,.53,.19,1.09,.23,1.52-.08,1.09-.78,2.26-1.36,3.53-1.75,.36-.11,.71-.32,.67-.89-.12-.54-.53-.63-.88-.76Zm-.92,.43c0,.16-.1,.2-.21,.2-.89,.04-1.59,.55-2.29,1.03-.1,.07-.23,.12-.35,.16-.11,.04-.25,.15-.35-.02-.1-.16,0-.3,.1-.38,.56-.43,1.01-1.03,1.81-1.34,.26-.16,.66-.1,1.08,.1,.11,.05,.22,.1,.22,.25Z"
          fill="#e5c676"
        />
        <path
          d="M61.28,56.35c.51-1.03,1.03-1.82,1.98-2.1,.1-.03,.22-.09,.29,.07,.08,.18-.02,.28-.13,.35-.13,.08-.26,.16-.4,.19-.56,.1-.66,.77-1.05,1.08-.17,.14-.31,.35-.7,.41Z"
          fill="#ffd797"
        />
        <path
          d="M68.65,51.26c-.66,.14-1.05,.8-1.7,.96,.26-.57,.81-.74,1.22-1.1,.14-.12,.33-.04,.47,.13Z"
          fill="#f7cf8f"
        />
        <path
          d="M63.52,58.95c-.92,.34-1.8,.12-2.52,.56,.34-.84,1.37-1.08,2.52-.56Z"
          fill="#efce8e"
        />
        <path
          d="M65.23,58.47c-.36,0-.74-.11-1.11-.16-1.12-.14-2.27-.22-3.21,.35-.81,.49-1.46,1.26-2.27,1.79-.58,.37-.63,1.17-.1,1.55,0,0,.45,.38,.89,.45,.87,.14,1.69,.18,2.5-.09,1.05-.34,1.99-.8,3.14-1.06,.56-.13,1.15-1.1,1.21-1.25,.36-1.07,0-1.6-1.04-1.6Zm-6.56,2.91c.35-.85,.95-.98,1.56-1.1-.49,.3-.81,.91-1.56,1.1Zm2.33-1.87c.34-.84,1.37-1.08,2.52-.56-.92,.34-1.8,.12-2.52,.56Z"
          fill="#e5c676"
        />
        <path
          d="M60.23,60.29c-.49,.3-.81,.91-1.56,1.1,.35-.85,.95-.98,1.56-1.1Z"
          fill="#efce8e"
        />
        <path
          d="M71.51,52.67c.26-.16,.66-.1,1.08,.1,.11,.05,.22,.1,.22,.25,0,.16-.1,.2-.21,.2-.89,.04-1.59,.55-2.29,1.03-.1,.07-.23,.12-.35,.16-.11,.04-.25,.15-.35-.02-.1-.16,0-.3,.1-.38,.56-.43,1.01-1.03,1.81-1.34Z"
          fill="#efce8e"
        />
        <path
          d="M71.52,57.13c-1.02-.15-2.06-.56-3.08-.14-.46,.23-.96,.47-1.45,.73-.39,.21-.44,.57-.21,1.06,.35,.74,1.19,1.2,2.01,1.21,1.35,.03,1.99-1.26,3-1.83,.4-.22,.18-.96-.27-1.03Zm-1.65,1.94c-.31,.14-.61,.35-1.15,.23,.4-.26,.67-.44,.95-.61,.09-.05,.21-.11,.29,.02,.11,.17,.02,.31-.09,.36Z"
          fill="#ffcc71"
        />
        <path
          d="M68.72,59.3c.4-.26,.67-.44,.95-.61,.09-.05,.21-.11,.29,.02,.11,.17,.02,.31-.09,.36-.31,.14-.61,.35-1.15,.23Z"
          fill="#ffd797"
        />
        <path
          d="M70.38,51.37c-.31-.17-.67-.2-.95-.41-.96-.69-1.69-.32-2.32,.35-1.08,1.14-2.39,1.81-3.8,2.3-.84,.3-1.59,.8-2.14,1.62-.83,1.24-1.73,2.39-2.94,3.17-.21,.13-.45,.28-.38,.67h0c.21,.57,.58,.4,.9,.34,.62-.11,1.12-.49,1.65-.79,1.24-.7,2.81-.65,3.89-1.76,0,0,.25-.32,.26-.43,.1-.37,.42-.38,.67-.39,.96-.05,1.67-.57,2.29-1.29,.77-.89,1.6-1.67,2.69-2.07,.22-.08,.48-.38,.52-.65,.09-.34-.12-.54-.34-.65Zm-6.95,3.3c-.13,.08-.26,.16-.4,.19-.56,.1-.66,.77-1.05,1.08-.17,.14-.31,.35-.7,.41,.51-1.03,1.03-1.82,1.98-2.1,.1-.03,.22-.09,.29,.07,.08,.18-.02,.28-.13,.35Zm3.53-2.44c.26-.57,.81-.74,1.22-1.1,.14-.12,.33-.04,.47,.13-.66,.14-1.05,.8-1.7,.96"
          fill="#ffcc71"
        />
        <g>
          <polygon
            points="63.83 56.58 63.83 56.57 63.83 56.57 63.83 56.58"
            fill="#f2c86b"
          />
          <path
            d="M67.39,55.84c-.66-.04-1.25,.38-1.94,.15-.47-.16-.89-.1-1.31,.08-.45,.23-.56,.55-.57,.83-.13,1.19,.37,1.27,1.13,1.13,1.08-.21,1.98-.87,2.98-1.29,.19-.08,.31-.33,.24-.59-.08-.31-.32-.28-.54-.3Z"
            fill="#f2c86b"
          />
          <polygon
            points="64.14 56.06 64.14 56.06 64.14 56.05 64.14 56.06"
            fill="#f2c86b"
          />
        </g>
        <path
          d="M64.64,56.29c.1-.06,.25-.04,.4,.04,.04,.02,.08,.04,.08,.1,0,.06-.04,.07-.08,.07-.33,.01-.59,.2-.86,.38-.04,.03-.08,.04-.13,.06-.04,.01-.09,.06-.13,0-.04-.06,0-.11,.04-.14,.21-.16,.38-.39,.67-.5Z"
          fill="#f7ce7c"
        />
      </g>
      <g>
        <path
          d="M78.95,52.99c-.54,.07-1.43,.38-1.62-.51-.21-1,.75-.82,1.29-1.03,.74-.29,1.48-.42,2.25-.03,.2,.1,.46,.22,.45,.55,0,.28-.25,.33-.41,.39-.64,.22-1.28,.41-1.96,.61Z"
          fill="#f2c86b"
        />
        <path
          d="M82.4,47.63c-.61-.39-1.26-.66-1.89-1-.64-.35-1.24-.29-1.87-.04-1.19,.46-2.27,1.23-3.54,1.52-.9,.21-1.42,1.03-1.42,2.22-.2,.62,.24,.86,.56,1.05,.73,.43,1.54,.65,2.26,.32,1.84-.83,3.74-1.36,5.73-1.57,.56-.06,1.14-.27,1.24-1.13-.03-.83-.61-1.08-1.08-1.39Zm-1.48,.36c-.04,.24-.2,.26-.37,.24-1.33-.2-2.51,.35-3.69,.86-.18,.08-.37,.11-.56,.13-.18,.02-.41,.15-.51-.14-.1-.27,.09-.45,.25-.53,.95-.47,1.79-1.24,3.06-1.46,.43-.16,1.01,.05,1.57,.46,.15,.11,.29,.21,.26,.44Z"
          fill="#e5c676"
        />
        <path
          d="M62.9,49.58c1.05-1.38,2.05-2.4,3.54-2.53,.15-.01,.35-.07,.41,.18,.07,.28-.1,.41-.29,.48-.21,.07-.43,.16-.65,.16-.86-.01-1.2,.95-1.86,1.3-.3,.16-.55,.43-1.15,.41Z"
          fill="#ffd797"
        />
        <path
          d="M75.27,44.19c-1.01,.01-1.78,.89-2.79,.94,.55-.77,1.41-.86,2.13-1.27,.24-.14,.5,.04,.66,.33Z"
          fill="#f7cf8f"
        />
        <path
          d="M65.45,54.08c-1.45,.23-2.7-.35-3.89,.1,.75-1.14,2.33-1.2,3.89-.1Z"
          fill="#efce8e"
        />
        <path
          d="M68.13,53.86c-.54-.1-1.06-.38-1.6-.55-1.62-.53-3.29-.99-4.85-.41-1.34,.5-2.53,1.45-3.88,1.98-.96,.38-1.27,1.54-.6,2.26,0,0,.56,.69,1.19,.93,1.24,.46,2.46,.75,3.72,.6,1.65-.2,3.18-.61,4.95-.66,.87-.02,2.02-1.29,2.14-1.5,.84-1.48,.47-2.36-1.07-2.66Zm-10.55,2.41c.76-1.16,1.69-1.17,2.62-1.17-.82,.3-1.46,1.12-2.62,1.17Zm3.98-2.09c.75-1.14,2.33-1.2,3.89-.1-1.45,.23-2.7-.35-3.89,.1Z"
          fill="#e5c676"
        />
        <path
          d="M60.2,55.1c-.82,.3-1.46,1.12-2.62,1.17,.76-1.16,1.69-1.17,2.62-1.17Z"
          fill="#efce8e"
        />
        <path
          d="M79.09,47.1c.43-.16,1.01,.05,1.57,.46,.15,.11,.29,.21,.26,.44-.04,.24-.2,.26-.37,.24-1.33-.2-2.51,.35-3.69,.86-.18,.08-.37,.11-.56,.13-.18,.02-.41,.15-.51-.14-.1-.27,.09-.45,.25-.53,.95-.47,1.79-1.24,3.06-1.46Z"
          fill="#efce8e"
        />
        <path
          d="M77.81,53.69c-1.46-.51-2.89-1.42-4.51-1.1-.75,.21-1.56,.42-2.36,.66-.65,.2-.82,.72-.62,1.5,.3,1.19,1.42,2.12,2.62,2.38,1.99,.43,3.3-1.28,4.97-1.84,.65-.22,.55-1.37-.11-1.6Zm-3,2.39c-.5,.12-1.01,.34-1.77,.01,.66-.27,1.12-.46,1.58-.63,.14-.05,.34-.09,.43,.12,.11,.28-.06,.46-.24,.5Z"
          fill="#ffcc71"
        />
        <path
          d="M73.04,56.09c.66-.27,1.12-.46,1.58-.63,.14-.05,.34-.09,.43,.12,.11,.28-.06,.46-.24,.5-.5,.12-1.01,.34-1.77,.01Z"
          fill="#ffd797"
        />
        <path
          d="M77.79,44.84c-.41-.33-.93-.49-1.29-.88-1.22-1.3-2.4-.96-3.53-.15-1.92,1.38-4.05,1.98-6.29,2.31-1.33,.19-2.59,.72-3.63,1.77-1.58,1.6-3.25,3.04-5.26,3.84-.34,.14-.75,.28-.75,.88h0c.15,.9,.75,.76,1.23,.76,.94,.01,1.8-.4,2.68-.7,2.04-.68,4.35-.15,6.26-1.47,0,0,.46-.4,.5-.56,.26-.51,.73-.44,1.11-.39,1.44,.2,2.64-.37,3.76-1.25,1.39-1.1,2.85-2.01,4.58-2.28,.34-.05,.82-.43,.95-.81,.22-.48-.02-.83-.32-1.07Zm-11.23,2.88c-.21,.07-.43,.16-.65,.16-.86-.01-1.2,.95-1.86,1.3-.3,.16-.55,.43-1.15,.41,1.05-1.38,2.05-2.4,3.54-2.53,.15-.01,.35-.07,.41,.18,.07,.28-.1,.41-.29,.48Zm5.92-2.59c.55-.77,1.41-.86,2.13-1.27,.24-.14,.5,.04,.66,.33-1.01,.01-1.78,.89-2.79,.94"
          fill="#ffcc71"
        />
        <g>
          <polygon
            points="66.6 50.65 66.61 50.65 66.61 50.64 66.6 50.65"
            fill="#f2c86b"
          />
          <path
            d="M72.08,50.58c-.97-.25-1.96,.2-2.91-.35-.65-.37-1.29-.4-1.96-.26-.73,.2-.99,.65-1.09,1.07-.53,1.72,.17,1.99,1.35,1.99,1.66,0,3.19-.72,4.79-1.05,.31-.06,.55-.4,.53-.81-.03-.48-.4-.51-.71-.6Z"
            fill="#f2c86b"
          />
          <polygon
            points="67.21 49.98 67.21 49.97 67.21 49.96 67.21 49.98"
            fill="#f2c86b"
          />
        </g>
        <path
          d="M67.89,50.46c.16-.06,.38,.02,.59,.17,.06,.04,.11,.08,.1,.16-.01,.09-.07,.1-.14,.09-.5-.07-.94,.13-1.38,.32-.07,.03-.14,.04-.21,.05-.07,0-.15,.06-.19-.05-.04-.1,.03-.17,.09-.2,.35-.18,.67-.46,1.14-.55Z"
          fill="#f7ce7c"
        />
      </g>
      <g>
        <path
          d="M84.02,52.38c-.36-.01-.98,.1-1-.51-.03-.68,.58-.45,.95-.53,.51-.11,1.01-.11,1.47,.22,.12,.09,.28,.19,.24,.41-.04,.18-.2,.19-.31,.21-.44,.08-.88,.13-1.35,.19Z"
          fill="#f2c86b"
        />
        <path
          d="M86.84,49.24c-.36-.32-.76-.57-1.13-.86-.38-.3-.78-.32-1.22-.23-.83,.18-1.62,.56-2.48,.62-.61,.04-1.04,.52-1.17,1.3-.19,.39,.06,.59,.25,.75,.43,.36,.94,.59,1.45,.45,1.3-.35,2.6-.49,3.92-.41,.37,.02,.78-.06,.93-.6,.07-.55-.28-.78-.56-1.02Zm-1.01,.08c-.05,.15-.16,.15-.27,.12-.85-.27-1.68-.04-2.51,.17-.12,.03-.25,.03-.38,.03-.12,0-.28,.06-.32-.14-.04-.19,.1-.28,.22-.32,.67-.21,1.31-.62,2.16-.63,.3-.06,.65,.14,.98,.47,.09,.09,.17,.17,.12,.32Z"
          fill="#e5c676"
        />
        <path
          d="M73.87,48.43c.84-.79,1.6-1.35,2.59-1.28,.1,0,.23,0,.25,.16,.02,.19-.11,.26-.24,.28-.15,.03-.3,.06-.44,.04-.56-.1-.89,.49-1.36,.65-.21,.07-.41,.22-.8,.15Z"
          fill="#ffd797"
        />
        <path
          d="M82.54,46.22c-.66-.1-1.26,.39-1.93,.31,.44-.45,1.02-.41,1.53-.6,.17-.06,.32,.08,.4,.29Z"
          fill="#f7cf8f"
        />
        <path
          d="M75.06,51.65c-.98,0-1.73-.52-2.56-.35,.61-.66,1.66-.54,2.56,.35Z"
          fill="#efce8e"
        />
        <path
          d="M76.83,51.79c-.34-.13-.66-.36-.99-.53-1-.52-2.05-1-3.13-.78-.93,.18-1.81,.68-2.75,.88-.67,.15-.99,.88-.63,1.42,0,0,.29,.51,.68,.74,.76,.44,1.53,.75,2.37,.79,1.1,.04,2.15-.06,3.31,.1,.57,.08,1.46-.63,1.56-.75,.71-.88,.56-1.5-.42-1.86Zm-7.16,.45c.62-.68,1.23-.59,1.84-.49-.57,.11-1.08,.57-1.84,.49Zm2.83-.94c.61-.66,1.66-.54,2.56,.35-.98,0-1.73-.52-2.56-.35Z"
          fill="#e5c676"
        />
        <path
          d="M71.51,51.75c-.57,.11-1.08,.57-1.84,.49,.62-.68,1.23-.59,1.84-.49Z"
          fill="#efce8e"
        />
        <path
          d="M84.74,48.54c.3-.06,.65,.14,.98,.47,.09,.09,.17,.17,.12,.32-.05,.15-.16,.15-.27,.12-.85-.27-1.68-.04-2.51,.17-.12,.03-.25,.03-.38,.03-.12,0-.28,.06-.32-.14-.04-.19,.1-.28,.22-.32,.67-.21,1.31-.62,2.16-.63Z"
          fill="#efce8e"
        />
        <path
          d="M83.19,52.72c-.9-.49-1.74-1.24-2.84-1.2-.51,.06-1.07,.11-1.61,.18-.44,.06-.61,.38-.57,.92,.07,.81,.7,1.54,1.46,1.84,1.26,.49,2.3-.49,3.45-.67,.45-.07,.51-.84,.1-1.06Zm-2.22,1.24c-.34,.02-.7,.11-1.16-.18,.46-.1,.78-.18,1.1-.24,.1-.02,.24-.03,.27,.12,.04,.19-.09,.3-.21,.3Z"
          fill="#ffcc71"
        />
        <path
          d="M79.81,53.78c.46-.1,.78-.18,1.1-.24,.1-.02,.24-.03,.27,.12,.04,.19-.09,.3-.21,.3-.34,.02-.7,.11-1.16-.18Z"
          fill="#ffd797"
        />
        <path
          d="M84.13,46.92c-.23-.26-.55-.42-.75-.71-.66-.99-1.47-.88-2.29-.48-1.41,.7-2.87,.86-4.37,.84-.89-.02-1.77,.2-2.56,.77-1.21,.88-2.45,1.64-3.86,1.95-.24,.05-.52,.11-.58,.49h0c0,.61,.41,.58,.72,.63,.62,.11,1.22-.07,1.83-.17,1.41-.23,2.86,.37,4.26-.29,0,0,.34-.21,.39-.31,.22-.31,.53-.21,.77-.13,.92,.29,1.77,.04,2.59-.42,1.03-.57,2.08-1.01,3.24-1,.23,0,.58-.19,.71-.43,.2-.29,.07-.54-.09-.73Zm-7.66,.68c-.15,.03-.3,.06-.44,.04-.56-.1-.89,.49-1.36,.65-.21,.07-.41,.22-.8,.15,.84-.79,1.6-1.35,2.59-1.28,.1,0,.23,0,.25,.16,.02,.19-.11,.26-.24,.28Zm4.15-1.06c.44-.45,1.02-.41,1.53-.6,.17-.06,.32,.08,.4,.29-.66-.1-1.26,.39-1.93,.31"
          fill="#ffcc71"
        />
        <g>
          <polygon
            points="76.18 49.53 76.18 49.53 76.18 49.52 76.18 49.53"
            fill="#f2c86b"
          />
          <path
            d="M79.77,50.07c-.61-.27-1.3-.08-1.87-.54-.38-.31-.8-.4-1.25-.38-.5,.06-.72,.32-.83,.58-.53,1.07-.1,1.32,.67,1.45,1.08,.18,2.16-.13,3.25-.17,.21,0,.41-.2,.43-.47,.03-.32-.2-.38-.4-.47Z"
            fill="#f2c86b"
          />
          <polygon
            points="76.65 49.15 76.65 49.15 76.65 49.14 76.65 49.15"
            fill="#f2c86b"
          />
        </g>
        <path
          d="M77.04,49.54c.11-.02,.24,.05,.37,.17,.03,.03,.06,.06,.04,.12-.02,.06-.06,.06-.1,.04-.32-.1-.63-.01-.94,.06-.05,.01-.09,.01-.14,0-.04,0-.11,.02-.12-.05-.01-.07,.04-.11,.08-.12,.25-.08,.49-.23,.81-.23Z"
          fill="#f7ce7c"
        />
      </g>
      <g>
        <path
          d="M63.1,57.5c-.34,.11-.88,.43-1.12-.13-.26-.63,.39-.63,.71-.83,.44-.28,.91-.46,1.46-.3,.14,.04,.33,.08,.36,.3,.03,.18-.12,.25-.22,.31-.39,.23-.78,.43-1.2,.65Z"
          fill="#f2c86b"
        />
        <path
          d="M64.66,53.57c-.44-.17-.91-.27-1.36-.41-.46-.15-.84-.03-1.22,.21-.72,.45-1.32,1.09-2.11,1.44-.56,.25-.8,.85-.64,1.63-.05,.43,.26,.53,.5,.61,.53,.19,1.09,.23,1.52-.08,1.09-.78,2.26-1.36,3.53-1.75,.36-.11,.71-.32,.67-.89-.12-.54-.53-.63-.88-.76Zm-.92,.43c0,.16-.1,.2-.21,.2-.89,.04-1.59,.55-2.29,1.03-.1,.07-.23,.12-.35,.16-.11,.04-.25,.15-.35-.02-.1-.16,0-.3,.1-.38,.56-.43,1.01-1.03,1.81-1.34,.26-.16,.66-.1,1.08,.1,.11,.05,.22,.1,.22,.25Z"
          fill="#e5c676"
        />
        <path
          d="M52.21,57.32c.51-1.03,1.03-1.82,1.98-2.1,.1-.03,.22-.09,.29,.07,.08,.18-.02,.28-.13,.35-.13,.08-.26,.16-.4,.19-.56,.1-.66,.77-1.05,1.08-.17,.14-.31,.35-.7,.41Z"
          fill="#ffd797"
        />
        <path
          d="M59.58,52.24c-.66,.14-1.05,.8-1.7,.96,.26-.57,.81-.74,1.22-1.1,.14-.12,.33-.04,.47,.13Z"
          fill="#f7cf8f"
        />
        <path
          d="M54.44,59.92c-.92,.34-1.8,.12-2.52,.56,.34-.84,1.37-1.08,2.52-.56Z"
          fill="#efce8e"
        />
        <path
          d="M56.16,59.44c-.36,0-.74-.11-1.11-.16-1.12-.14-2.27-.22-3.21,.35-.81,.49-1.46,1.26-2.27,1.79-.58,.37-.63,1.17-.1,1.55,0,0,.45,.38,.89,.45,.87,.14,1.69,.18,2.5-.09,1.05-.34,1.99-.8,3.14-1.06,.56-.13,1.15-1.1,1.21-1.25,.36-1.07,0-1.6-1.04-1.6Zm-6.56,2.91c.35-.85,.95-.98,1.56-1.1-.49,.3-.81,.91-1.56,1.1Zm2.33-1.87c.34-.84,1.37-1.08,2.52-.56-.92,.34-1.8,.12-2.52,.56Z"
          fill="#e5c676"
        />
        <path
          d="M51.16,61.26c-.49,.3-.81,.91-1.56,1.1,.35-.85,.95-.98,1.56-1.1Z"
          fill="#efce8e"
        />
        <path
          d="M62.44,53.65c.26-.16,.66-.1,1.08,.1,.11,.05,.22,.1,.22,.25,0,.16-.1,.2-.21,.2-.89,.04-1.59,.55-2.29,1.03-.1,.07-.23,.12-.35,.16-.11,.04-.25,.15-.35-.02-.1-.16,0-.3,.1-.38,.56-.43,1.01-1.03,1.81-1.34Z"
          fill="#efce8e"
        />
        <path
          d="M62.45,58.1c-1.02-.15-2.06-.56-3.08-.14-.46,.23-.96,.47-1.45,.73-.39,.21-.44,.57-.21,1.06,.35,.74,1.19,1.2,2.01,1.21,1.35,.03,1.99-1.26,3-1.83,.4-.22,.18-.96-.27-1.03Zm-1.65,1.94c-.31,.14-.61,.35-1.15,.23,.4-.26,.67-.44,.95-.61,.09-.05,.21-.11,.29,.02,.11,.17,.02,.31-.09,.36Z"
          fill="#ffcc71"
        />
        <path
          d="M59.65,60.27c.4-.26,.67-.44,.95-.61,.09-.05,.21-.11,.29,.02,.11,.17,.02,.31-.09,.36-.31,.14-.61,.35-1.15,.23Z"
          fill="#ffd797"
        />
        <path
          d="M61.31,52.34c-.31-.17-.67-.2-.95-.41-.96-.69-1.69-.32-2.32,.35-1.08,1.14-2.39,1.81-3.8,2.3-.84,.3-1.59,.8-2.14,1.62-.83,1.24-1.73,2.39-2.94,3.17-.21,.13-.45,.28-.38,.67h0c.21,.57,.58,.4,.9,.34,.62-.11,1.12-.49,1.65-.79,1.24-.7,2.81-.65,3.89-1.76,0,0,.25-.32,.26-.43,.1-.37,.42-.38,.67-.39,.96-.05,1.67-.57,2.29-1.29,.77-.89,1.6-1.67,2.69-2.07,.22-.08,.48-.38,.52-.65,.09-.34-.12-.54-.34-.65Zm-6.95,3.3c-.13,.08-.26,.16-.4,.19-.56,.1-.66,.77-1.05,1.08-.17,.14-.31,.35-.7,.41,.51-1.03,1.03-1.82,1.98-2.1,.1-.03,.22-.09,.29,.07,.08,.18-.02,.28-.13,.35Zm3.53-2.44c.26-.57,.81-.74,1.22-1.1,.14-.12,.33-.04,.47,.13-.66,.14-1.05,.8-1.7,.96"
          fill="#ffcc71"
        />
        <g>
          <polygon
            points="54.76 57.55 54.76 57.55 54.76 57.54 54.76 57.55"
            fill="#f2c86b"
          />
          <path
            d="M58.32,56.81c-.66-.04-1.25,.38-1.94,.15-.47-.16-.89-.1-1.31,.08-.45,.23-.56,.55-.57,.83-.13,1.19,.37,1.27,1.13,1.13,1.08-.21,1.98-.87,2.98-1.29,.19-.08,.31-.33,.24-.59-.08-.31-.32-.28-.54-.3Z"
            fill="#f2c86b"
          />
          <polygon
            points="55.07 57.03 55.07 57.03 55.07 57.02 55.07 57.03"
            fill="#f2c86b"
          />
        </g>
        <path
          d="M55.57,57.26c.1-.06,.25-.04,.4,.04,.04,.02,.08,.04,.08,.1,0,.06-.04,.07-.08,.07-.33,.01-.59,.2-.86,.38-.04,.03-.08,.04-.13,.06-.04,.01-.09,.06-.13,0-.04-.06,0-.11,.04-.14,.21-.16,.38-.39,.67-.5Z"
          fill="#f7ce7c"
        />
      </g>
      <g>
        <path
          d="M76.22,58.31c-.34,.11-.88,.43-1.12-.13-.26-.63,.39-.63,.71-.83,.44-.28,.91-.46,1.46-.3,.14,.04,.33,.08,.36,.3,.03,.18-.12,.25-.22,.31-.39,.23-.78,.43-1.2,.65Z"
          fill="#f2c86b"
        />
        <path
          d="M77.78,54.38c-.44-.17-.91-.27-1.36-.41-.46-.15-.84-.03-1.22,.21-.72,.45-1.32,1.09-2.11,1.44-.56,.25-.8,.85-.64,1.63-.05,.43,.26,.53,.5,.61,.53,.19,1.09,.23,1.52-.08,1.09-.78,2.26-1.36,3.53-1.75,.36-.11,.71-.32,.67-.89-.12-.54-.53-.63-.88-.76Zm-.92,.43c0,.16-.1,.2-.21,.2-.89,.04-1.59,.55-2.29,1.03-.1,.07-.23,.12-.35,.16-.11,.04-.25,.15-.35-.02-.1-.16,0-.3,.1-.38,.56-.43,1.01-1.03,1.81-1.34,.26-.16,.66-.1,1.08,.1,.11,.05,.22,.1,.22,.25Z"
          fill="#e5c676"
        />
        <path
          d="M65.33,58.13c.51-1.03,1.03-1.82,1.98-2.1,.1-.03,.22-.09,.29,.07,.08,.18-.02,.28-.13,.35-.13,.08-.26,.16-.4,.19-.56,.1-.66,.77-1.05,1.08-.17,.14-.31,.35-.7,.41Z"
          fill="#ffd797"
        />
        <path
          d="M72.7,53.05c-.66,.14-1.05,.8-1.7,.96,.26-.57,.81-.74,1.22-1.1,.14-.12,.33-.04,.47,.13Z"
          fill="#f7cf8f"
        />
        <path
          d="M67.57,60.73c-.92,.34-1.8,.12-2.52,.56,.34-.84,1.37-1.08,2.52-.56Z"
          fill="#efce8e"
        />
        <path
          d="M69.28,60.25c-.36,0-.74-.11-1.11-.16-1.12-.14-2.27-.22-3.21,.35-.81,.49-1.46,1.26-2.27,1.79-.58,.37-.63,1.17-.1,1.55,0,0,.45,.38,.89,.45,.87,.14,1.69,.18,2.5-.09,1.05-.34,1.99-.8,3.14-1.06,.56-.13,1.15-1.1,1.21-1.25,.36-1.07,0-1.6-1.04-1.6Zm-6.56,2.91c.35-.85,.95-.98,1.56-1.1-.49,.3-.81,.91-1.56,1.1Zm2.33-1.87c.34-.84,1.37-1.08,2.52-.56-.92,.34-1.8,.12-2.52,.56Z"
          fill="#e5c676"
        />
        <path
          d="M64.28,62.07c-.49,.3-.81,.91-1.56,1.1,.35-.85,.95-.98,1.56-1.1Z"
          fill="#efce8e"
        />
        <path
          d="M75.56,54.46c.26-.16,.66-.1,1.08,.1,.11,.05,.22,.1,.22,.25,0,.16-.1,.2-.21,.2-.89,.04-1.59,.55-2.29,1.03-.1,.07-.23,.12-.35,.16-.11,.04-.25,.15-.35-.02-.1-.16,0-.3,.1-.38,.56-.43,1.01-1.03,1.81-1.34Z"
          fill="#efce8e"
        />
        <path
          d="M75.57,58.91c-1.02-.15-2.06-.56-3.08-.14-.46,.23-.96,.47-1.45,.73-.39,.21-.44,.57-.21,1.06,.35,.74,1.19,1.2,2.01,1.21,1.35,.03,1.99-1.26,3-1.83,.4-.22,.18-.96-.27-1.03Zm-1.65,1.94c-.31,.14-.61,.35-1.15,.23,.4-.26,.67-.44,.95-.61,.09-.05,.21-.11,.29,.02,.11,.17,.02,.31-.09,.36Z"
          fill="#ffcc71"
        />
        <path
          d="M72.77,61.08c.4-.26,.67-.44,.95-.61,.09-.05,.21-.11,.29,.02,.11,.17,.02,.31-.09,.36-.31,.14-.61,.35-1.15,.23Z"
          fill="#ffd797"
        />
        <path
          d="M74.43,53.15c-.31-.17-.67-.2-.95-.41-.96-.69-1.69-.32-2.32,.35-1.08,1.14-2.39,1.81-3.8,2.3-.84,.3-1.59,.8-2.14,1.62-.83,1.24-1.73,2.39-2.94,3.17-.21,.13-.45,.28-.38,.67h0c.21,.57,.58,.4,.9,.34,.62-.11,1.12-.49,1.65-.79,1.24-.7,2.81-.65,3.89-1.76,0,0,.25-.32,.26-.43,.1-.37,.42-.38,.67-.39,.96-.05,1.67-.57,2.29-1.29,.77-.89,1.6-1.67,2.69-2.07,.22-.08,.48-.38,.52-.65,.09-.34-.12-.54-.34-.65Zm-6.95,3.3c-.13,.08-.26,.16-.4,.19-.56,.1-.66,.77-1.05,1.08-.17,.14-.31,.35-.7,.41,.51-1.03,1.03-1.82,1.98-2.1,.1-.03,.22-.09,.29,.07,.08,.18-.02,.28-.13,.35Zm3.53-2.44c.26-.57,.81-.74,1.22-1.1,.14-.12,.33-.04,.47,.13-.66,.14-1.05,.8-1.7,.96"
          fill="#ffcc71"
        />
        <g>
          <polygon
            points="67.88 58.36 67.88 58.36 67.88 58.35 67.88 58.36"
            fill="#f2c86b"
          />
          <path
            d="M71.44,57.62c-.66-.04-1.25,.38-1.94,.15-.47-.16-.89-.1-1.31,.08-.45,.23-.56,.55-.57,.83-.13,1.19,.37,1.27,1.13,1.13,1.08-.21,1.98-.87,2.98-1.29,.19-.08,.31-.33,.24-.59-.08-.31-.32-.28-.54-.3Z"
            fill="#f2c86b"
          />
          <polygon
            points="68.19 57.84 68.19 57.84 68.19 57.83 68.19 57.84"
            fill="#f2c86b"
          />
        </g>
        <path
          d="M68.69,58.07c.1-.06,.25-.04,.4,.04,.04,.02,.08,.04,.08,.1,0,.06-.04,.07-.08,.07-.33,.01-.59,.2-.86,.38-.04,.03-.08,.04-.13,.06-.04,.01-.09,.06-.13,0-.04-.06,0-.11,.04-.14,.21-.16,.38-.39,.67-.5Z"
          fill="#f7ce7c"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M64.85,66.11c-.44,.11-1.16,.48-1.37-.37-.24-.96,.57-.85,1-1.1,.59-.34,1.2-.52,1.86-.2,.17,.08,.4,.18,.41,.5,.01,.27-.19,.34-.32,.41-.51,.27-1.04,.49-1.58,.75Z"
          fill="#f2c86b"
        />
        <path
          d="M67.35,60.64c-.53-.33-1.09-.54-1.63-.83-.55-.29-1.05-.18-1.55,.1-.96,.54-1.8,1.37-2.83,1.76-.73,.27-1.11,1.11-1.03,2.27-.12,.62,.25,.82,.53,.98,.63,.36,1.32,.51,1.9,.14,1.47-.95,3.01-1.61,4.65-1.97,.46-.1,.93-.35,.96-1.19-.08-.8-.58-1.01-.99-1.26Zm-1.2,.47c-.01,.24-.15,.27-.29,.26-1.12-.09-2.06,.53-3,1.12-.14,.09-.3,.14-.46,.17-.15,.03-.33,.18-.44-.09-.1-.25,.04-.44,.17-.53,.76-.53,1.4-1.34,2.44-1.66,.34-.19,.84-.03,1.33,.32,.13,.09,.25,.18,.24,.41Z"
          fill="#e5c676"
        />
        <path
          d="M51.32,64.04c.78-1.42,1.54-2.49,2.77-2.73,.12-.02,.28-.09,.35,.15,.08,.27-.06,.41-.21,.49-.17,.09-.34,.19-.53,.21-.71,.06-.93,1.01-1.46,1.4-.24,.17-.43,.46-.92,.49Z"
          fill="#ffd797"
        />
        <path
          d="M61.21,57.84c-.84,.09-1.42,1-2.25,1.13,.4-.79,1.11-.95,1.68-1.4,.19-.15,.41,0,.57,.27Z"
          fill="#f7cf8f"
        />
        <path
          d="M53.73,68.21c-1.19,.34-2.26-.13-3.21,.4,.55-1.16,1.85-1.35,3.21-.4Z"
          fill="#efce8e"
        />
        <path
          d="M55.93,67.8c-.45-.06-.9-.28-1.36-.41-1.37-.39-2.79-.71-4.05-.02-1.08,.59-2,1.6-3.09,2.23-.77,.45-.95,1.6-.35,2.25,0,0,.51,.63,1.05,.81,1.06,.36,2.08,.54,3.12,.29,1.35-.33,2.59-.84,4.06-1.02,.72-.09,1.59-1.41,1.68-1.62,.6-1.5,.23-2.33-1.06-2.5Zm-8.58,3.16c.56-1.18,1.32-1.27,2.09-1.34-.66,.36-1.14,1.2-2.09,1.34Zm3.16-2.34c.55-1.16,1.85-1.35,3.21-.4-1.19,.34-2.26-.13-3.21,.4Z"
          fill="#e5c676"
        />
        <path
          d="M49.44,69.61c-.66,.36-1.14,1.2-2.1,1.34,.56-1.18,1.32-1.27,2.1-1.34Z"
          fill="#efce8e"
        />
        <path
          d="M64.58,60.38c.34-.19,.84-.03,1.33,.32,.13,.09,.25,.18,.24,.41-.01,.24-.15,.27-.29,.26-1.12-.09-2.06,.53-3,1.12-.14,.09-.3,.14-.46,.17-.15,.03-.33,.18-.44-.09-.1-.25,.04-.44,.17-.53,.76-.53,1.4-1.34,2.44-1.66Z"
          fill="#efce8e"
        />
        <path
          d="M63.95,66.88c-1.24-.39-2.49-1.16-3.81-.72-.61,.26-1.26,.53-1.91,.83-.52,.24-.63,.76-.41,1.51,.33,1.14,1.32,1.95,2.33,2.11,1.68,.26,2.65-1.5,4-2.18,.53-.26,.37-1.37-.2-1.55Zm-2.33,2.55c-.41,.15-.81,.4-1.46,.15,.53-.31,.9-.54,1.27-.74,.11-.06,.28-.12,.36,.08,.11,.26-.02,.45-.17,.51Z"
          fill="#ffcc71"
        />
        <path
          d="M60.16,69.58c.53-.31,.9-.54,1.27-.74,.11-.06,.28-.12,.36,.08,.11,.26-.02,.45-.17,.51-.41,.15-.81,.4-1.46,.15Z"
          fill="#ffd797"
        />
        <path
          d="M63.35,58.28c-.36-.29-.8-.41-1.12-.75-1.1-1.17-2.05-.74-2.93,.13-1.5,1.49-3.23,2.24-5.06,2.73-1.09,.29-2.1,.9-2.89,2-1.21,1.67-2.49,3.2-4.11,4.14-.28,.16-.6,.33-.56,.91h0c.19,.87,.67,.68,1.07,.65,.78-.06,1.46-.53,2.17-.88,1.65-.82,3.6-.48,5.09-1.92,0,0,.36-.42,.38-.58,.18-.52,.58-.49,.9-.46,1.2,.09,2.16-.56,3.03-1.51,1.08-1.18,2.23-2.18,3.64-2.57,.28-.08,.65-.48,.73-.86,.15-.48-.07-.8-.33-1.01Zm-9.12,3.67c-.17,.09-.34,.19-.53,.21-.71,.06-.93,1.01-1.46,1.4-.24,.17-.43,.46-.92,.49,.78-1.42,1.54-2.49,2.77-2.73,.12-.02,.28-.09,.35,.15,.08,.27-.06,.41-.21,.49Zm4.74-2.97c.4-.79,1.11-.95,1.68-1.4,.19-.15,.41,0,.57,.27-.84,.09-1.42,1-2.25,1.13"
          fill="#ffcc71"
        />
        <g>
          <polygon
            points="54.46 64.79 54.46 64.79 54.46 64.79 54.46 64.79"
            fill="#f2c86b"
          />
          <path
            d="M58.99,64.31c-.82-.17-1.61,.35-2.44-.11-.56-.31-1.1-.29-1.64-.11-.59,.25-.78,.71-.83,1.12-.33,1.71,.27,1.92,1.25,1.83,1.37-.12,2.59-.94,3.9-1.39,.25-.09,.43-.43,.39-.82-.06-.46-.36-.47-.63-.53Z"
            fill="#f2c86b"
          />
          <polygon
            points="54.91 64.09 54.92 64.09 54.92 64.08 54.91 64.09"
            fill="#f2c86b"
          />
        </g>
        <path
          d="M55.51,64.51c.13-.07,.31-.01,.5,.12,.05,.03,.09,.07,.09,.15,0,.09-.06,.1-.11,.1-.42-.03-.77,.2-1.12,.42-.05,.03-.11,.05-.17,.06-.05,.01-.12,.07-.16-.03-.04-.09,.02-.16,.06-.2,.28-.2,.52-.5,.91-.62Z"
          fill="#f7ce7c"
        />
      </g>
      <g>
        <path
          d="M73.8,62.08c-.68,.01-1.84,.32-1.94-1.01-.12-1.5,1.05-1.07,1.75-1.28,.96-.3,1.9-.36,2.8,.34,.23,.18,.54,.39,.48,.89-.05,.41-.36,.45-.57,.5-.82,.22-1.66,.38-2.52,.57Z"
          fill="#f2c86b"
        />
        <path
          d="M78.84,54.83c-.7-.67-1.48-1.17-2.21-1.78-.75-.62-1.5-.63-2.32-.37-1.55,.48-3,1.42-4.62,1.64-1.14,.15-1.92,1.27-2.09,3.01-.33,.87,.17,1.3,.55,1.63,.84,.75,1.83,1.21,2.77,.85,2.41-.91,4.85-1.37,7.36-1.34,.7,0,1.46-.21,1.71-1.44,.08-1.21-.6-1.68-1.15-2.2Zm-1.89,.29c-.08,.34-.29,.35-.49,.28-1.63-.51-3.17,.09-4.72,.64-.23,.08-.48,.1-.72,.1-.22,0-.53,.16-.62-.28-.09-.41,.17-.64,.39-.73,1.25-.53,2.41-1.51,4.02-1.62,.56-.16,1.25,.24,1.89,.93,.17,.18,.33,.36,.26,.69Z"
          fill="#e5c676"
        />
        <path
          d="M54.3,54.43c1.51-1.83,2.9-3.16,4.77-3.1,.19,0,.44-.04,.48,.34,.05,.43-.19,.58-.43,.65-.27,.07-.56,.17-.83,.13-1.07-.16-1.63,1.18-2.5,1.58-.39,.18-.75,.54-1.49,.41Z"
          fill="#ffd797"
        />
        <path
          d="M70.46,48.61c-1.26-.15-2.35,1-3.6,.9,.79-1.03,1.88-1.02,2.83-1.5,.32-.16,.61,.14,.78,.6Z"
          fill="#f7cf8f"
        />
        <path
          d="M56.83,61.41c-1.84,.1-3.31-.96-4.85-.5,1.09-1.54,3.07-1.37,4.85,.5Z"
          fill="#efce8e"
        />
        <path
          d="M60.19,61.55c-.66-.24-1.27-.73-1.91-1.07-1.94-1.05-3.95-1.99-5.98-1.4-1.74,.5-3.35,1.69-5.11,2.25-1.26,.4-1.79,2.04-1.07,3.2,0,0,.6,1.1,1.35,1.55,1.48,.89,2.95,1.51,4.54,1.49,2.08-.02,4.04-.36,6.25-.14,1.09,.11,2.7-1.54,2.88-1.83,1.26-2.02,.92-3.37-.96-4.06Zm-13.47,1.76c1.11-1.56,2.27-1.43,3.43-1.28-1.06,.3-1.98,1.39-3.43,1.28Zm5.26-2.39c1.09-1.54,3.07-1.37,4.85,.5-1.84,.1-3.31-.96-4.85-.5Z"
          fill="#e5c676"
        />
        <path
          d="M50.15,62.03c-1.06,.3-1.98,1.39-3.43,1.28,1.11-1.56,2.27-1.43,3.43-1.28Z"
          fill="#efce8e"
        />
        <path
          d="M74.81,53.5c.56-.16,1.25,.24,1.89,.93,.17,.18,.33,.36,.26,.69-.08,.34-.29,.35-.49,.28-1.63-.51-3.17,.09-4.72,.64-.23,.08-.48,.1-.72,.1-.22,0-.53,.16-.62-.28-.09-.41,.17-.64,.39-.73,1.25-.53,2.41-1.51,4.02-1.62Z"
          fill="#efce8e"
        />
        <path
          d="M72.28,62.91c-1.75-.99-3.39-2.56-5.46-2.36-.96,.18-2,.35-3.03,.58-.83,.18-1.12,.92-.99,2.09,.21,1.79,1.47,3.33,2.93,3.91,2.42,.96,4.29-1.33,6.46-1.86,.84-.21,.88-1.91,.09-2.36Zm-4.07,2.98c-.64,.09-1.3,.32-2.2-.28,.86-.28,1.46-.49,2.06-.66,.18-.05,.44-.08,.52,.24,.1,.42-.14,.66-.37,.7Z"
          fill="#ffcc71"
        />
        <path
          d="M66,65.62c.86-.28,1.46-.49,2.06-.66,.18-.05,.44-.08,.52,.24,.1,.42-.14,.66-.37,.7-.64,.09-1.3,.32-2.2-.28Z"
          fill="#ffd797"
        />
        <path
          d="M73.51,49.98c-.46-.56-1.08-.87-1.48-1.49-1.34-2.11-2.86-1.8-4.37-.81-2.59,1.69-5.33,2.22-8.16,2.32-1.69,.06-3.32,.62-4.77,1.98-2.2,2.07-4.48,3.89-7.1,4.73-.45,.14-.97,.29-1.06,1.15h0c.06,1.34,.83,1.23,1.42,1.32,1.17,.18,2.29-.29,3.43-.57,2.64-.66,5.44,.51,8-1.11,0,0,.63-.5,.71-.73,.39-.71,.97-.52,1.44-.38,1.76,.53,3.33-.1,4.86-1.2,1.89-1.38,3.84-2.47,6.02-2.57,.44-.02,1.08-.49,1.3-1.03,.35-.66,.09-1.21-.24-1.61Zm-14.4,2.33c-.27,.07-.56,.17-.83,.13-1.07-.16-1.63,1.18-2.5,1.58-.39,.18-.75,.54-1.49,.41,1.51-1.83,2.9-3.16,4.77-3.1,.19,0,.44-.04,.48,.34,.05,.43-.19,.58-.43,.65Zm7.74-2.79c.79-1.03,1.88-1.02,2.83-1.5,.32-.16,.61,.14,.78,.6-1.26-.15-2.35,1-3.6,.9"
          fill="#ffcc71"
        />
        <g>
          <polygon
            points="58.75 56.6 58.76 56.6 58.76 56.59 58.75 56.6"
            fill="#f2c86b"
          />
          <path
            d="M65.58,57.42c-1.17-.53-2.47-.04-3.58-.99-.75-.65-1.55-.8-2.4-.71-.94,.18-1.32,.78-1.51,1.38-.9,2.42-.07,2.93,1.4,3.13,2.06,.28,4.07-.52,6.11-.73,.39-.04,.75-.5,.78-1.09,.03-.71-.42-.82-.8-.99Z"
            fill="#f2c86b"
          />
          <path d="M59.6,55.72h0v0Z" fill="#f2c86b" />
        </g>
        <path
          d="M60.38,56.54c.21-.06,.47,.09,.7,.35,.06,.07,.12,.13,.1,.26-.03,.13-.11,.13-.18,.11-.61-.19-1.18,.03-1.76,.24-.09,.03-.18,.04-.27,.04-.08,0-.2,.06-.23-.11-.03-.15,.06-.24,.14-.27,.47-.2,.9-.56,1.5-.61Z"
          fill="#f7ce7c"
        />
      </g>
      <g>
        <path
          d="M80.19,62.03c-.44-.08-1.23-.02-1.18-.91,.06-.99,.79-.57,1.26-.62,.66-.07,1.28,0,1.8,.57,.14,.15,.32,.33,.23,.64-.07,.26-.28,.25-.42,.26-.56,.04-1.12,.04-1.7,.06Z"
          fill="#f2c86b"
        />
        <path
          d="M84.15,57.92c-.4-.52-.86-.95-1.28-1.44-.43-.5-.93-.6-1.48-.54-1.06,.12-2.09,.55-3.17,.49-.76-.04-1.37,.59-1.64,1.71-.3,.53,0,.87,.21,1.14,.48,.6,1.09,1.02,1.74,.9,1.66-.29,3.3-.28,4.94,.05,.46,.09,.97,.05,1.25-.73,.16-.78-.24-1.18-.55-1.59Zm-1.26-.05c-.08,.22-.22,.19-.35,.12-1.02-.54-2.09-.34-3.15-.17-.16,.02-.32,0-.48-.02-.15-.03-.36,.04-.38-.26-.02-.28,.17-.4,.32-.43,.87-.19,1.71-.68,2.78-.56,.38-.04,.79,.31,1.15,.84,.09,.14,.18,.27,.1,.48Z"
          fill="#e5c676"
        />
        <path
          d="M68.12,54.58c1.15-1.01,2.18-1.7,3.4-1.43,.12,.03,.29,.03,.29,.28,0,.29-.18,.36-.34,.37-.19,.01-.38,.04-.55-.02-.68-.24-1.17,.57-1.78,.72-.27,.07-.54,.26-1.01,.08Z"
          fill="#ffd797"
        />
        <path
          d="M79.23,52.8c-.81-.26-1.63,.36-2.44,.14,.61-.58,1.32-.44,1.99-.63,.22-.06,.39,.17,.45,.49Z"
          fill="#f7cf8f"
        />
        <path
          d="M69.14,59.47c-1.22-.17-2.08-1.04-3.13-.93,.86-.87,2.14-.51,3.13,.93Z"
          fill="#efce8e"
        />
        <path
          d="M71.33,59.98c-.41-.24-.76-.63-1.15-.94-1.17-.93-2.41-1.8-3.79-1.67-1.18,.11-2.35,.69-3.55,.83-.86,.1-1.36,1.11-.99,1.96,0,0,.29,.8,.74,1.19,.89,.77,1.79,1.36,2.84,1.55,1.37,.25,2.68,.27,4.11,.7,.7,.21,1.91-.67,2.05-.84,1.01-1.16,.91-2.09-.26-2.78Zm-8.98-.54c.87-.88,1.62-.65,2.36-.4-.72,.07-1.42,.66-2.36,.4Zm3.66-.9c.86-.87,2.14-.51,3.13,.93-1.22-.17-2.08-1.04-3.13-.93Z"
          fill="#e5c676"
        />
        <path
          d="M64.71,59.03c-.72,.07-1.42,.66-2.36,.4,.87-.88,1.62-.65,2.36-.4Z"
          fill="#efce8e"
        />
        <path
          d="M81.63,56.55c.38-.04,.79,.31,1.15,.84,.09,.14,.18,.27,.1,.48-.08,.22-.22,.19-.35,.12-1.02-.54-2.09-.34-3.15-.17-.16,.02-.32,0-.48-.02-.15-.03-.36,.04-.38-.26-.02-.28,.17-.4,.32-.43,.87-.19,1.71-.68,2.78-.56Z"
          fill="#efce8e"
        />
        <path
          d="M79.11,62.39c-1.05-.87-1.99-2.1-3.36-2.23-.65,0-1.34-.02-2.04,0-.56,.02-.82,.46-.84,1.25-.03,1.2,.66,2.36,1.56,2.93,1.49,.93,2.93-.33,4.4-.41,.57-.03,.75-1.14,.28-1.53Zm-2.94,1.44c-.43-.02-.88,.05-1.42-.46,.59-.08,1-.14,1.41-.17,.13-.01,.3,0,.32,.22,.03,.29-.15,.42-.31,.41Z"
          fill="#ffcc71"
        />
        <path
          d="M74.76,63.37c.59-.08,1-.14,1.41-.17,.13-.01,.3,0,.32,.22,.03,.29-.15,.42-.31,.41-.43-.02-.88,.05-1.42-.46Z"
          fill="#ffd797"
        />
        <path
          d="M81.1,54.08c-.25-.42-.63-.71-.83-1.16-.68-1.55-1.71-1.54-2.79-1.08-1.85,.78-3.69,.78-5.56,.49-1.11-.17-2.23,0-3.3,.7-1.63,1.08-3.29,1.99-5.08,2.21-.31,.04-.66,.07-.8,.62h0c-.08,.89,.43,.91,.81,1.04,.75,.26,1.53,.1,2.3,.06,1.79-.1,3.51,1.02,5.34,.28,0,0,.46-.25,.53-.39,.32-.42,.69-.22,.98-.07,1.1,.57,2.19,.36,3.29-.18,1.36-.66,2.74-1.13,4.18-.93,.29,.04,.75-.19,.94-.51,.29-.39,.17-.78-.01-1.09Zm-9.64-.28c-.19,.01-.38,.04-.55-.02-.68-.24-1.17,.57-1.78,.72-.27,.07-.54,.26-1.01,.08,1.15-1.01,2.18-1.7,3.4-1.43,.12,.03,.29,.03,.29,.28,0,.29-.18,.36-.34,.37Zm5.32-.86c.61-.58,1.32-.44,1.99-.63,.22-.06,.39,.17,.45,.49-.81-.26-1.63,.36-2.44,.14"
          fill="#ffcc71"
        />
        <g>
          <polygon
            points="70.83 56.56 70.84 56.56 70.84 56.56 70.83 56.56"
            fill="#f2c86b"
          />
          <path
            d="M75.23,57.95c-.72-.49-1.61-.33-2.25-1.1-.43-.52-.94-.72-1.5-.77-.63,0-.94,.35-1.11,.71-.81,1.47-.31,1.91,.63,2.23,1.32,.44,2.71,.17,4.07,.29,.26,.02,.54-.23,.61-.62,.09-.46-.2-.59-.43-.75Z"
            fill="#f2c86b"
          />
          <polygon
            points="71.47 56.09 71.47 56.09 71.48 56.08 71.47 56.09"
            fill="#f2c86b"
          />
        </g>
        <path
          d="M71.9,56.72c.14-.01,.3,.12,.43,.32,.04,.05,.07,.1,.04,.18-.03,.08-.08,.07-.13,.05-.38-.2-.78-.13-1.18-.06-.06,0-.12,0-.18,0-.05-.01-.13,.01-.14-.1,0-.1,.06-.15,.12-.16,.32-.07,.64-.26,1.04-.21Z"
          fill="#f7ce7c"
        />
      </g>
      <g>
        <path
          d="M53.42,66.02c-.44,.11-1.16,.48-1.37-.37-.24-.96,.57-.85,1-1.1,.59-.34,1.2-.52,1.86-.2,.17,.08,.4,.18,.41,.5,.01,.27-.19,.34-.32,.41-.51,.27-1.04,.49-1.58,.75Z"
          fill="#f2c86b"
        />
        <path
          d="M55.92,60.55c-.53-.33-1.09-.54-1.63-.83-.55-.29-1.05-.18-1.55,.1-.96,.54-1.8,1.37-2.83,1.76-.73,.27-1.11,1.11-1.03,2.27-.12,.62,.25,.82,.53,.98,.63,.36,1.32,.51,1.9,.14,1.47-.95,3.01-1.61,4.65-1.97,.46-.1,.93-.35,.96-1.19-.08-.8-.58-1.01-.99-1.26Zm-1.2,.47c-.01,.24-.15,.27-.29,.26-1.12-.09-2.06,.53-3,1.12-.14,.09-.3,.14-.46,.17-.15,.03-.33,.18-.44-.09-.1-.25,.04-.44,.17-.53,.76-.53,1.4-1.34,2.44-1.66,.34-.19,.84-.03,1.33,.32,.13,.09,.25,.18,.24,.41Z"
          fill="#e5c676"
        />
        <path
          d="M39.89,63.95c.78-1.42,1.54-2.49,2.77-2.73,.12-.02,.28-.09,.35,.15,.08,.27-.06,.41-.21,.49-.17,.09-.34,.19-.53,.21-.71,.06-.93,1.01-1.46,1.4-.24,.17-.43,.46-.92,.49Z"
          fill="#ffd797"
        />
        <path
          d="M49.78,57.75c-.84,.09-1.42,1-2.25,1.13,.4-.79,1.11-.95,1.68-1.4,.19-.15,.41,0,.57,.27Z"
          fill="#f7cf8f"
        />
        <path
          d="M42.3,68.12c-1.19,.34-2.26-.13-3.21,.4,.55-1.16,1.85-1.35,3.21-.4Z"
          fill="#efce8e"
        />
        <path
          d="M44.5,67.7c-.45-.06-.9-.28-1.36-.41-1.37-.39-2.79-.71-4.05-.02-1.08,.59-2,1.6-3.09,2.23-.77,.45-.95,1.6-.35,2.25,0,0,.51,.63,1.05,.81,1.06,.36,2.08,.54,3.12,.29,1.35-.33,2.59-.84,4.06-1.02,.72-.09,1.59-1.41,1.68-1.62,.6-1.5,.23-2.33-1.06-2.5Zm-8.58,3.16c.56-1.18,1.32-1.27,2.09-1.34-.66,.36-1.14,1.2-2.09,1.34Zm3.16-2.34c.55-1.16,1.85-1.35,3.21-.4-1.19,.34-2.26-.13-3.21,.4Z"
          fill="#e5c676"
        />
        <path
          d="M38.01,69.52c-.66,.36-1.14,1.2-2.1,1.34,.56-1.18,1.32-1.27,2.1-1.34Z"
          fill="#efce8e"
        />
        <path
          d="M53.14,60.28c.34-.19,.84-.03,1.33,.32,.13,.09,.25,.18,.24,.41-.01,.24-.15,.27-.29,.26-1.12-.09-2.06,.53-3,1.12-.14,.09-.3,.14-.46,.17-.15,.03-.33,.18-.44-.09-.1-.25,.04-.44,.17-.53,.76-.53,1.4-1.34,2.44-1.66Z"
          fill="#efce8e"
        />
        <path
          d="M52.52,66.79c-1.24-.39-2.49-1.16-3.81-.72-.61,.26-1.26,.53-1.91,.83-.52,.24-.63,.76-.41,1.51,.33,1.14,1.32,1.95,2.33,2.11,1.68,.26,2.65-1.5,4-2.18,.53-.26,.37-1.37-.2-1.55Zm-2.33,2.55c-.41,.15-.81,.4-1.46,.15,.53-.31,.9-.54,1.27-.74,.11-.06,.28-.12,.36,.08,.11,.26-.02,.45-.17,.51Z"
          fill="#ffcc71"
        />
        <path
          d="M48.72,69.49c.53-.31,.9-.54,1.27-.74,.11-.06,.28-.12,.36,.08,.11,.26-.02,.45-.17,.51-.41,.15-.81,.4-1.46,.15Z"
          fill="#ffd797"
        />
        <path
          d="M51.92,58.18c-.36-.29-.8-.41-1.12-.75-1.1-1.17-2.05-.74-2.93,.13-1.5,1.49-3.23,2.24-5.06,2.73-1.09,.29-2.1,.9-2.89,2-1.21,1.67-2.49,3.2-4.11,4.14-.28,.16-.6,.33-.56,.91h0c.19,.87,.67,.68,1.07,.65,.78-.06,1.46-.53,2.17-.88,1.65-.82,3.6-.48,5.09-1.92,0,0,.36-.42,.38-.58,.18-.52,.58-.49,.9-.46,1.2,.09,2.16-.56,3.03-1.51,1.08-1.18,2.23-2.18,3.64-2.57,.28-.08,.65-.48,.73-.86,.15-.48-.07-.8-.33-1.01Zm-9.12,3.67c-.17,.09-.34,.19-.53,.21-.71,.06-.93,1.01-1.46,1.4-.24,.17-.43,.46-.92,.49,.78-1.42,1.54-2.49,2.77-2.73,.12-.02,.28-.09,.35,.15,.08,.27-.06,.41-.21,.49Zm4.74-2.97c.4-.79,1.11-.95,1.68-1.4,.19-.15,.41,0,.57,.27-.84,.09-1.42,1-2.25,1.13"
          fill="#ffcc71"
        />
        <g>
          <polygon
            points="43.02 64.7 43.03 64.7 43.03 64.69 43.02 64.7"
            fill="#f2c86b"
          />
          <path
            d="M47.56,64.21c-.82-.17-1.61,.35-2.44-.11-.56-.31-1.1-.29-1.64-.11-.59,.25-.78,.71-.83,1.12-.33,1.71,.27,1.92,1.25,1.83,1.37-.12,2.59-.94,3.9-1.39,.25-.09,.43-.43,.39-.82-.06-.46-.36-.47-.63-.53Z"
            fill="#f2c86b"
          />
          <polygon
            points="43.48 64 43.48 64 43.49 63.99 43.48 64"
            fill="#f2c86b"
          />
        </g>
        <path
          d="M44.08,64.41c.13-.07,.31-.01,.5,.12,.05,.03,.09,.07,.09,.15,0,.09-.06,.1-.11,.1-.42-.03-.77,.2-1.12,.42-.05,.03-.11,.05-.17,.06-.05,.01-.12,.07-.16-.03-.04-.09,.02-.16,.06-.2,.28-.2,.52-.5,.91-.62Z"
          fill="#f7ce7c"
        />
      </g>
      <g>
        <path
          d="M69.64,69.39c-.44,.11-1.16,.48-1.37-.37-.24-.96,.57-.85,1-1.1,.59-.34,1.2-.52,1.86-.2,.17,.08,.4,.18,.41,.5,.01,.27-.19,.34-.32,.41-.51,.27-1.04,.49-1.58,.75Z"
          fill="#f2c86b"
        />
        <path
          d="M72.14,63.92c-.53-.33-1.09-.54-1.63-.83-.55-.29-1.05-.18-1.55,.1-.96,.54-1.8,1.37-2.83,1.76-.73,.27-1.11,1.11-1.03,2.27-.12,.62,.25,.82,.53,.98,.63,.36,1.32,.51,1.9,.14,1.47-.95,3.01-1.61,4.65-1.97,.46-.1,.93-.35,.96-1.19-.08-.8-.58-1.01-.99-1.26Zm-1.2,.47c-.01,.24-.15,.27-.29,.26-1.12-.09-2.06,.53-3,1.12-.14,.09-.3,.14-.46,.17-.15,.03-.33,.18-.44-.09-.1-.25,.04-.44,.17-.53,.76-.53,1.4-1.34,2.44-1.66,.34-.19,.84-.03,1.33,.32,.13,.09,.25,.18,.24,.41Z"
          fill="#e5c676"
        />
        <path
          d="M56.11,67.32c.78-1.42,1.54-2.49,2.77-2.73,.12-.02,.28-.09,.35,.15,.08,.27-.06,.41-.21,.49-.17,.09-.34,.19-.53,.21-.71,.06-.93,1.01-1.46,1.4-.24,.17-.43,.46-.92,.49Z"
          fill="#ffd797"
        />
        <path
          d="M66,61.12c-.84,.09-1.42,1-2.25,1.13,.4-.79,1.11-.95,1.68-1.4,.19-.15,.41,0,.57,.27Z"
          fill="#f7cf8f"
        />
        <path
          d="M58.52,71.49c-1.19,.34-2.26-.13-3.21,.4,.55-1.16,1.85-1.35,3.21-.4Z"
          fill="#efce8e"
        />
        <path
          d="M60.72,71.07c-.45-.06-.9-.28-1.36-.41-1.37-.39-2.79-.71-4.05-.02-1.08,.59-2,1.6-3.09,2.23-.77,.45-.95,1.6-.35,2.25,0,0,.51,.63,1.05,.81,1.06,.36,2.08,.54,3.12,.29,1.35-.33,2.59-.84,4.06-1.02,.72-.09,1.59-1.41,1.68-1.62,.6-1.5,.23-2.33-1.06-2.5Zm-8.58,3.16c.56-1.18,1.32-1.27,2.09-1.34-.66,.36-1.14,1.2-2.09,1.34Zm3.16-2.34c.55-1.16,1.85-1.35,3.21-.4-1.19,.34-2.26-.13-3.21,.4Z"
          fill="#e5c676"
        />
        <path
          d="M54.23,72.89c-.66,.36-1.14,1.2-2.1,1.34,.56-1.18,1.32-1.27,2.1-1.34Z"
          fill="#efce8e"
        />
        <path
          d="M69.37,63.65c.34-.19,.84-.03,1.33,.32,.13,.09,.25,.18,.24,.41-.01,.24-.15,.27-.29,.26-1.12-.09-2.06,.53-3,1.12-.14,.09-.3,.14-.46,.17-.15,.03-.33,.18-.44-.09-.1-.25,.04-.44,.17-.53,.76-.53,1.4-1.34,2.44-1.66Z"
          fill="#efce8e"
        />
        <path
          d="M68.74,70.16c-1.24-.39-2.49-1.16-3.81-.72-.61,.26-1.26,.53-1.91,.83-.52,.24-.63,.76-.41,1.51,.33,1.14,1.32,1.95,2.33,2.11,1.68,.26,2.65-1.5,4-2.18,.53-.26,.37-1.37-.2-1.55Zm-2.33,2.55c-.41,.15-.81,.4-1.46,.15,.53-.31,.9-.54,1.27-.74,.11-.06,.28-.12,.36,.08,.11,.26-.02,.45-.17,.51Z"
          fill="#ffcc71"
        />
        <path
          d="M64.94,72.86c.53-.31,.9-.54,1.27-.74,.11-.06,.28-.12,.36,.08,.11,.26-.02,.45-.17,.51-.41,.15-.81,.4-1.46,.15Z"
          fill="#ffd797"
        />
        <path
          d="M68.14,61.55c-.36-.29-.8-.41-1.12-.75-1.1-1.17-2.05-.74-2.93,.13-1.5,1.49-3.23,2.24-5.06,2.73-1.09,.29-2.1,.9-2.89,2-1.21,1.67-2.49,3.2-4.11,4.14-.28,.16-.6,.33-.56,.91h0c.19,.87,.67,.68,1.07,.65,.78-.06,1.46-.53,2.17-.88,1.65-.82,3.6-.48,5.09-1.92,0,0,.36-.42,.38-.58,.18-.52,.58-.49,.9-.46,1.2,.09,2.16-.56,3.03-1.51,1.08-1.18,2.23-2.18,3.64-2.57,.28-.08,.65-.48,.73-.86,.15-.48-.07-.8-.33-1.01Zm-9.12,3.67c-.17,.09-.34,.19-.53,.21-.71,.06-.93,1.01-1.46,1.4-.24,.17-.43,.46-.92,.49,.78-1.42,1.54-2.49,2.77-2.73,.12-.02,.28-.09,.35,.15,.08,.27-.06,.41-.21,.49Zm4.74-2.97c.4-.79,1.11-.95,1.68-1.4,.19-.15,.41,0,.57,.27-.84,.09-1.42,1-2.25,1.13"
          fill="#ffcc71"
        />
        <g>
          <polygon
            points="59.24 68.07 59.25 68.07 59.25 68.06 59.24 68.07"
            fill="#f2c86b"
          />
          <path
            d="M63.78,67.58c-.82-.17-1.61,.35-2.44-.11-.56-.31-1.1-.29-1.64-.11-.59,.25-.78,.71-.83,1.12-.33,1.71,.27,1.92,1.25,1.83,1.37-.12,2.59-.94,3.9-1.39,.25-.09,.43-.43,.39-.82-.06-.46-.36-.47-.63-.53Z"
            fill="#f2c86b"
          />
          <polygon
            points="59.7 67.37 59.71 67.37 59.71 67.36 59.7 67.37"
            fill="#f2c86b"
          />
        </g>
        <path
          d="M60.3,67.79c.13-.07,.31-.01,.5,.12,.05,.03,.09,.07,.09,.15,0,.09-.06,.1-.11,.1-.42-.03-.77,.2-1.12,.42-.05,.03-.11,.05-.17,.06-.05,.01-.12,.07-.16-.03-.04-.09,.02-.16,.06-.2,.28-.2,.52-.5,.91-.62Z"
          fill="#f7ce7c"
        />
      </g>
    </g>
  </SvgIcon>
);
export default PoopDiaperFull;
