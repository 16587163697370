import React from 'react';

const SharingIcon = ({ width, height }) => {
    const viewBoxValues = '0 0 52 52';

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox={viewBoxValues}
        >
            <defs>
                <filter
                    id="Квадрат_бял_със_сянка"
                    x="0"
                    y="0"
                    width="51.833"
                    height="51.833"
                    filterUnits="userSpaceOnUse"
                >
                    <feOffset dy="1" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="1" result="blur" />
                    <feFlood floodOpacity="0.161" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g
                id="Икона_-_Споделяте"
                data-name="Икона - Споделяте"
                transform="translate(-43.877 -336.775)"
            >
                <g
                    transform="matrix(1, 0, 0, 1, 43.88, 336.78)"
                    filter="url(#Квадрат_бял_със_сянка)"
                >
                    <g
                        id="Квадрат_бял_със_сянка-2"
                        data-name="Квадрат бял със сянка"
                        transform="translate(3 2)"
                        fill="#fff"
                        stroke="#cacaca"
                        strokeWidth="1"
                    >
                        <rect
                            width="45.833"
                            height="45.833"
                            rx="6"
                            stroke="none"
                        />
                        <rect
                            x="0.5"
                            y="0.5"
                            width="44.833"
                            height="44.833"
                            rx="5.5"
                            fill="none"
                        />
                    </g>
                </g>
                <path
                    id="Икона_-_Линк"
                    data-name="Икона - Линк"
                    d="M19.874,17.431a2.985,2.985,0,0,0-2.009.789l-7.308-4.254a3.355,3.355,0,0,0,.092-.717,3.355,3.355,0,0,0-.092-.717l7.226-4.213A3.068,3.068,0,1,0,16.8,6.075a3.355,3.355,0,0,0,.092.717L9.666,11a3.075,3.075,0,1,0,0,4.489l7.3,4.264a2.892,2.892,0,0,0-.082.666,2.993,2.993,0,1,0,2.993-2.993Z"
                    transform="translate(55.955 348.083)"
                    fill="#87ba3b"
                />
            </g>
        </svg>
    );
};

export default SharingIcon;
