import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { loadGoalsFromLocalStorage } from '../../actions';
import { FORMS } from '../../utils/constants';
import { Fields } from '../common/Fields';

class TagFilter extends React.Component {
    state = { isDropdownOpen: false };

    onDropdownPress = () => {
        this.setState({ isDropdownOpen: !this.state.isDropdownOpen });
    };

    render = () => {
        return (
            <Fragment>
                {Fields.commonFields.tag({
                    onPress: this.onDropdownPress,
                    isOpen: this.state.isDropdownOpen,
                    onTagSelectedCallback: this.props.onTagSelectedCallback,
                })}
            </Fragment>
        );
    };
}

const mapStateToProps = (state, props) => {
    return {
        selectedTag: state.selectedTag,
        user: state.authentication.userData,
        goals: state.goals,
        initialValues: { tag: props.initialValues },
    };
};

const tagForm = reduxForm({
    form: FORMS.TAG_FORM,
    destroyOnUnmount: false,
})(TagFilter);

export default connect(mapStateToProps, { loadGoalsFromLocalStorage })(tagForm);
