import React from 'react';
import { reduxForm } from 'redux-form';
import { GOOGLE_CAPTCHA_SITE_KEY, FORMS } from '../../utils/constants';
import { formUtils } from '../../utils/formUtils';
import { connect } from 'react-redux';
import { Fields } from '../common/Fields';
import * as i18n from 'i18next';
import TermsAndConditions from '../common/svgComponents/TermsAndConditions';
import ReCAPTCHA from 'react-google-recaptcha';
import Dots from '../common/Dots';
import { Button, Col } from 'reactstrap';

class TermsAndConditionsScreen extends React.Component {
    render() {
        const {
            handleSubmit,
            pristine,
            submitting,
            currentPage,
            captchaValue,
        } = this.props;

        return (
            <form
                onSubmit={handleSubmit}
                className="flexContainerColumn flexContainerCentered fixed-padding-register"
            >
                <div className="flexContainerCentered">
                    <p className="header mainGreenColor uppercase register-section-title noMargin">
                        {i18n.t('registerScreen:headerText.conditions')}
                    </p>
                </div>
                <div
                    xs={10}
                    sm={10}
                    md={10}
                    lg={8}
                    className="flexContainerCentered"
                >
                    <TermsAndConditions width={166} height={168} />
                </div>
                <div>
                    {Fields.commonFields.areTermsAccepted()}
                    {Fields.commonFields.areGDPRAccepted()}
                    {Fields.commonFields.isSubscriptionActive()}
                    <br />
                    <ReCAPTCHA
                        sitekey={GOOGLE_CAPTCHA_SITE_KEY}
                        onChange={(e) => captchaValue(e)}
                        hl="bg"
                    />
                    <br />
                </div>
                <Col
                    xs={10}
                    sm={10}
                    md={10}
                    lg={8}
                    className="flexContainerColumn centred"
                    style={{ margin: '24px 0px 12px 0px' }}
                >
                    <Button
                        className="greenBtn header next uppercase"
                        type="submit"
                        disabled={pristine || submitting}
                    >
                        {i18n.t('buttons:registerUser')}
                    </Button>
                </Col>
                <Dots page={currentPage} />
            </form>
        );
    }
}

const registerForm = reduxForm({
    form: FORMS.REGISTER_FORM,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate: formUtils.validateSignUp,
})(TermsAndConditionsScreen);

const mapStateToProps = (state) => {
    return {
        modal: state.modal,
    };
};

export default connect(mapStateToProps, {})(registerForm);
