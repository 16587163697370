import type { SVGProps } from 'react';
export const SleepTrackerButtonStopNightIcon = (
  props: SVGProps<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 150.2 150.2"
    {...props}
  >
    <path
      fill="#F5FFE6"
      d="M13.8 8h124c3.3 0 6 2.7 6 6v122.6c0 3.3-2.7 6-6 6h-124c-3.3 0-6-2.7-6-6V14c0-3.3 2.6-6 6-6z"
    />
    <path
      fill="#FFF"
      d="M30.8 19h90c6.1 0 11 4.9 11 11v90c0 6.1-4.9 11-11 11h-90c-6.1 0-11-4.9-11-11V30c0-6.1 4.9-11 11-11z"
    />
    <path
      fill="#87BA3B"
      d="M120.8 131h-90c-6.1 0-11-4.9-11-11V30c0-6.1 4.9-11 11-11h90c6.1 0 11 4.9 11 11v90c0 6.1-5 11-11 11zm-90-110c-5 0-9 4-9 9v90c0 5 4 9 9 9h90c5 0 9-4 9-9V30c0-5-4-9-9-9h-90z"
    />
    <path
      fill="#87BA3B"
      d="M84 76.9H67.5c-2.6 0-4.8-2.1-4.8-4.8V55.7c0-2.6 2.1-4.8 4.8-4.8H84c2.6 0 4.8 2.1 4.8 4.8v16.5c0 2.6-2.1 4.7-4.8 4.7z"
    />
    <g fill="#87BA3B">
      <path d="M49.7 106.7c-.3 0-.6-.1-.8-.3s-.3-.5-.3-.8V99c0-.4.1-.6.3-.8s.5-.3.8-.3.6.1.8.3.3.5.3.8v2.3h3.7V99c0-.4.1-.6.3-.8.2-.2.4-.3.8-.3s.6.1.8.3.3.5.3.8v6.5c0 .4-.1.6-.3.8s-.5.3-.8.3-.6-.1-.8-.3c-.2-.2-.3-.5-.3-.8V103h-3.7v2.5c0 .4-.1.6-.3.8-.2.3-.5.4-.8.4zM63.2 106.7c-.9 0-1.6-.2-2.3-.5s-1.1-.9-1.5-1.5c-.4-.7-.5-1.4-.5-2.3 0-.7.1-1.3.3-1.8.2-.5.5-1 .9-1.4.4-.4.8-.7 1.4-.9.5-.2 1.1-.3 1.8-.3.9 0 1.6.2 2.3.5s1.1.9 1.5 1.5c.4.7.5 1.4.5 2.3 0 .7-.1 1.3-.3 1.8-.2.5-.5 1-.9 1.4-.4.4-.8.7-1.4.9-.5.2-1.1.3-1.8.3zm0-1.6c.4 0 .8-.1 1.1-.3.3-.2.6-.5.7-.9.2-.4.3-.9.3-1.5 0-.9-.2-1.6-.6-2.1-.4-.5-.9-.7-1.5-.7-.4 0-.8.1-1.1.3-.3.2-.6.5-.7.9-.2.4-.3.9-.3 1.5 0 .9.2 1.6.6 2.1.4.4.9.7 1.5.7zM82.2 108.9c-.3 0-.5-.1-.7-.2-.2-.2-.2-.4-.2-.7v-1.4H71c-.3 0-.6-.1-.8-.3s-.3-.4-.3-.8V99c0-.4.1-.6.3-.8.2-.2.4-.3.7-.3.3 0 .6.1.8.3.2.2.3.5.3.8v5.8h2.9V99c0-.4.1-.6.3-.8s.4-.3.7-.3c.3 0 .6.1.8.3.2.2.3.5.3.8v5.8h2.9V99c0-.4.1-.6.3-.8.2-.2.4-.3.7-.3.3 0 .6.1.8.3.2.2.3.5.3.8v6.8l-1-1h1.4c.3 0 .6.1.7.2.2.2.3.4.3.7v2.1c-.3.8-.6 1.1-1.2 1.1zM89.2 106.7c-1 0-1.8-.2-2.5-.5s-1.2-.9-1.6-1.5c-.4-.7-.6-1.4-.6-2.3 0-.9.2-1.6.5-2.3.4-.7.9-1.2 1.5-1.5.6-.4 1.4-.6 2.2-.6.6 0 1.1.1 1.6.3.5.2.9.5 1.2.8.3.4.6.8.8 1.3.2.5.3 1.1.3 1.8 0 .2-.1.4-.2.5-.1.1-.3.2-.6.2h-5.5v-1.2h4.8l-.3.3c0-.5-.1-1-.2-1.3-.2-.4-.4-.6-.7-.8-.3-.2-.7-.3-1.1-.3-.5 0-.9.1-1.2.3-.3.2-.6.5-.8.9s-.3.9-.3 1.5v.1c0 .9.2 1.6.7 2.1s1.1.7 2 .7c.3 0 .6 0 1-.1s.7-.2 1.1-.4c.2-.1.5-.2.6-.2.2 0 .3.1.4.2.1.1.2.3.2.4 0 .2 0 .3-.1.5s-.2.3-.4.4c-.4.3-.9.4-1.4.6-.4 0-.9.1-1.4.1zM96.1 106.7c-.3 0-.6-.1-.8-.3s-.3-.5-.3-.8V99c0-.4.1-.6.3-.8s.5-.3.8-.3c.3 0 .6.1.8.3s.3.5.3.8v2.3h3.7V99c0-.4.1-.6.3-.8s.4-.3.8-.3c.3 0 .6.1.8.3s.3.5.3.8v6.5c0 .4-.1.6-.3.8s-.5.3-.8.3c-.3 0-.6-.1-.8-.3s-.3-.5-.3-.8V103h-3.7v2.5c0 .4-.1.6-.3.8s-.5.4-.8.4z" />
    </g>
  </svg>
);
