import i18n from 'i18next';

export const formatDuration = (duration) => {
  if (!duration) return '';
  if (duration.hours() === 0) {
    if (duration.minutes() === 0) {
      return i18n.t('trackingHistory:seconds', { sec: duration.seconds() });
    }
    if (duration.seconds() === 0) {
      return i18n.t('trackingHistory:minutes', { min: duration.minutes() });
    }
    return i18n.t('trackingHistory:duration', {
      min: i18n.t('trackingHistory:minutes', { min: duration.minutes() }),
      sec: i18n.t('trackingHistory:seconds', { sec: duration.seconds() }),
    });
  }
  if (duration.minutes() === 0) {
    return i18n.t('trackingHistory:hours', { hours: duration.hours() });
  }
  return i18n.t('trackingHistory:duration-short', {
    min: i18n.t('trackingHistory:minutes', { min: duration.minutes() }),
    hours: i18n.t('trackingHistory:hours', { hours: duration.hours() }),
  });
};
