import React from 'react';

const DiaryIcon = ({ width, height }) => {
    const viewBoxValues = '0 0 52 52';

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox={viewBoxValues}
        >
            <defs>
                <filter
                    id="Квадрат_бял_със_сянка"
                    x="0"
                    y="0"
                    width="51.833"
                    height="51.833"
                    filterUnits="userSpaceOnUse"
                >
                    <feOffset dy="1" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="1" result="blur" />
                    <feFlood floodOpacity="0.161" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g
                id="Икона_-_Водите_дневник"
                data-name="Икона - Водите дневник"
                transform="translate(-43.424 -274.775)"
            >
                <g
                    transform="matrix(1, 0, 0, 1, 43.42, 274.78)"
                    filter="url(#Квадрат_бял_със_сянка)"
                >
                    <g
                        id="Квадрат_бял_със_сянка-2"
                        data-name="Квадрат бял със сянка"
                        transform="translate(3 2)"
                        fill="#fff"
                        stroke="#cacaca"
                        strokeWidth="1"
                    >
                        <rect
                            width="45.833"
                            height="45.833"
                            rx="6"
                            stroke="none"
                        />
                        <rect
                            x="0.5"
                            y="0.5"
                            width="44.833"
                            height="44.833"
                            rx="5.5"
                            fill="none"
                        />
                    </g>
                </g>
                <path
                    id="Икона_-_Дневник"
                    data-name="Икона - Дневник"
                    d="M15.1,17.645H4.1a1,1,0,0,1-1-1V15.187h13v1.459A1,1,0,0,1,15.1,17.645Zm1-3.689H3.1V11.5h13v2.459Zm0-3.689H3.1V7.807H1.323a1,1,0,0,1-.572-1.82L9.025.212a1,1,0,0,1,1.145,0l8.276,5.774a1,1,0,0,1-.572,1.82H16.1v2.46Z"
                    transform="translate(60.029 290.853)"
                    fill="#87ba3b"
                />
            </g>
        </svg>
    );
};

export default DiaryIcon;
