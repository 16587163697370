import React from 'react';

const ReminderIcon = ({ width, height }) => {
    const viewBoxValues = '0 0 52 52';

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox={viewBoxValues}
        >
            <defs>
                <filter
                    id="Квадрат_бял_със_сянка"
                    x="0"
                    y="0"
                    width="51.833"
                    height="51.833"
                    filterUnits="userSpaceOnUse"
                >
                    <feOffset dy="1" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="1" result="blur" />
                    <feFlood floodOpacity="0.161" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g
                id="Икона_-_Подсещаме_ви"
                data-name="Икона - Подсещаме ви"
                transform="translate(-43.424 -213.338)"
            >
                <g
                    transform="matrix(1, 0, 0, 1, 43.42, 213.34)"
                    filter="url(#Квадрат_бял_със_сянка)"
                >
                    <g
                        id="Квадрат_бял_със_сянка-2"
                        data-name="Квадрат бял със сянка"
                        transform="translate(3 2)"
                        fill="#fff"
                        stroke="#cacaca"
                        strokeWidth="1"
                    >
                        <rect
                            width="45.833"
                            height="45.833"
                            rx="6"
                            stroke="none"
                        />
                        <rect
                            x="0.5"
                            y="0.5"
                            width="44.833"
                            height="44.833"
                            rx="5.5"
                            fill="none"
                        />
                    </g>
                </g>
                <g
                    id="Икона_-_Внимание"
                    data-name="Икона - Внимание"
                    transform="translate(59.315 227.942)"
                >
                    <ellipse
                        id="Ellipse_313"
                        data-name="Ellipse 313"
                        cx="10.312"
                        cy="10.312"
                        rx="10.312"
                        ry="10.312"
                        transform="translate(0 0)"
                        fill="#ffdd02"
                    />
                    <g
                        id="Group_588"
                        data-name="Group 588"
                        transform="translate(8.88 4.297)"
                    >
                        <ellipse
                            id="Ellipse_308"
                            data-name="Ellipse 308"
                            cx="1.432"
                            cy="1.432"
                            rx="1.432"
                            ry="1.432"
                            transform="translate(0 9.167)"
                            fill="#fff"
                        />
                        <rect
                            id="Rectangle_1208"
                            data-name="Rectangle 1208"
                            width="2.865"
                            height="6.875"
                            rx="1.432"
                            transform="translate(0 0)"
                            fill="#fff"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default ReminderIcon;
