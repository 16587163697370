import React, { SVGProps } from 'react';

const RightPauseButton = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 150.24 150.24"
      {...props}
    >
      <title>Button_pause</title>
      <rect
        id="Rectangle_1887-2"
        data-name="Rectangle 1887-2"
        x="7.76"
        y="8"
        width="136.04"
        height="134.57"
        rx="6"
        fill="#f5ffe6"
      />
      <g id="Rectangle_1888" data-name="Rectangle 1888">
        <rect x="19.76" y="19" width="112" height="112" rx="11" fill="#fff" />
        <path
          d="M120.76,131h-90a11,11,0,0,1-11-11V30a11,11,0,0,1,11-11h90a11,11,0,0,1,11,11v90A11,11,0,0,1,120.76,131Zm-90-110a9,9,0,0,0-9,9v90a9,9,0,0,0,9,9h90a9,9,0,0,0,9-9V30a9,9,0,0,0-9-9Z"
          fill="#87ba3b"
        />
      </g>
      <g id="Group_1906" data-name="Group 1906">
        <path
          id="Rectangle_1891"
          data-name="Rectangle 1891"
          d="M68.76,51.28h0a4,4,0,0,1,4,4v18a4,4,0,0,1-4,4h0a4,4,0,0,1-4-4v-18A4,4,0,0,1,68.76,51.28Z"
          fill="#87ba3b"
        />
        <path
          id="Rectangle_1892"
          data-name="Rectangle 1892"
          d="M83.76,51.28h0a4,4,0,0,1,4,4v18a4,4,0,0,1-4,4h0a4,4,0,0,1-4-4v-18A4,4,0,0,1,83.76,51.28Z"
          fill="#87ba3b"
        />
      </g>
      <g id="лява" style={{ isolation: 'isolate' }}>
        <g style={{ isolation: 'isolate' }}>
          <path
            d="M53.62,108.63c-.61,0-.91-.32-.91-.95V105.6a.86.86,0,0,1,1-1,5.59,5.59,0,0,0,.68-1.79,12.33,12.33,0,0,0,.21-2.5V99.16A1.05,1.05,0,0,1,55.77,98h4.09a1.05,1.05,0,0,1,1.19,1.19v5.46h.38a.87.87,0,0,1,1,1v2.08c0,.63-.3.95-.9.95s-.9-.32-.9-.95v-1.4h-6.1v1.4C54.51,108.31,54.21,108.63,53.62,108.63Zm2-4h3.48v-5H56.39v1.23a10.09,10.09,0,0,1-.21,2.07A6.3,6.3,0,0,1,55.58,104.62Z"
            fill="#87ba3b"
          />
          <path
            d="M70.45,106.42a.88.88,0,0,1-1-1v-2h-.89a1.45,1.45,0,0,0-.87.27,2.12,2.12,0,0,0-.62.68l-.84,1.46a1,1,0,0,1-.68.57,1.16,1.16,0,0,1-.78-.12,1.08,1.08,0,0,1-.49-.6.92.92,0,0,1,.14-.83L65,104a2.62,2.62,0,0,1,.5-.57,2.39,2.39,0,0,1,.64-.39A2.27,2.27,0,0,1,64,100.55a2.36,2.36,0,0,1,.82-1.89,3.62,3.62,0,0,1,2.4-.69h3.16a.94.94,0,0,1,1.07,1v6.4a.92.92,0,0,1-1,1Zm-2.8-4.4h1.82V99.36H67.65a1.87,1.87,0,0,0-1.24.35,1.22,1.22,0,0,0-.41,1,1.26,1.26,0,0,0,.41,1A1.82,1.82,0,0,0,67.65,102Z"
            fill="#87ba3b"
          />
          <path
            d="M78.05,106.45a4.74,4.74,0,0,1-2.28-.53,3.76,3.76,0,0,1-1.49-1.52,4.87,4.87,0,0,1-.53-2.32,4.68,4.68,0,0,1,.53-2.28,3.6,3.6,0,0,1,1.49-1.48,4.74,4.74,0,0,1,2.28-.52,5.49,5.49,0,0,1,1.22.15,4.57,4.57,0,0,1,1.23.51.79.79,0,0,1,.4.53.91.91,0,0,1-.08.59.84.84,0,0,1-.43.4.69.69,0,0,1-.64-.09,3.1,3.1,0,0,0-1.51-.44,2.14,2.14,0,0,0-1.71.69,2.89,2.89,0,0,0-.6,2,3,3,0,0,0,.6,2,2.1,2.1,0,0,0,1.71.72,3.17,3.17,0,0,0,1.51-.42.68.68,0,0,1,.63-.09.76.76,0,0,1,.39.4,1,1,0,0,1,0,.6.79.79,0,0,1-.39.52,4.27,4.27,0,0,1-1.19.49A5.1,5.1,0,0,1,78.05,106.45Z"
            fill="#87ba3b"
          />
          <path
            d="M83.87,106.42a1,1,0,0,1-1.07-1.09V98.91a1.06,1.06,0,1,1,2.12,0v2.27h3.67V98.91a1.07,1.07,0,1,1,2.13,0v6.42a1.07,1.07,0,1,1-2.13,0v-2.48H84.92v2.48C84.92,106.06,84.57,106.42,83.87,106.42Z"
            fill="#87ba3b"
          />
          <path
            d="M96.23,106.45a3.5,3.5,0,0,1-1.61-.35,2.77,2.77,0,0,1-1.11-.94,2.4,2.4,0,0,1-.4-1.34,2.12,2.12,0,0,1,.48-1.46,2.89,2.89,0,0,1,1.55-.77,13.63,13.63,0,0,1,2.87-.24h.6V101a1.69,1.69,0,0,0-.38-1.23A1.74,1.74,0,0,0,97,99.4a5.14,5.14,0,0,0-1.06.12,5.82,5.82,0,0,0-1.2.4.76.76,0,0,1-.68,0,.73.73,0,0,1-.36-.46.94.94,0,0,1,0-.64.87.87,0,0,1,.52-.47A7.56,7.56,0,0,1,97,97.8a3.78,3.78,0,0,1,2.7.84,3.45,3.45,0,0,1,.89,2.61v4.08c0,.73-.33,1.09-1,1.09s-1-.36-1-1.09V105a2.28,2.28,0,0,1-.92,1.09A2.7,2.7,0,0,1,96.23,106.45Zm.44-1.46a1.82,1.82,0,0,0,1.38-.58,2,2,0,0,0,.56-1.46v-.37H98a6.94,6.94,0,0,0-2.22.24.91.91,0,0,0-.62.9,1.17,1.17,0,0,0,.39.91A1.54,1.54,0,0,0,96.67,105Z"
            fill="#87ba3b"
          />
        </g>
      </g>
    </svg>
  );
};

export default RightPauseButton;
