import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useRouteMatch } from 'react-router';
import { getAllTrackers } from '../actions/trackerActions';
import { getAllTrackingRecords } from '../actions/trackingRecordsActions';
import { RELATIVE_PAGE_PATHS } from '../utils/constants';
import TrackScreen from './TrackScreen';
import ScreenContainer from './common/Container';
import TrackerList from './tracker/TrackerList';
import { BreastFeedingTracker } from './tracker/breast-feeding/BreastFeedingTracker';
import { SleepTracker } from './tracker/sleep/SleepTracker';

const TrackingScreen = () => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllTrackers());
    dispatch(getAllTrackingRecords());
  }, [dispatch]);

  return (
    <ScreenContainer>
      <Switch>
        <Route exact path={path}>
          <TrackerList />
        </Route>
        <Route exact path={`${RELATIVE_PAGE_PATHS.SLEEP}`}>
          <SleepTracker />
        </Route>
        <Route exact path={`${RELATIVE_PAGE_PATHS.SLEEP}/:recordId`}>
          <SleepTracker />
        </Route>
        <Route exact path={RELATIVE_PAGE_PATHS.BREAST_FEEDING}>
          <BreastFeedingTracker />
        </Route>
        <Route exact path={`${RELATIVE_PAGE_PATHS.BREAST_FEEDING}/:recordId`}>
          <BreastFeedingTracker />
        </Route>
        <Route exact path={`${path}/:typeId`}>
          <TrackScreen />
        </Route>
        <Route exact path={`${path}/:typeId/:recordId`}>
          <TrackScreen />
        </Route>
      </Switch>
    </ScreenContainer>
  );
};

export default TrackingScreen;
