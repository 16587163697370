import { SVGProps } from 'react';

const LeftPlayButtonDisabled = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 150.24 150.24"
      {...props}
    >
      <title>Button_pause</title>
      <g id="Rectangle_1888" data-name="Rectangle 1888">
        <rect x="19.24" y="19" width="112" height="112" rx="11" fill="#fff" />
        <path
          d="M120.24,131h-90a11,11,0,0,1-11-11V30a11,11,0,0,1,11-11h90a11,11,0,0,1,11,11v90A11,11,0,0,1,120.24,131Zm-90-110a9,9,0,0,0-9,9v90a9,9,0,0,0,9,9h90a9,9,0,0,0,9-9V30a9,9,0,0,0-9-9Z"
          fill={'#cacaca'}
        />
      </g>
      <g id="лява" style={{ isolation: 'isolate' }}>
        <g style={{ isolation: 'isolate' }}>
          <path
            d="M64.11,106.42a1,1,0,0,1-1.06-1.09V99.64H60.44v1a9.69,9.69,0,0,1-.59,3.73,3.5,3.5,0,0,1-1.91,1.94,1,1,0,0,1-.8,0,.89.89,0,0,1-.5-.45.91.91,0,0,1-.08-.67,1,1,0,0,1,.51-.59,2.41,2.41,0,0,0,1.13-1.46,9.68,9.68,0,0,0,.28-2.62V99.16A1.05,1.05,0,0,1,59.67,98H64a1.05,1.05,0,0,1,1.19,1.19v6.17A1,1,0,0,1,64.11,106.42Z"
            fill={'#cacaca'}
          />
          <path
            d="M74.07,106.42a.88.88,0,0,1-1-1v-2H72.2a1.5,1.5,0,0,0-.88.27,2.24,2.24,0,0,0-.62.68l-.83,1.46a1,1,0,0,1-.69.57,1.13,1.13,0,0,1-.77-.12,1.08,1.08,0,0,1-.49-.6.91.91,0,0,1,.13-.83l.53-.89a2.57,2.57,0,0,1,.49-.57,2.29,2.29,0,0,1,.65-.39,2.26,2.26,0,0,1-2.11-2.44,2.35,2.35,0,0,1,.81-1.89,3.65,3.65,0,0,1,2.4-.69H74a.93.93,0,0,1,1.07,1v6.4a.92.92,0,0,1-1,1ZM71.26,102h1.82V99.36H71.26a1.91,1.91,0,0,0-1.24.35,1.22,1.22,0,0,0-.4,1,1.25,1.25,0,0,0,.4,1A1.85,1.85,0,0,0,71.26,102Z"
            fill={'#cacaca'}
          />
          <path
            d="M78.86,106.28a.93.93,0,0,1-1-1V99a.93.93,0,0,1,1-1H82.3a3.73,3.73,0,0,1,2.32.57,2,2,0,0,1,.7,1.61,1.87,1.87,0,0,1-.35,1.12,2,2,0,0,1-1,.69,2.1,2.1,0,0,1,1.22.72,2,2,0,0,1,.41,1.27,2,2,0,0,1-.81,1.7,3.84,3.84,0,0,1-2.37.63Zm.92-4.89h2.31a1.52,1.52,0,0,0,1-.28,1,1,0,0,0,.33-.79,1,1,0,0,0-.33-.78,1.59,1.59,0,0,0-1-.26H79.78Zm0,3.58h2.47c1,0,1.42-.38,1.42-1.14s-.47-1.13-1.42-1.13H79.78Z"
            fill={'#cacaca'}
          />
          <path
            d="M90.56,106.45A3.5,3.5,0,0,1,89,106.1a2.68,2.68,0,0,1-1.1-.94,2.32,2.32,0,0,1-.4-1.34,2.17,2.17,0,0,1,.47-1.46,2.93,2.93,0,0,1,1.55-.77,13.63,13.63,0,0,1,2.87-.24h.6V101a1.37,1.37,0,0,0-1.63-1.6,5.23,5.23,0,0,0-1.07.12,5.75,5.75,0,0,0-1.19.4.76.76,0,0,1-.68,0,.71.71,0,0,1-.37-.46,1,1,0,0,1,0-.64.87.87,0,0,1,.52-.47,7.38,7.38,0,0,1,1.51-.46,7.23,7.23,0,0,1,1.31-.14,3.81,3.81,0,0,1,2.71.84,3.45,3.45,0,0,1,.88,2.61v4.08c0,.73-.33,1.09-1,1.09s-1-.36-1-1.09V105a2.28,2.28,0,0,1-.92,1.09A2.68,2.68,0,0,1,90.56,106.45ZM91,105a1.83,1.83,0,0,0,1.39-.58,2,2,0,0,0,.55-1.46v-.37h-.58a7,7,0,0,0-2.22.24.91.91,0,0,0-.62.9,1.17,1.17,0,0,0,.39.91A1.56,1.56,0,0,0,91,105Z"
            fill={'#cacaca'}
          />
        </g>
      </g>
      <path
        id="Polygon_9"
        data-name="Polygon 9"
        d="M87.65,60.37a4,4,0,0,1,0,6.83L72.32,76.56a4,4,0,0,1-6.08-3.41V54.41A4,4,0,0,1,72.32,51Z"
        fill={'#cacaca'}
      />
    </svg>
  );
};

export default LeftPlayButtonDisabled;
