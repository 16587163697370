import * as i18n from 'i18next';
import { reset } from 'redux-form';
import appUtils from '../../utils/appUtils';
import { FORMS, RELATIVE_PAGE_PATHS } from '../../utils/constants';
import navigationUtils from '../../utils/navigationUtils';
import { getAxiosWithoutToken } from '../../utils/webApi';
import {
    REGISTER_PROFILE, SHOW_SUCCESS_MODAL
} from '../actionTypes';
import { setUserDataInLocalStorage } from '../authenticationActions';
import { startLoader, stopLoader } from '../commonActions';

export const registerUser = (data) => async (dispatch) => {
    let formData = {
        email: data.email,
        password: data.password,
        motherOrFatherName: data.motherOrFatherName,
        town: data.town,
        childName: data.childName,
        isChildGirl: data.isChildGirl,
        childDateOfBirth: appUtils.convertDateToBackendFormat(
            data.childDateOfBirth
        ),
        isChildBorn4WeeksBeforeDueDate: data.isChildBorn4WeeksBeforeDueDate,
        signUpForEmailNewsletter: data.signUpForEmailNewsletter,
    };

    dispatch(startLoader());

    const response = await getAxiosWithoutToken().post(
        '/user/sign-up',
        formData
    );

    dispatch(stopLoader());

    const returnedData = response.data;
    if (returnedData.success) {
        setUserDataInLocalStorage(returnedData.data);
        const successfulRegistration = i18n.t(
            'registerScreen:childBirthScreen.successfulRegistration'
        );

        dispatch({ type: SHOW_SUCCESS_MODAL, payload: successfulRegistration });
        dispatch({ type: REGISTER_PROFILE, payload: returnedData.data });
        dispatch(reset(FORMS.REGISTER_FORM));

        navigationUtils.navigate(RELATIVE_PAGE_PATHS.LOGIN);
    }
};
