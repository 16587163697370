import React from 'react';
import BackButtonSVG from './svgComponents/BackButton';

class MenuItem extends React.Component {
    render() {
        return (
            <div className="button" onClick={this.props.onClick}>
                <div className="content">
                    <span>{this.props.title}</span>
                    <div className="svg">
                        <BackButtonSVG
                            width={28}
                            height={28}
                            color={'#87ba3b'}
                            svgClicked={this.props.previousPage}
                        />
                    </div>
                </div>
                <hr />
            </div>
        );
    }
}

export default MenuItem;
