import { clearReducers } from './commonActions';
import navigationUtils from '../utils/navigationUtils';
import { clearLocalStorageData } from './authenticationActions';
import { RELATIVE_PAGE_PATHS } from '../utils/constants';

export const navigateToHomeScreen = () => {
    return (dispatch) => {
        clearLocalStorageData();
        dispatch(clearReducers());
        navigationUtils.navigate(RELATIVE_PAGE_PATHS.HOME);
    };
};

export const navigateToHomePage = () => {
    return (dispatch) => {
        clearLocalStorageData();
        dispatch(clearReducers());
        navigationUtils.navigate(RELATIVE_PAGE_PATHS.LOGIN);
    };
};

export const navigateToLoginPage = () => {
    return (dispatch) => {
        clearLocalStorageData();
        dispatch(clearReducers());
        navigationUtils.navigate(RELATIVE_PAGE_PATHS.LOGIN);
    };
};
