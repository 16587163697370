import { FC } from 'react';
import RightPauseButton from '../../common/svgComponents/RightPauseButton';
import RightPlayButton from '../../common/svgComponents/RightPlayButton';
import RightPlayButtonDisabled from '../../common/svgComponents/RightPlayButtonDisabled';
import { StartStopButton } from '../shared/StartStopButton';

interface BreastFeedingRightButtonProps {
  disabled?: boolean;
  onClick?: () => void;
  isActive?: boolean;
}
export const BreastFeedingRightButton: FC<BreastFeedingRightButtonProps> = (
  props
) => {
  return (
    <StartStopButton
      startButton={RightPlayButton}
      stopButton={RightPauseButton}
      disabledButton={RightPlayButtonDisabled}
      color={'#87ba3b'}
      {...props}
    />
  );
};
