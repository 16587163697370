import { useLocalStorage } from 'react-use';
import { TIMER_STATE, TimerStateType } from '../models/timerStatuses';

interface TimerState {
  tracker?: string;
  startTime?: Date;
  state?: TimerStateType;
  side?: string;
  duration?: number;
}

export const useTimerState = () => {
  const [side, setSide, removeSide] = useLocalStorage<string | undefined>(
    'dmt-timer-active-side'
  );

  const [tracker, setTracker] = useLocalStorage<string>(
    'dmt-timer-tracker',
    ''
  );

  const [duration, setDuration, removeDuration] = useLocalStorage<number>(
    'dmt-timer-duration',
    0
  );

  const [startTime, setStartTime, removeStartTime] = useLocalStorage<
    Date | undefined
  >('dmt-timer-startTime');

  const [timerState, setTimerState, removeTimerState] = useLocalStorage<
    TimerStateType | undefined
  >('dmt-timer-state', TIMER_STATE.INIT);

  const clear = () => {
    removeSide();
    removeStartTime();
    removeTimerState();
    removeDuration();
  };

  const clearStartTime = () => {
    removeStartTime();
  };

  const state: TimerState = {
    side,
    tracker,
    state: timerState,
    startTime,
    duration,
  };

  return {
    clear,
    clearStartTime,
    state,
    setSide,
    setStartTime,
    setTimerState,
    setTracker,
    setDuration,
  };
};
