import { useEffect } from 'react';
import { useAdManager } from '../context/AdProvider';
import { useLocation } from 'react-router-dom';

export interface useDfpSlotProps {
  path: string;
  id: string;
  size: googletag.GeneralSize;
}
const useDfpSlot = ({ path, size, id }: useDfpSlotProps) => {
  const adManager = useAdManager();
  const location = useLocation();
  useEffect(() => {
    adManager.createAdSlot(id, path, size);
    adManager.display(path);
    return () => {
      // Do not destroy slots unless the admanager provider is fixed to remove the slot.
      adManager.destroySlot(path);
    };
  }, [path, size, id, adManager, location]);
};

export default useDfpSlot;
