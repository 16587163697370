import { getAxiosWithToken } from '../utils/webApi';
import { LOAD_TRACKERS, SELECT_TRACKER } from './actionTypes';
import { startLoader, stopLoader } from './commonActions';

export const getAllTrackers = () => async (dispatch) => {
  dispatch(startLoader());

  const response = await getAxiosWithToken().get('trackers');

  dispatch(stopLoader());

  const returnedData = response.data;

  if (returnedData.success) {
    dispatch({ type: LOAD_TRACKERS, payload: returnedData.data });
  }
};

export const selectTracker = (tracker) => (dispatch) => {
  dispatch({ type: SELECT_TRACKER, payload: tracker });
};
