import * as i18n from 'i18next';
import React from 'react';
import { connect } from 'react-redux';
import { Button, Col } from 'reactstrap';
import { reduxForm } from 'redux-form';
import { scrollToTop } from '../../utils/commonUtils';
import { FORMS } from '../../utils/constants';
import { formUtils } from '../../utils/formUtils';
import Dots from '../common/Dots';
import { Fields } from '../common/Fields';
import Child from '../common/svgComponents/Child';

class ChildInfoScreen extends React.Component {
    componentDidMount() {
        scrollToTop();
    }

    render() {
        const { handleSubmit, currentPage } = this.props;

        return (
            <form
                onSubmit={handleSubmit}
                className="flexContainerColumn flexContainerCentered fixed-padding-register"
            >
                <div className="flexContainerCentered">
                    <p className="header mainGreenColor uppercase register-section-title noMargin">
                        {i18n.t('registerScreen:headerText.child')}
                    </p>
                </div>
                <div
                    xs={10}
                    sm={10}
                    md={10}
                    lg={8}
                    className="flexContainerCentered"
                >
                    <Child width={166} height={168} />
                </div>
                <div
                    className="flexContainerColumn flexContainerCentered"
                    style={{ margin: '24px 0px' }}
                >
                    {Fields.commonFields.childName()}
                    {Fields.commonFields.isChildGirl()}
                </div>
                <div></div>
                <Col
                    xs={10}
                    sm={10}
                    md={10}
                    lg={8}
                    className="flexContainerColumn centred"
                    style={{ margin: '24px 0px 12px 0px' }}
                >
                    <Button className="greenBtn header next uppercase">
                        {i18n.t('buttons:next')}
                    </Button>
                </Col>
                <Dots page={currentPage} />
            </form>
        );
    }
}

const registerForm = reduxForm({
    form: FORMS.REGISTER_FORM,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate: formUtils.validateSignUp,
})(ChildInfoScreen);

export default connect(null, {})(registerForm);
