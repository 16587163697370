import React from 'react';

const ForwardButton = ({ width, height, fill }) => {
    const viewBoxValues = '0 0 9.2 17';

    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox={viewBoxValues}
            width={width}
            height={height}
        >
            <g id="Стрелка_до_таг_-_меню_добави" fill={fill}>
                <path
                    className="st0"
                    d="M1.1,16.7c-0.2,0-0.4-0.1-0.5-0.2c-0.3-0.3-0.3-0.8,0-1.1l6.9-6.9l-6.9-7c-0.3-0.3-0.3-0.8,0-1.1
		        c0.3-0.3,0.8-0.3,1.1,0L9,8c0.3,0.3,0.3,0.8,0,1.1l-7.4,7.4C1.4,16.7,1.3,16.7,1.1,16.7z"
                />
            </g>
        </svg>
    );
};

export default ForwardButton;
