import { START_NAV_LOADER, STOP_NAV_LOADER } from '../actions/actionTypes';

const initialState = false;

const navLoaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_NAV_LOADER:
      state = true;
      return state;
    case STOP_NAV_LOADER:
      state = false;
      return state;
    default:
      return state;
  }
};

export default navLoaderReducer;
