import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Col, Container } from 'reactstrap';
import { getReadOnlyAddedSticker, getReadOnlyJournalUser } from '../../actions';
import {
    getActiveColor,
    getColor,
    isExistingSticker,
} from '../../utils/commonUtils';
import StickerSection from './StickerSection';
import StickerDateAndDescription from './StickerDateAndDescription';
import LocalNavigationComponent from '../LocalNavigationComponent';
import navigationMethods from '../../utils/navigationUtils';

class StickerReadOnlyScreen extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            unActiveColor: getActiveColor('unActiveColor'),
            unActiveTextColor: getActiveColor('unActiveTextColor'),
            borderColor: getActiveColor('borderColor'),
            backgroundColor: getActiveColor('backgroundColor'),
            expiredBorderColor: getActiveColor('expiredBorderColor'),
            expiredBackgroundColor: getActiveColor('expiredBackgroundColor'),
        };
    }

    componentWillMount() {
        // Note: The sticker ID (for the AddedSticker) will be part of the query string
        // only when we are editing the sticker. If we are adding a new sticker there
        // will be no ID passed in the URL. The sticker data (StickerTemplate)
        // will be available through the Redux store instead
        const stickerId = this.props.match.params.id;
        const params = new URLSearchParams(this.props.location.search);
        if (stickerId) {
            this.props.getReadOnlyAddedSticker(
                params.get('user-id'),
                stickerId
            );
        }
        if (!this.props.user) {
            this.props.getReadOnlyJournalUser(params.get('user-id'));
        }
    }

    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.selectedStickerTemplate.area) {
            const selectedGoal =
                this.props.selectedStickerTemplate.area.tag.goal;
            if (
                this.state.backgroundColor !==
                getColor(selectedGoal.backgroundColor)
            ) {
                this.setState({
                    borderColor: getColor(selectedGoal.borderColor),
                    backgroundColor: getColor(selectedGoal.backgroundColor),
                });
            }
        }
    }

    render() {
        if (!Object.keys(this.props.selectedStickerTemplate).length) {
            return <Fragment />;
        }
        return (
            <Container className="noPadding">
                <Col
                    xs={12}
                    sm={12}
                    md={8}
                    lg={8}
                    className="centred noPadding"
                >
                    <LocalNavigationComponent
                        previousPage={navigationMethods.goBack}
                    />
                    <div className="flexContainerColumn flexContainerCentered profile-form fix-padding">
                        <StickerSection
                            {...this.state}
                            selectedAddedSticker={
                                this.props.selectedAddedSticker
                            }
                        />
                        <StickerDateAndDescription {...this.state} />
                    </div>
                </Col>
            </Container>
        );
    }
}

const mapStateToProps = (state, props) => {
    const stickerId = props.match.params.id;
    return {
        selectedStickerTemplate: stickerId
            ? state.selectedSticker.selectedAddedSticker.stickerTemplate
            : state.selectedSticker.selectedStickerTemplate,
        user: state.authentication.userData,
        selectedAddedSticker: isExistingSticker()
            ? state.selectedSticker.selectedAddedSticker
            : {},
    };
};

export default connect(mapStateToProps, {
    getReadOnlyAddedSticker,
    getReadOnlyJournalUser,
})(StickerReadOnlyScreen);
