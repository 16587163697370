import {
  LOCAL_STORAGE_DATA_LOADED,
  SAVE_PAID_FUNCTIONALITY_CREDENTIALS,
} from '../actions/actionTypes';

const initialState = { username: '', password: '' };

const paidFunctionalityCredentialsReducer = (
  state = { ...initialState },
  action
) => {
  switch (action.type) {
    case SAVE_PAID_FUNCTIONALITY_CREDENTIALS:
      state = { ...action.payload };
      return state;
    case LOCAL_STORAGE_DATA_LOADED:
      state = { ...action.payload.paidFunctionalityCredentials };
      return state;
    default:
      return state;
  }
};

export default paidFunctionalityCredentialsReducer;
