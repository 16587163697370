import React from 'react';
import { Button } from 'reactstrap';
import i18n from '../../i18next';
import { RELATIVE_PAGE_PATHS } from '../../utils/constants';
import navigationUtils from '../../utils/navigationUtils';

const ReturnToHomeScreen = () => (
    <Button
        onClick={() => navigateToHome()}
        className="greenBtn header"
        style={{ width: '150px' }}
    >
        {i18n.t('returnToHomeScreen:buttonCaption')}
    </Button>
);

export default ReturnToHomeScreen;

const navigateToHome = () => {
    navigationUtils.navigate(RELATIVE_PAGE_PATHS.HOME);
};
