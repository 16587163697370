import { useCallback } from 'react';
import formatMeasurement from '../utils/trackerMeasurement';

const useMeasurementFormatter = (initialSymbol, type, subType) => {
  return useCallback(
    (value, symbol) =>
      formatMeasurement(value, symbol || initialSymbol, type, subType),
    [initialSymbol, type, subType]
  );
};

export default useMeasurementFormatter;
