import React from 'react';
import { Col } from 'reactstrap';
import { COLOR_SCHEME, RELATIVE_PAGE_PATHS } from '../../utils/constants';
import navigationMethods from '../../utils/navigationUtils';
import BackButtonSVG from '../common/svgComponents/BackButton';

class PhotoPreviewScreen extends React.Component {
    state = { showNavBar: true };

    backButton() {
        const previousPage = () => {
            navigationMethods.goBack();
        };

        if (navigationMethods.getRoute() !== RELATIVE_PAGE_PATHS.HOME) {
            return (
                <Col>
                    <BackButtonSVG
                        width={15}
                        height={15}
                        svgClicked={previousPage}
                        color={COLOR_SCHEME.WHITE}
                    />
                </Col>
            );
        }
    }

    renderNavBar = () => {
        if (this.state.showNavBar) {
            return (
                <div
                    className="flexContainer noPadding noMargin transparent"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    id="mainNav"
                >
                    {this.backButton()}
                </div>
            );
        }
    };

    triggerNavBar = (e) => {
        this.setState({ showNavBar: !this.state.showNavBar });
    };

    render() {
        const imgUrl = navigationMethods.getUserImageUrl(
            this.props.match.params.photo
        );
        return (
            <div onClick={this.triggerNavBar}>
                {this.renderNavBar()}
                <img
                    src={imgUrl}
                    alt="Preview"
                    className="sticker-photo-preview"
                />
            </div>
        );
    }
}

export default PhotoPreviewScreen;
