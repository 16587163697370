import React from 'react';

const AchievementIcon = ({ width, height, fill }) => {
    const viewBoxValues = '0 0 74.001 59.986';

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox={viewBoxValues}
        >
            <g id="Звезда" transform="translate(-2.265 -1.66)">
                <path
                    id="Icon_awesome-star"
                    data-name="Icon awesome-star"
                    d="M35.321,21,29.95,31.889,17.933,33.641a2.633,2.633,0,0,0-1.454,4.493l8.692,8.47L23.115,58.572a2.63,2.63,0,0,0,3.817,2.772l10.747-5.651,10.75,5.651a2.633,2.633,0,0,0,3.817-2.772L50.192,46.6l8.694-8.47a2.633,2.633,0,0,0-1.456-4.491L45.413,31.889,40.042,21a2.635,2.635,0,0,0-4.721,0Z"
                    fill={fill ? fill : '#87ba3b'}
                />
                <g id="Line_2" data-name="Line 2">
                    <path
                        id="Path_1731"
                        data-name="Path 1731"
                        d="M59.685,21.034a1.5,1.5,0,0,1-.831-2.749l5.082-3.374a1.5,1.5,0,1,1,1.66,2.5l-5.082,3.374A1.5,1.5,0,0,1,59.685,21.034Z"
                        fill={fill ? fill : '#87ba3b'}
                    />
                </g>
                <g id="Line_6" data-name="Line 6">
                    <path
                        id="Path_1732"
                        data-name="Path 1732"
                        d="M37.8,11h-.021a1.5,1.5,0,0,1-1.519-1.481l-.081-6.339a1.5,1.5,0,0,1,1.461-1.518h.02a1.5,1.5,0,0,1,1.519,1.481l.081,6.339A1.5,1.5,0,0,1,37.8,11Z"
                        fill={fill ? fill : '#87ba3b'}
                    />
                </g>
                <g id="Line_4" data-name="Line 4">
                    <path
                        id="Path_1733"
                        data-name="Path 1733"
                        d="M16.927,22.467a1.5,1.5,0,0,1-1.022-.4l-5.161-4.807a1.5,1.5,0,1,1,2.045-2.195L17.95,19.87a1.5,1.5,0,0,1-1.023,2.6Z"
                        fill={fill ? fill : '#87ba3b'}
                    />
                </g>
                <g id="Line_3" data-name="Line 3">
                    <path
                        id="Path_1734"
                        data-name="Path 1734"
                        d="M74.767,47.66a1.459,1.459,0,0,1-.346-.04l-6.214-1.469a1.5,1.5,0,0,1,.689-2.919L75.11,44.7a1.5,1.5,0,0,1-.343,2.96Z"
                        fill={fill ? fill : '#87ba3b'}
                    />
                </g>
                <g id="Line_5" data-name="Line 5">
                    <path
                        id="Path_1735"
                        data-name="Path 1735"
                        d="M3.765,47.66a1.5,1.5,0,0,1-.294-2.97L9,43.578a1.5,1.5,0,1,1,.59,2.942L4.062,47.631A1.6,1.6,0,0,1,3.765,47.66Z"
                        fill={fill ? fill : '#87ba3b'}
                    />
                </g>
            </g>
        </svg>
    );
};

export default AchievementIcon;
