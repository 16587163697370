import React from 'react';

const Bulbs = ({ width, height }) => {
    const viewBoxValues = '50 0 240 200';

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox={viewBoxValues}
        >
            <defs>
                <clipPath id="clipPath">
                    <rect
                        id="Rectangle_1728"
                        data-name="Rectangle 1728"
                        width="0.6"
                        height="12.4"
                        transform="translate(244.371 1.011)"
                        fill="none"
                    />
                </clipPath>
            </defs>
            <g
                id="Крушки_начален_екран"
                data-name="Крушки начален екран"
                transform="translate(-0.084 0)"
            >
                <g id="Сиви_крушки_фон" data-name="Сиви крушки фон">
                    <g
                        id="Сива_крушка_фон_3"
                        data-name="Сива крушка фон 3"
                        opacity="0.47"
                    >
                        <g id="Group_1755" data-name="Group 1755">
                            <path
                                id="Path_1982"
                                data-name="Path 1982"
                                d="M200.774.924h.7v10.5h-.7Z"
                                fill="#b9b7b7"
                            />
                            <path
                                id="Rectangle_2"
                                data-name="Rectangle 2"
                                d="M196.474,13.524h9.5a.86.86,0,0,1,.8.8h0a.86.86,0,0,1-.8.8h-9.5a.86.86,0,0,1-.8-.8h0A.86.86,0,0,1,196.474,13.524Z"
                                fill="#b9b7b7"
                            />
                            <path
                                id="Rectangle_3"
                                data-name="Rectangle 3"
                                d="M195.274,15.824h11.9a.86.86,0,0,1,.8.8h0a.86.86,0,0,1-.8.8h-11.9a.86.86,0,0,1-.8-.8h0A.789.789,0,0,1,195.274,15.824Z"
                                fill="#b9b7b7"
                            />
                            <path
                                id="Rectangle_4"
                                data-name="Rectangle 4"
                                d="M195.674,18.024h11.1a.86.86,0,0,1,.8.8h0a.86.86,0,0,1-.8.8h-11.1a.86.86,0,0,1-.8-.8h0A.789.789,0,0,1,195.674,18.024Z"
                                fill="#b9b7b7"
                            />
                            <path
                                id="Path_1"
                                data-name="Path 1"
                                d="M201.174,9.924a4.227,4.227,0,0,0-3.9,2.7.367.367,0,0,0,.1.3h7.5a.215.215,0,0,0,.2-.2v-.1A3.873,3.873,0,0,0,201.174,9.924Z"
                                fill="#b9b7b7"
                            />
                        </g>
                        <path
                            id="Path_1740"
                            data-name="Path 1740"
                            d="M210.451,26.524c-1.3-1.9-2.9-3.7-3-6.2l-12.7.1c.1,1.8-.9,3.2-1.7,4.6a37.678,37.678,0,0,0-2.8,4.8,11.02,11.02,0,0,0,.1,10,12.592,12.592,0,0,0,11.8,6.8,12.366,12.366,0,0,0,10.8-8.5C214.151,33.824,212.851,30.024,210.451,26.524Z"
                            fill="#e6e6e6"
                        />
                    </g>
                    <g
                        id="Сива_крушка_фон_4"
                        data-name="Сива крушка фон 4"
                        opacity="0.47"
                    >
                        <g id="Group_1755-2" data-name="Group 1755-2">
                            <rect
                                id="Rectangle_1"
                                data-name="Rectangle 1"
                                width="0.6"
                                height="12.6"
                                transform="translate(328.215 0.989)"
                                fill="#b9b7b7"
                            />
                            <path
                                id="Rectangle_2-2"
                                data-name="Rectangle 2-2"
                                d="M324.115,15.689h9a.789.789,0,0,1,.8.8h0a.789.789,0,0,1-.8.8h-9a.789.789,0,0,1-.8-.8h0A.789.789,0,0,1,324.115,15.689Z"
                                fill="#b9b7b7"
                            />
                            <path
                                id="Rectangle_3-2"
                                data-name="Rectangle 3-2"
                                d="M322.915,17.789h11.3a.789.789,0,0,1,.8.8h0a.789.789,0,0,1-.8.8h-11.3a.789.789,0,0,1-.8-.8h0C322.215,18.089,322.515,17.789,322.915,17.789Z"
                                fill="#b9b7b7"
                            />
                            <path
                                id="Rectangle_4-2"
                                data-name="Rectangle 4-2"
                                d="M323.315,19.889h10.6a.789.789,0,0,1,.8.8h0a.789.789,0,0,1-.8.8h-10.6a.789.789,0,0,1-.8-.8h0A.86.86,0,0,1,323.315,19.889Z"
                                fill="#b9b7b7"
                            />
                            <path
                                id="Path_1-2"
                                data-name="Path 1-2"
                                d="M328.615,12.089a3.784,3.784,0,0,0-3.7,2.6c0,.1,0,.2.1.2h7.2a.215.215,0,0,0,.2-.2v-.1A4.218,4.218,0,0,0,328.615,12.089Z"
                                fill="#b9b7b7"
                            />
                        </g>
                        <path
                            id="Path_1740-2"
                            data-name="Path 1740-2"
                            d="M337.447,27.989c-1.2-1.8-2.8-3.5-2.9-5.9l-12.1.1a7.17,7.17,0,0,1-1.7,4.3,42.546,42.546,0,0,0-2.7,4.6,10.287,10.287,0,0,0,.1,9.5,11.6,11.6,0,0,0,21.5-1.6C340.947,34.889,339.647,31.389,337.447,27.989Z"
                            fill="#e6e6e6"
                        />
                    </g>
                    <g
                        id="Сива_крушка_фон_2"
                        data-name="Сива крушка фон 2"
                        opacity="0.47"
                    >
                        <g id="Group_1755-3" data-name="Group 1755-3">
                            <rect
                                id="Rectangle_1-2"
                                data-name="Rectangle 1-2"
                                width="0.6"
                                height="13"
                                transform="translate(119.092 0.939)"
                                fill="#b9b7b7"
                            />
                            <path
                                id="Rectangle_2-3"
                                data-name="Rectangle 2-3"
                                d="M114.992,16.139h9a.789.789,0,0,1,.8.8h0a.789.789,0,0,1-.8.8h-9a.789.789,0,0,1-.8-.8h0A.789.789,0,0,1,114.992,16.139Z"
                                fill="#b9b7b7"
                            />
                            <path
                                id="Rectangle_3-3"
                                data-name="Rectangle 3-3"
                                d="M113.792,18.239h11.3a.789.789,0,0,1,.8.8h0a.789.789,0,0,1-.8.8h-11.3a.789.789,0,0,1-.8-.8h0C113.092,18.539,113.392,18.239,113.792,18.239Z"
                                fill="#b9b7b7"
                            />
                            <path
                                id="Rectangle_4-3"
                                data-name="Rectangle 4-3"
                                d="M114.192,20.339h10.6a.789.789,0,0,1,.8.8h0a.789.789,0,0,1-.8.8h-10.6a.789.789,0,0,1-.8-.8h0A.86.86,0,0,1,114.192,20.339Z"
                                fill="#b9b7b7"
                            />
                            <path
                                id="Path_1-3"
                                data-name="Path 1-3"
                                d="M119.492,12.539a3.784,3.784,0,0,0-3.7,2.6c0,.1,0,.2.1.2h7.2a.215.215,0,0,0,.2-.2v-.1A4.218,4.218,0,0,0,119.492,12.539Z"
                                fill="#b9b7b7"
                            />
                        </g>
                        <path
                            id="Path_1740-3"
                            data-name="Path 1740-3"
                            d="M128.324,28.439c-1.2-1.8-2.8-3.5-2.9-5.9l-12.1.1a7.17,7.17,0,0,1-1.7,4.3,42.547,42.547,0,0,0-2.7,4.6,10.287,10.287,0,0,0,.1,9.5,11.6,11.6,0,0,0,21.5-1.6C131.824,35.339,130.524,31.839,128.324,28.439Z"
                            fill="#e6e6e6"
                        />
                    </g>
                    <g
                        id="Сива_крушка_фон_1"
                        data-name="Сива крушка фон 1"
                        opacity="0.47"
                    >
                        <g id="Group_1755-4" data-name="Group 1755-4">
                            <rect
                                id="Rectangle_1-3"
                                data-name="Rectangle 1-3"
                                width="0.6"
                                height="12.6"
                                transform="translate(10.963 0.961)"
                                fill="#b9b7b7"
                            />
                            <path
                                id="Rectangle_2-4"
                                data-name="Rectangle 2-4"
                                d="M6.963,15.561h8.8a.684.684,0,0,1,.7.7h0a.684.684,0,0,1-.7.7h-8.8a.684.684,0,0,1-.7-.7h0C6.163,15.961,6.463,15.561,6.963,15.561Z"
                                fill="#b9b7b7"
                            />
                            <path
                                id="Rectangle_3-4"
                                data-name="Rectangle 3-4"
                                d="M5.763,17.661h11.1a.684.684,0,0,1,.7.7h0a.684.684,0,0,1-.7.7H5.763a.684.684,0,0,1-.7-.7h0A.751.751,0,0,1,5.763,17.661Z"
                                fill="#b9b7b7"
                            />
                            <path
                                id="Rectangle_4-4"
                                data-name="Rectangle 4-4"
                                d="M6.163,19.761h10.3a.684.684,0,0,1,.7.7h0a.684.684,0,0,1-.7.7H6.163a.684.684,0,0,1-.7-.7h0C5.363,20.161,5.763,19.761,6.163,19.761Z"
                                fill="#b9b7b7"
                            />
                            <path
                                id="Path_1-4"
                                data-name="Path 1-4"
                                d="M11.263,12.161a3.792,3.792,0,0,0-3.6,2.5c0,.1,0,.2.1.2h7a.215.215,0,0,0,.2-.2v-.1A3.987,3.987,0,0,0,11.263,12.161Z"
                                fill="#b9b7b7"
                            />
                        </g>
                        <path
                            id="Path_1740-4"
                            data-name="Path 1740-4"
                            d="M19.922,27.661c-1.2-1.8-2.7-3.4-2.8-5.8l-11.8.1a7.731,7.731,0,0,1-1.6,4.2,28.219,28.219,0,0,0-2.6,4.4,10.487,10.487,0,0,0,.1,9.3,11.653,11.653,0,0,0,10.9,6.3,11.779,11.779,0,0,0,10.1-7.9C23.322,34.461,22.122,30.961,19.922,27.661Z"
                            fill="#e6e6e6"
                        />
                    </g>
                </g>
                <g id="Group_868" data-name="Group 868">
                    <g id="Group_93" data-name="Group 93">
                        <g id="Group_1" data-name="Group 1">
                            <rect
                                id="Rectangle_1-4"
                                data-name="Rectangle 1-4"
                                width="1"
                                height="71.8"
                                transform="translate(109.633 1)"
                                fill="#7b7b7b"
                            />
                            <path
                                id="Rectangle_2-5"
                                data-name="Rectangle 2-5"
                                d="M103.433,75.9h13.5a1.11,1.11,0,0,1,1.1,1.1h0a1.11,1.11,0,0,1-1.1,1.1h-13.5a1.11,1.11,0,0,1-1.1-1.1h0A1.11,1.11,0,0,1,103.433,75.9Z"
                                fill="#7b7b7b"
                            />
                            <path
                                id="Rectangle_3-5"
                                data-name="Rectangle 3-5"
                                d="M101.733,79.1h17a1.11,1.11,0,0,1,1.1,1.1h0a1.11,1.11,0,0,1-1.1,1.1h-17a1.11,1.11,0,0,1-1.1-1.1h0A1.11,1.11,0,0,1,101.733,79.1Z"
                                fill="#7b7b7b"
                            />
                            <path
                                id="Rectangle_4-5"
                                data-name="Rectangle 4-5"
                                d="M102.333,82.3h15.9a1.11,1.11,0,0,1,1.1,1.1h0a1.11,1.11,0,0,1-1.1,1.1h-15.9a1.11,1.11,0,0,1-1.1-1.1h0A1,1,0,0,1,102.333,82.3Z"
                                fill="#7b7b7b"
                            />
                            <path
                                id="Path_1-5"
                                data-name="Path 1-5"
                                d="M110.233,70.6a5.923,5.923,0,0,0-5.6,3.9c-.1.2,0,.3.2.4h10.7a.265.265,0,0,0,.3-.3v-.1A5.923,5.923,0,0,0,110.233,70.6Z"
                                fill="#7b7b7b"
                            />
                        </g>
                        <g id="Group_4" data-name="Group 4">
                            <path
                                id="Path_2"
                                data-name="Path 2"
                                d="M123.5,94.362c-1.8-2.7-4.2-5.2-4.4-8.8l-18.1.1c.1,2.6-1.3,4.5-2.5,6.5a67.135,67.135,0,0,0-4,6.8,15.779,15.779,0,0,0,.1,14.2,17.469,17.469,0,0,0,32.3-2.4C128.7,104.762,126.8,99.362,123.5,94.362Z"
                                fill="#f37920"
                            />
                        </g>
                        <g id="Group_91" data-name="Group 91">
                            <path
                                id="Path_326"
                                data-name="Path 326"
                                d="M118.985,102.859a3.6,3.6,0,0,0-2.1-1.3l-.9,1.1c1.2.5,1.8,1,1.8,1.6,0,1.5-3.6,2.6-8.1,2.6s-8.1-1.2-8.1-2.6,3.6-2.6,8.1-2.6h1.5l.4-1.1c-.6,0-1.2-.1-1.8-.1-4.5,0-8.2,1-9.2,2.3a1.6,1.6,0,0,0-.3.8v1.4c0,4.8,4.3,8.6,9.5,8.6s9.5-3.8,9.5-8.6v-1.4A1.08,1.08,0,0,0,118.985,102.859Zm-13.1,7.5a1.1,1.1,0,1,1,1.1-1.1A1,1,0,0,1,105.885,110.359Zm4.1.9a1.6,1.6,0,1,1,1.6-1.6,1.718,1.718,0,0,1-1.6,1.6Zm4-.9a1.1,1.1,0,1,1,1.1-1.1,1.11,1.11,0,0,1-1.1,1.1Z"
                                fill="#fff"
                            />
                            <path
                                id="Path_327"
                                data-name="Path 327"
                                d="M112.085,102.359Z"
                                fill="#fff"
                            />
                            <path
                                id="Path_328"
                                data-name="Path 328"
                                d="M117.785,95.659c-1.5-1-2.3.3-3.8,2.5a8.265,8.265,0,0,1-.7,1.3l-.6,1.2c-.1.4-.3.8-.4,1.1a1.421,1.421,0,0,0-.2.6h0a19.269,19.269,0,0,0-2.4-.1c-3.6,0-6.6.9-6.6,2s2.9,2,6.6,2,6.6-.9,6.6-2c0-.5-.6-.9-1.5-1.3h0a2.651,2.651,0,0,1,.5-.6,10.628,10.628,0,0,0,.9-1.1c.2-.2.3-.4.5-.6.1-.2.3-.4.4-.6C118.485,97.959,119.185,96.559,117.785,95.659Z"
                                fill="#fff"
                            />
                        </g>
                    </g>
                </g>
                <g id="Group_1340" data-name="Group 1340">
                    <path
                        id="Path_1583"
                        data-name="Path 1583"
                        d="M92.66,136.451a.413.413,0,0,1-.387-.2.376.376,0,0,1,.054-.436l1.989-2.664-1.872-2.511a.385.385,0,0,1-.072-.45.409.409,0,0,1,.387-.207.607.607,0,0,1,.486.3l1.6,2.187,1.584-2.187a.612.612,0,0,1,.5-.3.41.41,0,0,1,.387.2.392.392,0,0,1-.072.454l-1.872,2.511,1.98,2.664a.384.384,0,0,1,.059.436.412.412,0,0,1-.392.2.631.631,0,0,1-.495-.3l-1.683-2.3-1.674,2.3A.636.636,0,0,1,92.66,136.451Z"
                        fill="#f37920"
                    />
                    <path
                        id="Path_1584"
                        data-name="Path 1584"
                        d="M98.951,138.07a.4.4,0,0,1-.45-.459v-5.22q0-.459.441-.459a.4.4,0,0,1,.45.459v.342a1.466,1.466,0,0,1,.585-.6,1.76,1.76,0,0,1,.882-.22,1.788,1.788,0,0,1,1.7,1.067,2.842,2.842,0,0,1,.238,1.21,2.872,2.872,0,0,1-.238,1.215,1.807,1.807,0,0,1-.676.788,1.885,1.885,0,0,1-1.021.274,1.752,1.752,0,0,1-.868-.212,1.412,1.412,0,0,1-.581-.58v1.935A.406.406,0,0,1,98.951,138.07Zm1.683-2.3a1.119,1.119,0,0,0,.9-.405,1.782,1.782,0,0,0,.337-1.17,1.762,1.762,0,0,0-.337-1.165,1.222,1.222,0,0,0-1.81,0,1.762,1.762,0,0,0-.337,1.165,1.782,1.782,0,0,0,.337,1.17A1.119,1.119,0,0,0,100.634,135.766Z"
                        fill="#f37920"
                    />
                    <path
                        id="Path_1585"
                        data-name="Path 1585"
                        d="M105.323,136.468a1.807,1.807,0,0,1-.819-.18,1.479,1.479,0,0,1-.571-.486,1.188,1.188,0,0,1-.212-.693,1.114,1.114,0,0,1,.248-.765,1.5,1.5,0,0,1,.819-.4,7.47,7.47,0,0,1,1.543-.126h.306v-.234a1.045,1.045,0,0,0-.216-.738.953.953,0,0,0-.711-.225,2.637,2.637,0,0,0-.6.072,2.8,2.8,0,0,0-.644.243.341.341,0,0,1-.31.027.333.333,0,0,1-.167-.2.407.407,0,0,1,.009-.279.381.381,0,0,1,.225-.216,4.025,4.025,0,0,1,.783-.266,3.448,3.448,0,0,1,.729-.085,1.536,1.536,0,0,1,1.764,1.764v2.313c0,.306-.141.46-.423.46s-.432-.154-.432-.46v-.332a1.281,1.281,0,0,1-.5.593A1.5,1.5,0,0,1,105.323,136.468Zm.18-.648a1.07,1.07,0,0,0,.81-.342,1.206,1.206,0,0,0,.324-.864v-.243h-.3a6.528,6.528,0,0,0-1.03.063,1.011,1.011,0,0,0-.531.212.53.53,0,0,0-.158.409.71.71,0,0,0,.234.554A.936.936,0,0,0,105.5,135.82Z"
                        fill="#f37920"
                    />
                    <path
                        id="Path_1586"
                        data-name="Path 1586"
                        d="M109.31,136.451a.406.406,0,0,1-.459-.46v-3.6a.455.455,0,1,1,.909,0v1.377h2.187v-1.377a.455.455,0,1,1,.909,0v3.6a.455.455,0,1,1-.909,0v-1.5H109.76v1.5A.4.4,0,0,1,109.31,136.451Z"
                        fill="#f37920"
                    />
                    <path
                        id="Path_1587"
                        data-name="Path 1587"
                        d="M116.285,136.468a2.328,2.328,0,0,1-1.7-.607,2.218,2.218,0,0,1-.621-1.66,2.513,2.513,0,0,1,.274-1.194,2,2,0,0,1,.761-.8,2.138,2.138,0,0,1,1.116-.289,1.83,1.83,0,0,1,1.431.58,2.3,2.3,0,0,1,.522,1.6q0,.306-.36.306h-2.862a1.3,1.3,0,0,0,1.449,1.368,2.558,2.558,0,0,0,.567-.067,1.8,1.8,0,0,0,.576-.247.467.467,0,0,1,.328-.078.293.293,0,0,1,.2.154.359.359,0,0,1,.022.269.447.447,0,0,1-.211.257,2.275,2.275,0,0,1-.716.311A3.174,3.174,0,0,1,116.285,136.468Zm-.135-3.915a1.182,1.182,0,0,0-.9.351,1.6,1.6,0,0,0-.4.936H117.3a1.494,1.494,0,0,0-.324-.949A1.042,1.042,0,0,0,116.15,132.553Z"
                        fill="#f37920"
                    />
                    <path
                        id="Path_1588"
                        data-name="Path 1588"
                        d="M119.606,136.451a.406.406,0,0,1-.459-.46v-3.6a.455.455,0,1,1,.909,0v1.377h2.187v-1.377a.455.455,0,1,1,.909,0v3.6a.455.455,0,1,1-.909,0v-1.5h-2.187v1.5A.4.4,0,0,1,119.606,136.451Z"
                        fill="#f37920"
                    />
                    <path
                        id="Path_1589"
                        data-name="Path 1589"
                        d="M126.581,136.468a2.326,2.326,0,0,1-1.7-.607,2.218,2.218,0,0,1-.621-1.66,2.513,2.513,0,0,1,.274-1.194,2,2,0,0,1,.76-.8,2.144,2.144,0,0,1,1.117-.289,1.83,1.83,0,0,1,1.431.58,2.309,2.309,0,0,1,.522,1.6q0,.306-.361.306h-2.861a1.3,1.3,0,0,0,1.449,1.368,2.565,2.565,0,0,0,.567-.067,1.8,1.8,0,0,0,.575-.247.47.47,0,0,1,.329-.078.293.293,0,0,1,.2.154.359.359,0,0,1,.022.269.451.451,0,0,1-.211.257,2.275,2.275,0,0,1-.716.311A3.174,3.174,0,0,1,126.581,136.468Zm-.135-3.915a1.182,1.182,0,0,0-.9.351,1.6,1.6,0,0,0-.4.936H127.6a1.488,1.488,0,0,0-.325-.949A1.039,1.039,0,0,0,126.446,132.553Z"
                        fill="#f37920"
                    />
                </g>
                <g id="Group_1341" data-name="Group 1341">
                    <path
                        id="Path_1590"
                        data-name="Path 1590"
                        d="M246.651,149.321a.418.418,0,0,1-.467-.478v-5.427a.445.445,0,0,1,.5-.5h4.366a.441.441,0,0,1,.494.5v5.427c0,.318-.152.478-.459.478a.418.418,0,0,1-.468-.478V143.7h-3.5v5.147A.418.418,0,0,1,246.651,149.321Z"
                        fill="#e9346a"
                    />
                    <path
                        id="Path_1591"
                        data-name="Path 1591"
                        d="M254.887,149.338a2.276,2.276,0,0,1-1.153-.279,1.909,1.909,0,0,1-.752-.787,2.9,2.9,0,0,1,0-2.421,1.912,1.912,0,0,1,.752-.788,2.276,2.276,0,0,1,1.153-.278,2.236,2.236,0,0,1,1.133.278,1.933,1.933,0,0,1,.756.788,2.839,2.839,0,0,1,0,2.421,1.931,1.931,0,0,1-.756.787A2.237,2.237,0,0,1,254.887,149.338Zm0-.7a1.118,1.118,0,0,0,.9-.4,1.765,1.765,0,0,0,.342-1.17,1.745,1.745,0,0,0-.342-1.165,1.124,1.124,0,0,0-.9-.4,1.137,1.137,0,0,0-.91.4,1.745,1.745,0,0,0-.342,1.165,1.765,1.765,0,0,0,.342,1.17A1.131,1.131,0,0,0,254.887,148.636Z"
                        fill="#e9346a"
                    />
                    <path
                        id="Path_1592"
                        data-name="Path 1592"
                        d="M258.621,149.257a.405.405,0,0,1-.459-.459v-3.465a.409.409,0,0,1,.459-.467H260.4a1.884,1.884,0,0,1,1.184.3,1.032,1.032,0,0,1,.374.851.967.967,0,0,1-.193.6,1.115,1.115,0,0,1-.527.374,1.116,1.116,0,0,1,.639.378,1.026,1.026,0,0,1,.225.666,1.076,1.076,0,0,1-.422.9,1.937,1.937,0,0,1-1.207.328Zm.4-2.529h1.322a.864.864,0,0,0,.572-.171.578.578,0,0,0,.2-.468.564.564,0,0,0-.2-.468.9.9,0,0,0-.572-.162h-1.322Zm0,1.944h1.4c.563,0,.847-.227.847-.683s-.284-.676-.847-.676h-1.4Z"
                        fill="#e9346a"
                    />
                    <path
                        id="Path_1593"
                        data-name="Path 1593"
                        d="M265.236,149.338a2.329,2.329,0,0,1-1.7-.607,2.217,2.217,0,0,1-.621-1.66,2.513,2.513,0,0,1,.275-1.194,2,2,0,0,1,.76-.8,2.14,2.14,0,0,1,1.116-.288,1.825,1.825,0,0,1,1.431.58,2.3,2.3,0,0,1,.523,1.6c0,.2-.121.306-.361.306H263.8a1.3,1.3,0,0,0,1.449,1.368,2.549,2.549,0,0,0,.566-.067,1.8,1.8,0,0,0,.577-.247.467.467,0,0,1,.328-.078.293.293,0,0,1,.2.154.359.359,0,0,1,.021.269.443.443,0,0,1-.211.257,2.274,2.274,0,0,1-.714.311A3.2,3.2,0,0,1,265.236,149.338Zm-.134-3.915a1.185,1.185,0,0,0-.9.351,1.6,1.6,0,0,0-.4.936h2.457a1.505,1.505,0,0,0-.324-.949A1.048,1.048,0,0,0,265.1,145.423Z"
                        fill="#e9346a"
                    />
                    <path
                        id="Path_1594"
                        data-name="Path 1594"
                        d="M268.044,150.491c-.265,0-.4-.139-.4-.414v-1.108a.386.386,0,0,1,.441-.432h.054a2.95,2.95,0,0,0,.41-1,6.723,6.723,0,0,0,.121-1.391v-.765a.453.453,0,0,1,.513-.512h2.223a.453.453,0,0,1,.513.512v3.159h.27a.382.382,0,0,1,.432.432v1.108c0,.275-.129.414-.387.414s-.386-.139-.386-.414v-.82h-3.42v.82C268.432,150.352,268.3,150.491,268.044,150.491Zm.936-1.954h2.079v-2.96h-1.593v.755a5.711,5.711,0,0,1-.126,1.206A3.511,3.511,0,0,1,268.98,148.537Z"
                        fill="#e9346a"
                    />
                    <path
                        id="Path_1595"
                        data-name="Path 1595"
                        d="M275.6,149.338a2.328,2.328,0,0,1-1.7-.607,2.217,2.217,0,0,1-.621-1.66,2.5,2.5,0,0,1,.275-1.194,2.009,2.009,0,0,1,.759-.8,2.147,2.147,0,0,1,1.118-.288,1.826,1.826,0,0,1,1.43.58,2.3,2.3,0,0,1,.523,1.6q0,.306-.362.306h-2.861a1.3,1.3,0,0,0,1.449,1.368,2.558,2.558,0,0,0,.567-.067,1.791,1.791,0,0,0,.576-.247.469.469,0,0,1,.328-.078.291.291,0,0,1,.2.154.364.364,0,0,1,.023.269.449.449,0,0,1-.212.257,2.259,2.259,0,0,1-.715.311A3.187,3.187,0,0,1,275.6,149.338Zm-.135-3.915a1.183,1.183,0,0,0-.9.351,1.61,1.61,0,0,0-.4.936h2.457a1.488,1.488,0,0,0-.324-.949A1.044,1.044,0,0,0,275.469,145.423Z"
                        fill="#e9346a"
                    />
                    <path
                        id="Path_1596"
                        data-name="Path 1596"
                        d="M278.925,149.321a.406.406,0,0,1-.459-.46v-3.6a.455.455,0,1,1,.909,0v1.377h2.187v-1.377a.4.4,0,0,1,.45-.459.406.406,0,0,1,.459.459v3.6a.406.406,0,0,1-.459.46.4.4,0,0,1-.45-.46v-1.5h-2.187v1.5A.4.4,0,0,1,278.925,149.321Z"
                        fill="#e9346a"
                    />
                    <path
                        id="Path_1597"
                        data-name="Path 1597"
                        d="M284.253,149.321q-.423,0-.423-.487v-3.59c0-.294.138-.442.414-.442s.414.148.414.442v2.807l2.205-2.952a.922.922,0,0,1,.2-.207.52.52,0,0,1,.324-.09q.414,0,.414.486v3.591c0,.294-.135.442-.4.442s-.424-.148-.424-.442v-2.8l-2.205,2.953a1.055,1.055,0,0,1-.2.2A.5.5,0,0,1,284.253,149.321Z"
                        fill="#e9346a"
                    />
                    <path
                        id="Path_1598"
                        data-name="Path 1598"
                        d="M291.236,149.338a2.327,2.327,0,0,1-1.7-.607,2.217,2.217,0,0,1-.621-1.66,2.513,2.513,0,0,1,.274-1.194,2.018,2.018,0,0,1,.76-.8,2.141,2.141,0,0,1,1.117-.288,1.828,1.828,0,0,1,1.431.58,2.3,2.3,0,0,1,.523,1.6c0,.2-.122.306-.362.306H289.8a1.3,1.3,0,0,0,1.449,1.368,2.543,2.543,0,0,0,.566-.067,1.786,1.786,0,0,0,.577-.247.469.469,0,0,1,.328-.078.291.291,0,0,1,.2.154.359.359,0,0,1,.022.269.442.442,0,0,1-.212.257,2.253,2.253,0,0,1-.714.311A3.2,3.2,0,0,1,291.236,149.338Zm-.134-3.915a1.183,1.183,0,0,0-.9.351,1.6,1.6,0,0,0-.4.936h2.457a1.494,1.494,0,0,0-.324-.949A1.045,1.045,0,0,0,291.1,145.423Z"
                        fill="#e9346a"
                    />
                </g>
                <g id="Group_867" data-name="Group 867">
                    <g id="крушка_баня" data-name="крушка баня">
                        <g id="Group_1-2" data-name="Group 1-2">
                            <rect
                                id="Rectangle_1-5"
                                data-name="Rectangle 1-5"
                                width="1"
                                height="82.7"
                                transform="translate(267.216 0.989)"
                                fill="#7b7b7b"
                            />
                            <path
                                id="Rectangle_2-6"
                                data-name="Rectangle 2-6"
                                d="M260.716,86.989h14.2a1.155,1.155,0,0,1,1.2,1.2h0a1.155,1.155,0,0,1-1.2,1.2h-14.2a1.155,1.155,0,0,1-1.2-1.2h0A1.155,1.155,0,0,1,260.716,86.989Z"
                                fill="#7b7b7b"
                            />
                            <path
                                id="Rectangle_3-6"
                                data-name="Rectangle 3-6"
                                d="M258.916,90.289h17.8a1.155,1.155,0,0,1,1.2,1.2h0a1.155,1.155,0,0,1-1.2,1.2h-17.8a1.155,1.155,0,0,1-1.2-1.2h0A1.216,1.216,0,0,1,258.916,90.289Z"
                                fill="#7b7b7b"
                            />
                            <path
                                id="Rectangle_4-6"
                                data-name="Rectangle 4-6"
                                d="M259.516,93.689h16.6a1.155,1.155,0,0,1,1.2,1.2h0a1.155,1.155,0,0,1-1.2,1.2h-16.7a1.155,1.155,0,0,1-1.2-1.2h0A1.305,1.305,0,0,1,259.516,93.689Z"
                                fill="#7b7b7b"
                            />
                            <path
                                id="Path_1-6"
                                data-name="Path 1-6"
                                d="M267.816,81.389a6.259,6.259,0,0,0-5.9,4.1c-.1.2,0,.3.2.4h11.3a.265.265,0,0,0,.3-.3v-.1A6.259,6.259,0,0,0,267.816,81.389Z"
                                fill="#7b7b7b"
                            />
                        </g>
                        <g id="Group_4-2" data-name="Group 4-2">
                            <path
                                id="Path_2-2"
                                data-name="Path 2-2"
                                d="M281.783,106.421c-1.9-2.9-4.4-5.5-4.6-9.3l-19,.1c.1,2.8-1.3,4.8-2.6,6.8a51.979,51.979,0,0,0-4.2,7.2,16.625,16.625,0,0,0,.1,14.9,18.406,18.406,0,0,0,17.5,10.2,18.893,18.893,0,0,0,16.3-12.7C287.283,117.321,285.183,111.621,281.783,106.421Z"
                                fill="#e9346a"
                            />
                        </g>
                        <g id="Group_19" data-name="Group 19">
                            <path
                                id="Path_135"
                                data-name="Path 135"
                                d="M260.359,114.671c-.8.1-1.5.3-2.2.4a1.339,1.339,0,0,1-1.4-.8,1.285,1.285,0,0,1,.6-1.5,1.248,1.248,0,0,1,1.6.3C259.459,113.571,259.859,114.171,260.359,114.671Z"
                                fill="#fff"
                            />
                            <path
                                id="Path_136"
                                data-name="Path 136"
                                d="M275.459,113.871c.5-.8.9-1.5,1.3-2.1a1.1,1.1,0,0,1,1.5-.3,1.274,1.274,0,0,1,.7,1.4,1.39,1.39,0,0,1-1.2,1Z"
                                fill="#fff"
                            />
                            <path
                                id="Path_137"
                                data-name="Path 137"
                                d="M261.259,112.671l-1.5-.9a1.04,1.04,0,0,1-.4-1.2.95.95,0,0,1,1-.6.817.817,0,0,1,.8.9A5.462,5.462,0,0,1,261.259,112.671Z"
                                fill="#fff"
                            />
                            <path
                                id="Path_138"
                                data-name="Path 138"
                                d="M276.559,117.871h0a8.762,8.762,0,0,0-8.3-6.3h-.3a2.361,2.361,0,0,1-.9-.6c-.3-.4-.1-.8.4-1a1.268,1.268,0,0,1,.6-.1.446.446,0,0,1,.3.4c0,.1-.2.2-.3.2v-.1c.1-.4-.4-.5-.6-.2-.1.3-.2.7.2.8a.959.959,0,0,0,.9-.2c.3,0,.4-.4.3-.7a.849.849,0,0,0-.8-.7c-.8-.1-1.8.4-1.8,1.3a1.382,1.382,0,0,0,.6,1.1,8.727,8.727,0,0,0-7,6.2h-.1a2.2,2.2,0,1,0,0,4.4,8.692,8.692,0,0,0,16.8,0h0a2.251,2.251,0,0,0,0-4.5Zm-5.7-1.2h.2a2.732,2.732,0,0,1,1.9.8.494.494,0,0,1,.1.7h0a.483.483,0,0,1-.7,0v-.1a1.553,1.553,0,0,0-1.4-.5.457.457,0,0,1-.5-.4h0c-.1-.2.1-.4.4-.5Zm-7.3.9a2.792,2.792,0,0,1,2-.8c.4,0,.6.2.6.5s-.2.5-.7.5a1.656,1.656,0,0,0-1.2.5.5.5,0,1,1-.8-.6.1.1,0,0,1,.1-.1Zm4.7,8.1c-3,0-5.4-.8-5.4-2.9s2.4-4,5.4-4,5.4,1.8,5.4,4-2.5,2.9-5.4,2.9Z"
                                fill="#fff"
                            />
                        </g>
                    </g>
                </g>
                <g id="Group_1342" data-name="Group 1342">
                    <path
                        id="Path_1599"
                        data-name="Path 1599"
                        d="M232.083,71.585a.422.422,0,0,1-.369-.18.412.412,0,0,1,.018-.451l.693-1.34-2.412-3.87a.4.4,0,0,1-.035-.441.48.48,0,0,1,.656-.1,1.084,1.084,0,0,1,.2.238l2.034,3.321,1.693-3.312a.915.915,0,0,1,.174-.234.409.409,0,0,1,.293-.1.427.427,0,0,1,.37.175.412.412,0,0,1-.028.455l-2.817,5.508a.737.737,0,0,1-.171.234A.421.421,0,0,1,232.083,71.585Z"
                        fill="#6ec2f2"
                    />
                    <path
                        id="Path_1600"
                        data-name="Path 1600"
                        d="M236.232,71.585q-.4,0-.4-.432v-3.6q0-.486.414-.486a.439.439,0,0,1,.306.1.957.957,0,0,1,.181.226l1.439,2.52,1.432-2.52a.93.93,0,0,1,.188-.23.435.435,0,0,1,.3-.094q.414,0,.414.486v3.6q0,.432-.4.432t-.4-.432v-2.61l-1.215,2.106a.537.537,0,0,1-.117.144.29.29,0,0,1-.2.063.274.274,0,0,1-.2-.068.712.712,0,0,1-.112-.139l-1.225-2.106v2.61Q236.628,71.585,236.232,71.585Z"
                        fill="#6ec2f2"
                    />
                    <path
                        id="Path_1601"
                        data-name="Path 1601"
                        d="M243.936,71.6a2.322,2.322,0,0,1-1.7-.608,2.217,2.217,0,0,1-.621-1.66,2.5,2.5,0,0,1,.275-1.193,2,2,0,0,1,.759-.805,2.153,2.153,0,0,1,1.117-.288,1.83,1.83,0,0,1,1.431.58,2.3,2.3,0,0,1,.522,1.6q0,.306-.361.306H242.5a1.3,1.3,0,0,0,1.449,1.368,2.568,2.568,0,0,0,.567-.068,1.8,1.8,0,0,0,.576-.247.471.471,0,0,1,.328-.077.289.289,0,0,1,.2.153.366.366,0,0,1,.023.27.453.453,0,0,1-.212.257,2.255,2.255,0,0,1-.715.31A3.143,3.143,0,0,1,243.936,71.6Zm-.135-3.915a1.185,1.185,0,0,0-.9.35,1.607,1.607,0,0,0-.4.937h2.457a1.491,1.491,0,0,0-.324-.949A1.042,1.042,0,0,0,243.8,67.688Z"
                        fill="#6ec2f2"
                    />
                    <path
                        id="Path_1602"
                        data-name="Path 1602"
                        d="M247.257,71.585a.406.406,0,0,1-.459-.46v-3.6a.455.455,0,1,1,.909,0V68.9h2.187V67.526a.455.455,0,1,1,.909,0v3.6a.455.455,0,1,1-.909,0v-1.5h-2.187v1.5A.4.4,0,0,1,247.257,71.585Z"
                        fill="#6ec2f2"
                    />
                    <path
                        id="Path_1603"
                        data-name="Path 1603"
                        d="M252.585,71.585q-.423,0-.423-.486V67.508q0-.441.414-.441t.414.441v2.808l2.2-2.952a.928.928,0,0,1,.2-.207.52.52,0,0,1,.324-.09q.414,0,.414.486v3.591q0,.441-.405.441c-.283,0-.423-.147-.423-.441v-2.8L253.1,71.3a.979.979,0,0,1-.2.2A.5.5,0,0,1,252.585,71.585Z"
                        fill="#6ec2f2"
                    />
                    <path
                        id="Path_1604"
                        data-name="Path 1604"
                        d="M260.648,71.585a.377.377,0,0,1-.422-.432V69.92h-.567a.928.928,0,0,0-.545.161,1.2,1.2,0,0,0-.364.414l-.478.837a.473.473,0,0,1-.3.244.454.454,0,0,1-.329-.054.466.466,0,0,1-.211-.257.419.419,0,0,1,.048-.355l.361-.612a1.436,1.436,0,0,1,.274-.329,1.159,1.159,0,0,1,.364-.221,1.4,1.4,0,0,1-.854-.418,1.375,1.375,0,0,1,.116-1.836,1.822,1.822,0,0,1,1.234-.364H260.6a.413.413,0,0,1,.467.468v3.555a.4.4,0,0,1-.423.432ZM259.164,69.3h1.062V67.75h-1.062a1.1,1.1,0,0,0-.729.2.7.7,0,0,0-.234.563.727.727,0,0,0,.234.576A1.079,1.079,0,0,0,259.164,69.3Z"
                        fill="#6ec2f2"
                    />
                </g>
                <g id="Group_1348" data-name="Group 1348">
                    <path
                        id="Path_1148"
                        data-name="Path 1148"
                        d="M245,26.9c2.5,0,8.2.2,8,0-.1,1.4,0,3.6.9,4.7,1.8,2.4,6.6,8.1,6.6,13.4a15.5,15.5,0,0,1-31,0c0-5.2,5.8-11.7,6.5-13.4a18.353,18.353,0,0,0,.6-4.7C236.6,27,242.5,26.9,245,26.9Z"
                        fill="#6ec2f2"
                    />
                    <path
                        id="Rectangle_1371"
                        data-name="Rectangle 1371"
                        d="M237,20.4h16a1,1,0,0,1,1,1h0a1,1,0,0,1-1,1H237a1,1,0,0,1-1-1h0A.945.945,0,0,1,237,20.4Z"
                        fill="#707070"
                    />
                    <path
                        id="Rectangle_1372"
                        data-name="Rectangle 1372"
                        d="M238.3,17.2h13.4a1,1,0,0,1,1,1h0a1,1,0,0,1-1,1H238.3a1,1,0,0,1-1-1h0A.945.945,0,0,1,238.3,17.2Z"
                        fill="#707070"
                    />
                    <path
                        id="Rectangle_1373"
                        data-name="Rectangle 1373"
                        d="M237.6,23.6h14.7a1,1,0,0,1,1,1h0a1,1,0,0,1-1,1H237.6a1,1,0,0,1-1-1h0A1.071,1.071,0,0,1,237.6,23.6Z"
                        fill="#707070"
                    />
                    <path
                        id="Subtraction_19"
                        data-name="Subtraction 19"
                        d="M250.5,16.2H239.6a5.663,5.663,0,0,1,7-3.9,5.493,5.493,0,0,1,3.9,3.9Z"
                        fill="#707070"
                    />
                    <g id="Rectangle_1375" data-name="Rectangle 1375">
                        <rect
                            id="fill"
                            width="0.6"
                            height="12.4"
                            transform="translate(244.371 1.011)"
                            fill="#fff"
                        />
                        <g id="Group_1347" data-name="Group 1347">
                            <g id="Group_1346" data-name="Group 1346">
                                <g id="Group_1345" data-name="Group 1345">
                                    <g id="Group_1344" data-name="Group 1344">
                                        <g
                                            id="Group_1343"
                                            data-name="Group 1343"
                                            clipPath="url(#clipPath)"
                                        >
                                            <path
                                                id="Path_1605"
                                                data-name="Path 1605"
                                                d="M244.371,1.511h.6m-.1-.5v12.4m.1-.5h-.6m.1.5V1.011"
                                                fill="none"
                                                stroke="#707070"
                                                strokeWidth="1"
                                            />
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                    <g id="Group_1741" data-name="Group 1741">
                        <path
                            id="Path_1727"
                            data-name="Path 1727"
                            d="M245.772,41.781a.764.764,0,0,0,.5-.2l.3-.6,1.3.3.1.7c0,.2.2.3.4.4a.382.382,0,0,0,.5-.3h0v-.1l-.5-3.8c0-.3-.1-.5-.4-.5-.2-.1-.5.1-.6.3l-1.9,3.3v.1c-.1.1.1.3.3.4Zm1.7-2.5a1.421,1.421,0,0,0,.2-.6v.6l.1,1.1-.9-.2Z"
                            fill="#fff"
                        />
                        <path
                            id="Path_1728"
                            data-name="Path 1728"
                            d="M240.172,45.981l-.5.2-.3-.8,1.4-.5a.55.55,0,0,0,.3-.5h0a.365.365,0,0,0-.5-.2l-1.7.6a.486.486,0,0,0-.3.6l1.2,3.4c.1.2.4.4.6.3l.9-.3a1.432,1.432,0,0,0-.6-2.8.781.781,0,0,0-.5,0Zm.7,1.9-.5.2-.4-1.2.5-.2a.593.593,0,0,1,.9.3.682.682,0,0,1-.5.9Z"
                            fill="#fff"
                        />
                        <path
                            id="Path_1729"
                            data-name="Path 1729"
                            d="M251.772,43.981h-1.1a.908.908,0,0,0,.3-.7l1-5.2a1.556,1.556,0,0,0-1.1-1.7l-5.2-1a1.466,1.466,0,0,0-1.7,1.1h0l-1,5.2v.4a1.258,1.258,0,0,0-1.3-.2l-5,1.7a1.556,1.556,0,0,0-.9,1.8l1.7,5a1.556,1.556,0,0,0,1.8.9l5-1.7a1.057,1.057,0,0,0,.7-.6v1.6a1.367,1.367,0,0,0,1.4,1.4h5.3a1.367,1.367,0,0,0,1.4-1.4v-5.2A1.236,1.236,0,0,0,251.772,43.981Zm-7.5-2.7.7-3.8a1.012,1.012,0,0,1,1.2-.8l3.8.7a1.012,1.012,0,0,1,.8,1.2h0l-.7,3.8a1.012,1.012,0,0,1-1.2.8h0l-3.8-.7a.967.967,0,0,1-.8-1.2Zm-1,7.6-3.6,1.2a1.04,1.04,0,0,1-1.3-.6h0l-1.2-3.6a1.04,1.04,0,0,1,.6-1.3h0l3.6-1.2a1.04,1.04,0,0,1,1.3.6l1.2,3.6a1.04,1.04,0,0,1-.6,1.3Zm1.8-3.5v2l-1.4-4.2c.2.1.3.2.5.2l2.8.5h-.5a1.52,1.52,0,0,0-1.4,1.5Zm7,4.6a1,1,0,0,1-1,1h-3.9a1,1,0,0,1-1-1h0v-3.8a1,1,0,0,1,1-1h3.8a1,1,0,0,1,1,1h0l.1,3.8Z"
                            fill="#fff"
                        />
                        <path
                            id="Path_1730"
                            data-name="Path 1730"
                            d="M250.072,47.981a1.107,1.107,0,0,0,.7-.9,1.181,1.181,0,0,0-1.3-1.2h-1.2a.472.472,0,0,0-.5.5h0v3.5a.472.472,0,0,0,.5.5h1a1.383,1.383,0,0,0,1.6-1.2v-.1A1.011,1.011,0,0,0,250.072,47.981Zm-1.4-1.3h.8a.366.366,0,0,1,.4.4v.1a.472.472,0,0,1-.5.5h-.7Zm.7,2.8h-.7v-1.1h.7a.56.56,0,0,1,.6.5v.1c0,.3-.2.5-.6.5Z"
                            fill="#fff"
                        />
                    </g>
                </g>
                <g id="Group_1349" data-name="Group 1349">
                    <path
                        id="Path_1606"
                        data-name="Path 1606"
                        d="M281,82.147a.418.418,0,0,1-.469-.477V76.143a.468.468,0,0,1,.937,0V78.5h1.016l1.675-2.583a.479.479,0,0,1,.3-.235.458.458,0,0,1,.324.05.426.426,0,0,1,.2.248.434.434,0,0,1-.078.36l-1.538,2.313a1.784,1.784,0,0,1,.665.445,3.069,3.069,0,0,1,.514.751l.8,1.648a.423.423,0,0,1,.04.387.428.428,0,0,1-.242.225.51.51,0,0,1-.347,0,.471.471,0,0,1-.279-.266l-.756-1.61a1.768,1.768,0,0,0-.568-.733,1.378,1.378,0,0,0-.8-.221h-.927V81.67A.418.418,0,0,1,281,82.147Z"
                        fill="#51cead"
                    />
                    <path
                        id="Path_1607"
                        data-name="Path 1607"
                        d="M288.231,82.165a2.269,2.269,0,0,1-1.151-.279,1.906,1.906,0,0,1-.752-.788,2.885,2.885,0,0,1,0-2.42,1.905,1.905,0,0,1,.752-.788,2.269,2.269,0,0,1,1.151-.279,2.233,2.233,0,0,1,1.134.279,1.942,1.942,0,0,1,.757.788,2.846,2.846,0,0,1,0,2.42,1.942,1.942,0,0,1-.757.788A2.233,2.233,0,0,1,288.231,82.165Zm0-.7a1.12,1.12,0,0,0,.9-.406,2.162,2.162,0,0,0,0-2.334,1.222,1.222,0,0,0-1.81,0,2.162,2.162,0,0,0,0,2.334A1.131,1.131,0,0,0,288.231,81.463Z"
                        fill="#51cead"
                    />
                    <path
                        id="Path_1608"
                        data-name="Path 1608"
                        d="M291.967,82.147a.406.406,0,0,1-.459-.46v-3.6a.455.455,0,1,1,.909,0v1.377H294.6V78.088a.455.455,0,1,1,.909,0v3.6a.455.455,0,1,1-.909,0v-1.5h-2.187v1.5A.4.4,0,0,1,291.967,82.147Z"
                        fill="#51cead"
                    />
                    <path
                        id="Path_1609"
                        data-name="Path 1609"
                        d="M298.815,82.165a2.3,2.3,0,0,1-1.165-.283,1.935,1.935,0,0,1-.76-.8,2.6,2.6,0,0,1-.27-1.219,2.241,2.241,0,0,1,.594-1.647,2.144,2.144,0,0,1,1.6-.6,2.529,2.529,0,0,1,.663.094,1.992,1.992,0,0,1,.643.31.333.333,0,0,1,.166.23.4.4,0,0,1-.045.262.335.335,0,0,1-.193.162.365.365,0,0,1-.288-.05,1.509,1.509,0,0,0-.873-.288,1.237,1.237,0,0,0-.986.4,1.689,1.689,0,0,0-.346,1.139,1.76,1.76,0,0,0,.346,1.156,1.206,1.206,0,0,0,.986.419,1.555,1.555,0,0,0,.427-.063,1.573,1.573,0,0,0,.446-.216.336.336,0,0,1,.283-.045.317.317,0,0,1,.181.166.432.432,0,0,1,.035.262.334.334,0,0,1-.166.229,1.916,1.916,0,0,1-.626.3A2.532,2.532,0,0,1,298.815,82.165Z"
                        fill="#51cead"
                    />
                    <path
                        id="Path_1610"
                        data-name="Path 1610"
                        d="M302.227,83.766a.354.354,0,0,1-.347-.184.478.478,0,0,1,.014-.427l.531-1.188-1.6-3.717a.467.467,0,0,1-.005-.432.424.424,0,0,1,.41-.189.417.417,0,0,1,.265.081.672.672,0,0,1,.185.279l1.233,3.042,1.251-3.042a.607.607,0,0,1,.189-.284.5.5,0,0,1,.3-.076.333.333,0,0,1,.333.189.494.494,0,0,1-.008.423l-2.25,5.175a.623.623,0,0,1-.2.279A.539.539,0,0,1,302.227,83.766Z"
                        fill="#51cead"
                    />
                    <path
                        id="Path_1611"
                        data-name="Path 1611"
                        d="M309.13,82.147a.4.4,0,0,1-.45-.46V78.421h-1.593v.531a5.784,5.784,0,0,1-.3,2.079,1.914,1.914,0,0,1-.99,1.062.444.444,0,0,1-.342.022.409.409,0,0,1-.226-.193.388.388,0,0,1-.031-.288.449.449,0,0,1,.221-.261,1.387,1.387,0,0,0,.652-.842,5.963,5.963,0,0,0,.166-1.606v-.72a.454.454,0,0,1,.514-.513h2.321a.454.454,0,0,1,.514.513v3.482A.406.406,0,0,1,309.13,82.147Z"
                        fill="#51cead"
                    />
                    <path
                        id="Path_1612"
                        data-name="Path 1612"
                        d="M312.333,82.147a.406.406,0,0,1-.459-.46V78.421H310.65q-.4,0-.4-.369t.4-.36h3.357q.4,0,.4.36t-.4.369h-1.224v3.266A.4.4,0,0,1,312.333,82.147Z"
                        fill="#51cead"
                    />
                    <path
                        id="Path_1613"
                        data-name="Path 1613"
                        d="M316.482,82.165a1.823,1.823,0,0,1-.819-.18,1.486,1.486,0,0,1-.571-.486,1.189,1.189,0,0,1-.211-.693,1.11,1.11,0,0,1,.247-.765,1.5,1.5,0,0,1,.819-.4,7.484,7.484,0,0,1,1.543-.126h.307v-.234a1.037,1.037,0,0,0-.217-.738.952.952,0,0,0-.711-.226,2.653,2.653,0,0,0-.6.073,2.752,2.752,0,0,0-.643.243.346.346,0,0,1-.311.027.336.336,0,0,1-.167-.2.407.407,0,0,1,.009-.279.383.383,0,0,1,.226-.216,3.979,3.979,0,0,1,.783-.266,3.439,3.439,0,0,1,.728-.085,1.835,1.835,0,0,1,1.333.427,1.812,1.812,0,0,1,.431,1.337v2.312c0,.307-.14.46-.423.46s-.431-.153-.431-.46v-.332a1.3,1.3,0,0,1-.5.594A1.506,1.506,0,0,1,316.482,82.165Zm.18-.649a1.071,1.071,0,0,0,.811-.341,1.211,1.211,0,0,0,.324-.865v-.242h-.3a6.538,6.538,0,0,0-1.03.063,1.015,1.015,0,0,0-.531.212.533.533,0,0,0-.158.409.709.709,0,0,0,.235.554A.935.935,0,0,0,316.662,81.516Z"
                        fill="#51cead"
                    />
                    <path
                        id="Path_1614"
                        data-name="Path 1614"
                        d="M324.2,83.317a.346.346,0,0,1-.386-.4v-.837H320.47a.405.405,0,0,1-.459-.459V78.088a.405.405,0,0,1,.459-.459.4.4,0,0,1,.449.459v3.276h2.053V78.088a.455.455,0,1,1,.909,0v3.276h.269a.382.382,0,0,1,.432.432v1.125A.346.346,0,0,1,324.2,83.317Z"
                        fill="#51cead"
                    />
                    <path
                        id="Path_1615"
                        data-name="Path 1615"
                        d="M325.914,82.147q-.423,0-.423-.486V78.07q0-.441.414-.441c.277,0,.414.147.414.441v2.808l2.2-2.952a.929.929,0,0,1,.2-.207.515.515,0,0,1,.323-.09q.414,0,.414.486v3.591q0,.441-.4.441c-.283,0-.423-.147-.423-.441v-2.8l-2.2,2.952a1.037,1.037,0,0,1-.2.2A.5.5,0,0,1,325.914,82.147Z"
                        fill="#51cead"
                    />
                    <path
                        id="Path_1616"
                        data-name="Path 1616"
                        d="M331.251,82.147q-.423,0-.423-.486V78.07q0-.441.414-.441c.277,0,.414.147.414.441v2.808l2.2-2.952a.907.907,0,0,1,.2-.207.515.515,0,0,1,.323-.09q.414,0,.414.486v3.591q0,.441-.4.441c-.283,0-.423-.147-.423-.441v-2.8l-2.2,2.952a1.038,1.038,0,0,1-.2.2A.5.5,0,0,1,331.251,82.147Z"
                        fill="#51cead"
                    />
                </g>
                <g id="Group_1766" data-name="Group 1766">
                    <g id="Group_1-3" data-name="Group 1-3">
                        <rect
                            id="Rectangle_1-6"
                            data-name="Rectangle 1-6"
                            width="0.9"
                            height="26.2"
                            transform="translate(304.984 1)"
                            fill="#7b7b7b"
                        />
                        <path
                            id="Rectangle_2-7"
                            data-name="Rectangle 2-7"
                            d="M299.484,29.9h12a1,1,0,0,1,1,1h0a1,1,0,0,1-1,1h-12a1,1,0,0,1-1-1h0A1,1,0,0,1,299.484,29.9Z"
                            fill="#7b7b7b"
                        />
                        <path
                            id="Rectangle_3-7"
                            data-name="Rectangle 3-7"
                            d="M297.984,32.8h15.1a1,1,0,0,1,1,1h0a1,1,0,0,1-1,1h-15.1a1,1,0,0,1-1-1h0C296.884,33.2,297.384,32.8,297.984,32.8Z"
                            fill="#7b7b7b"
                        />
                        <path
                            id="Rectangle_4-7"
                            data-name="Rectangle 4-7"
                            d="M298.384,35.6h14.1a1,1,0,0,1,1,1h0a1,1,0,0,1-1,1h-14.1a1,1,0,0,1-1-1h0A1.075,1.075,0,0,1,298.384,35.6Z"
                            fill="#7b7b7b"
                        />
                        <path
                            id="Path_1-7"
                            data-name="Path 1-7"
                            d="M305.484,25.3a5.3,5.3,0,0,0-5,3.4c0,.1,0,.3.2.3h9.5a.319.319,0,0,0,.3-.3v-.1A5.213,5.213,0,0,0,305.484,25.3Z"
                            fill="#7b7b7b"
                        />
                    </g>
                    <path
                        id="Path_2-3"
                        data-name="Path 2-3"
                        d="M317.3,46.4c-1.6-2.5-3.7-4.6-3.9-7.9l-16.1.1c.1,2.3-1.1,4-2.2,5.8a61.1,61.1,0,0,0-3.6,6.1,14.192,14.192,0,0,0,.1,12.7,15.542,15.542,0,0,0,28.7-2.1C322,55.6,320.2,50.9,317.3,46.4Z"
                        fill="#51cead"
                    />
                    <g id="Group_1743" data-name="Group 1743">
                        <path
                            id="Path_1731"
                            data-name="Path 1731"
                            d="M308.03,49.867c0,.5,0,.5-.3.5h-.1c-.2,0-.2-.1-.2-.2v-.6a2.567,2.567,0,0,0-.5-1.2c-.2-.2-.2-.2-.4,0a1.059,1.059,0,0,1-1.3.1,1.021,1.021,0,0,1-.2-1.5v-.1a.967.967,0,0,1,1.4,0l.2.2c.1.1.1.3.2.4s.1.2.2.2a1.764,1.764,0,0,1,.8.9A6.882,6.882,0,0,1,308.03,49.867Z"
                            fill="#fff"
                        />
                        <path
                            id="Path_1732"
                            data-name="Path 1732"
                            d="M299.23,49.967h0c0,.4,0,.4-.3.4s-.3,0-.3-.3a4.013,4.013,0,0,1,.3-1.5,1.891,1.891,0,0,1,.8-1c.1-.1.2-.2.2-.3a.675.675,0,0,1,.4-.6.984.984,0,0,1,1.4.3,1.059,1.059,0,0,1-.1,1.3,1.129,1.129,0,0,1-1.1.3.758.758,0,0,0-.4-.2.188.188,0,0,0-.3,0h0a2.044,2.044,0,0,0-.5.9c0,.3-.1.5-.1.7Z"
                            fill="#fff"
                        />
                        <g id="Group_1742" data-name="Group 1742">
                            <path
                                id="Path_1733"
                                data-name="Path 1733"
                                d="M314.73,57.689a1.854,1.854,0,0,0-.2-.7v-.2a3.075,3.075,0,0,0-1.8-1.9.349.349,0,0,1-.2-.1,1.087,1.087,0,0,0-.5-.1,3.415,3.415,0,0,0-2.4.7,3.175,3.175,0,0,0-.4,4.5,3.412,3.412,0,0,0,1.8,1.1h0v.1l-.3.6a4.573,4.573,0,0,1-1.6,1.5,4.013,4.013,0,0,1-1.5.3h-.2a.367.367,0,0,1-.3-.1,2.92,2.92,0,0,1-.9-.2,1.8,1.8,0,0,1-.9-.6,3.553,3.553,0,0,1-1.1-1.6v-.1a3.553,3.553,0,0,1-.2-1v-2.2a5.032,5.032,0,0,0,2.9-1.2,3.814,3.814,0,0,0,1.1-1.4,3.552,3.552,0,0,0,.4-.9,1.854,1.854,0,0,0,.2-.7c0-.4.1-.8.1-1.2v-1.3c0-.2-.1-.4-.3-.4h-1.1a.265.265,0,0,0-.3.3v1a7.568,7.568,0,0,1-.1,1.5,3.379,3.379,0,0,1-.6,1.2,3.482,3.482,0,0,1-1.3,1.1,2.934,2.934,0,0,1-1.6.4,5.019,5.019,0,0,1-1.2-.2,4.073,4.073,0,0,1-2.1-1.9,5.731,5.731,0,0,1-.4-1.4v-1.6c0-.2,0-.3-.1-.4s-.2-.2-.3-.2h0c-.3,0-.6.1-.9.1-.1,0-.2.1-.3.2,0,.2-.1.3-.1.4v1.1a8.806,8.806,0,0,0,.2,1.7c0,.1.1.2.1.3.1.2.2.5.3.7a4.013,4.013,0,0,0,1.3,1.6,6.072,6.072,0,0,0,1,.7l.6.3.2.1c.1,0,.3.1.4.1l.2.1v1.8a3.749,3.749,0,0,0,.1,1.1,4.419,4.419,0,0,0,.6,1.6,4.7,4.7,0,0,0,.9,1.2,3.592,3.592,0,0,0,1,.7,3.553,3.553,0,0,0,.9.4,2.92,2.92,0,0,0,.9.2h.8a5.852,5.852,0,0,0,1.3-.2,6.116,6.116,0,0,0,1.2-.5,4.44,4.44,0,0,0,1.1-.8,4.508,4.508,0,0,0,1-1.4,4.875,4.875,0,0,0,.3-.7l.1-.2c0-.1,0-.1.1-.2,0-.1,0-.1.1-.2.1,0,.1-.1.2-.1.1-.1.2-.1.3-.2a3.09,3.09,0,0,0,1.5-2.2Zm-1.3.1a1.95,1.95,0,0,1-1.8,1.9h-.1a1.9,1.9,0,0,1,0-3.8A1.965,1.965,0,0,1,313.43,57.789Z"
                                fill="#fff"
                            />
                            <path
                                id="Path_1734"
                                data-name="Path 1734"
                                d="M310.33,57.889a1.3,1.3,0,0,1,.8-1.2c.1,0,.2-.1.4-.1.1,0,.2.1.1.2l-.3.6a1.269,1.269,0,0,0-.1.6c.1.3.2.5.3.8v.2a.349.349,0,0,1-.2.1.961.961,0,0,1-.9-.7C310.43,58.189,310.33,58.089,310.33,57.889Z"
                                fill="#fff"
                            />
                        </g>
                    </g>
                </g>
                <g id="Group_1350" data-name="Group 1350">
                    <path
                        id="Path_1617"
                        data-name="Path 1617"
                        d="M196.881,113.661a.418.418,0,0,1-.469-.477V107.73a.422.422,0,0,1,.478-.477h2.151a2.269,2.269,0,0,1,1.584.508,2.114,2.114,0,0,1,0,2.862,2.269,2.269,0,0,1-1.584.509h-1.692v2.052A.418.418,0,0,1,196.881,113.661Zm.468-3.276H198.9q1.368,0,1.368-1.2T198.9,108h-1.556Z"
                        fill="#faaa0e"
                    />
                    <path
                        id="Path_1618"
                        data-name="Path 1618"
                        d="M203.441,113.679a1.81,1.81,0,0,1-.819-.18,1.486,1.486,0,0,1-.571-.486,1.19,1.19,0,0,1-.211-.693,1.112,1.112,0,0,1,.247-.765,1.5,1.5,0,0,1,.819-.4,7.484,7.484,0,0,1,1.543-.126h.307v-.234a1.04,1.04,0,0,0-.217-.738.952.952,0,0,0-.711-.225,2.65,2.65,0,0,0-.6.072,2.8,2.8,0,0,0-.643.243.343.343,0,0,1-.311.027.336.336,0,0,1-.167-.2.407.407,0,0,1,.009-.279.381.381,0,0,1,.226-.216,3.979,3.979,0,0,1,.783-.266,3.439,3.439,0,0,1,.728-.085,1.831,1.831,0,0,1,1.332.427,1.809,1.809,0,0,1,.432,1.337V113.2c0,.306-.14.459-.423.459s-.431-.153-.431-.459v-.333a1.3,1.3,0,0,1-.5.594A1.506,1.506,0,0,1,203.441,113.679Zm.18-.648a1.072,1.072,0,0,0,.811-.342,1.211,1.211,0,0,0,.324-.864v-.243h-.3a6.538,6.538,0,0,0-1.03.063,1.015,1.015,0,0,0-.531.212.533.533,0,0,0-.158.409.709.709,0,0,0,.235.554A.935.935,0,0,0,203.621,113.031Z"
                        fill="#faaa0e"
                    />
                    <path
                        id="Path_1619"
                        data-name="Path 1619"
                        d="M208.913,113.679a2.3,2.3,0,0,1-1.165-.283,1.944,1.944,0,0,1-.761-.8,2.612,2.612,0,0,1-.269-1.219,2.241,2.241,0,0,1,.594-1.647,2.147,2.147,0,0,1,1.6-.6,2.558,2.558,0,0,1,.662.094,1.989,1.989,0,0,1,.644.311.332.332,0,0,1,.166.229.4.4,0,0,1-.045.262.333.333,0,0,1-.194.162.366.366,0,0,1-.288-.05,1.508,1.508,0,0,0-.441-.221,1.531,1.531,0,0,0-.432-.067,1.234,1.234,0,0,0-.985.4,1.69,1.69,0,0,0-.347,1.139A1.76,1.76,0,0,0,208,112.54a1.2,1.2,0,0,0,.985.419,1.557,1.557,0,0,0,.428-.063,1.53,1.53,0,0,0,.445-.216.338.338,0,0,1,.284-.045.315.315,0,0,1,.18.166.425.425,0,0,1,.035.262.326.326,0,0,1-.166.229,1.9,1.9,0,0,1-.626.3A2.519,2.519,0,0,1,208.913,113.679Z"
                        fill="#faaa0e"
                    />
                    <path
                        id="Path_1620"
                        data-name="Path 1620"
                        d="M212.657,113.661a.406.406,0,0,1-.459-.459v-3.267h-1.223q-.4,0-.4-.369c0-.239.132-.36.4-.36h3.356c.265,0,.4.121.4.36s-.132.369-.4.369h-1.224V113.2A.4.4,0,0,1,212.657,113.661Z"
                        fill="#faaa0e"
                    />
                    <path
                        id="Path_1621"
                        data-name="Path 1621"
                        d="M217.437,113.679a2.33,2.33,0,0,1-1.7-.607,2.219,2.219,0,0,1-.621-1.661,2.512,2.512,0,0,1,.275-1.193,2.007,2.007,0,0,1,.76-.805,2.153,2.153,0,0,1,1.117-.288,1.828,1.828,0,0,1,1.43.58,2.3,2.3,0,0,1,.523,1.6q0,.306-.361.306H216a1.3,1.3,0,0,0,1.449,1.368,2.635,2.635,0,0,0,.568-.067,1.776,1.776,0,0,0,.575-.248.474.474,0,0,1,.329-.077.291.291,0,0,1,.2.154.359.359,0,0,1,.023.269.451.451,0,0,1-.211.257,2.274,2.274,0,0,1-.716.311A3.18,3.18,0,0,1,217.437,113.679Zm-.135-3.915a1.184,1.184,0,0,0-.9.351,1.608,1.608,0,0,0-.4.936h2.457a1.488,1.488,0,0,0-.324-.949A1.04,1.04,0,0,0,217.3,109.764Z"
                        fill="#faaa0e"
                    />
                    <path
                        id="Path_1622"
                        data-name="Path 1622"
                        d="M223.232,113.661a.4.4,0,0,1-.45-.459v-1.5h-.512a1,1,0,0,0-.536.122,1.375,1.375,0,0,0-.4.463l-.657,1.125a.423.423,0,0,1-.28.234.5.5,0,0,1-.324-.036.421.421,0,0,1-.216-.225.367.367,0,0,1,.054-.342l.6-1.008a3.59,3.59,0,0,1,.391-.558,1.185,1.185,0,0,1,.41-.306l-1.036-1.35a.423.423,0,0,1-.108-.328.367.367,0,0,1,.158-.248.405.405,0,0,1,.292-.068.468.468,0,0,1,.3.194l1.242,1.62h.63V109.62a.455.455,0,1,1,.909,0v1.368h.621l1.243-1.62a.414.414,0,0,1,.292-.2.425.425,0,0,1,.293.068.363.363,0,0,1,.157.243.424.424,0,0,1-.1.337l-1.035,1.359a1.105,1.105,0,0,1,.41.292,3.421,3.421,0,0,1,.392.563l.594,1.008a.379.379,0,0,1,.045.342.42.42,0,0,1-.212.225.455.455,0,0,1-.315.031.466.466,0,0,1-.284-.229l-.656-1.125a1.4,1.4,0,0,0-.4-.463.983.983,0,0,0-.527-.122h-.513v1.5A.406.406,0,0,1,223.232,113.661Z"
                        fill="#faaa0e"
                    />
                </g>
                <g id="Group_1351" data-name="Group 1351">
                    <g id="Group_1754" data-name="Group 1754">
                        <rect
                            id="Rectangle_1-7"
                            data-name="Rectangle 1-7"
                            width="0.9"
                            height="53.2"
                            transform="translate(209.82 1)"
                            fill="#7b7b7b"
                        />
                        <path
                            id="Rectangle_2-8"
                            data-name="Rectangle 2-8"
                            d="M203.82,57.2h13a1.11,1.11,0,0,1,1.1,1.1h0a1.11,1.11,0,0,1-1.1,1.1h-13a1.11,1.11,0,0,1-1.1-1.1h0A1.11,1.11,0,0,1,203.82,57.2Z"
                            fill="#7b7b7b"
                        />
                        <path
                            id="Rectangle_3-8"
                            data-name="Rectangle 3-8"
                            d="M202.22,60.3h16.3a1.11,1.11,0,0,1,1.1,1.1h0a1.11,1.11,0,0,1-1.1,1.1h-16.3a1.11,1.11,0,0,1-1.1-1.1h0A1.11,1.11,0,0,1,202.22,60.3Z"
                            fill="#7b7b7b"
                        />
                        <path
                            id="Rectangle_4-8"
                            data-name="Rectangle 4-8"
                            d="M202.72,63.4h15.2a1.11,1.11,0,0,1,1.1,1.1h0a1.11,1.11,0,0,1-1.1,1.1h-15.2a1.11,1.11,0,0,1-1.1-1.1h0A1.11,1.11,0,0,1,202.72,63.4Z"
                            fill="#7b7b7b"
                        />
                        <path
                            id="Path_1-8"
                            data-name="Path 1-8"
                            d="M210.32,52.2a5.5,5.5,0,0,0-5.3,3.7c-.1.1,0,.3.2.4h10.3a.265.265,0,0,0,.3-.3v-.1A5.8,5.8,0,0,0,210.32,52.2Z"
                            fill="#7b7b7b"
                        />
                    </g>
                    <path
                        id="Path_1739"
                        data-name="Path 1739"
                        d="M223,75c-1.7-2.6-4-5-4.2-8.5l-17.4.1c.1,2.5-1.2,4.3-2.4,6.2a71.562,71.562,0,0,0-3.9,6.6,15.143,15.143,0,0,0,.1,13.6,16.7,16.7,0,0,0,30.9-2.3C228.1,84.9,226.2,79.8,223,75Z"
                        fill="#faaa0e"
                    />
                    <g id="Group_1761" data-name="Group 1761">
                        <g id="Group_96" data-name="Group 96">
                            <path
                                id="Path_340"
                                data-name="Path 340"
                                d="M216.925,92.194a6.874,6.874,0,0,1-3,2.6,3.079,3.079,0,0,1-1.3.3,1.383,1.383,0,0,1-1.6-1.2,1.949,1.949,0,0,1,.1-.8,4.2,4.2,0,0,1,.9-1.4,2.5,2.5,0,0,0,.8-1.8,5.662,5.662,0,0,0-.3-1.8,2.476,2.476,0,0,1,1.5-3,2.292,2.292,0,0,1,1.8.2,3.378,3.378,0,0,1,2.3,2.2,4.27,4.27,0,0,1-.1,2.5A15.053,15.053,0,0,1,216.925,92.194Z"
                                fill="#fff"
                            />
                            <path
                                id="Path_341"
                                data-name="Path 341"
                                d="M212.125,84.694a1.266,1.266,0,0,1-.2-1.8,1.131,1.131,0,0,1,1.6-.3h0a1.388,1.388,0,0,1,.2,1.8,1.114,1.114,0,0,1-1.6.3Z"
                                fill="#fff"
                            />
                            <path
                                id="Path_342"
                                data-name="Path 342"
                                d="M216.125,84.394a.856.856,0,0,1-1.2.3h0a1.075,1.075,0,0,1-.2-1.4.954.954,0,0,1,1.2-.3h0a1.058,1.058,0,0,1,.2,1.4Z"
                                fill="#fff"
                            />
                            <path
                                id="Path_343"
                                data-name="Path 343"
                                d="M216.725,84.294a.71.71,0,0,1,1.1-.2,1.063,1.063,0,0,1,.2,1.2.83.83,0,0,1-1.2.2.925.925,0,0,1-.1-1.2Z"
                                fill="#fff"
                            />
                            <path
                                id="Path_344"
                                data-name="Path 344"
                                d="M218.225,86.794a.751.751,0,0,1-.2-1v-.1a.777.777,0,0,1,1-.3h0a.775.775,0,0,1,.2,1A.687.687,0,0,1,218.225,86.794Z"
                                fill="#fff"
                            />
                            <path
                                id="Path_345"
                                data-name="Path 345"
                                d="M219.525,87.294a.633.633,0,0,1,.1.9.651.651,0,0,1-.9.2.633.633,0,0,1-.1-.9.765.765,0,0,1,.9-.2Z"
                                fill="#fff"
                            />
                        </g>
                        <g id="Group_97" data-name="Group 97">
                            <path
                                id="Path_346"
                                data-name="Path 346"
                                d="M204.02,85.025a6.874,6.874,0,0,0,3,2.6,3.079,3.079,0,0,0,1.3.3,1.383,1.383,0,0,0,1.6-1.2,1.95,1.95,0,0,0-.1-.8,4.2,4.2,0,0,0-.9-1.4,2.5,2.5,0,0,1-.8-1.8,5.663,5.663,0,0,1,.3-1.8,2.476,2.476,0,0,0-1.5-3,2.292,2.292,0,0,0-1.8.2,3.378,3.378,0,0,0-2.3,2.2,4.27,4.27,0,0,0,.1,2.5A9.061,9.061,0,0,0,204.02,85.025Z"
                                fill="#fff"
                            />
                            <path
                                id="Path_347"
                                data-name="Path 347"
                                d="M208.72,77.525a1.363,1.363,0,0,0,.2-1.8,1.131,1.131,0,0,0-1.6-.3h0a1.388,1.388,0,0,0-.2,1.8,1.114,1.114,0,0,0,1.6.3Z"
                                fill="#fff"
                            />
                            <path
                                id="Path_348"
                                data-name="Path 348"
                                d="M204.72,77.225a.856.856,0,0,0,1.2.3h0a1.075,1.075,0,0,0,.2-1.4.954.954,0,0,0-1.2-.3h0a1.075,1.075,0,0,0-.2,1.4Z"
                                fill="#fff"
                            />
                            <path
                                id="Path_349"
                                data-name="Path 349"
                                d="M204.12,77.125a.71.71,0,0,0-1.1-.2,1.063,1.063,0,0,0-.2,1.2.823.823,0,1,0,1.3-1Z"
                                fill="#fff"
                            />
                            <path
                                id="Path_350"
                                data-name="Path 350"
                                d="M202.62,79.625a.751.751,0,0,0,.2-1v-.1a.777.777,0,0,0-1-.3h0a.775.775,0,0,0-.2,1A.687.687,0,0,0,202.62,79.625Z"
                                fill="#fff"
                            />
                            <path
                                id="Path_351"
                                data-name="Path 351"
                                d="M201.32,80.125a.633.633,0,0,0-.1.9.651.651,0,0,0,.9.2.633.633,0,0,0,.1-.9c-.1-.2-.5-.3-.9-.2Z"
                                fill="#fff"
                            />
                        </g>
                    </g>
                </g>
                <g id="Group_1352" data-name="Group 1352">
                    <path
                        id="Path_1623"
                        data-name="Path 1623"
                        d="M132.225,78.58a3.224,3.224,0,0,1-1.647-.4,2.618,2.618,0,0,1-1.049-1.129,4.3,4.3,0,0,1,0-3.461,2.636,2.636,0,0,1,1.049-1.125,3.234,3.234,0,0,1,1.647-.4,3.6,3.6,0,0,1,1.021.143,2.7,2.7,0,0,1,.851.414.46.46,0,0,1,.229.3.432.432,0,0,1-.054.314.4.4,0,0,1-.248.185.43.43,0,0,1-.359-.09,2.216,2.216,0,0,0-.662-.333,2.6,2.6,0,0,0-.752-.107,1.946,1.946,0,0,0-1.565.63,3.247,3.247,0,0,0,0,3.586,1.942,1.942,0,0,0,1.565.634,2.661,2.661,0,0,0,.761-.108,2.265,2.265,0,0,0,.689-.342.445.445,0,0,1,.351-.085.379.379,0,0,1,.234.175.441.441,0,0,1,.053.3.422.422,0,0,1-.189.283,2.649,2.649,0,0,1-.877.45A3.529,3.529,0,0,1,132.225,78.58Z"
                        fill="#b564a4"
                    />
                    <path
                        id="Path_1624"
                        data-name="Path 1624"
                        d="M135.87,78.563a.406.406,0,0,1-.459-.46V74.62a.453.453,0,0,1,.512-.512h2.952a.453.453,0,0,1,.514.512V78.1a.455.455,0,1,1-.91,0V74.836h-2.16V78.1A.4.4,0,0,1,135.87,78.563Z"
                        fill="#b564a4"
                    />
                    <path
                        id="Path_1625"
                        data-name="Path 1625"
                        d="M142.665,78.58a2.26,2.26,0,0,1-1.152-.279,1.891,1.891,0,0,1-.752-.787,2.9,2.9,0,0,1,0-2.421,1.905,1.905,0,0,1,.752-.788,2.27,2.27,0,0,1,1.152-.278,2.231,2.231,0,0,1,1.133.278,1.936,1.936,0,0,1,.757.788,2.848,2.848,0,0,1,0,2.421,1.921,1.921,0,0,1-.757.787A2.221,2.221,0,0,1,142.665,78.58Zm0-.7a1.115,1.115,0,0,0,.9-.4,1.765,1.765,0,0,0,.342-1.17,1.747,1.747,0,0,0-.342-1.165,1.123,1.123,0,0,0-.9-.4,1.137,1.137,0,0,0-.91.4,1.747,1.747,0,0,0-.341,1.165,1.765,1.765,0,0,0,.341,1.17A1.129,1.129,0,0,0,142.665,77.878Z"
                        fill="#b564a4"
                    />
                    <path
                        id="Path_1626"
                        data-name="Path 1626"
                        d="M146.345,78.563c-.27,0-.4-.145-.4-.433v-3.6q0-.487.414-.487a.441.441,0,0,1,.306.1.934.934,0,0,1,.18.226l1.44,2.519,1.431-2.519a.957.957,0,0,1,.189-.23.436.436,0,0,1,.3-.1q.414,0,.414.487v3.6c0,.288-.134.433-.4.433s-.4-.145-.4-.433V75.521l-1.216,2.1a.557.557,0,0,1-.116.145.3.3,0,0,1-.2.062.274.274,0,0,1-.2-.067.8.8,0,0,1-.113-.14l-1.224-2.1V78.13C146.741,78.418,146.609,78.563,146.345,78.563Z"
                        fill="#b564a4"
                    />
                    <path
                        id="Path_1627"
                        data-name="Path 1627"
                        d="M154.049,78.58a2.325,2.325,0,0,1-1.7-.607,2.217,2.217,0,0,1-.621-1.66A2.516,2.516,0,0,1,152,75.119a2,2,0,0,1,.76-.8,2.138,2.138,0,0,1,1.116-.288,1.83,1.83,0,0,1,1.432.58,2.308,2.308,0,0,1,.522,1.6c0,.2-.121.306-.361.306h-2.862a1.3,1.3,0,0,0,1.45,1.368,2.556,2.556,0,0,0,.566-.067,1.791,1.791,0,0,0,.576-.247.469.469,0,0,1,.329-.077.287.287,0,0,1,.2.153.361.361,0,0,1,.023.27.451.451,0,0,1-.212.256,2.246,2.246,0,0,1-.715.311A3.182,3.182,0,0,1,154.049,78.58Zm-.134-3.915a1.184,1.184,0,0,0-.9.351,1.6,1.6,0,0,0-.406.937h2.457a1.494,1.494,0,0,0-.324-.95A1.043,1.043,0,0,0,153.915,74.665Z"
                        fill="#b564a4"
                    />
                    <path
                        id="Path_1628"
                        data-name="Path 1628"
                        d="M157.37,78.563a.406.406,0,0,1-.459-.46V74.5a.455.455,0,1,1,.909,0V75.88h2.187V74.5a.455.455,0,1,1,.909,0v3.6a.455.455,0,1,1-.909,0V76.6H157.82v1.5A.4.4,0,0,1,157.37,78.563Z"
                        fill="#b564a4"
                    />
                    <path
                        id="Path_1629"
                        data-name="Path 1629"
                        d="M162.7,78.563q-.423,0-.423-.486V74.486c0-.294.138-.442.414-.442s.415.148.415.442v2.807l2.2-2.952a.945.945,0,0,1,.2-.207.526.526,0,0,1,.324-.09q.414,0,.414.487v3.59c0,.294-.135.442-.4.442s-.422-.148-.422-.442v-2.8l-2.206,2.952a1.056,1.056,0,0,1-.2.2A.5.5,0,0,1,162.7,78.563Z"
                        fill="#b564a4"
                    />
                </g>
                <g id="Group_1353" data-name="Group 1353">
                    <rect
                        id="Rectangle_1-8"
                        data-name="Rectangle 1-8"
                        width="0.9"
                        height="22.4"
                        transform="translate(146.9 0.9)"
                        fill="#7b7b7b"
                    />
                    <path
                        id="Rectangle_2-9"
                        data-name="Rectangle 2-9"
                        d="M141.5,26h11.8a1,1,0,0,1,1,1h0a1,1,0,0,1-1,1H141.5a1,1,0,0,1-1-1h0A1.075,1.075,0,0,1,141.5,26Z"
                        fill="#7b7b7b"
                    />
                    <path
                        id="Rectangle_3-9"
                        data-name="Rectangle 3-9"
                        d="M140,28.9h14.9a1,1,0,0,1,1,1h0a1,1,0,0,1-1,1H140a1,1,0,0,1-1-1h0A1,1,0,0,1,140,28.9Z"
                        fill="#7b7b7b"
                    />
                    <path
                        id="Rectangle_4-9"
                        data-name="Rectangle 4-9"
                        d="M140.5,31.6h13.9a1,1,0,0,1,1,1h0a1,1,0,0,1-1,1H140.5a1,1,0,0,1-1-1h0A1.075,1.075,0,0,1,140.5,31.6Z"
                        fill="#7b7b7b"
                    />
                    <path
                        id="Path_1-9"
                        data-name="Path 1-9"
                        d="M147.4,21.5a5.172,5.172,0,0,0-4.9,3.4c0,.1,0,.3.1.3H152a.319.319,0,0,0,.3-.3v-.1A4.993,4.993,0,0,0,147.4,21.5Z"
                        fill="#7b7b7b"
                    />
                    <g id="Group_4-3" data-name="Group 4-3">
                        <path
                            id="Path_2-4"
                            data-name="Path 2-4"
                            d="M158.989,42.21c-1.6-2.4-3.6-4.6-3.8-7.7l-15.8.1c.1,2.3-1.1,4-2.2,5.7a44.756,44.756,0,0,0-3.5,6,13.874,13.874,0,0,0,.1,12.4,15.9,15.9,0,0,0,14.7,8.5,15.686,15.686,0,0,0,13.5-10.5C163.589,51.31,161.889,46.61,158.989,42.21Z"
                            fill="#b564a4"
                        />
                    </g>
                    <g id="Group_1772" data-name="Group 1772">
                        <g id="Group_1771" data-name="Group 1771">
                            <path
                                id="Path_1748"
                                data-name="Path 1748"
                                d="M144.323,50.93s1.8-3.3,4.3-1.6c3.6,2.5-1.4,9.3-2.2,9.5-.7.2-4.1-1.3-6.2-3.9a2.971,2.971,0,0,1,.3-4.1A3.149,3.149,0,0,1,144.323,50.93Z"
                                fill="#fff"
                            />
                            <path
                                id="Path_1749"
                                data-name="Path 1749"
                                d="M152.923,46.23s1.5-1.1,2.4.1c1.2,1.8-2.6,3.9-3,3.9a4.921,4.921,0,0,1-2.1-3,1.443,1.443,0,0,1,2.7-1Z"
                                fill="#fff"
                            />
                        </g>
                        <circle
                            id="Ellipse_1035"
                            data-name="Ellipse 1035"
                            cx="0.8"
                            cy="0.8"
                            r="0.8"
                            transform="translate(145.823 44.458)"
                            fill="#fff"
                        />
                        <circle
                            id="Ellipse_1036"
                            data-name="Ellipse 1036"
                            cx="0.8"
                            cy="0.8"
                            r="0.8"
                            transform="translate(151.723 52.058)"
                            fill="#fff"
                        />
                        <circle
                            id="Ellipse_1037"
                            data-name="Ellipse 1037"
                            cx="0.4"
                            cy="0.4"
                            r="0.4"
                            transform="translate(150.823 41.958)"
                            fill="#fff"
                        />
                    </g>
                </g>
                <g id="Group_1354" data-name="Group 1354">
                    <path
                        id="Path_1630"
                        data-name="Path 1630"
                        d="M45.876,67.878a.418.418,0,0,1-.468-.477V61.974a.446.446,0,0,1,.5-.5h4.364a.442.442,0,0,1,.495.5V67.4c0,.318-.152.477-.459.477a.418.418,0,0,1-.468-.477V62.253h-3.5V67.4A.418.418,0,0,1,45.876,67.878Z"
                        fill="#ffc900"
                    />
                    <path
                        id="Path_1631"
                        data-name="Path 1631"
                        d="M52.653,69.5a.4.4,0,0,1-.45-.459V63.819q0-.459.441-.459a.4.4,0,0,1,.45.459v.342a1.46,1.46,0,0,1,.585-.6,1.75,1.75,0,0,1,.882-.22,1.865,1.865,0,0,1,1.022.278,1.841,1.841,0,0,1,.675.789,3.205,3.205,0,0,1,0,2.424,1.788,1.788,0,0,1-.675.788,1.88,1.88,0,0,1-1.022.275,1.752,1.752,0,0,1-.868-.212,1.424,1.424,0,0,1-.581-.58v1.934A.405.405,0,0,1,52.653,69.5Zm1.683-2.3a1.116,1.116,0,0,0,.905-.406,2.191,2.191,0,0,0,0-2.335,1.223,1.223,0,0,0-1.809,0,2.185,2.185,0,0,0,0,2.335A1.114,1.114,0,0,0,54.336,67.194Z"
                        fill="#ffc900"
                    />
                    <path
                        id="Path_1632"
                        data-name="Path 1632"
                        d="M58.035,67.878q-.423,0-.423-.486V63.8q0-.441.414-.441t.414.441v2.808l2.205-2.952a.9.9,0,0,1,.2-.207.52.52,0,0,1,.324-.09q.414,0,.414.486v3.591q0,.441-.405.441-.423,0-.423-.441v-2.8l-2.2,2.952a1.027,1.027,0,0,1-.2.2A.5.5,0,0,1,58.035,67.878Z"
                        fill="#ffc900"
                    />
                    <path
                        id="Path_1633"
                        data-name="Path 1633"
                        d="M64.326,67.878a.406.406,0,0,1-.459-.46V64.152H62.643q-.4,0-.4-.369t.4-.36H66q.4,0,.4.36t-.4.369H64.776v3.266A.4.4,0,0,1,64.326,67.878Z"
                        fill="#ffc900"
                    />
                    <path
                        id="Path_1634"
                        data-name="Path 1634"
                        d="M69.105,67.9a2.324,2.324,0,0,1-1.7-.608,2.217,2.217,0,0,1-.621-1.66,2.512,2.512,0,0,1,.275-1.193,2.007,2.007,0,0,1,.76-.8,2.147,2.147,0,0,1,1.116-.288,1.83,1.83,0,0,1,1.431.58,2.3,2.3,0,0,1,.522,1.6q0,.306-.36.306H67.665a1.3,1.3,0,0,0,1.449,1.368,2.561,2.561,0,0,0,.567-.068,1.79,1.79,0,0,0,.576-.247.472.472,0,0,1,.328-.077.289.289,0,0,1,.2.153.366.366,0,0,1,.023.27.449.449,0,0,1-.212.257,2.268,2.268,0,0,1-.715.31A3.143,3.143,0,0,1,69.105,67.9Zm-.135-3.915a1.185,1.185,0,0,0-.9.35,1.607,1.607,0,0,0-.4.937h2.457a1.491,1.491,0,0,0-.324-.949A1.042,1.042,0,0,0,68.97,63.981Z"
                        fill="#ffc900"
                    />
                    <path
                        id="Path_1635"
                        data-name="Path 1635"
                        d="M73.911,67.9a2.3,2.3,0,0,1-1.165-.283,1.938,1.938,0,0,1-.761-.8,2.6,2.6,0,0,1-.27-1.219,2.245,2.245,0,0,1,.594-1.647,2.147,2.147,0,0,1,1.6-.6,2.516,2.516,0,0,1,.662.094,1.982,1.982,0,0,1,.643.31.334.334,0,0,1,.167.23.4.4,0,0,1-.045.262.34.34,0,0,1-.194.162.365.365,0,0,1-.288-.05,1.5,1.5,0,0,0-.873-.288,1.234,1.234,0,0,0-.985.4,1.69,1.69,0,0,0-.347,1.139A1.76,1.76,0,0,0,73,66.757a1.207,1.207,0,0,0,.985.419,1.522,1.522,0,0,0,.873-.279.338.338,0,0,1,.284-.045.315.315,0,0,1,.18.166.425.425,0,0,1,.035.262.328.328,0,0,1-.166.229,1.918,1.918,0,0,1-.626.3A2.52,2.52,0,0,1,73.911,67.9Z"
                        fill="#ffc900"
                    />
                    <path
                        id="Path_1636"
                        data-name="Path 1636"
                        d="M76.737,67.878a.406.406,0,0,1-.459-.46v-3.6a.455.455,0,1,1,.909,0V65.2h2.187V63.819a.4.4,0,0,1,.45-.459.406.406,0,0,1,.459.459v3.6a.406.406,0,0,1-.459.46.4.4,0,0,1-.45-.46v-1.5H77.187v1.5A.4.4,0,0,1,76.737,67.878Z"
                        fill="#ffc900"
                    />
                    <path
                        id="Path_1637"
                        data-name="Path 1637"
                        d="M83.712,67.9a2.324,2.324,0,0,1-1.7-.608,2.217,2.217,0,0,1-.621-1.66,2.5,2.5,0,0,1,.275-1.193,2.007,2.007,0,0,1,.76-.8,2.147,2.147,0,0,1,1.116-.288,1.83,1.83,0,0,1,1.431.58,2.3,2.3,0,0,1,.522,1.6q0,.306-.36.306H82.272a1.3,1.3,0,0,0,1.449,1.368,2.561,2.561,0,0,0,.567-.068,1.79,1.79,0,0,0,.576-.247.472.472,0,0,1,.328-.077.289.289,0,0,1,.2.153.361.361,0,0,1,.022.27.443.443,0,0,1-.211.257,2.268,2.268,0,0,1-.715.31A3.143,3.143,0,0,1,83.712,67.9Zm-.135-3.915a1.185,1.185,0,0,0-.9.35,1.607,1.607,0,0,0-.4.937h2.457a1.491,1.491,0,0,0-.324-.949A1.042,1.042,0,0,0,83.577,63.981Z"
                        fill="#ffc900"
                    />
                    <path
                        id="Path_1638"
                        data-name="Path 1638"
                        d="M87.033,67.878a.406.406,0,0,1-.459-.46v-3.6a.406.406,0,0,1,.459-.459.4.4,0,0,1,.449.459V65.2h2.187V63.819a.455.455,0,1,1,.91,0v3.6a.455.455,0,1,1-.91,0v-1.5H87.482v1.5A.4.4,0,0,1,87.033,67.878Z"
                        fill="#ffc900"
                    />
                    <path
                        id="Path_1639"
                        data-name="Path 1639"
                        d="M92.361,67.878q-.423,0-.423-.486V63.8q0-.441.414-.441t.414.441v2.808l2.205-2.952a.9.9,0,0,1,.2-.207.52.52,0,0,1,.324-.09q.414,0,.414.486v3.591q0,.441-.4.441-.423,0-.423-.441v-2.8L92.874,67.59a1.027,1.027,0,0,1-.2.2A.5.5,0,0,1,92.361,67.878Z"
                        fill="#ffc900"
                    />
                    <path
                        id="Path_1640"
                        data-name="Path 1640"
                        d="M100.424,67.878a.378.378,0,0,1-.423-.432V66.213h-.566a.926.926,0,0,0-.545.161,1.193,1.193,0,0,0-.365.414l-.477.837a.472.472,0,0,1-.3.244.456.456,0,0,1-.329-.054.47.47,0,0,1-.211-.257.419.419,0,0,1,.049-.355l.36-.612a1.441,1.441,0,0,1,.275-.329,1.137,1.137,0,0,1,.364-.221,1.407,1.407,0,0,1-.855-.418,1.377,1.377,0,0,1,.117-1.836,1.818,1.818,0,0,1,1.233-.364h1.629a.413.413,0,0,1,.468.468v3.555a.4.4,0,0,1-.423.432Zm-1.485-2.286H100V64.043H98.939a1.1,1.1,0,0,0-.729.2.7.7,0,0,0-.233.563.73.73,0,0,0,.233.576A1.079,1.079,0,0,0,98.939,65.592Z"
                        fill="#ffc900"
                    />
                </g>
                <g id="Group_1357" data-name="Group 1357">
                    <g id="Group_1355" data-name="Group 1355">
                        <g id="Group_1754-2" data-name="Group 1754-2">
                            <rect
                                id="Rectangle_1-9"
                                data-name="Rectangle 1-9"
                                width="0.8"
                                height="15.5"
                                transform="translate(72.59 0.928)"
                                fill="#7b7b7b"
                            />
                            <path
                                id="Rectangle_2-10"
                                data-name="Rectangle 2-10"
                                d="M67.69,18.928h10.8a.9.9,0,0,1,.9.9h0a.9.9,0,0,1-.9.9H67.69a.9.9,0,0,1-.9-.9h0A.9.9,0,0,1,67.69,18.928Z"
                                fill="#7b7b7b"
                            />
                            <path
                                id="Rectangle_3-10"
                                data-name="Rectangle 3-10"
                                d="M66.29,21.528h13.6a.9.9,0,0,1,.9.9h0a.9.9,0,0,1-.9.9H66.29a.9.9,0,0,1-.9-.9h0A.9.9,0,0,1,66.29,21.528Z"
                                fill="#7b7b7b"
                            />
                            <path
                                id="Rectangle_4-10"
                                data-name="Rectangle 4-10"
                                d="M66.69,24.028h12.7a.9.9,0,0,1,.9.9h0a.9.9,0,0,1-.9.9H66.69a.9.9,0,0,1-.9-.9h0A.967.967,0,0,1,66.69,24.028Z"
                                fill="#7b7b7b"
                            />
                            <path
                                id="Path_1-10"
                                data-name="Path 1-10"
                                d="M73.09,14.728a4.755,4.755,0,0,0-4.5,3.1c0,.1,0,.3.1.3h8.6a.215.215,0,0,0,.2-.2v-.1A4.535,4.535,0,0,0,73.09,14.728Z"
                                fill="#7b7b7b"
                            />
                        </g>
                        <path
                            id="Path_1739-2"
                            data-name="Path 1739-2"
                            d="M83.7,33.8c-1.5-2.2-3.4-4.2-3.5-7.1l-14.5.1c.1,2.1-1,3.6-2,5.2a43.65,43.65,0,0,0-3.2,5.5,12.71,12.71,0,0,0,.1,11.4A14.474,14.474,0,0,0,74,56.7,14.486,14.486,0,0,0,86.4,47C87.9,42.1,86.4,37.8,83.7,33.8Z"
                            fill="#ffc900"
                        />
                    </g>
                    <g id="Group_1356" data-name="Group 1356">
                        <path
                            id="Icon_awesome-bell"
                            data-name="Icon awesome-bell"
                            d="M73,50a1.58,1.58,0,0,0,1.6-1.6H71.5A1.561,1.561,0,0,0,73,50Zm5.3-3.7c-.5-.5-1.4-1.2-1.4-3.7a3.861,3.861,0,0,0-3.1-3.8v-.5a.8.8,0,0,0-1.6,0v.5a3.861,3.861,0,0,0-3.1,3.8c0,2.5-.9,3.3-1.4,3.8a.764.764,0,0,0-.2.5.86.86,0,0,0,.8.8h9.4a.789.789,0,0,0,.8-.8h0A1.421,1.421,0,0,0,78.3,46.3Z"
                            fill="#fff"
                        />
                        <g id="Group_1881" data-name="Group 1881">
                            <g id="Line_2" data-name="Line 2">
                                <path
                                    id="Path_1641"
                                    data-name="Path 1641"
                                    d="M69,36.4a.758.758,0,0,1-.4-.2l-1-1.1a.495.495,0,1,1,.7-.7l1,1.1a.483.483,0,0,1,0,.7C69.3,36.3,69.2,36.4,69,36.4Z"
                                    fill="#fff"
                                />
                            </g>
                            <g id="Line_173" data-name="Line 173">
                                <path
                                    id="Path_1642"
                                    data-name="Path 1642"
                                    d="M77,36.4a.367.367,0,0,1-.3-.1.483.483,0,0,1,0-.7l1-1.1a.495.495,0,0,1,.7.7l-1,1.1C77.3,36.3,77.2,36.4,77,36.4Z"
                                    fill="#fff"
                                />
                            </g>
                            <g id="Line_3" data-name="Line 3">
                                <path
                                    id="Path_1643"
                                    data-name="Path 1643"
                                    d="M73,35.7a.472.472,0,0,1-.5-.5V33.4a.5.5,0,1,1,1,0v1.8A.536.536,0,0,1,73,35.7Z"
                                    fill="#fff"
                                />
                            </g>
                        </g>
                    </g>
                </g>
                <g id="Group_1358" data-name="Group 1358">
                    <path
                        id="Path_1644"
                        data-name="Path 1644"
                        d="M139.39,171.767a3.018,3.018,0,0,1-1.576-.4,2.7,2.7,0,0,1-1.03-1.135,4.261,4.261,0,0,1,0-3.451,2.651,2.651,0,0,1,1.03-1.125,3.315,3.315,0,0,1,3.146,0,2.637,2.637,0,0,1,1.035,1.125,3.8,3.8,0,0,1,.364,1.718,3.85,3.85,0,0,1-.364,1.733,2.686,2.686,0,0,1-1.035,1.135A3.024,3.024,0,0,1,139.39,171.767Zm0-.811a1.8,1.8,0,0,0,1.471-.642,3.359,3.359,0,0,0,0-3.606,2.021,2.021,0,0,0-2.947,0,3.353,3.353,0,0,0,0,3.606A1.8,1.8,0,0,0,139.39,170.956Z"
                        fill="#9fd54e"
                    />
                    <path
                        id="Path_1645"
                        data-name="Path 1645"
                        d="M144.934,171.749a.406.406,0,0,1-.459-.461v-3.265H143.25q-.4,0-.4-.369c0-.241.132-.36.4-.36h3.357c.265,0,.4.119.4.36s-.131.369-.4.369h-1.223v3.265A.4.4,0,0,1,144.934,171.749Z"
                        fill="#9fd54e"
                    />
                    <path
                        id="Path_1646"
                        data-name="Path 1646"
                        d="M149.2,171.767a4.133,4.133,0,0,1-.9-.1,2.536,2.536,0,0,1-.761-.283.432.432,0,0,1-.211-.228.369.369,0,0,1,0-.266.287.287,0,0,1,.18-.172.408.408,0,0,1,.319.055,2.309,2.309,0,0,0,.648.238,3.413,3.413,0,0,0,.711.076,1.729,1.729,0,0,0,.878-.166.608.608,0,0,0,.049-.967,1,1,0,0,0-.611-.162h-.792a.379.379,0,0,1-.275-.092.315.315,0,0,1-.095-.242c0-.222.123-.332.37-.332h.692a.915.915,0,0,0,.563-.158.513.513,0,0,0,.211-.437.555.555,0,0,0-.234-.471,1.353,1.353,0,0,0-.791-.176,2.823,2.823,0,0,0-.653.072,2.622,2.622,0,0,0-.6.215.394.394,0,0,1-.311.045.3.3,0,0,1-.185-.166.348.348,0,0,1-.013-.252.357.357,0,0,1,.2-.211,2.977,2.977,0,0,1,.792-.289,4.319,4.319,0,0,1,.9-.09,2.138,2.138,0,0,1,1.292.328,1.071,1.071,0,0,1,.436.905.985.985,0,0,1-.189.6,1.074,1.074,0,0,1-.522.373,1.141,1.141,0,0,1,.64.366,1.009,1.009,0,0,1,.233.679,1.1,1.1,0,0,1-.5.953A2.556,2.556,0,0,1,149.2,171.767Z"
                        fill="#9fd54e"
                    />
                    <path
                        id="Path_1647"
                        data-name="Path 1647"
                        d="M152.664,171.749q-.423,0-.423-.486v-3.592c0-.293.138-.441.414-.441s.414.148.414.441v2.809l2.205-2.953a.924.924,0,0,1,.2-.207.526.526,0,0,1,.324-.09q.414,0,.414.486v3.592c0,.293-.135.441-.405.441s-.423-.148-.423-.441v-2.8l-2.205,2.951a.977.977,0,0,1-.2.2A.5.5,0,0,1,152.664,171.749Z"
                        fill="#9fd54e"
                    />
                    <path
                        id="Path_1648"
                        data-name="Path 1648"
                        d="M158.037,171.685a.405.405,0,0,1-.459-.459v-3.465a.409.409,0,0,1,.459-.467h1.782a1.877,1.877,0,0,1,1.183.3,1.03,1.03,0,0,1,.374.85.969.969,0,0,1-.193.6,1.107,1.107,0,0,1-.527.373,1.127,1.127,0,0,1,.639.379,1.028,1.028,0,0,1,.225.666,1.073,1.073,0,0,1-.423.895,1.93,1.93,0,0,1-1.206.328Zm.4-2.529h1.323a.869.869,0,0,0,.572-.17.583.583,0,0,0,.2-.469.566.566,0,0,0-.2-.467.891.891,0,0,0-.572-.162h-1.323Zm0,1.945h1.4c.564,0,.846-.229.846-.684s-.282-.675-.846-.675h-1.4Z"
                        fill="#9fd54e"
                    />
                    <path
                        id="Path_1649"
                        data-name="Path 1649"
                        d="M165.858,171.749q-.459,0-.459-.486v-1.269a2.176,2.176,0,0,1-1.1.279,1.929,1.929,0,0,1-1.413-.459,1.9,1.9,0,0,1-.459-1.395v-.73a.455.455,0,1,1,.909,0v.658a1.3,1.3,0,0,0,.256.9,1.1,1.1,0,0,0,.851.28,1.867,1.867,0,0,0,.513-.063,1.274,1.274,0,0,0,.432-.217v-1.537c0-.318.153-.478.459-.478s.45.16.45.478v3.555Q166.3,171.749,165.858,171.749Z"
                        fill="#9fd54e"
                    />
                    <path
                        id="Path_1650"
                        data-name="Path 1650"
                        d="M168.09,171.749q-.423,0-.423-.486v-3.592c0-.293.138-.441.414-.441s.414.148.414.441v2.809l2.205-2.953a.924.924,0,0,1,.2-.207.526.526,0,0,1,.324-.09q.414,0,.414.486v3.592c0,.293-.135.441-.406.441s-.422-.148-.422-.441v-2.8L168.6,171.46a.977.977,0,0,1-.2.2A.5.5,0,0,1,168.09,171.749Z"
                        fill="#9fd54e"
                    />
                    <path
                        id="Path_1651"
                        data-name="Path 1651"
                        d="M173.463,171.685a.4.4,0,0,1-.459-.459v-3.465a.409.409,0,0,1,.459-.467h1.781a1.881,1.881,0,0,1,1.184.3,1.03,1.03,0,0,1,.374.85.97.97,0,0,1-.194.6,1.1,1.1,0,0,1-.526.373,1.134,1.134,0,0,1,.639.379,1.033,1.033,0,0,1,.224.666,1.071,1.071,0,0,1-.423.895,1.928,1.928,0,0,1-1.206.328Zm.4-2.529h1.324a.865.865,0,0,0,.571-.17.581.581,0,0,0,.2-.469.564.564,0,0,0-.2-.467.886.886,0,0,0-.571-.162h-1.324Zm0,1.945h1.4c.563,0,.845-.229.845-.684s-.282-.675-.845-.675h-1.4Z"
                        fill="#9fd54e"
                    />
                    <path
                        id="Path_1652"
                        data-name="Path 1652"
                        d="M178.431,171.749c-.283,0-.423-.162-.423-.486v-3.592c0-.293.138-.441.414-.441s.414.148.414.441v2.809l2.2-2.953a.947.947,0,0,1,.2-.207.528.528,0,0,1,.324-.09q.416,0,.415.486v3.592c0,.293-.135.441-.406.441s-.423-.148-.423-.441v-2.8l-2.205,2.951a.952.952,0,0,1-.2.2A.5.5,0,0,1,178.431,171.749Z"
                        fill="#9fd54e"
                    />
                    <path
                        id="Path_1653"
                        data-name="Path 1653"
                        d="M186.18,171.749c-.307,0-.459-.16-.459-.478v-3.465a.452.452,0,0,1,.512-.512h2.34c.264,0,.4.119.4.36s-.133.369-.4.369h-1.944v3.248C186.629,171.589,186.479,171.749,186.18,171.749Z"
                        fill="#9fd54e"
                    />
                    <path
                        id="Path_1654"
                        data-name="Path 1654"
                        d="M190.185,173.369a.4.4,0,0,1-.451-.459v-5.221c0-.3.147-.459.441-.459a.4.4,0,0,1,.45.459v.342a1.451,1.451,0,0,1,.585-.6,1.753,1.753,0,0,1,.882-.221,1.854,1.854,0,0,1,1.021.28,1.832,1.832,0,0,1,.676.787,3.208,3.208,0,0,1,0,2.425,1.8,1.8,0,0,1-.676.788,1.877,1.877,0,0,1-1.021.275,1.745,1.745,0,0,1-.868-.213,1.415,1.415,0,0,1-.58-.58v1.936A.406.406,0,0,1,190.185,173.369Zm1.682-2.305a1.117,1.117,0,0,0,.9-.4,1.78,1.78,0,0,0,.338-1.17,1.761,1.761,0,0,0-.338-1.166,1.219,1.219,0,0,0-1.808,0,1.761,1.761,0,0,0-.338,1.166,1.78,1.78,0,0,0,.338,1.17A1.117,1.117,0,0,0,191.867,171.064Z"
                        fill="#9fd54e"
                    />
                    <path
                        id="Path_1655"
                        data-name="Path 1655"
                        d="M195.566,171.749c-.282,0-.422-.162-.422-.486v-3.592c0-.293.137-.441.414-.441s.414.148.414.441v2.809l2.2-2.953a.924.924,0,0,1,.2-.207.528.528,0,0,1,.324-.09c.277,0,.414.162.414.486v3.592c0,.293-.134.441-.4.441s-.423-.148-.423-.441v-2.8l-2.205,2.951a.977.977,0,0,1-.2.2A.5.5,0,0,1,195.566,171.749Z"
                        fill="#9fd54e"
                    />
                    <path
                        id="Path_1656"
                        data-name="Path 1656"
                        d="M203.414,171.749a.4.4,0,0,1-.45-.461v-1.5h-.513a1.011,1.011,0,0,0-.535.122,1.389,1.389,0,0,0-.4.462l-.658,1.125a.42.42,0,0,1-.279.235.5.5,0,0,1-.324-.035.42.42,0,0,1-.216-.225.366.366,0,0,1,.054-.342l.594-1.009a3.573,3.573,0,0,1,.392-.557,1.185,1.185,0,0,1,.409-.307l-1.035-1.349a.416.416,0,0,1-.108-.328.367.367,0,0,1,.158-.249.4.4,0,0,1,.292-.068.473.473,0,0,1,.3.193l1.242,1.622h.63v-1.368a.455.455,0,1,1,.909,0v1.368h.621l1.242-1.622a.418.418,0,0,1,.292-.2.424.424,0,0,1,.293.069.363.363,0,0,1,.158.242.426.426,0,0,1-.1.338l-1.035,1.359a1.1,1.1,0,0,1,.41.291,3.51,3.51,0,0,1,.392.563l.593,1.009a.376.376,0,0,1,.045.342.42.42,0,0,1-.212.225.457.457,0,0,1-.314.031.47.47,0,0,1-.284-.231l-.657-1.125a1.412,1.412,0,0,0-.4-.462,1,1,0,0,0-.527-.122h-.513v1.5A.407.407,0,0,1,203.414,171.749Z"
                        fill="#9fd54e"
                    />
                    <path
                        id="Path_1657"
                        data-name="Path 1657"
                        d="M208.13,171.749q-.423,0-.423-.486v-3.592c0-.293.138-.441.414-.441s.414.148.414.441v2.809l2.205-2.953a.924.924,0,0,1,.2-.207.526.526,0,0,1,.324-.09q.414,0,.414.486v3.592c0,.293-.135.441-.4.441s-.422-.148-.422-.441v-2.8l-2.205,2.951a1.01,1.01,0,0,1-.2.2A.5.5,0,0,1,208.13,171.749Z"
                        fill="#9fd54e"
                    />
                </g>
                <g id="Group_1359" data-name="Group 1359">
                    <path
                        id="Rectangle_2-11"
                        data-name="Rectangle 2-11"
                        d="M164.4,98.3h17.1a1.367,1.367,0,0,1,1.4,1.4h0a1.367,1.367,0,0,1-1.4,1.4H164.4a1.367,1.367,0,0,1-1.4-1.4h0A1.312,1.312,0,0,1,164.4,98.3Z"
                        fill="#7b7b7b"
                    />
                    <path
                        id="Rectangle_3-11"
                        data-name="Rectangle 3-11"
                        d="M162.2,102.3h21.5a1.367,1.367,0,0,1,1.4,1.4h0a1.367,1.367,0,0,1-1.4,1.4H162.2a1.367,1.367,0,0,1-1.4-1.4h0A1.365,1.365,0,0,1,162.2,102.3Z"
                        fill="#7b7b7b"
                    />
                    <path
                        id="Rectangle_4-11"
                        data-name="Rectangle 4-11"
                        d="M162.9,106.4H183a1.367,1.367,0,0,1,1.4,1.4h0a1.367,1.367,0,0,1-1.4,1.4H162.9a1.367,1.367,0,0,1-1.4-1.4h0A1.312,1.312,0,0,1,162.9,106.4Z"
                        fill="#7b7b7b"
                    />
                    <path
                        id="Path_1-11"
                        data-name="Path 1-11"
                        d="M172.9,91.6a7.327,7.327,0,0,0-7.1,4.9.365.365,0,0,0,.2.5h13.5a.43.43,0,0,0,.4-.4v-.1A7.24,7.24,0,0,0,172.9,91.6Z"
                        fill="#7b7b7b"
                    />
                    <g id="Group_4-4" data-name="Group 4-4">
                        <path
                            id="Path_2-5"
                            data-name="Path 2-5"
                            d="M189.663,121.707c-2.3-3.5-5.3-6.6-5.5-11.2l-22.9.1c.1,3.3-1.6,5.7-3.2,8.2a84.417,84.417,0,0,0-5.1,8.6,20.018,20.018,0,0,0,.1,18c4,7.8,12.9,12.9,21.3,12.3,8.8-.7,17-7,19.6-15.3C196.263,134.807,193.863,128.007,189.663,121.707Z"
                            fill="#9fd54e"
                        />
                    </g>
                    <rect
                        id="Rectangle_1610"
                        data-name="Rectangle 1610"
                        width="0.9"
                        height="94"
                        transform="translate(172.7 0)"
                        fill="#7b7b7b"
                    />
                    <g id="Group_1883" data-name="Group 1883">
                        <circle
                            id="Ellipse_1103"
                            data-name="Ellipse 1103"
                            cx="5.5"
                            cy="5.5"
                            r="5.5"
                            transform="translate(167.572 116.639)"
                            fill="#fff"
                        />
                        <path
                            id="Path_1979"
                            data-name="Path 1979"
                            d="M179.472,130.139a11.807,11.807,0,0,0-6.3-1.8,11.96,11.96,0,0,0-7.7,2.6,9.677,9.677,0,0,0-3.4,4.8,11.438,11.438,0,0,0-.5,5.2,6.3,6.3,0,0,0,1,2.7,10.17,10.17,0,0,0,3.9,3.8,16.268,16.268,0,0,0,2.3,1.1,10.389,10.389,0,0,0,3.5.7,2.855,2.855,0,0,0,2.3-.8,1.974,1.974,0,0,0,.6-1.4,2.506,2.506,0,0,0-.5-1.9,9.95,9.95,0,0,0,4.8-.8,8.072,8.072,0,0,0,3.3-2.5,7.386,7.386,0,0,0,.7-1.2,2.607,2.607,0,0,0,.3-1.1,10.911,10.911,0,0,0,.2-1.8A9.466,9.466,0,0,0,179.472,130.139Zm-7.6,11a2.742,2.742,0,0,0,.6,3.1,11.132,11.132,0,0,1-3.8-2.2,5.908,5.908,0,0,1-1.7-3.2c0-.1.1-.1.1-.2a6.893,6.893,0,0,0,.8-.3h0l.3-.1a9.287,9.287,0,0,1,1.3-.4,22.543,22.543,0,0,1-.6-3.6,1.5,1.5,0,0,1,1.5-1.6,1.526,1.526,0,0,1,1.7,1.5,13.591,13.591,0,0,0,.7,3.5,5.134,5.134,0,0,0,2.4,2.2c-1.4-.2-2.7,0-3.3,1.3Zm5.1-1.4a3.3,3.3,0,1,1,3.3-3.3h0a3.265,3.265,0,0,1-3.3,3.3Z"
                            fill="#fff"
                        />
                    </g>
                </g>
                <g id="Group_1360" data-name="Group 1360">
                    <path
                        id="Path_1658"
                        data-name="Path 1658"
                        d="M14.4,110.367a.418.418,0,0,1-.468-.478v-5.454a.422.422,0,0,1,.477-.477h2.151a2.269,2.269,0,0,1,1.584.508,2.114,2.114,0,0,1,0,2.862,2.269,2.269,0,0,1-1.584.509H14.863v2.052A.418.418,0,0,1,14.4,110.367Zm.468-3.277H16.42q1.368,0,1.368-1.2t-1.368-1.188H14.863Z"
                        fill="#0d80b8"
                    />
                    <path
                        id="Path_1659"
                        data-name="Path 1659"
                        d="M21.568,110.384a2.328,2.328,0,0,1-1.7-.607,2.218,2.218,0,0,1-.621-1.66,2.5,2.5,0,0,1,.275-1.194,2,2,0,0,1,.76-.8,2.138,2.138,0,0,1,1.116-.289,1.827,1.827,0,0,1,1.431.581,2.3,2.3,0,0,1,.522,1.6q0,.306-.36.306H20.128a1.3,1.3,0,0,0,1.449,1.368,2.557,2.557,0,0,0,.567-.067,1.79,1.79,0,0,0,.576-.247.468.468,0,0,1,.328-.078.291.291,0,0,1,.2.154.359.359,0,0,1,.022.269.443.443,0,0,1-.211.257,2.269,2.269,0,0,1-.715.311A3.188,3.188,0,0,1,21.568,110.384Zm-.135-3.915a1.182,1.182,0,0,0-.9.351,1.6,1.6,0,0,0-.405.936h2.457a1.494,1.494,0,0,0-.324-.949A1.042,1.042,0,0,0,21.433,106.469Z"
                        fill="#0d80b8"
                    />
                    <path
                        id="Path_1660"
                        data-name="Path 1660"
                        d="M27.364,110.367a.4.4,0,0,1-.45-.46v-1.5H26.4a1,1,0,0,0-.535.122,1.376,1.376,0,0,0-.4.463l-.657,1.125a.421.421,0,0,1-.279.234.5.5,0,0,1-.324-.036.417.417,0,0,1-.216-.225.367.367,0,0,1,.054-.342l.594-1.008a3.587,3.587,0,0,1,.392-.558,1.182,1.182,0,0,1,.409-.306l-1.035-1.35a.414.414,0,0,1-.108-.328.363.363,0,0,1,.158-.248.4.4,0,0,1,.292-.068.471.471,0,0,1,.3.194l1.242,1.62h.63v-1.367a.455.455,0,1,1,.909,0v1.367h.621l1.242-1.62a.416.416,0,0,1,.293-.2.423.423,0,0,1,.292.068.365.365,0,0,1,.158.243.424.424,0,0,1-.1.337l-1.035,1.359a1.114,1.114,0,0,1,.41.292,3.364,3.364,0,0,1,.391.563l.594,1.008a.379.379,0,0,1,.045.342.419.419,0,0,1-.211.225.456.456,0,0,1-.315.031.464.464,0,0,1-.284-.229l-.657-1.125a1.386,1.386,0,0,0-.4-.463.988.988,0,0,0-.527-.122h-.513v1.5A.406.406,0,0,1,27.364,110.367Z"
                        fill="#0d80b8"
                    />
                    <path
                        id="Path_1661"
                        data-name="Path 1661"
                        d="M32.08,110.367c-.283,0-.423-.163-.423-.487v-3.591c0-.294.137-.441.414-.441s.414.147.414.441V109.1l2.2-2.952a.951.951,0,0,1,.2-.207.517.517,0,0,1,.323-.09c.277,0,.414.162.414.486v3.591c0,.294-.134.442-.4.442s-.423-.148-.423-.442v-2.8l-2.205,2.952a1.031,1.031,0,0,1-.2.2A.508.508,0,0,1,32.08,110.367Z"
                        fill="#0d80b8"
                    />
                    <path
                        id="Path_1662"
                        data-name="Path 1662"
                        d="M37.4,110.367c-.27,0-.4-.145-.4-.433v-3.6c0-.324.137-.486.414-.486a.432.432,0,0,1,.305.1.909.909,0,0,1,.181.225l1.439,2.52,1.432-2.52a.89.89,0,0,1,.189-.229.428.428,0,0,1,.3-.1c.276,0,.415.162.415.486v3.6c0,.288-.135.433-.405.433s-.4-.145-.4-.433v-2.609l-1.216,2.105a.519.519,0,0,1-.116.145.3.3,0,0,1-.2.062.275.275,0,0,1-.2-.067.748.748,0,0,1-.112-.14l-1.225-2.105v2.609C37.794,110.222,37.663,110.367,37.4,110.367Z"
                        fill="#0d80b8"
                    />
                </g>
                <g id="Group_1361" data-name="Group 1361">
                    <g id="Group_1-4" data-name="Group 1-4">
                        <rect
                            id="Rectangle_1-10"
                            data-name="Rectangle 1-10"
                            width="0.9"
                            height="50.2"
                            transform="translate(26.903 1.022)"
                            fill="#7b7b7b"
                        />
                        <path
                            id="Rectangle_2-12"
                            data-name="Rectangle 2-12"
                            d="M21.1,54.122H33.6a1.11,1.11,0,0,1,1.1,1.1h0a1.11,1.11,0,0,1-1.1,1.1H21.1a1.11,1.11,0,0,1-1.1-1.1h0A1.175,1.175,0,0,1,21.1,54.122Z"
                            fill="#7b7b7b"
                        />
                        <path
                            id="Rectangle_3-12"
                            data-name="Rectangle 3-12"
                            d="M19.5,57.122H35.2a1.11,1.11,0,0,1,1.1,1.1h0a1.11,1.11,0,0,1-1.1,1.1H19.5a1.11,1.11,0,0,1-1.1-1.1h0A1.175,1.175,0,0,1,19.5,57.122Z"
                            fill="#7b7b7b"
                        />
                        <path
                            id="Rectangle_4-12"
                            data-name="Rectangle 4-12"
                            d="M20,60.022H34.7a1.11,1.11,0,0,1,1.1,1.1h0a1.11,1.11,0,0,1-1.1,1.1H20a1.11,1.11,0,0,1-1.1-1.1h0A1.269,1.269,0,0,1,20,60.022Z"
                            fill="#7b7b7b"
                        />
                        <path
                            id="Path_1-12"
                            data-name="Path 1-12"
                            d="M27.4,49.222a5.507,5.507,0,0,0-5.2,3.6c-.1.1,0,.3.2.3h9.9a.319.319,0,0,0,.3-.3v-.1A5.56,5.56,0,0,0,27.4,49.222Z"
                            fill="#7b7b7b"
                        />
                    </g>
                    <g id="Group_4-5" data-name="Group 4-5">
                        <path
                            id="Path_2-6"
                            data-name="Path 2-6"
                            d="M39.73,71.278c-1.7-2.6-3.9-4.9-4.1-8.2l-16.8.1c.1,2.4-1.2,4.2-2.3,6a49.5,49.5,0,0,0-3.7,6.3,14.617,14.617,0,0,0,.1,13.2,16.645,16.645,0,0,0,15.5,9,16.505,16.505,0,0,0,14.3-11.2C44.53,80.878,42.73,75.878,39.73,71.278Z"
                            fill="#0d80b8"
                        />
                    </g>
                    <g id="Group_1886" data-name="Group 1886">
                        <path
                            id="Icon_awesome-star"
                            data-name="Icon awesome-star"
                            d="M23.384,72.762l-.8.9-1.2-.2c-.1,0-.3.1-.3.2v.2l.6,1.1-.5,1.1c-.1.1,0,.3.1.4h.2l1.2-.3.9.9a.3.3,0,0,0,.4,0,.349.349,0,0,0,.1-.2l.1-1.2,1.1-.6c.1-.1.2-.2.1-.4a.1.1,0,0,0-.1-.1l-1.1-.5-.2-1.2c0-.1-.2-.2-.3-.2A.438.438,0,0,0,23.384,72.762Z"
                            fill="#fff"
                        />
                        <path
                            id="Icon_awesome-star-2"
                            data-name="Icon awesome-star-2"
                            d="M19.784,81.862l-.1.8-.7.3c-.1,0-.1.1-.1.2a.1.1,0,0,0,.1.1l.7.4.1.8a.215.215,0,0,0,.2.2h.1l.6-.6.8.2c.1,0,.2,0,.2-.1v-.1l-.5-.9.4-.7c.1-.1,0-.2-.1-.2h-.1l-.8.1-.5-.6c-.1-.1-.2-.1-.3.1Z"
                            fill="#fff"
                        />
                        <path
                            id="Icon_awesome-star-3"
                            data-name="Icon awesome-star-3"
                            d="M34.984,80.562l-.4.5-.6-.1c-.1,0-.1,0-.1.1v.1l.3.5-.2.6c0,.1,0,.1.1.2h.1l.6-.2.5.4c.1.1.1,0,.2,0v-.7l.5-.3c.1,0,.1-.1,0-.2l-.1-.1-.6-.2-.1-.6s0-.1-.2,0Z"
                            fill="#fff"
                        />
                        <path
                            id="Path_1981"
                            data-name="Path 1981"
                            d="M28.784,82.262a4.4,4.4,0,0,1,3.1-3.9,5.9,5.9,0,0,1,1.4-.2.265.265,0,0,0,.3-.3c0-.1-.1-.2-.1-.3a6.15,6.15,0,0,0-7.3,9.9,6.3,6.3,0,0,0,7.4,0,.44.44,0,0,0,.1-.5.367.367,0,0,0-.3-.1h-.1a4.268,4.268,0,0,1-4.3-4.3C28.784,82.462,28.784,82.362,28.784,82.262Z"
                            fill="#fff"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default Bulbs;
