import { CLEAR_REDUCERS, LOAD_GOALS_SUCCESS } from '../../actions/actionTypes';

const initialState = [];

const goalsAndTagsReducer = (state = initialState, action) => {
  let updatedState = {};
  switch (action.type) {
    case LOAD_GOALS_SUCCESS:
      updatedState = { ...initialState };
      updatedState = action.payload;

      return updatedState;
    case CLEAR_REDUCERS:
      updatedState = { ...initialState };
      return updatedState;
    default:
      return state;
  }
};

export default goalsAndTagsReducer;
