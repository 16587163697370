import moment from 'moment';
import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { useTimer } from 'react-compound-timer';
import { useEffectOnce } from 'react-use';
import { getAdditionalInfo } from '../components/tracker/tracking/config';
import { useTimerState } from '../hooks/useTimerState';
import { TIMER_STATE, TimerStateType } from '../models/timerStatuses';
export interface TimerValue {
  ms: number;
  s: number;
  m: number;
  h: number;
  d: number;
  duration: moment.Duration;
}
interface TrackingTimerContextProps {
  start: (value?: string) => void;
  stop: () => void;
  reset: () => void;
  setActiveSide: (side: string) => void;
  setTracker: (name?: string) => void;
  getDuration: () => number;
  activeTracker: string;
  value?: TimerValue;
  timerState: TimerStateType;
  startTime?: Date;
  activeSide: string;
  additionalInfo: string;
  isWorking: boolean;
}

const defaultValues: TrackingTimerContextProps = {
  start(value) {},
  stop() {},
  reset() {},
  setTracker(name) {},
  setActiveSide(side) {},
  activeTracker: '',
  timerState: TIMER_STATE.INIT,
  activeSide: '',
  additionalInfo: '',
  isWorking: false,
  getDuration: () => 0,
};

export const TrackingTimerContext =
  createContext<TrackingTimerContextProps>(defaultValues);

export const TrackingTimerProvider: FC<PropsWithChildren> = ({ children }) => {
  const {
    state: timerState,
    setSide,
    setStartTime,
    setTimerState,
    setTracker,
    clear,
    setDuration,
  } = useTimerState();

  const handleSetTimerStatus = useCallback(
    (status: TimerStateType) => {
      setTimerState(status);
    },
    [setTimerState]
  );

  const handleStart = () => {
    if (timerState?.state !== TIMER_STATE.PLAY) {
      handleSetTimerStatus(TIMER_STATE.PLAY);
      setStartTime(new Date());
    }
  };
  const handleStop = () => {
    // the timer stops automatically when destroyed.
    // we don't want that, because time should be counted even if the browser is closed.
  };
  const handleReset = () => {
    handleSetTimerStatus(TIMER_STATE.STOP);
    clear();
  };
  const handlePause = () => {
    handleSetTimerStatus(TIMER_STATE.PAUSE);
    setDuration(moment().diff(moment(timerState.startTime), 'milliseconds'));
  };

  const timer = useTimer({
    initialTime: 0,
    startImmediately: false,
    onStart: handleStart,
    onStop: handleStop,
    onPause: handlePause,
    onReset: handleReset,
  });

  useEffectOnce(() => {
    let duration = timerState?.duration || 0;
    if (timerState?.state === TIMER_STATE.PLAY) {
      duration = moment().diff(moment(timerState.startTime), 'milliseconds');
    }
    timer.controls.setTime(duration);
    if (timerState?.state === TIMER_STATE.PLAY) {
      timer.controls.start();
    }
  });

  const start = useCallback(
    (value?: string) => {
      if (value) {
        setSide(value);
      }
      timer.controls.setTime(0);
      timer.controls.start();
    },
    [setSide, timer.controls]
  );

  const stop = useCallback(() => {
    timer.controls.pause();
  }, [timer.controls]);

  const reset = useCallback(() => {
    timer.controls.reset();
    timer.controls.setTime(0);
  }, [timer.controls]);

  const getDuration = () => {
    const duration = moment
      .duration(timerState?.duration, 'milliseconds')
      .asSeconds();
    return duration;
  };

  const getAddInfo = () =>
    getAdditionalInfo(timerState?.tracker, timerState?.side);

  const isWorking =
    timerState?.state === TIMER_STATE.PLAY &&
    timerState?.side !== undefined &&
    timerState?.tracker !== undefined &&
    timerState?.startTime !== undefined;

  const activateTracker = (trackerType?: string) => {
    if (!isWorking && trackerType && timerState?.tracker !== trackerType) {
      reset();
      setTracker(trackerType);
    }
  };

  // The timer.value is used only for ticking.
  // The actual tracking period is calulcated from the startTime.
  // If the browser tab was in sleep mode and then waken up the timer.value will be behind.
  // Page refresh will be required to start the time with the correct duration.
  const timerValue = useMemo<TimerValue>(() => {
    const duration = moment.duration(moment().diff(timerState?.startTime));
    return {
      ms: duration.milliseconds(),
      s: duration.seconds(),
      m: duration.minutes(),
      h: duration.hours(),
      d: duration.days(),
      duration,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timerState?.startTime, timer.value]);

  return (
    <TrackingTimerContext.Provider
      value={{
        start,
        stop,
        reset,
        setTracker: activateTracker,
        activeTracker: timerState?.tracker || '',
        timerState: timerState?.state || TIMER_STATE.INIT,
        value: timerValue,
        additionalInfo: getAddInfo(),
        activeSide: timerState?.side || '',
        isWorking,
        getDuration,
        startTime: timerState?.startTime,
        setActiveSide: setSide,
      }}
    >
      {children}
    </TrackingTimerContext.Provider>
  );
};

export const useTrackingTimer = (trackerType?: string) => {
  const { setTracker, isWorking, activeTracker, ...rest } =
    useContext(TrackingTimerContext);

  setTracker(trackerType);

  const isActiveTracker = activeTracker === trackerType;
  return {
    isActiveTracker,
    activeTracker,
    isWorking,
    ...rest,
  };
};
