import {
  ADD_STICKER_TO_JOURNAL_SUCCESS,
  CLEAR_REDUCERS,
  CLEAR_SELECTED_TAG,
  LOAD_ADDED_STICKER_SUCCESS,
  SELECT_STICKER_SUCCESS,
} from '../../actions/actionTypes';

const initialState = {
  selectedStickerTemplate: { area: { tag: { goal: {} } } },
  selectedAddedSticker: { stickerTemplate: { area: { tag: { goal: {} } } } },
};

const selectedStickerReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case SELECT_STICKER_SUCCESS:
      state.selectedStickerTemplate = { ...action.payload };
      state = { ...state };
      return state;
    case ADD_STICKER_TO_JOURNAL_SUCCESS:
      state.selectedStickerTemplate = { ...action.payload };
      state = { ...state };
      return state;
    case LOAD_ADDED_STICKER_SUCCESS:
      state.selectedAddedSticker = { ...action.payload };
      state = { ...state };

      return state;
    case CLEAR_SELECTED_TAG:
      state.selectedStickerTemplate = {
        ...initialState.selectedStickerTemplate,
      };
      state.selectedAddedSticker = {
        ...initialState.selectedAddedSticker,
      };
      state = { ...initialState };
      return state;
    case CLEAR_REDUCERS:
      state.selectedStickerTemplate = {
        ...initialState.selectedStickerTemplate,
      };
      state.selectedAddedSticker = {
        ...initialState.selectedAddedSticker,
      };
      state = { ...initialState };
      return state;
    default:
      return state;
  }
};

export default selectedStickerReducer;
