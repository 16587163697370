import React from 'react';

const Password = ({ width, height }) => {
    const viewBoxValues = '0 0 166 166';

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox={viewBoxValues}
        >
            <defs>
                <filter
                    id="Rectangle_1731"
                    x="0"
                    y="0"
                    width="166"
                    height="166"
                    filterUnits="userSpaceOnUse"
                >
                    <feOffset dy="1" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="1.5" result="blur" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g
                id="Квадрат_с_картинка_за_парола_в_криви"
                data-name="Квадрат с картинка за парола в криви"
                transform="translate(-104.5 -117.5)"
            >
                <g
                    transform="matrix(1, 0, 0, 1, 104.5, 117.5)"
                    filter="url(#Rectangle_1731)"
                >
                    <g
                        id="Rectangle_1731-2"
                        data-name="Rectangle 1731"
                        transform="translate(4.5 3.5)"
                        fill="#fff"
                        stroke="#cacaca"
                        stroke-width="2"
                    >
                        <rect width="157" height="157" rx="12" stroke="none" />
                        <rect
                            x="1"
                            y="1"
                            width="155"
                            height="155"
                            rx="11"
                            fill="none"
                        />
                    </g>
                </g>
                <g
                    id="Цифри-букви-парола"
                    transform="translate(105.469 117.312)"
                >
                    <g
                        id="Картинка_-_Цифри_за_парола"
                        data-name="Картинка - Цифри за парола"
                    >
                        <g id="Group_1529" data-name="Group 1529">
                            <g id="a">
                                <g id="Group_1704" data-name="Group 1704">
                                    <path
                                        id="Path_2534"
                                        data-name="Path 2534"
                                        d="M115.2,66.58a3.1,3.1,0,0,1-1,1.51,2,2,0,0,1-1.83,0c-.92-.34-1.38-2.6-1.38-6.8a43.42,43.42,0,0,1,.21-4.59q-1.72-.46-5.66-1.82t-5.85-2q-2.111,3-4.16,5.92c-1.44,1.75-2.72,2.43-3.83,2a2.345,2.345,0,0,1-1.13-.63c-.12-.18-.08-.51.09-1l.16-.44a29.03,29.03,0,0,1,2.09-3.44q1.89-2.87,4.47-6.54T102.22,42q2.719-3.74,4.32-5.59a32.61,32.61,0,0,1,5-5.13,2.918,2.918,0,0,1,2.69-.44,3.549,3.549,0,0,1,2.16,3c.08.57.14,2.21.17,4.9q0,2.7-.2,7.77c-.13,3.38-.3,6.41-.52,9.11-.18,2.08-.29,4-.35,5.74C115.33,64.71,115.25,66.45,115.2,66.58Zm-3.42-14.16.52-16.64q-1.59,1.82-5.3,6.72t-5,6.68l5,1.72c.74.27,1.49.52,2.25.76S110.86,52.16,111.78,52.42Z"
                                        fill="#87ba3b"
                                    />
                                </g>
                            </g>
                            <g id="_." data-name=" .">
                                <g id="Group_1705" data-name="Group 1705">
                                    <path
                                        id="Path_2535"
                                        data-name="Path 2535"
                                        d="M78.53,40.88a2.231,2.231,0,0,1-1.16,2,4,4,0,0,1-2.06.72,3.14,3.14,0,0,1-2.64-1.49,3.677,3.677,0,0,1-.86-2,2.2,2.2,0,0,1,.72-1.48A3.13,3.13,0,0,1,75,37.52a3.39,3.39,0,0,1,2.55,1.39A3.49,3.49,0,0,1,78.53,40.88Z"
                                        fill="#6bcfc2"
                                    />
                                </g>
                            </g>
                            <g id="_5" data-name=" 5">
                                <g id="Group_1706" data-name="Group 1706">
                                    <path
                                        id="Path_2536"
                                        data-name="Path 2536"
                                        d="M135.17,98.74a6.937,6.937,0,0,1-1.36,2.14,11,11,0,0,1-2.44,2.05,8.8,8.8,0,0,1-3.39,1.34,8.327,8.327,0,0,1-4.17-.47,16.479,16.479,0,0,1-2.76-1.31,12.149,12.149,0,0,1-2.36-1.8,7,7,0,0,1-1.57-2.36,3.08,3.08,0,0,1-.29-2.1,1.907,1.907,0,0,1,.57-.77.829.829,0,0,1,.84-.2,2,2,0,0,1,.92.73c.16.23.31.47.44.7a10.529,10.529,0,0,0,1.49,2.18,9.305,9.305,0,0,0,3.53,2.11,5.188,5.188,0,0,0,2.39.3,6.493,6.493,0,0,0,2.24-.69A6.627,6.627,0,0,0,131,99.24a4.978,4.978,0,0,0,1-1.54,3.091,3.091,0,0,0-.55-3.35,7.417,7.417,0,0,0-3-1.82,17.721,17.721,0,0,0-2.7-.63,11.37,11.37,0,0,1-1.64-.37,1.11,1.11,0,0,1-.78-.89,3,3,0,0,1,.23-1.56c.08-.23.43-.94,1-2.11a24.3,24.3,0,0,0,1.18-2.43,2.576,2.576,0,0,1,0-1.79.909.909,0,0,1,.65-.54,1.584,1.584,0,0,1,1,0l5.18,1.88L137,85.75a4,4,0,0,1,1.44.81,1,1,0,0,1,.25,1.11c-.23.64-.59,1-1.06,1.06a5.8,5.8,0,0,1-2.4-.51L131.85,87c-1.14-.4-2.25-.8-3.36-1.21L127,89a11.164,11.164,0,0,0,1.36.35,9.126,9.126,0,0,1,1.22.3,9.919,9.919,0,0,1,5.22,3.72A5.9,5.9,0,0,1,135.17,98.74Z"
                                        fill="#ffd500"
                                    />
                                </g>
                            </g>
                            <g id="_2" data-name=" 2">
                                <g id="Group_1707" data-name="Group 1707">
                                    <path
                                        id="Path_2537"
                                        data-name="Path 2537"
                                        d="M48.72,118.09a.71.71,0,0,1-.34.9,8.229,8.229,0,0,1-1.8.83l-1.1.4-2.79,1c-1.25.48-2.12.8-2.61,1a7.913,7.913,0,0,1-1.8.5,1.12,1.12,0,0,1-1.23-.77,2.322,2.322,0,0,1,.08-1.69c.27-.56.48-1,.64-1.41l.86-2.55a27.693,27.693,0,0,0,.88-3.2,19.032,19.032,0,0,0,.4-3.38,7.644,7.644,0,0,0-.37-2.76,3.12,3.12,0,0,0-1.15-1.67,1.828,1.828,0,0,0-1.72-.09,2.3,2.3,0,0,0-1.36,1.19,3.079,3.079,0,0,0-.24,1.8c.11.63-.2,1.08-.91,1.34a.841.841,0,0,1-.87-.26,2.182,2.182,0,0,1-.57-.79,3.5,3.5,0,0,1,.45-3.15A6,6,0,0,1,36.35,103a4,4,0,0,1,3.31.07A4.833,4.833,0,0,1,42,105.8a9.228,9.228,0,0,1,.41,3.33,27.458,27.458,0,0,1-.45,3.94q-.281,1.75-.78,3.66c-.27,1.06-.51,1.87-.71,2.43l6-2.19a2.238,2.238,0,0,1,1.41-.23,1.47,1.47,0,0,1,.61.87Z"
                                        fill="#ff8901"
                                    />
                                </g>
                            </g>
                            <g id="k">
                                <g id="Group_1708" data-name="Group 1708">
                                    <path
                                        id="Path_2538"
                                        data-name="Path 2538"
                                        d="M107.56,113.51a1.568,1.568,0,0,1,0,.92c-.08.18-.36.3-.82.38s-1.6-.5-3.36-1.76l-2.64-2c-.65-.47-1.25-.92-1.78-1.34s-1-.86-1.54-1.31l1.12,6.39a1.555,1.555,0,0,1-.12,1,1,1,0,0,1-.76.54c-.74.13-1.19-.21-1.33-1-.1-.57-.31-1.69-.62-3.36s-.54-2.88-.66-3.6c-.22-1.19-.55-2.95-1-5.3s-.67-3.52-.68-3.54a1.411,1.411,0,0,1,.23-1.12,1.24,1.24,0,0,1,.8-.54,1,1,0,0,1,1.13.38A4.847,4.847,0,0,1,96,99.92l1,5.59a21.905,21.905,0,0,0,1.28-2.07l.87-1.55c.6-1.07,1.2-2.16,1.82-3.25q1.14-1.871,1.86-2a1,1,0,0,1,.7.07.76.76,0,0,1,.28.53c.09.49-.29,1.46-1.13,2.89l-1.46,2.39c-.33.55-.81,1.43-1.43,2.64a18.672,18.672,0,0,1-.92,1.67c.28.25,1.2.93,2.75,2q3.15,2.27,3.9,2.85A8.533,8.533,0,0,1,107.56,113.51Z"
                                        fill="#5abae9"
                                    />
                                </g>
                            </g>
                            <g id="_" data-name=" ">
                                <g id="Group_1709" data-name="Group 1709">
                                    <path
                                        id="Path_2539"
                                        data-name="Path 2539"
                                        d="M80.2,131.86a2.054,2.054,0,0,1,0,1.1,1.289,1.289,0,0,1-1,.68,2.435,2.435,0,0,1-.79,0,11.136,11.136,0,0,1-1.41-.5c-.26-.12-.54-.22-.83-.32a2.791,2.791,0,0,0-.76-.17c.07.37.13.73.2,1.1s.14.72.22,1.06.11.58.16.86,0,.75.07,1a1.17,1.17,0,0,1-.42.92,1.78,1.78,0,0,1-1,.6,1.341,1.341,0,0,1-1.17-.2,2.051,2.051,0,0,1-.59-1c-.11-.325-.2-.656-.28-.99a5.824,5.824,0,0,1,0-1.9l-1.4,1.5a7.806,7.806,0,0,1-1.2,1.09,3.57,3.57,0,0,1-1.51.65.9.9,0,0,1-1.149-.547.91.91,0,0,1-.051-.253,1.9,1.9,0,0,1,.13-1.1,6.992,6.992,0,0,1,1.25-1.67l2.07-2.27c-1.48-.61-2.26-.92-2.31-.93-1.13-.56-1.75-1.13-1.85-1.71-.15-.84.12-1.32.8-1.44a4.809,4.809,0,0,1,2.26.33,9.683,9.683,0,0,1,2,.94c-.1-1.33-.17-2.4-.2-3.21-.06-1.54.36-2.38,1.26-2.54s1.6.11,1.74.87a12.245,12.245,0,0,1,.07,1.68v2.54a11.228,11.228,0,0,1,1-.94,2.9,2.9,0,0,1,1.28-.8,2.41,2.41,0,0,1,1.2.09.877.877,0,0,1,.63.6c.17.51-.06,1.11-.7,1.79a12.629,12.629,0,0,1-1.22,1.15,18.2,18.2,0,0,1,2.13.8Q80.109,131.31,80.2,131.86Z"
                                        fill="#95e9a0"
                                    />
                                </g>
                            </g>
                            <g id="_7" data-name=" 7">
                                <g id="Group_1710" data-name="Group 1710">
                                    <path
                                        id="Path_2540"
                                        data-name="Path 2540"
                                        d="M57.33,34.1a1.817,1.817,0,0,1-.14,1.09,3.513,3.513,0,0,1-.74,1.09c-.34.9-.73,2.06-1.17,3.47s-.94,3.12-1.51,5.12l1.52-.27a11.85,11.85,0,0,1,1.55-.17,2.108,2.108,0,0,1,1.16.19,1.211,1.211,0,0,1,.52.92,2.218,2.218,0,0,1-.12,1.56,1.829,1.829,0,0,1-1.31.67l-4.2.86c-.09.52-.27,1.78-.52,3.78-.17,1.27-.38,2.81-.61,4.62-.08.75-.15,1.49-.2,2.22-.15.5-.64.82-1.46,1A1.808,1.808,0,0,1,48.55,60a2,2,0,0,1-.66-1.27c0-.2.12-1.61.45-4.21s.6-4.3.8-5.08l-2.85.43c-1.64.29-2.54,0-2.7-.91a2.325,2.325,0,0,1,.12-1.49,1.936,1.936,0,0,1,1.2-.81c.61-.18,1.5-.39,2.66-.62s2-.4,2.63-.51c.19-1,.59-2.52,1.2-4.61.48-1.64.91-3,1.29-4.15-.38.07-2.17.45-5.34,1.14S42,39,40.7,39.26a2.641,2.641,0,0,1-1.36-.16,1.171,1.171,0,0,1-.85-.92c-.15-.84.63-1.54,2.36-2.1a63.793,63.793,0,0,1,7.81-1.75l3.07-.55,1.78-.33c.55-.11,1.07-.22,1.55-.34a4.192,4.192,0,0,1,1.5.12A1,1,0,0,1,57.33,34.1Z"
                                        fill="#ab7ece"
                                    />
                                </g>
                            </g>
                            <g id="_-" data-name=" -">
                                <g id="Group_1711" data-name="Group 1711">
                                    <path
                                        id="Path_2541"
                                        data-name="Path 2541"
                                        d="M100.73,77.27a1.389,1.389,0,0,1-.38,1.44,4.24,4.24,0,0,1-1.87.68L94,80.22l-2.46.42-2.83.44A1.763,1.763,0,0,1,87.48,81a1.142,1.142,0,0,1-.68-.87c-.09-.5.26-1,1-1.35a8.892,8.892,0,0,1,2.62-.87l7-1.31a7.5,7.5,0,0,1,2.11-.08C100.27,76.6,100.66,76.85,100.73,77.27Z"
                                        fill="#e95a9c"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                    <g id="Group_1712" data-name="Group 1712">
                        <path
                            id="Path_2542"
                            data-name="Path 2542"
                            d="M64.14,97.65a14,14,0,0,1-7.4-.7,11.52,11.52,0,0,1-5.4-4.1,15.577,15.577,0,0,1-2.7-7,16.577,16.577,0,0,1,.2-5.9,14.467,14.467,0,0,1,2.2-4.9,13.486,13.486,0,0,1,4-3.5,16,16,0,0,1,5.6-1.8,14.72,14.72,0,0,1,6.7.5,10.811,10.811,0,0,1,4.9,3.5,11.567,11.567,0,0,1,2.4,6,13.3,13.3,0,0,1-.1,4.9,6.837,6.837,0,0,1-1.7,3.4,4.934,4.934,0,0,1-3,1.6,4.356,4.356,0,0,1-2.6-.4,3.242,3.242,0,0,1-1.5-1.9,5.462,5.462,0,0,1-1.8,2.4,6.193,6.193,0,0,1-2.5,1.1,5.217,5.217,0,0,1-4.1-1,6.4,6.4,0,0,1-2-4.1,10.211,10.211,0,0,1,.3-4.3,7,7,0,0,1,2-3.2,5.612,5.612,0,0,1,3.3-1.5,3.86,3.86,0,0,1,4.3,1.8l.1-1a1.27,1.27,0,0,1,1.132-1.4l.068,0q1.35-.15,1.2,1.2l-.2,6.8a7.588,7.588,0,0,0,.1,1.6c.2,1.4.9,1.9,2.1,1.8a3.35,3.35,0,0,0,2.5-2.3,10.721,10.721,0,0,0,.4-5.2,10.186,10.186,0,0,0-2-5,8.138,8.138,0,0,0-4-2.8,11.5,11.5,0,0,0-5.6-.4,12.417,12.417,0,0,0-5.9,2.3,10.256,10.256,0,0,0-3.5,4.7,13.8,13.8,0,0,0-.6,6.5,12.775,12.775,0,0,0,2.3,6.1,10.006,10.006,0,0,0,4.4,3.5,12.158,12.158,0,0,0,6.1.6,12.462,12.462,0,0,0,4-1.2,10.826,10.826,0,0,0,2.9-2.1c.4-.4.8-.5,1.1-.3a1,1,0,0,1,.7.8,1.472,1.472,0,0,1-.4,1.2A13.332,13.332,0,0,1,64.14,97.65Zm-2.6-9a3.578,3.578,0,0,0,2.2-1.1,5.532,5.532,0,0,0,1.3-2.4,7.272,7.272,0,0,0,.1-3.3c-.3-2.2-1.5-3.2-3.5-2.9a3.21,3.21,0,0,0-2.2,1,4.778,4.778,0,0,0-1.3,2.3,7.869,7.869,0,0,0-.2,3.1,4.846,4.846,0,0,0,1.2,2.7A2.761,2.761,0,0,0,61.54,88.65Z"
                            fill="#4f87c6"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default Password;
