import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import * as i18n from 'i18next';
import { Container, Col, Button } from 'reactstrap';

import LocalNavigationComponent from '../LocalNavigationComponent';
import ForgottenPassword from '../common/svgComponents/ForgottenPassword';
import { Fields } from '../common/Fields';
import { formUtils } from '../../utils/formUtils';
import { navigateToLoginPage, requestPasswordReset } from '../../actions';

class RequestPasswordResetScreen extends Component {
    render = () => {
        return (
            <Container className="noPadding">
                <Col
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    className="centred noPadding"
                >
                    <LocalNavigationComponent
                        header={i18n.t('requestPasswordResetScreen:title')}
                        previousPage={this.props.navigateToLoginPage}
                    />
                    <form
                        onSubmit={this.props.handleSubmit(this.onSubmit)}
                        className="flexContainerColumn flexContainerCentered fix-padding"
                    >
                        <p style={{ marginTop: 30 }} />
                        <div
                            xs={10}
                            sm={10}
                            md={10}
                            lg={8}
                            className="flexContainerCentered"
                        >
                            <ForgottenPassword width={166} height={168} />
                        </div>
                        <div
                            className="flexContainerColumn flexContainerCentered"
                            style={{ marginTop: '48px', textAlign: 'center' }}
                        >
                            <div>
                                {i18n.t(
                                    'requestPasswordResetScreen:explanationLabelLine1'
                                )}
                            </div>
                            <div>
                                {i18n.t(
                                    'requestPasswordResetScreen:explanationLabelLine2'
                                )}
                            </div>
                        </div>
                        <div className="flexContainerColumn flexContainerCentered">
                            {Fields.commonFields.email()}
                        </div>
                        <Col
                            xs={10}
                            sm={10}
                            md={10}
                            lg={8}
                            className="flexContainerColumn centred"
                            style={{ margin: '24px 0px 12px 0px' }}
                        >
                            <Button className="greenBtn header next uppercase">
                                {i18n.t(
                                    'requestPasswordResetScreen:submitButton'
                                )}
                            </Button>
                        </Col>
                    </form>
                </Col>
            </Container>
        );
    };

    onSubmit = (formValues) =>
        this.props.requestPasswordReset(formValues.email);
}

const requestPasswordResetForm = reduxForm({
    form: 'requestPasswordResetForm',
    validate: formUtils.validateRequestPasswordReset,
})(RequestPasswordResetScreen);

export default connect(null, { requestPasswordReset, navigateToLoginPage })(
    requestPasswordResetForm
);
