import React from 'react';

const ReadingIcon = ({ width, height }) => {
    const viewBoxValues = '0 0 52 52';

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox={viewBoxValues}
        >
            <defs>
                <filter
                    id="Квадрат_бял_със_сянка"
                    x="0"
                    y="0"
                    width="51.833"
                    height="51.833"
                    filterUnits="userSpaceOnUse"
                >
                    <feOffset dy="1" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="1" result="blur" />
                    <feFlood floodOpacity="0.161" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g
                id="Икона_-_Четете"
                data-name="Икона - Четете"
                transform="translate(-44.125 -452.167)"
            >
                <g
                    transform="matrix(1, 0, 0, 1, 44.12, 452.17)"
                    filter="url(#Квадрат_бял_със_сянка)"
                >
                    <g
                        id="Квадрат_бял_със_сянка-2"
                        data-name="Квадрат бял със сянка"
                        transform="translate(3 2)"
                        fill="#fff"
                        stroke="#cacaca"
                        strokeWidth="1"
                    >
                        <rect
                            width="45.833"
                            height="45.833"
                            rx="6"
                            stroke="none"
                        />
                        <rect
                            x="0.5"
                            y="0.5"
                            width="44.833"
                            height="44.833"
                            rx="5.5"
                            fill="none"
                        />
                    </g>
                </g>
                <path
                    id="Икона_-_Прочети"
                    data-name="Икона - Прочети"
                    d="M15.241,4.157a4.157,4.157,0,1,0-4.157,4.157A4.157,4.157,0,0,0,15.241,4.157Zm-5.127,6.283c-2.569-1.573-6.73-2-8.824-2.124A1.2,1.2,0,0,0,0,9.489v9.647a1.191,1.191,0,0,0,1.147,1.171c1.89.1,5.715.462,8.358,1.794a.6.6,0,0,0,.887-.514V10.936a.576.576,0,0,0-.278-.5ZM20.879,8.316c-2.094.119-6.255.551-8.824,2.124a.587.587,0,0,0-.278.5V21.586a.6.6,0,0,0,.889.515c2.643-1.33,6.465-1.693,8.355-1.793a1.192,1.192,0,0,0,1.147-1.172V9.489A1.2,1.2,0,0,0,20.879,8.316Z"
                    transform="translate(59.293 465.999)"
                    fill="none"
                    stroke="#87ba3b"
                    strokeWidth="1.7"
                />
            </g>
        </svg>
    );
};

export default ReadingIcon;
