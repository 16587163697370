import { FC } from 'react';
import { TRACKERS_SUB_TYPE } from '../../../utils/constants';
import { BreastFeedingLeftButton } from '../breast-feeding/BreastFeedingLeftButon';
import { SleepDayButton } from '../sleep/SleepDayButton';
import { BUTTON_SIDES, TrackingButtonProps } from './models';
import { useTrackingButtonProps } from './hooks/useTrackingButtonProps';

const buttonSide = BUTTON_SIDES.LEFT;

export const LeftButton: FC<TrackingButtonProps> = (props) => {
  const newProps = useTrackingButtonProps(buttonSide, props);
  const { tracker } = props;

  switch (tracker) {
    case TRACKERS_SUB_TYPE.SLEEP:
      return <SleepDayButton {...newProps} />;
    case TRACKERS_SUB_TYPE.BREAST_FEEDING:
      return <BreastFeedingLeftButton {...newProps} />;
    default:
      return null;
  }
};
