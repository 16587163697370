import { FC } from 'react';
import {
  SleepTrackerButtonStartNightDisabledIcon,
  SleepTrackerButtonStartNightIcon,
  SleepTrackerButtonStopNightIcon,
} from '../../common/icons';
import { StartStopButton } from '../shared/StartStopButton';
import { LeftRightButtonProps } from '../tracking/models';

export const SleepNightButton: FC<LeftRightButtonProps> = (props) => {
  return (
    <StartStopButton
      startButton={SleepTrackerButtonStartNightIcon}
      stopButton={SleepTrackerButtonStopNightIcon}
      disabledButton={SleepTrackerButtonStartNightDisabledIcon}
      {...props}
    />
  );
};
