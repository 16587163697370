import { Box, Typography } from '@mui/material';
import React from 'react';
import moment from '../../utils/moment';

const DateDivider = ({ current, previous }) => {
  if (
    !previous ||
    moment(current.date).format('DD.MM.YYYY') !==
      moment(previous.date).format('DD.MM.YYYY')
  ) {
    return (
      <Box with="100%" textAlign={'center'} paddingTop={2}>
        <Typography>{moment(current.date).format('DD.MM.YYYY')}</Typography>
      </Box>
    );
  }
  return null;
};

export default DateDivider;
