export const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;
export const FRONTEND_BASE_URL = process.env.REACT_APP_FRONTEND_BASE_URL;
export const SYSTEM_IMAGES_RELATIVE_URL =
  process.env.REACT_APP_SYSTEM_IMAGES_RELATIVE_URL;
export const USER_IMAGES_RELATIVE_URL =
  process.env.REACT_APP_USER_IMAGES_RELATIVE_URL;
export const STICKER_FILE_SIZE_LIMIT_IN_MB =
  process.env.REACT_APP_STICKER_FILE_SIZE_LIMIT_IN_MB;
export const GOOGLE_CAPTCHA_SITE_KEY =
  process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY;
export const USER_REGISTRATION_DISABLED = process.env
  .REACT_APP_USER_REGISTRATION_DISABLED
  ? JSON.parse(process.env.REACT_APP_USER_REGISTRATION_DISABLED.toLowerCase())
  : false;

export const TOKEN_IN_LOCAL_STORAGE = 'USER_TOKEN';
export const USER_DATA_IN_LOCAL_STORAGE = 'USER_DATA';
export const SELECTED_GOAL_IN_LOCAL_STORAGE = 'SELECTED_GOAL';
export const SELECTED_TAG_IN_LOCAL_STORAGE = 'SELECTED_TAG';
export const SELECTED_STICKER_IN_LOCAL_STORAGE = 'SELECTED_STICKER';
export const ADD_STICKER_IN_LOCAL_STORAGE = 'ADD_STICKER';
export const GOALS = 'GOALS';
export const SELECTED_STICKER_IMAGE_DATA = 'IMAGE_DATA';
export const PAID_FUNCTIONALITY_CREDENTIALS_IN_LOCAL_STORAGE =
  'PAID_FUNCTIONALITY_CREDENTIALS_IN_LOCAL_STORAGE';

export const DEFAULT_LOCALE_CODE = 'bg';

export const DEFAULT_BACKEND_DATE_FORMAT = 'YYYY-MM-DD';

export const EMPTY_STRING = '';
export const FOUR_WEEKS_BEFORE_DUE_DATE = 30;

export const PWA_VERSION = 11;

export const REQUEST_RESPONSE_CODE = {
  NOT_ACCEPTABLE_406: 406,
};

export const NUMBER_OF_BYTES_IN_MB = 1048576;
export const IMAGE_MAX_SIZE_IN_BYTES =
  process.env.REACT_APP_STICKER_FILE_SIZE_LIMIT_IN_MB * NUMBER_OF_BYTES_IN_MB;
export const SUPPORTED_IMAGE_FORMATS = ['JPEG', 'JPEG', 'PNG'];

export const MIN_TEXT_FIELD_LENGTH = {
  PASSWORD: 6,
  USERNAME: 6,
};

export const MAX_TEXT_FIELD_LENGTH = {
  SHORT: 20,
  NORMAL: 100,
  LONG: 250,
  VERY_LONG: 1000,
};

export const ALLOWED_SPECIALISTS_PER_USER = 20;

export const USEFUL_INFO_MAX_MONTH = 12;

export const FORMS = {
  REGISTER_FORM: 'registerForm',
  PROFILE_FORM: 'profileForm',
  TAG_FORM: 'tagForm',
  STICKER_FORM: 'stickerForm',
  SPECIALIST_FORM: 'specialistForm',
  ADMIN_FORM: 'adminForm',
};

export const RELATIVE_PAGE_PATHS = {
  HOME: '/',
  REGISTER: '/register',
  SELECTED_TAG: '/tag/',
  ACTIVATE_PROFILE: '/activate-profile',
  DELETE_PROFILE: '/delete-profile',
  PROFILE: '/profile',
  CHANGE_PASSWORD: '/change-password',
  REQUEST_PASSWORD_RESET: '/request-password-reset',
  RESET_PASSWORD: '/reset-password',
  LOGIN: '/login',
  NOT_FOUND: '/404',
  INTERNAL_SERVER_ERROR: '/500',
  UNHANDLED_ERROR: '/unhandled-error',
  JOURNAL: '/journal',
  REQUEST_JOURNAL_DOWNLOAD: '/request-journal-download',
  DOWNLOAD_JOURNAL: '/download-journal',
  JOURNAL_READ_ONLY: '/read-only-journal',
  EXPIRED_STICKERS: '/expired-stickers',
  ADD_STICKER: '/add-sticker-to-journal/',
  EDIT_STICKER: '/sticker/',
  STICKER_READ_ONLY: '/read-only-sticker/',
  SPECIALISTS: '/consult/',
  ADD_SPECIALISTS: '/add-consult/',
  PAID_FUNCTIONALITY: '/unlock-paid-stickers',
  USEFUL_INFO: '/useful-info',
  PHOTO_PREVIEW: '/photo/',
  BREAST_FEEDING: '/tracking/breast-feeding',
  TRACKING: '/tracking',
  TRACK: '/track',
  SLEEP: '/tracking/sleep',
};

export const TAG_NAMES = {
  ABILITIES: 'Умения',
  WORRIES: 'Притеснения',
  PROPHYLACTICS: 'Профилактични',
  CELEBRATIONS: 'Празнични моменти',
  CREATIVE_PERFORMANCES: 'Творчески изяви',
  CAPTURED_MOMENTS: 'Уловени мигове',
};

export const EXTERNAL_URLS = {
  TERMS_AND_CONDITIONS: 'https://www.namama.bg/page/obshti-usloviya',
  GDPR: 'https://www.namama.bg/GDPR/',
  POLICY: 'https://www.namama.bg/',
  NEWSLETTER: 'https://www.namama.bg/newsletter/',
};

export const COLOR_SCHEME = {
  DARK: '#4b4b4b',
  LIGHTER_DARK: '#9f9f9f',
  GREY: '#848484',
  DARK_GREY: '#adadad',
  LIGHT_BLUE: '#f1f9ff',
  WHITE: '#ffffff',
  SHADOW_COLOR: '#F7F7F7',
  LIGHT: '#f5f5f5',
  LIGHT_GRAY: '#cacaca',
  GRAY: '#c9c9c9',
  LIGHTER_GREEN: '#f1ffd5',
  GREEN: '#87ba3b',
  LIGHTER_YELLOW: '#fffded',
  YELLOW: '#ffd400',
  ORANGE: '#ff8901',
  RED: '#fe3f43',
};

export const DMT_WEBSITE = 'https://namamaitatko.bg/';

export const DOWNLOADED_JOURNAL_FILE_NAME = 'Дневник.zip';

export const TRACKERS_TYPE = {
  TRACKING: 'tracking',
  FEEDING: 'feeding',
  MEASUREMENT: 'measurement',
  CHANGE_DIAPERS: 'change-diapers',
};

export const TRACKERS_SUB_TYPE = {
  BREAST_FEEDING: 'breast-feeding',
  SLEEP: 'sleep',
};
