import { FC, FunctionComponent, SVGProps, createElement } from 'react';
import { LeftRightButtonProps } from '../tracking/models';

interface StartStopButtonProps extends LeftRightButtonProps {
  startButton: FunctionComponent<SVGProps<SVGSVGElement>>;
  disabledButton: FunctionComponent<SVGProps<SVGSVGElement>>;
  stopButton: FunctionComponent<SVGProps<SVGSVGElement>>;
  color?: string;
}
export const StartStopButton: FC<StartStopButtonProps> = ({
  stopButton,
  startButton,
  disabledButton,
  disabled,
  onClick,
  isActive,
  color,
}) => {
  if (disabled) {
    return createElement(disabledButton);
  }
  if (isActive) {
    return createElement(stopButton, { onClick, color });
  }
  return createElement(startButton, { onClick, color });
};
