import React from 'react';

import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#87BA3B',
      contrastText: 'rgba(255,255,255,0.95)',
    },
  },
  typography: {
    fontFamily: ['Nunito', 'Roboto'].join(','),
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        // disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 24,
          paddingLeft: 32,
          paddingRight: 32,
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'none',
      },
    },
    MuiIcon: {
      styleOverrides: {
        fontSizeLarge: {
          fontSize: 54,
        },
      },
    },
  },
});

const DmtThemeProvider = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default DmtThemeProvider;
