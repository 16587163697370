import {
  CLEAR_REDUCERS,
  CLEAR_SELECTED_TAG,
  SELECT_TAG_SUCCESS,
} from '../../actions/actionTypes';

const initialState = { goal: {} };

const selectedTagReducer = (state = { ...initialState }, action) => {
  let updatedState = {};
  switch (action.type) {
    case SELECT_TAG_SUCCESS:
      updatedState = { ...initialState };
      updatedState = action.payload;

      return updatedState;
    case CLEAR_SELECTED_TAG:
      updatedState = { ...initialState };
      return updatedState;
    case CLEAR_REDUCERS:
      updatedState = { ...initialState };
      return updatedState;
    default:
      return state;
  }
};

export default selectedTagReducer;
