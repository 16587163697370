import React from 'react';

const Erase = ({ width, height }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 164 164"
        >
            <defs>
                <filter
                    id="Rectangle_1990"
                    x="0"
                    y="0"
                    width="164"
                    height="164"
                    filterUnits="userSpaceOnUse"
                >
                    <feOffset dy="1" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="1" result="blur" />
                    <feFlood flood-opacity="0.157" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g
                id="Квадрат_с_картинка_-_гума"
                data-name="Квадрат с картинка - гума"
                transform="translate(-106 -109)"
            >
                <g
                    transform="matrix(1, 0, 0, 1, 106, 109)"
                    filter="url(#Rectangle_1990)"
                >
                    <g
                        id="Rectangle_1990-2"
                        data-name="Rectangle 1990"
                        transform="translate(3 2)"
                        fill="#fff"
                        stroke="#cacaca"
                        stroke-width="2"
                    >
                        <rect width="158" height="158" rx="11" stroke="none" />
                        <rect
                            x="1"
                            y="1"
                            width="156"
                            height="156"
                            rx="10"
                            fill="none"
                        />
                    </g>
                </g>
                <g
                    id="Group_1347"
                    data-name="Group 1347"
                    transform="translate(1684.943 -574.403)"
                >
                    <rect
                        id="Rectangle_1723"
                        data-name="Rectangle 1723"
                        width="64.416"
                        height="3.077"
                        rx="1.539"
                        transform="translate(-1527.308 788.976)"
                        fill="#8abb2a"
                    />
                    <rect
                        id="Rectangle_1724"
                        data-name="Rectangle 1724"
                        width="13.232"
                        height="3.077"
                        rx="1.539"
                        transform="translate(-1551.301 778.352) rotate(12.039)"
                        fill="#8abb2a"
                    />
                    <rect
                        id="Rectangle_1725"
                        data-name="Rectangle 1725"
                        width="13.163"
                        height="3.077"
                        rx="1.539"
                        transform="matrix(0.933, -0.359, 0.359, 0.933, -1550.037, 793.703)"
                        fill="#8abb2a"
                    />
                    <rect
                        id="Rectangle_1726"
                        data-name="Rectangle 1726"
                        width="12.463"
                        height="3.077"
                        rx="1.539"
                        transform="translate(-1541.224 766.122) rotate(44.517)"
                        fill="#8abb2a"
                    />
                    <path
                        id="Path_1592"
                        data-name="Path 1592"
                        d="M-1417.7,865.822h8.895l6.362-4.965-3.367-4.314Z"
                        transform="translate(-79.167 -76.845)"
                        fill="#199aba"
                    />
                    <path
                        id="Path_1593"
                        data-name="Path 1593"
                        d="M-1352.263,773.241l-3.367-4.314a7.984,7.984,0,0,1-1.382,11.205l-31.7,24.743,3.367,4.314,31.7-24.743A7.983,7.983,0,0,0-1352.263,773.241Z"
                        transform="translate(-96.256 -25.178)"
                        fill="#00617c"
                    />
                    <path
                        id="Path_1594"
                        data-name="Path 1594"
                        d="M-1435.216,796.961l2.02,2.588h0l-2.02-2.588Z"
                        transform="translate(-68.835 -41.71)"
                        fill="#006c88"
                    />
                    <path
                        id="Path_1595"
                        data-name="Path 1595"
                        d="M-1385.953,743.749l-11.274-14.446a7.984,7.984,0,0,0-11.205-1.382l-31.7,24.743,2.019,2.587,2.019,2.587,17.059,21.858,31.7-24.743A7.983,7.983,0,0,0-1385.953,743.749Z"
                        transform="translate(-65.933 0)"
                        fill="#0079a0"
                    />
                    <path
                        id="Path_1596"
                        data-name="Path 1596"
                        d="M-1463.634,795.83l-2.019-2.587-2.019-2.587-16.086,12.554a7.983,7.983,0,0,0-1.382,11.205l9.8,12.552h16.879l11.89-9.279Z"
                        transform="translate(-38.397 -37.992)"
                        fill="#45c0e3"
                    />
                    <g
                        id="Group_1346"
                        data-name="Group 1346"
                        transform="translate(-1507.891 732.061)"
                    >
                        <path
                            id="Path_1597"
                            data-name="Path 1597"
                            d="M-1397.131,745.944l2.694,3.452a1.642,1.642,0,0,0,2.3.284,1.641,1.641,0,0,0,.284-2.3l-2.694-3.452a9.057,9.057,0,0,0-12.712-1.568l-27.532,21.487,2.019,2.588,27.532-21.487A5.774,5.774,0,0,1-1397.131,745.944Z"
                            transform="translate(1438.803 -740.439)"
                            fill="#008bb3"
                        />
                        <path
                            id="Path_1598"
                            data-name="Path 1598"
                            d="M-1443.944,800.123a1.641,1.641,0,0,0-.284,2.3,1.641,1.641,0,0,0,2.3.284l3.384-2.641-2.019-2.588Z"
                            transform="translate(1444.575 -774.078)"
                            fill="#5cc6e4"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default Erase;
