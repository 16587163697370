import * as i18n from 'i18next';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Container } from 'reactstrap';
import { reduxForm } from 'redux-form';

import { changePassword } from '../../actions';
import appUtils from '../../utils/appUtils';
import { formUtils } from '../../utils/formUtils';
import { Fields } from '../common/Fields';
import Password from '../common/svgComponents/Password';

class ChangePasswordScreen extends Component {
    state = {
        resetPasswordToken: null,
    };

    componentDidMount = () => {
        var token = appUtils.getQueryStringAfterFirstEqualsCharacter(
            this.props.location.search
        );
        this.setState({ resetPasswordToken: token });
    };

    render = () => {
        return (
            <Container className="noPadding">
                <Col
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    className="centred noPadding"
                >
                    <form
                        onSubmit={this.props.handleSubmit(this.onSubmit)}
                        className="flexContainerColumn flexContainerCentered fix-padding"
                    >
                        <p style={{ marginTop: 30 }} />
                        <div
                            xs={10}
                            sm={10}
                            md={10}
                            lg={8}
                            className="flexContainerCentered"
                        >
                            <Password width={166} height={168} />
                        </div>
                        <div className="flexContainerColumn flexContainerCentered">
                            {Fields.commonFields.oldPassword({
                                className:
                                    'change-password-field input-container',
                            })}
                            {Fields.commonFields.password({
                                className:
                                    'change-password-field input-container',
                            })}
                            {Fields.commonFields.repeatPassword({
                                className:
                                    'change-password-field input-container',
                            })}
                            <span style={{ fontSize: 12, color: '#9f9f9f' }}>
                                {i18n.t('shared:labels.passwordLength')}
                            </span>
                        </div>
                        <Col
                            xs={10}
                            sm={10}
                            md={10}
                            lg={8}
                            className="flexContainerColumn centred"
                            style={{ margin: '24px 0px 12px 0px' }}
                        >
                            <Button className="greenBtn header next uppercase">
                                {i18n.t('buttons:save')}
                            </Button>
                        </Col>
                    </form>
                </Col>
            </Container>
        );
    };

    onSubmit = (formValues) =>
        this.props.changePassword(formValues.oldPassword, formValues.password);
}

const changePasswordForm = reduxForm({
    form: 'changePasswordForm',
    validate: formUtils.validateChangePassword,
})(ChangePasswordScreen);

export default connect(null, { changePassword })(changePasswordForm);
