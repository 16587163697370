import React from 'react';

const Lock = ({ style }) => {
    const viewBoxValues = '0 0 132 132';

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox={viewBoxValues}
            style={style}
        >
            <defs>
                <filter
                    id="Path_1723"
                    x="41.211"
                    y="24.292"
                    width="50.595"
                    height="42.305"
                    filterUnits="userSpaceOnUse"
                >
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="3" result="blur" />
                    <feFlood floodOpacity="0.161" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <filter
                    id="Path_1724"
                    x="36.109"
                    y="49.821"
                    width="60.788"
                    height="54.359"
                    filterUnits="userSpaceOnUse"
                >
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="3" result="blur-2" />
                    <feFlood floodOpacity="0.161" />
                    <feComposite operator="in" in2="blur-2" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g
                id="Катинар_за_стикер"
                data-name="Катинар за стикер"
                transform="translate(-38.833 -858.706)"
            >
                <rect
                    id="Rectangle_1971"
                    data-name="Rectangle 1971"
                    width="133"
                    height="132"
                    rx="11"
                    transform="translate(38.833 858.706)"
                    fill="#afadad"
                    opacity="0.61"
                />
                <g
                    id="Group_1692"
                    data-name="Group 1692"
                    transform="translate(83.942 888.998)"
                >
                    <path
                        id="Path_1722"
                        data-name="Path 1722"
                        d="M4053.961,961.766a10.915,10.915,0,0,0-10.9,10.9v7.942h21.805V972.67a10.916,10.916,0,0,0-10.9-10.9Z"
                        transform="translate(-4032.434 -955.091)"
                        fill="none"
                    />
                    <g
                        transform="matrix(1, 0, 0, 1, -45.11, -30.29)"
                        filter="url(#Path_1723)"
                    >
                        <path
                            id="Path_1723-2"
                            data-name="Path 1723"
                            d="M4033.071,967.437v-7.942a10.9,10.9,0,0,1,21.807,0v7.942h4.567a5.863,5.863,0,0,1,.826.065v-8.007a16.3,16.3,0,0,0-16.3-16.3h0a16.3,16.3,0,0,0-16.3,16.3v7.97c.182-.017.365-.028.551-.028Z"
                            transform="translate(-3977.47 -912.9)"
                            fill="#fff"
                        />
                    </g>
                    <g
                        transform="matrix(1, 0, 0, 1, -45.11, -30.29)"
                        filter="url(#Path_1724)"
                    >
                        <path
                            id="Path_1724-2"
                            data-name="Path 1724"
                            d="M4051.339,1014.239a5.805,5.805,0,0,0-.826-.066h-31.219c-.186,0-.369.011-.551.028a5.784,5.784,0,0,0-5.234,5.756v24.791a5.784,5.784,0,0,0,5.784,5.784h31.219a5.784,5.784,0,0,0,5.784-5.784v-24.791a5.78,5.78,0,0,0-4.957-5.718Zm-13.313,20.1v4.072a2.984,2.984,0,1,1-5.968,0v-4.072a5.923,5.923,0,1,1,5.968,0Z"
                            transform="translate(-3968.4 -958.35)"
                            fill="#fff"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default Lock;
