import React from 'react';

const FacebookIcon = ({ color }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18.728"
            height="18.586"
            viewBox="0 0 18.728 18.586"
        >
            <path
                id="Икона_-_facebook"
                data-name="Икона - facebook"
                d="M-424.908,1208.568a9.293,9.293,0,0,1-7.872-9.284,9.327,9.327,0,0,1,9.174-9.283,9.3,9.3,0,0,1,9.544,8.939,9.3,9.3,0,0,1-7.858,9.645V1202.1c.559,0,1.129-.018,1.7.006.332.014.5-.042.538-.43.077-.759.225-1.51.351-2.309h-2.572a16.775,16.775,0,0,1,.018-2.092c.1-.8.624-1.109,1.644-1.131.317-.007.634,0,.973,0v-2.3a7.992,7.992,0,0,0-2.966-.1,3.149,3.149,0,0,0-2.634,3.2c-.01.79,0,1.579,0,2.4h-2.366v2.754h2.332Z"
                transform="translate(432.781 -1189.998)"
                fill={color || '#c9c9c9'}
            />
        </svg>
    );
};

export default FacebookIcon;
