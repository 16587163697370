import React from 'react';

const RoundBulb = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43.455 44.693">
            <defs>
                <filter
                    id="Ellipse_1000"
                    x="1.455"
                    y="0"
                    width="42"
                    height="42"
                    filterUnits="userSpaceOnUse"
                >
                    <feOffset dy="1" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="1.5" result="blur" />
                    <feFlood floodColor="#0c54b1" floodOpacity="0.157" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g
                id="Кръгче_с_крушка_за_дневник"
                data-name="Кръгче с крушка за дневник"
                transform="translate(-227.045 -1081.903)"
            >
                <path
                    id="Subtraction_42"
                    data-name="Subtraction 42"
                    d="M257.7,35a16.79,16.79,0,0,1-11.809-4.779,16.036,16.036,0,0,1-2.042-20.655,16.622,16.622,0,0,1,7.341-5.911,16.114,16.114,0,0,0-6.214,12.7,16.53,16.53,0,0,0,16.7,16.316,16.933,16.933,0,0,0,6.509-1.286,16.748,16.748,0,0,1-4.828,2.656A17,17,0,0,1,257.7,35Z"
                    transform="translate(63.002 1282.337) rotate(-48)"
                    fill={color}
                    opacity="0.52"
                />
                <g
                    transform="matrix(1, 0, 0, 1, 227.04, 1081.9)"
                    filter="url(#Ellipse_1000)"
                >
                    <circle
                        id="Ellipse_1000-2"
                        data-name="Ellipse 1000"
                        cx="16.5"
                        cy="16.5"
                        r="16.5"
                        transform="translate(5.96 3.5)"
                        fill="#fff"
                    />
                </g>
                <g
                    id="Group_1701"
                    data-name="Group 1701"
                    transform="translate(101 1078.195)"
                >
                    <g id="Group_1645" data-name="Group 1645">
                        <g id="Line_2" data-name="Line 2">
                            <path
                                id="Path_2109"
                                data-name="Path 2109"
                                d="M154.769,18.559a.65.65,0,0,1-.361-1.191l1.561-1.037a.65.65,0,1,1,.718,1.083l-1.56,1.037A.647.647,0,0,1,154.769,18.559Z"
                                fill="#ffd300"
                            />
                        </g>
                        <g id="Line_6" data-name="Line 6">
                            <path
                                id="Path_2110"
                                data-name="Path 2110"
                                d="M148.487,15.478h-.009a.65.65,0,0,1-.641-.659l.026-1.946a.681.681,0,0,1,.659-.641.65.65,0,0,1,.641.659l-.026,1.946A.65.65,0,0,1,148.487,15.478Z"
                                fill="#ffd300"
                            />
                        </g>
                        <g id="Line_4" data-name="Line 4">
                            <path
                                id="Path_2111"
                                data-name="Path 2111"
                                d="M141.641,19a.65.65,0,0,1-.443-.174l-1.585-1.477a.65.65,0,0,1,.886-.951l1.585,1.476A.65.65,0,0,1,141.641,19Z"
                                fill="#ffd300"
                            />
                        </g>
                        <g id="Line_3" data-name="Line 3">
                            <path
                                id="Path_2112"
                                data-name="Path 2112"
                                d="M159.4,26.734a.7.7,0,0,1-.15-.017l-1.908-.451a.65.65,0,1,1,.3-1.266l1.908.451a.65.65,0,0,1-.149,1.283Z"
                                fill="#ffd300"
                            />
                        </g>
                        <g id="Line_5" data-name="Line 5">
                            <path
                                id="Path_2113"
                                data-name="Path 2113"
                                d="M137.6,26.734a.65.65,0,0,1-.128-1.287l1.7-.341a.65.65,0,1,1,.256,1.274l-1.7.341A.625.625,0,0,1,137.6,26.734Z"
                                fill="#ffd300"
                            />
                        </g>
                    </g>
                    <path
                        id="Path_1678"
                        data-name="Path 1678"
                        d="M151.02,32.19c.009-.394-.283-.56-.7-.56H146.7c-.415,0-.717.151-.717.55v.059c.051.87,1.156,1.566,2.52,1.566s2.473-.7,2.52-1.571V32.19Z"
                        fill="#ffd300"
                    />
                    <path
                        id="Path_2114"
                        data-name="Path 2114"
                        d="M148.5,18.4c-2.919,0-5.575,2.2-5.616,5.059a5.825,5.825,0,0,0,.943,3.2c.394.606.806,1.2,1.216,1.8a2.924,2.924,0,0,1,.553,1.5c.043.742.217.877,1.025.88.626,0,1.253,0,1.879,0s1.253,0,1.879,0c.809,0,.982-.138,1.025-.88a2.916,2.916,0,0,1,.554-1.5c.409-.6.822-1.193,1.215-1.8a5.825,5.825,0,0,0,.943-3.2C154.075,20.6,151.418,18.4,148.5,18.4Z"
                        fill="#ffd300"
                    />
                </g>
            </g>
        </svg>
    );
};

export default RoundBulb;
