import { Box } from '@mui/material';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useField } from 'react-final-form';
import useMeasurementFormatter from '../../../hooks/useMeasurementFormatter';
import formatMeasurement from '../../../utils/trackerMeasurement';

const defaultMarks = (symbol, min, max) => [
  {
    value: min,
    label: formatMeasurement(min, symbol),
  },
  {
    value: max,
    label: formatMeasurement(max, symbol),
  },
];

const StyledSlider = styled(Slider)(({ theme }) => ({
  height: theme.spacing(1),
  padding: '16px 0',
  '& .MuiSlider-thumb': {
    height: 40,
    width: 40,
  },
  '& .MuiSlider-markLabel': {
    paddingTop: theme.spacing(2),
  },
}));

const ValueMeter = styled('div')(({ theme }) => ({
  fontSize: 40,
  paddingTop: 16,
  paddingBottom: 16,
  textAlign: 'center',
}));

const TrackerSlider = ({
  name,
  min,
  max,
  step,
  measurementSymbol,
  marks,
  type,
  subType,
  children,
}) => {
  const { input } = useField(name);

  const formatter = useMeasurementFormatter(measurementSymbol, type, subType);

  return (
    <Box
      paddingX={2}
      width={'100%'}
      display="flex"
      flexDirection="column"
      justifyItems="center"
    >
      <ValueMeter name="ValueMeter">
        {(children && children(input.value)) || formatter(input.value)}
      </ValueMeter>
      <StyledSlider
        getAriaValueText={formatter}
        step={step}
        min={min}
        max={max}
        marks={marks || defaultMarks(measurementSymbol, min, max)}
        onChange={input.onChange}
        value={typeof input.value === 'number' ? input.value : 0}
      />
    </Box>
  );
};

export default TrackerSlider;
