import { Component } from 'react';
import { connect } from 'react-redux';
import { Router, Switch } from 'react-router-dom';
import {
  closeModal,
  isAuthenticated,
  loadLocalStorageData,
  openModal,
} from './actions';
import Main from './components/Main';
import Unauthenticated from './components/Unauthenticated';
import HamburgerMenu from './components/common/HamburgerMenu';
import Loader from './components/common/Loader';
import ModalDialog from './components/common/ModalDialog';
import ErrorBoundary from './components/errors/ErrorBoundary';

class App extends Component {
  state = { isLocalStorageDataLoaded: false };

  componentDidMount = () => {
    this.props
      .loadLocalStorageData()
      .then(() => this.setState({ isLocalStorageDataLoaded: true }));
  };

  render() {
    if (!this.state.isLocalStorageDataLoaded) {
      return null;
    }
    const { history } = this.props;
    return (
      <Router history={history}>
        <ErrorBoundary>
          <HamburgerMenu />
          {/* NOTE: We need to enclose <Main /> and <Unauthenticated /> in a <Switch>. 
                    Otherwise, the redirect between pages doesn't work at all */}
          <Switch>{isAuthenticated() ? <Main /> : <Unauthenticated />}</Switch>
          {this.renderModal()}
          <Loader />
        </ErrorBoundary>
      </Router>
    );
  }

  renderModal = () => {
    const { modal } = this.props;
    return modal.status ? (
      <ModalDialog
        isOpen={true}
        message={modal.message}
        type={modal.status}
        toggle={this.props.closeModal}
        onDelete={modal.onDelete}
        onConfirm={modal.onConfirm}
        onDeny={modal.onDeny}
      />
    ) : null;
  };
}

const mapStateToProps = (state) => {
  // Currently, we don't need any Redux state in this component. The method here only ensures that
  // when the user logs in or logs out, this "root" component, App, will refresh itself
  // (since the Redux state for "userData" is changed during login/logout) and will show
  // the appropriate page (as sub-component of the App component)

  return {
    userData: state.authentication.userData,
    modal: state.modal,
  };
};

export default connect(mapStateToProps, {
  loadLocalStorageData,
  openModal,
  closeModal,
})(App);
