import React from 'react';
import Erase from '../common/svgComponents/Erase';
import * as i18n from 'i18next';
import { Button, Col, Container } from 'reactstrap';
import { connect } from 'react-redux';
import { showDeleteModal, deleteProfile } from '../../actions';
import { EXTERNAL_URLS } from '../../utils/constants';

class DeleteProfileScreen extends React.Component {
    onDelete = () => {
        const message = (
            <p>
                {i18n.t('deleteProfileScreen:deleteConfirmation')}
                <a href={EXTERNAL_URLS.GDPR} target="_blank" rel="noreferrer">
                    {i18n.t('deleteProfileScreen:ourPolicy')}
                </a>
                {i18n.t('deleteProfileScreen:and')}
                <a
                    href={EXTERNAL_URLS.TERMS_AND_CONDITIONS}
                    target="_blank"
                    rel="noreferrer"
                >
                    {i18n.t('deleteProfileScreen:termsAndConditions')}
                </a>
            </p>
        );

        this.props.showDeleteModal({
            message,
            onDelete: () => {
                this.props.deleteProfile(this.props.user.id);
            },
        });
    };

    render() {
        return (
            <Container className="noPadding">
                <Col
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    className="centred noPadding"
                >
                    <div className="flexContainerColumn flexContainerCentered fix-padding">
                        <p className="mt-s" />
                        <div
                            xs={10}
                            sm={10}
                            md={10}
                            lg={8}
                            className="flexContainerCentered"
                        >
                            <Erase width={166} height={168} />
                        </div>
                        <div className="flexContainerColumn flexContainerCentered description break-line-text">
                            {i18n.t('deleteProfileScreen:description')}
                        </div>
                        <Col
                            xs={10}
                            sm={10}
                            md={10}
                            lg={8}
                            className="flexContainerColumn centred mt-m"
                        >
                            <Button
                                onClick={this.onDelete}
                                className="header next uppercase redBtn"
                            >
                                {i18n.t(
                                    'deleteProfileScreen:buttons.deleteProfile'
                                )}
                            </Button>
                        </Col>
                    </div>
                </Col>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authentication.userData,
    };
};

export default connect(mapStateToProps, { showDeleteModal, deleteProfile })(
    DeleteProfileScreen
);
