import moment from 'moment';
import { DEFAULT_LOCALE_CODE } from './constants';

moment.defineLocale('bg', {
  parentLocale: 'en',
  relativeTime: {
    future: 'след %s',
    past: 'преди %s',
    ss: '%d сек.',
    s: '%d сек.',
    m: '%d мин.',
    mm: '%d мин.',
    h: '%d ч',
    hh: '%d ч',
    d: '%d д',
    dd: '%d д',
    w: 'седмица',
    ww: '%d седмици',
    M: 'месец',
    MM: '%d месеца',
    y: 'година',
    yy: '%d г',
  },
});

// Set new thresholds
moment.relativeTimeThreshold('s', 60);
moment.relativeTimeThreshold('ss', 60);
moment.relativeTimeThreshold('m', 60);
moment.relativeTimeThreshold('h', 24);
moment.relativeTimeThreshold('d', 7);
moment.relativeTimeThreshold('w', 4);
moment.relativeTimeThreshold('M', 12);

moment.locale(DEFAULT_LOCALE_CODE);

export default moment;
