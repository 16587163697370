import { Box, InputAdornment, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useField } from 'react-final-form';
import i18n from 'i18next';

const StyleTextField = styled(TextField)(({ theme }) => ({
  '& legend': { display: 'none' },
  '& fieldset': { top: 0 },
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    textAlign: 'center',
    fontSize: 24,
  },
}));

const TrackerValueInput = ({
  name,
  label,
  min,
  max,
  step,
  measurementSymbol,
}) => {
  const { input, meta } = useField(name);

  return (
    <Box
      paddingY={2}
      width={'100%'}
      display="flex"
      flexDirection="column"
      justifyItems="center"
    >
      <StyleTextField
        error={meta.error && meta.touched}
        helperText={meta.error && meta.touched && meta.error}
        value={input.value}
        onBlur={input.onBlur}
        onChange={input.onChange}
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
        InputProps={{
          endAdornment: measurementSymbol && (
            <InputAdornment position="end">
              {measurementSymbol
                ? i18n.t(`trackingScreen:symbols.${measurementSymbol}`)
                : ''}
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default TrackerValueInput;
