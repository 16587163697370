import i18next from 'i18next';

import bg from './translations/bg.json';
import { initReactI18next } from 'react-i18next';

let userLang = 'bg';
let resourceFiles = bg;

i18next.use(initReactI18next).init({
  interpolation: { escapeValue: false },
  lng: userLang,
  resources: resourceFiles,
});

export default i18next;
