import React from 'react';

const Read = ({ width, height }) => {
    const viewBox = '0 0 22.818 22.828';

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox={viewBox}
        >
            <path
                id="Икона_-_ПРОЧЕТИ"
                data-name="Икона - ПРОЧЕТИ"
                d="M14.656,4a4,4,0,1,0-4,4A4,4,0,0,0,14.656,4Zm-4.93,6.041C7.255,8.526,3.254,8.111,1.241,8A1.152,1.152,0,0,0,0,9.124V18.4a1.145,1.145,0,0,0,1.1,1.126A22.4,22.4,0,0,1,9.14,21.252a.58.58,0,0,0,.853-.494V10.516a.554.554,0,0,0-.267-.477ZM20.077,8c-2.013.114-6.015.53-8.485,2.042a.564.564,0,0,0-.267.484V20.756a.581.581,0,0,0,.855.5,22.406,22.406,0,0,1,8.035-1.724,1.146,1.146,0,0,0,1.1-1.127V9.124A1.153,1.153,0,0,0,20.077,8Z"
                transform="translate(0.75 0.75)"
                fill="none"
                stroke="#87ba3b"
                strokeWidth="1.5"
            />
        </svg>
    );
};

export default Read;
