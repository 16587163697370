import React from 'react';

const LogoIcon = ({ width, height }) => {
    const viewBoxValues = '0 0 25 26';

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox={viewBoxValues}
        >
            <g
                id="Лого_-_Дневникът"
                data-name="Лого - Дневникът"
                transform="translate(-1232.414 85.528)"
            >
                <path
                    id="Path_274"
                    data-name="Path 274"
                    d="M246.069,37.188v2.648h-2.648v2.648h-2.648v-5.3h5.3Z"
                    transform="translate(995.389 -118.97)"
                    fill="#ffd300"
                    fillRule="evenodd"
                />
                <path
                    id="Path_275"
                    data-name="Path 275"
                    d="M246.069,96.286V93.638h-2.648V90.989h-2.648v5.3h5.3Z"
                    transform="translate(995.389 -159.978)"
                    fill="#fbae17"
                    fillRule="evenodd"
                />
                <path
                    id="Path_276"
                    data-name="Path 276"
                    d="M294.571,37.187v2.648h2.648v2.648h2.648v-5.3h-5.3Z"
                    transform="translate(954.383 -118.969)"
                    fill="#fbae17"
                    fillRule="evenodd"
                />
                <path
                    id="Path_277"
                    data-name="Path 277"
                    d="M310.86,66.954l-1.872-1.873,1.872-1.873-1.872-1.873,1.872-1.873,1.873,1.873,1.873,1.873-1.873,1.873-1.873,1.873Z"
                    transform="translate(943.395 -135.949)"
                    fill="#f37920"
                    fillRule="evenodd"
                />
                <path
                    id="Path_278"
                    data-name="Path 278"
                    d="M228.759,59.488l1.873,1.873-1.873,1.873,1.873,1.873-1.873,1.873-1.873-1.873-1.873-1.873,1.873-1.873,1.873-1.873Z"
                    transform="translate(1007.4 -135.968)"
                    fill="#ffd300"
                    fillRule="evenodd"
                />
                <path
                    id="Path_279"
                    data-name="Path 279"
                    d="M263.054,107.253l1.873-1.873,1.873,1.873,1.873-1.873,1.873,1.873-1.873,1.873L266.8,111l-1.873-1.873-1.873-1.873Z"
                    transform="translate(978.406 -170.947)"
                    fill="#f37920"
                    fillRule="evenodd"
                />
                <path
                    id="Path_280"
                    data-name="Path 280"
                    d="M270.552,25.18l-1.872,1.873-1.873-1.873-1.873,1.873-1.873-1.873,1.873-1.873,1.873-1.873,1.873,1.873,1.872,1.873Z"
                    transform="translate(978.4 -106.963)"
                    fill="#ffd300"
                    fillRule="evenodd"
                />
                <path
                    id="Path_281"
                    data-name="Path 281"
                    d="M294.57,96.271V93.62h2.65v-2.65h2.651l0,5.3Z"
                    transform="translate(954.384 -159.963)"
                    fill="#f37920"
                    fillRule="evenodd"
                />
                <path
                    id="Path_282"
                    data-name="Path 282"
                    d="M270.477,65.537v.674H269.8v.674h-.674V65.537h1.348Z"
                    transform="translate(973.776 -140.578)"
                    fill="#ffd300"
                    fillRule="evenodd"
                />
                <path
                    id="Path_283"
                    data-name="Path 283"
                    d="M270.477,80.584V79.91H269.8v-.674h-.674v1.348h1.348Z"
                    transform="translate(973.776 -151.019)"
                    fill="#fbae17"
                    fillRule="evenodd"
                />
                <path
                    id="Path_284"
                    data-name="Path 284"
                    d="M282.825,65.537v.674h.674v.674h.674V65.537h-1.349Z"
                    transform="translate(963.336 -140.578)"
                    fill="#fbae17"
                    fillRule="evenodd"
                />
                <path
                    id="Path_285"
                    data-name="Path 285"
                    d="M286.973,73.115l-.477-.477.477-.477-.477-.477.477-.477.477.477.477.477-.477.477-.477.477Z"
                    transform="translate(960.538 -144.901)"
                    fill="#f37920"
                    fillRule="evenodd"
                />
                <path
                    id="Path_286"
                    data-name="Path 286"
                    d="M266.07,71.215l.477.477-.477.477.477.477-.477.477-.477-.477-.477-.477.477-.477.477-.477Z"
                    transform="translate(976.834 -144.906)"
                    fill="#ffd300"
                    fillRule="evenodd"
                />
                <path
                    id="Path_287"
                    data-name="Path 287"
                    d="M274.8,83.375l.477-.477.477.477.477-.477.477.477-.477.477-.477.477-.477-.477-.477-.477Z"
                    transform="translate(969.452 -153.811)"
                    fill="#f37920"
                    fillRule="evenodd"
                />
                <path
                    id="Path_288"
                    data-name="Path 288"
                    d="M276.71,62.479l-.477.477-.477-.477-.477.477-.477-.477.477-.477.477-.477.477.477.477.477Z"
                    transform="translate(969.451 -137.521)"
                    fill="#ffd300"
                    fillRule="evenodd"
                />
                <path
                    id="Path_289"
                    data-name="Path 289"
                    d="M282.825,80.579V79.9h.675v-.675h.675v1.35Z"
                    transform="translate(963.336 -151.014)"
                    fill="#f37920"
                    fillRule="evenodd"
                />
                <path
                    id="Path_290"
                    data-name="Path 290"
                    d="M252.428,58.113l.6,1.439-1.439.6.6,1.439-1.439.6-.6-1.439-.6-1.439,1.439-.6,1.439-.6Z"
                    transform="translate(988.699 -134.919)"
                    fill="#ffd300"
                    fillRule="evenodd"
                />
                <path
                    id="Path_291"
                    data-name="Path 291"
                    d="M265.778,92.147l-.6-1.439-1.439.6-.6-1.439-1.439.6.6,1.439.6,1.439,1.439-.6,1.439-.6Z"
                    transform="translate(979.435 -159.123)"
                    fill="#fbae17"
                    fillRule="evenodd"
                />
                <path
                    id="Path_292"
                    data-name="Path 292"
                    d="M278.786,47.157l.6,1.439,1.439-.6.6,1.439,1.439-.6-.6-1.439-.6-1.439-1.439.6-1.439.6Z"
                    transform="translate(966.415 -125.657)"
                    fill="#fbae17"
                    fillRule="evenodd"
                />
                <path
                    id="Path_293"
                    data-name="Path 293"
                    d="M295.718,62.133l-1.441-.595.595-1.441-1.441-.595.595-1.441,1.44.595,1.441.595-.595,1.441-.595,1.441Z"
                    transform="translate(955.252 -134.881)"
                    fill="#f37920"
                    fillRule="evenodd"
                />
                <path
                    id="Path_294"
                    data-name="Path 294"
                    d="M250.771,75.266l1.441.595-.595,1.441,1.441.594-.595,1.441-1.441-.594-1.441-.595.594-1.441.595-1.44Z"
                    transform="translate(988.675 -147.994)"
                    fill="#fbae17"
                    fillRule="evenodd"
                />
                <path
                    id="Path_295"
                    data-name="Path 295"
                    d="M278.84,92.119l.595-1.441,1.441.595.594-1.441,1.441.595-.595,1.44-.595,1.441-1.441-.594-1.441-.595Z"
                    transform="translate(966.373 -159.097)"
                    fill="#f37920"
                    fillRule="evenodd"
                />
                <path
                    id="Path_296"
                    data-name="Path 296"
                    d="M265.732,47.18l-.595,1.441-1.441-.595-.594,1.441-1.441-.595.595-1.441.595-1.441,1.441.595,1.44.595Z"
                    transform="translate(979.467 -125.68)"
                    fill="#ffd300"
                    fillRule="evenodd"
                />
                <path
                    id="Path_297"
                    data-name="Path 297"
                    d="M294.05,79.261l-.6-1.44,1.441-.6-.6-1.441,1.441-.6,1.2,2.882-2.88,1.2Z"
                    transform="translate(955.236 -147.93)"
                    fill="#f37920"
                    fillRule="evenodd"
                />
            </g>
        </svg>
    );
};

export default LogoIcon;
