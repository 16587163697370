import React from 'react';

const ExpiredBorder = ({ style }) => {
    const viewBoxValues = '0 0 145 138';

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox={viewBoxValues}
            style={style}
        >
            <g
                id="Стикер_-_Обърни_внимание"
                data-name="Стикер - Обърни внимание"
                transform="translate(-39.621 -379.115)"
            >
                <g
                    id="Rectangle_1319"
                    data-name="Rectangle 1319"
                    transform="translate(50.771 385.806)"
                    fill="transparent"
                    stroke="#ffdf02"
                    strokeWidth="2"
                >
                    <rect
                        width="124.888"
                        height="124.888"
                        rx="11"
                        stroke="none"
                    />
                    <rect
                        x="1"
                        y="1"
                        width="122.888"
                        height="122.888"
                        rx="10"
                        fill="none"
                    />
                </g>
                <g
                    id="Кръгче_с_удивителна"
                    data-name="Кръгче с удивителна"
                    transform="translate(39.621 379.115)"
                >
                    <g
                        id="Ellipse_313"
                        data-name="Ellipse 313"
                        fill="#ffdf02"
                        stroke="#ffdf02"
                        strokeWidth="2"
                    >
                        <circle
                            cx="11.708"
                            cy="11.708"
                            r="11.708"
                            stroke="none"
                        />
                        <circle
                            cx="11.708"
                            cy="11.708"
                            r="10.708"
                            fill="none"
                        />
                    </g>
                    <g
                        id="Group_588"
                        data-name="Group 588"
                        transform="translate(10.036 4.46)"
                    >
                        <circle
                            id="Ellipse_308"
                            data-name="Ellipse 308"
                            cx="1.673"
                            cy="1.673"
                            r="1.673"
                            transform="translate(0 11.151)"
                            fill="#fff"
                        />
                        <rect
                            id="Rectangle_1208"
                            data-name="Rectangle 1208"
                            width="3.345"
                            height="8.921"
                            rx="1.673"
                            transform="translate(0)"
                            fill="#fff"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default ExpiredBorder;
