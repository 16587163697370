import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';

import { Col } from 'reactstrap';
import { RELATIVE_PAGE_PATHS } from '../utils/constants';
import navigationMethods from '../utils/navigationUtils';
import NavigationComponent from './NavigationComponent';
import PaidFunctionalityScreen from './PaidFunctionalityScreen';
import TrackingScreen from './TrackingScreen';
import UsefulInfoScreen from './UsefulInfoScreen';
import AdSlot from './common/AdSlot';
import FooterNavigationBar from './common/FooterNavigationBar';
import InternalServerErrorScreen from './errors/InternalServerErrorScreen';
import NotFoundErrorScreen from './errors/NotFoundErrorScreen';
import UnhandledErrorScreen from './errors/UnhandledErrorScreen';
import AddSticker from './journal/AddStickerScreen';
import DownloadJournalScreen from './journal/DownloadJournalScreen';
import ExpiredStickers from './journal/ExpiredStickersScreen';
import GoalsAndTags from './journal/GoalsAndTagsScreen';
import JournalReadOnlyScreen from './journal/JournalReadOnlyScreen';
import Journal from './journal/JournalScreen';
import PhotoPreviewScreen from './journal/PhotoPreviewScreen';
import RequestJournalDownloadScreen from './journal/RequestJournalDownloadScreen';
import SelectedTag from './journal/SelectedTagScreen';
import ChangePassword from './profile/ChangePasswordScreen';
import DeleteProfileScreen from './profile/DeleteProfileScreen';
import Profile from './profile/ProfileScreen';
import SpecialistDetailsScreen from './specialists/SpecialistDetailsScreen';
import SpecialistsScreen from './specialists/SpecialistsScreen';

class Main extends Component {
  render() {
    const isPhotoPreviewScreen = navigationMethods
      .getRoute()
      .startsWith(RELATIVE_PAGE_PATHS.PHOTO_PREVIEW);
    const allowLandscapeClass = isPhotoPreviewScreen ? 'allow-landscape' : '';

    return (
      <Fragment>
        <div className="noPadding">
          <NavigationComponent />

          <Col
            xs={12}
            className="flexColumnCentered fix-padding-safari top-banner-container pr-0 pl-0"
          >
            {!this.props.user.isSubscribedToPaidStickers && (
              <AdSlot
                id="div-gpt-ad-1668693543692-0"
                path="/21663792601/Denvenik_Banner"
                size={[[300, 250]]}
              />
            )}
          </Col>
          <div
            className={
              'flexContainerCentered centred noPadding ml-3 mr-3 main-section ' +
              allowLandscapeClass
            }
          >
            <Switch>
              <Route
                exact
                path={RELATIVE_PAGE_PATHS.HOME}
                component={GoalsAndTags}
              />
              <Route
                exact
                path={RELATIVE_PAGE_PATHS.SELECTED_TAG + ':id'}
                component={SelectedTag}
              />
              <Route
                exact
                path={RELATIVE_PAGE_PATHS.PROFILE}
                component={Profile}
              />
              <Route
                exact
                path={RELATIVE_PAGE_PATHS.CHANGE_PASSWORD}
                component={ChangePassword}
              />
              <Route
                exact
                path={RELATIVE_PAGE_PATHS.DELETE_PROFILE}
                component={DeleteProfileScreen}
              />
              <Route
                exact
                path={RELATIVE_PAGE_PATHS.JOURNAL}
                component={Journal}
              />
              <Route
                exact
                path={RELATIVE_PAGE_PATHS.REQUEST_JOURNAL_DOWNLOAD}
                component={RequestJournalDownloadScreen}
              />
              <Route
                exact
                path={RELATIVE_PAGE_PATHS.DOWNLOAD_JOURNAL}
                component={DownloadJournalScreen}
              />
              <Route
                exact
                path={RELATIVE_PAGE_PATHS.JOURNAL_READ_ONLY}
                component={JournalReadOnlyScreen}
              />
              <Route
                exact
                path={RELATIVE_PAGE_PATHS.EXPIRED_STICKERS}
                component={ExpiredStickers}
              />
              <Route
                exact
                path={RELATIVE_PAGE_PATHS.ADD_STICKER + ':id'}
                component={AddSticker}
              />
              <Route
                path={RELATIVE_PAGE_PATHS.EDIT_STICKER + ':id'}
                exact
                component={AddSticker}
              />
              <Route
                exact
                path={RELATIVE_PAGE_PATHS.SPECIALISTS}
                component={SpecialistsScreen}
              />
              <Route
                exact
                path={RELATIVE_PAGE_PATHS.SPECIALISTS + ':id'}
                component={SpecialistDetailsScreen}
              />
              <Route
                exact
                path={RELATIVE_PAGE_PATHS.PAID_FUNCTIONALITY}
                component={PaidFunctionalityScreen}
              />
              <Route
                exact
                path={RELATIVE_PAGE_PATHS.PHOTO_PREVIEW + ':photo'}
                component={PhotoPreviewScreen}
              />
              <Route
                exact
                path={RELATIVE_PAGE_PATHS.USEFUL_INFO}
                component={UsefulInfoScreen}
              />
              <Route
                exact
                path={RELATIVE_PAGE_PATHS.ADD_SPECIALISTS}
                component={SpecialistDetailsScreen}
              />
              <Route path="/breastfeeding">
                <Redirect to={RELATIVE_PAGE_PATHS.BREAST_FEEDING} />
              </Route>
              <Route
                path={RELATIVE_PAGE_PATHS.TRACKING}
                component={TrackingScreen}
              />
              <Route
                exact
                path={RELATIVE_PAGE_PATHS.INTERNAL_SERVER_ERROR}
                component={InternalServerErrorScreen}
              />
              <Route
                exact
                path={RELATIVE_PAGE_PATHS.INTERNAL_SERVER_ERROR}
                component={InternalServerErrorScreen}
              />
              <Route
                exact
                path={RELATIVE_PAGE_PATHS.NOT_FOUND}
                component={NotFoundErrorScreen}
              />
              <Route
                exact
                path={RELATIVE_PAGE_PATHS.UNHANDLED_ERROR}
                component={UnhandledErrorScreen}
              />
              <Route component={NotFoundErrorScreen} />
            </Switch>
          </div>
          <FooterNavigationBar className={allowLandscapeClass} />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authentication.userData,
  };
};

export default connect(mapStateToProps, {})(Main);
