import React from 'react';
import { Col, Container } from 'reactstrap';
import { connect } from 'react-redux';
import * as i18n from 'i18next';
import { loadExpiredStickers } from '../../actions';
import NotAddedStickersList from './NotAddedStickersList';
import TagFilter from './TagFilter';
import { COLOR_SCHEME, FORMS } from '../../utils/constants';
import { getFormValues } from 'redux-form';

class ExpiredStickersScreen extends React.Component {
    componentDidMount() {
        const tag =
            this.props.formValues && this.props.formValues.tag
                ? this.props.formValues.tag
                : {};
        this.props.loadExpiredStickers(tag.id);
    }

    render = () => (
        <Container className="noPadding">
            <Col lg={12} className="centred noPadding limited-width">
                <div className="flexContainerColumn flexContainerCentered profile-form fix-padding">
                    <div className="pl-3 pr-3">
                        {this.renderHeader()}
                        {this.renderInfoSection()}
                        <TagFilter
                            onTagSelectedCallback={(tagId) =>
                                this.onTagSelected(tagId)
                            }
                            filterClassName="expiredStickersFilter"
                            initialValues={{
                                name: i18n.t('journalScreen:all'),
                            }}
                        />
                    </div>
                    <div className="pl-3 pr-3 w-100">
                        <NotAddedStickersList
                            stickers={this.props.stickers}
                            oneColorMonths
                        />
                    </div>
                </div>
            </Col>
        </Container>
    );

    renderHeader = () => (
        <div className="primaryHeader">
            <p>{i18n.t('expiredStickersScreen:title')}</p>
        </div>
    );

    renderInfoSection = () => (
        <Col
            lg={12}
            className="expiredStickersInfoBox flexColumnCentered"
            style={{ backgroundColor: COLOR_SCHEME.LIGHTER_YELLOW }}
        >
            <p className="expiredStickersInfoText centredText noMargin">
                {i18n.t('expiredStickersScreen:info')}
            </p>
        </Col>
    );

    onTagSelected = (tagId) => this.props.loadExpiredStickers(tagId);
}

const mapStateToProps = (state) => {
    return {
        stickers: state.expiredStickers.expiredStickerList,
        goals: state.goals,
        formValues: getFormValues(FORMS.TAG_FORM)(state),
    };
};

export default connect(mapStateToProps, { loadExpiredStickers })(
    ExpiredStickersScreen
);
