import * as i18n from 'i18next';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Container } from 'reactstrap';
import Bulbs from './common/svgComponents/Bulbs';
import LogoDMT from './common/svgComponents/LogoDMT';

class WelcomeScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <Container className="themed-container">
                <Col sm={12} md={4} lg={4} className="centred noPadding">
                    <div className="flexContainerCentered">
                        <Bulbs width={360} height={180} />
                    </div>
                    <div className="centredText">
                        <p
                            className="header noMargin mainTextColor uppercase"
                            style={{ fontSize: 12, fontWeight: 'bold' }}
                        >
                            {i18n.t(
                                'welcomeScreen:headerText.headerTextFirstRow'
                            )}
                        </p>
                        <p
                            className="header mainTextColor uppercase"
                            style={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                marginBottom: 26,
                            }}
                        >
                            {i18n.t(
                                'welcomeScreen:headerText.headerTextSecondRow'
                            )}
                        </p>
                    </div>
                    <div className="flexContainerCentered">
                        <LogoDMT width={177} height={212} />
                    </div>
                    <div className="flexContainerCentered">
                        <p className="header mainGreenColor welcomeMsg uppercase">
                            {i18n.t('welcomeScreen:headerText.welcomeMsg')}
                        </p>
                    </div>
                    <Col
                        xs={10}
                        sm={10}
                        md={10}
                        lg={10}
                        className="flexContainerColumn centred"
                    >
                        <Link
                            to={'/register'}
                            className="greenBtn header flexContainerCentered uppercase"
                        >
                            {i18n.t('buttons:register')}
                        </Link>
                        <Link
                            to="/login"
                            className="btn greenBtnOutline header flexContainerCentered uppercase"
                        >
                            {i18n.t('buttons:login')}
                        </Link>
                    </Col>
                </Col>
            </Container>
        );
    }
}

export default connect(null, {})(WelcomeScreen);
