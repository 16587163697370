import { Button } from '@mui/material';
import * as i18n from 'i18next';
import { orderBy } from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkForNewVersion } from '../../../actions';
import { getAllTrackers } from '../../../actions/trackerActions';
import {
  addTrackingRecord,
  getAllTrackingRecords,
  updateTrackingRecord,
} from '../../../actions/trackingRecordsActions';
import {
  RELATIVE_PAGE_PATHS,
  TRACKERS_SUB_TYPE,
} from '../../../utils/constants';

import { useHistory, useParams } from 'react-router';
import { SleepTrackerSleepIconIcon } from '../../common/icons';
import TrackerHistory from '../TrackerHistory';
import { TrackingEditDialog } from '../tracking/TrackingEditDialog';
import { TrackingTimer } from '../tracking/TrackingTimer';

export const SleepTracker = () => {
  const trackerId = useSelector(trackerIdSelector);
  const records = useSelector((state) =>
    orderBy(
      state.trackingRecords.filter((x) => x.subTypeId === trackerId),
      (x) => new Date(x.date),
      ['desc']
    )
  );

  const [_isAddOpened, setIsAddOpened] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const { recordId } = useParams();

  useEffect(() => {
    dispatch(getAllTrackers());
    dispatch(getAllTrackingRecords());
    dispatch(checkForNewVersion());
  }, [dispatch]);

  const recordToEdit = useMemo(() => {
    if (!records || !recordId) {
      return null;
    }
    return records.find((x) => x.id + '' === recordId);
  }, [recordId, records]);

  const isAddOpened = useMemo(() => {
    return !!recordToEdit || _isAddOpened;
  }, [_isAddOpened, recordToEdit]);

  const renderLastRecordDetails = () => {
    const lastRecord = records[0];

    if (!lastRecord) {
      return (
        <div className="text">
          <p>{i18n.t('sleepTrackerScreen:lastRecord')}</p>
          <span>{i18n.t('sleepTrackerScreen:noRecordYet')}</span>
        </div>
      );
    }

    const side = i18n.t(`trackingScreen:tracking.${lastRecord.additionalInfo}`);
    const beforeTime = moment.duration(
      moment(new Date()).diff(lastRecord.date)
    );
    const days = Math.floor(beforeTime.asDays());
    return (
      <div className="text">
        <p>{i18n.t('sleepTrackerScreen:lastRecord')}</p>
        <p className="mb-0">
          <strong className="uppercase">{side} </strong>
          {i18n.t('sleepTrackerScreen:sleep')}
        </p>
        <span>
          {i18n.t('sleepTrackerScreen:sleepTime', {
            hours: beforeTime.hours(),
            minutes: beforeTime.minutes(),
            days: days ? i18n.t('sleepTrackerScreen:days', { days }) : '',
          })}
        </span>
      </div>
    );
  };

  const renderLastRecord = () => {
    return (
      <div className="details">
        <SleepTrackerSleepIconIcon
          style={{
            width: '128px',
          }}
        />
        {renderLastRecordDetails()}
      </div>
    );
  };

  const onAddClose = () => {
    setIsAddOpened(false);
    history.replace(`${RELATIVE_PAGE_PATHS.SLEEP}`);
  };

  const onAdd = () => {
    setIsAddOpened(true);
  };

  const save = (trackerId, startTime, duration, timeOfTheDay) => {
    dispatch(addTrackingRecord(trackerId, startTime, duration, timeOfTheDay));
  };

  const update = (id, startTime, duration, timeOfTheDay) => {
    dispatch(updateTrackingRecord(id, startTime, duration, timeOfTheDay));
  };

  const handleSave = (data) => {
    const { startTime, duration, additionalInfo } = data;

    if (recordToEdit) {
      update(recordToEdit.id, startTime, duration, additionalInfo);
      history.replace(`${RELATIVE_PAGE_PATHS.SLEEP}`);
      return;
    }

    save(trackerId, startTime, duration, additionalInfo);
  };

  const handleEdit = (id) => {
    history.push(`${RELATIVE_PAGE_PATHS.SLEEP}/${id}`);
  };

  return (
    <>
      <div className="flexContainerColumn flexContainerCentered breastfeeding">
        {renderLastRecord()}
        <TrackingTimer onSave={handleSave} tracker={TRACKERS_SUB_TYPE.SLEEP} />
        <div className="pb-3 d-flex justify-content-center">
          <Button
            type="submit"
            variant="outlined"
            onClick={onAdd}
            disabled={isAddOpened}
          >
            {i18n.t('sleepTrackerScreen:buttons.add')}
          </Button>
        </div>
        <TrackerHistory
          historyRecords={records}
          isBreastFeeding
          onEdit={handleEdit}
          // disableEdit={timerStatus === TIMER_STATE.PLAY}
        />
      </div>

      {isAddOpened && (
        <TrackingEditDialog
          trackerType={TRACKERS_SUB_TYPE.SLEEP}
          startTime={recordToEdit?.date}
          quantity={recordToEdit?.quantity}
          additionalInfo={recordToEdit?.additionalInfo}
          isOpen={isAddOpened}
          onClose={onAddClose}
          onSave={handleSave}
          isEdit={!!recordToEdit}
        />
      )}
    </>
  );
};

const trackerIdSelector = (state) => {
  const tracker = (state.trackers || []).find(
    (x) => x.name === TRACKERS_SUB_TYPE.SLEEP
  );
  return tracker ? tracker.id : -1;
};
