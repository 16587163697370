import {
    LOCAL_STORAGE_DATA_LOADED,
    LOGIN_ATTEMPT,
    LOGOUT,
    EDIT_PROFILE,
    PASSWORD_CHANGED,
    REGISTER_PROFILE,
    GET_USER_DETAILS,
} from '../actions/actionTypes';

const initialState = {
    userData: {},
    token: null,
    loginAttempt: false,
};

const authenticationReducer = (state = initialState, action) => {
    // Always resetting the values to 'false' of some of the actions
    state = {
        ...state,
        loginAttempt: false,
    };

    var data = action.payload;
    switch (action.type) {
        case LOCAL_STORAGE_DATA_LOADED:
            return {
                ...state,
                userData: data ? data.userData : null,
                token: data ? data.token : null,
            };

        case LOGIN_ATTEMPT:
            return {
                ...state,
                loginAttempt: true,
                isLoginSuccessful: data.success,
                userData: data.user,
                token: data.token ? data.token.value : null,
            };

        case PASSWORD_CHANGED:
            return {
                ...state,
                token: data.value,
            };

        case LOGOUT:
            return {
                ...state,
                userData: null,
                token: null,
            };

        case REGISTER_PROFILE:
            return {
                ...state,
                userData: data,
            };

        case EDIT_PROFILE:
            return {
                ...state,
                userData: data,
            };

        case GET_USER_DETAILS:
            return {
                ...state,
                userData: data,
            };

        default:
            return state;
    }
};

export default authenticationReducer;
