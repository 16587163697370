import React from 'react';

const Dots = ({ page }) => {
    return (
        <div className="centredText">
            <span className={page === 1 ? 'dot activeDot' : 'dot'}></span>
            <span className={page === 2 ? 'dot activeDot' : 'dot'}></span>
            <span className={page === 3 ? 'dot activeDot' : 'dot'}></span>
            <span className={page === 4 ? 'dot activeDot' : 'dot'}></span>
            <span className={page === 5 ? 'dot activeDot' : 'dot'}></span>
            <span className={page === 6 ? 'dot activeDot' : 'dot'}></span>
        </div>
    );
};

export default Dots;
