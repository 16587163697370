import React from 'react';

const ChildBirth = ({ width, height }) => {
    const viewBoxValues = '0 0 164 164';

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox={viewBoxValues}
        >
            <defs>
                <filter
                    id="Rectangle_1662"
                    x="0"
                    y="0"
                    width="164"
                    height="164"
                    filterUnits="userSpaceOnUse"
                >
                    <feOffset dy="1" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="1" result="blur" />
                    <feFlood floodOpacity="0.161" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g
                id="Квадрат_с_картинка_дата_на_раждане"
                data-name="Квадрат с картинка дата на раждане"
                transform="translate(-106 -166)"
            >
                <g
                    transform="matrix(1, 0, 0, 1, 106, 166)"
                    filter="url(#Rectangle_1662)"
                >
                    <g
                        id="Rectangle_1662-2"
                        data-name="Rectangle 1662"
                        transform="translate(3 2)"
                        fill="#fff"
                        stroke="#cacaca"
                        strokeWidth="2"
                    >
                        <rect width="158" height="158" rx="11" stroke="none" />
                        <rect
                            x="1"
                            y="1"
                            width="156"
                            height="156"
                            rx="10"
                            fill="none"
                        />
                    </g>
                </g>
                <g
                    id="Картинка_-_рожден_ден"
                    data-name="Картинка - рожден ден"
                    transform="translate(1.75 -18)"
                >
                    <path
                        id="Icon_awesome-heart"
                        data-name="Icon awesome-heart"
                        d="M31.292,4.321a9.256,9.256,0,0,0-12.631.921L17.328,6.616,15.995,5.242A9.256,9.256,0,0,0,3.364,4.321a9.719,9.719,0,0,0-.67,14.072l13.1,13.524a2.122,2.122,0,0,0,3.066,0l13.1-13.524a9.713,9.713,0,0,0-.663-14.072Z"
                        transform="translate(170.172 250.084)"
                        fill="#e64b83"
                    />
                    <line
                        id="Line_164"
                        data-name="Line 164"
                        y2="20"
                        transform="translate(187.5 204.5)"
                        fill="none"
                        stroke="#ffd300"
                        strokeLinecap="round"
                        strokeWidth="2"
                    />
                    <line
                        id="Line_168"
                        data-name="Line 168"
                        y1="9"
                        x2="26"
                        transform="translate(133.5 271)"
                        fill="none"
                        stroke="#ffd300"
                        strokeLinecap="round"
                        strokeWidth="2"
                    />
                    <line
                        id="Line_165"
                        data-name="Line 165"
                        y2="22"
                        transform="translate(187.5 303.5)"
                        fill="none"
                        stroke="#ffd300"
                        strokeLinecap="round"
                        strokeWidth="2"
                    />
                    <line
                        id="Line_169"
                        data-name="Line 169"
                        x2="10"
                        y2="8.5"
                        transform="translate(226.594 293.75)"
                        fill="none"
                        stroke="#ffd300"
                        strokeLinecap="round"
                        strokeWidth="2"
                    />
                    <line
                        id="Line_166"
                        data-name="Line 166"
                        x2="12"
                        transform="translate(222.5 264)"
                        fill="none"
                        stroke="#07ac9d"
                        strokeLinecap="round"
                        strokeWidth="2"
                    />
                    <line
                        id="Line_170"
                        data-name="Line 170"
                        y1="5"
                        x2="5"
                        transform="translate(161.377 288)"
                        fill="none"
                        stroke="#66abe0"
                        strokeLinecap="round"
                        strokeWidth="2"
                    />
                    <line
                        id="Line_171"
                        data-name="Line 171"
                        x2="4.75"
                        y2="7.5"
                        transform="translate(208 299)"
                        fill="none"
                        stroke="#66abe0"
                        strokeLinecap="round"
                        strokeWidth="2"
                    />
                    <line
                        id="Line_172"
                        data-name="Line 172"
                        x2="7.25"
                        y2="3.873"
                        transform="translate(152.625 244.324)"
                        fill="none"
                        stroke="#66abe0"
                        strokeLinecap="round"
                        strokeWidth="2"
                    />
                    <g id="Group_1862" data-name="Group 1862">
                        <line
                            id="Line_167"
                            data-name="Line 167"
                            x2="8"
                            y2="9"
                            transform="translate(145.5 210.5)"
                            fill="none"
                            stroke="#07ac9d"
                            strokeLinecap="round"
                            strokeWidth="2"
                        />
                        <circle
                            id="Ellipse_1042"
                            data-name="Ellipse 1042"
                            cx="2.5"
                            cy="2.5"
                            r="2.5"
                            transform="translate(162 228)"
                            fill="#66abe0"
                        />
                    </g>
                    <line
                        id="Line_167-2"
                        data-name="Line 167"
                        x2="8"
                        y2="9"
                        transform="translate(213 243.146) rotate(-87)"
                        fill="none"
                        stroke="#07ac9d"
                        strokeLinecap="round"
                        strokeWidth="2"
                    />
                    <circle
                        id="Ellipse_1042-2"
                        data-name="Ellipse 1042"
                        cx="2.5"
                        cy="2.5"
                        r="2.5"
                        transform="translate(231.339 227.585) rotate(-87)"
                        fill="#66abe0"
                    />
                    <circle
                        id="Ellipse_1092"
                        data-name="Ellipse 1092"
                        cx="2.5"
                        cy="2.5"
                        r="2.5"
                        transform="translate(163.75 311.493) rotate(-87)"
                        fill="#07ac9d"
                    />
                    <circle
                        id="Ellipse_1043"
                        data-name="Ellipse 1043"
                        cx="1"
                        cy="1"
                        r="1"
                        transform="translate(214 213)"
                        fill="#07ac9d"
                    />
                    <circle
                        id="Ellipse_1088"
                        data-name="Ellipse 1088"
                        cx="1"
                        cy="1"
                        r="1"
                        transform="translate(147 255)"
                        fill="#07ac9d"
                    />
                    <circle
                        id="Ellipse_1089"
                        data-name="Ellipse 1089"
                        cx="1"
                        cy="1"
                        r="1"
                        transform="translate(203 306)"
                        fill="#07ac9d"
                    />
                    <circle
                        id="Ellipse_1090"
                        data-name="Ellipse 1090"
                        cx="1"
                        cy="1"
                        r="1"
                        transform="translate(239 246)"
                        fill="#07ac9d"
                    />
                    <circle
                        id="Ellipse_1091"
                        data-name="Ellipse 1091"
                        cx="1"
                        cy="1"
                        r="1"
                        transform="translate(145 287)"
                        fill="#c65b9e"
                    />
                    <circle
                        id="Ellipse_1093"
                        data-name="Ellipse 1093"
                        cx="1"
                        cy="1"
                        r="1"
                        transform="translate(146 299.5)"
                        fill="#ffd300"
                    />
                    <circle
                        id="Ellipse_1094"
                        data-name="Ellipse 1094"
                        cx="1"
                        cy="1"
                        r="1"
                        transform="translate(144.5 225.095)"
                        fill="#ffd300"
                    />
                    <circle
                        id="Ellipse_1095"
                        data-name="Ellipse 1095"
                        cx="1"
                        cy="1"
                        r="1"
                        transform="translate(221.406 245.553)"
                        fill="#ffd300"
                    />
                    <circle
                        id="Ellipse_1096"
                        data-name="Ellipse 1096"
                        cx="1"
                        cy="1"
                        r="1"
                        transform="translate(238 278)"
                        fill="#ffd300"
                    />
                    <circle
                        id="Ellipse_1044"
                        data-name="Ellipse 1044"
                        cx="1.5"
                        cy="1.5"
                        r="1.5"
                        transform="translate(225.094 272.5)"
                        fill="#66abe0"
                    />
                    <circle
                        id="Ellipse_1045"
                        data-name="Ellipse 1045"
                        cx="2.5"
                        cy="2.5"
                        r="2.5"
                        transform="translate(213 283)"
                        fill="#c65b9e"
                    />
                    <circle
                        id="Ellipse_1086"
                        data-name="Ellipse 1086"
                        cx="2"
                        cy="2"
                        r="2"
                        transform="translate(135.741 244.811) rotate(-7)"
                        fill="#c65b9e"
                    />
                    <circle
                        id="Ellipse_1087"
                        data-name="Ellipse 1087"
                        cx="2"
                        cy="2"
                        r="2"
                        transform="translate(200.371 226.583) rotate(-7)"
                        fill="#c65b9e"
                    />
                </g>
            </g>
        </svg>
    );
};

export default ChildBirth;
