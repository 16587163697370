import { CLEAR_REDUCERS, LOAD_BREASTFEEDING } from '../actions/actionTypes';

const initialState = [];

const breastfeedingReducer = (state = [...initialState], action) => {
  switch (action.type) {
    case LOAD_BREASTFEEDING:
      state = [...action.payload];
      return [...state];
    case CLEAR_REDUCERS:
      state = [...initialState];
      return state;
    default:
      return state;
  }
};

export default breastfeedingReducer;
