import {
  CLEAR_MODAL_STATE,
  SHOW_SUCCESS_MODAL,
  SHOW_ERROR_MODAL,
  SHOW_INFO_MODAL,
  SHOW_AGREEMENT_MODAL,
  SHOW_SHARE_MODAL,
  SHOW_DELETE_MODAL,
  SHOW_CONFIRM_DIALOG,
} from '../actions/actionTypes';

const initialState = {
  status: null,
  message: null,
  redirect: false,
};

const modalReducer = (state = initialState, action) => {
  let updatedState = {};
  switch (action.type) {
    case SHOW_SUCCESS_MODAL:
      updatedState = { ...initialState };
      updatedState.status = SHOW_SUCCESS_MODAL;
      updatedState.message = action.payload;
      if (action.redirect) {
        updatedState.redirect = action.redirect;
      }

      return updatedState;
    case SHOW_INFO_MODAL:
      updatedState = { ...initialState };
      updatedState.status = SHOW_INFO_MODAL;
      updatedState.message = action.payload;

      return updatedState;
    case SHOW_AGREEMENT_MODAL:
      updatedState = { ...initialState };
      updatedState.status = SHOW_AGREEMENT_MODAL;
      updatedState.message = action.payload;

      return updatedState;
    case SHOW_ERROR_MODAL:
      updatedState = { ...initialState };
      updatedState.status = SHOW_ERROR_MODAL;
      updatedState.message = action.payload;

      return updatedState;
    case SHOW_SHARE_MODAL:
      updatedState = { ...initialState };
      updatedState.status = SHOW_SHARE_MODAL;
      updatedState.message = action.payload;

      return updatedState;
    case SHOW_DELETE_MODAL:
      updatedState = { ...initialState };
      updatedState.status = SHOW_DELETE_MODAL;
      updatedState.message = action.payload.message;
      updatedState.onDelete = action.payload.onDelete;

      return updatedState;

    case SHOW_CONFIRM_DIALOG:
      updatedState = { ...initialState };
      updatedState.status = SHOW_CONFIRM_DIALOG;
      updatedState.message = action.payload.message;
      updatedState.onConfirm = action.payload.onConfirm;
      updatedState.onDeny = action.payload.onDeny;

      return updatedState;
    case CLEAR_MODAL_STATE:
      updatedState = { ...initialState };
      updatedState.status = null;
      updatedState.message = null;
      updatedState.redirect = false;

      return updatedState;

    default:
      return state;
  }
};

export default modalReducer;
