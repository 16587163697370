import React, { Component, Fragment } from 'react';
import { Col } from 'reactstrap';
import '../styles/app.scss';
import {
    COLOR_SCHEME,
    DMT_WEBSITE,
    RELATIVE_PAGE_PATHS
} from '../utils/constants';
import navigationMethods from '../utils/navigationUtils';
import BackButtonSVG from './common/svgComponents/BackButton';
import LogoIcon from './common/svgComponents/LogoIcon';

class LocalNavigationComponent extends Component {
    backButton() {
        const { previousPage } = this.props;
        const hiddenButton = previousPage ? {} : { visibility: 'hidden' };

        return (
            <Col style={hiddenButton}>
                <BackButtonSVG
                    width={15}
                    height={15}
                    svgClicked={previousPage}
                    color={COLOR_SCHEME.GREY}
                />
            </Col>
        );
    }

    navBarHeader() {
        const { header } = this.props;
        return (
            <Col style={{ whiteSpace: 'nowrap' }}>
                <p className="noMargin centredText nav-header-text">{header}</p>
            </Col>
        );
    }

    navBarLogo() {
        return (
            <Col className="logo-icon">
                <a href={DMT_WEBSITE} target="_blank" rel="noreferrer">
                    <LogoIcon width={29} height={29} />
                </a>
            </Col>
        );
    }

    render() {
        if (
            navigationMethods.getRoute() ===
            RELATIVE_PAGE_PATHS.JOURNAL_READ_ONLY
        ) {
            return <Fragment />;
        }
        return (
            <div className="flexContainer noPadding noMargin mainNav header localNav">
                {this.backButton()}
                {this.navBarHeader()}
                {this.navBarLogo()}
            </div>
        );
    }
}

export default LocalNavigationComponent;
