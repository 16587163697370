import React, { Component } from 'react';
import UnhandledErrorScreen from './UnhandledErrorScreen';

class ErrorBoundary extends Component {
    state = {
        errorOccurred: false,
    };

    componentDidCatch = (error, info) => {
        this.setState({ errorOccurred: true });
    };

    render = () =>
        this.state.errorOccurred ? (
            <UnhandledErrorScreen />
        ) : (
            this.props.children
        );
}

export default ErrorBoundary;
