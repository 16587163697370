import React from 'react';

const DetectTime = ({ width, height }) => {
    const viewBox = '0 0 17.688 20.473';

    return (
        <svg
            id="Икона_-_ИЗМЕРИ"
            data-name="Икона - ИЗМЕРИ"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox={viewBox}
        >
            <path
                id="Path_435"
                data-name="Path 435"
                d="M52.951,44.567a1.7,1.7,0,0,1-1.635-.987,1.61,1.61,0,0,1,.333-1.791.558.558,0,0,0,.176-.393c.056-1.035.119-2.07.178-3.1a.8.8,0,0,1,.5-.745.782.782,0,0,1,.834.108.845.845,0,0,1,.316.664c.055,1.008.118,2.015.172,3.023a.69.69,0,0,0,.2.47,1.541,1.541,0,0,1,.308,1.773A1.559,1.559,0,0,1,52.951,44.567Z"
                transform="translate(-43.985 -31.653)"
                fill="#87ba3b"
            />
            <g
                id="Group_260"
                data-name="Group 260"
                transform="translate(0 2.785)"
            >
                <path
                    id="Path_436"
                    data-name="Path 436"
                    d="M36.331,45.169a8.844,8.844,0,1,1,8.844-8.844A8.854,8.854,0,0,1,36.331,45.169Zm0-16.17a7.326,7.326,0,1,0,7.326,7.326A7.334,7.334,0,0,0,36.331,29Z"
                    transform="translate(-27.487 -27.481)"
                    fill="#87ba3b"
                />
            </g>
            <rect
                id="Rectangle_1048"
                data-name="Rectangle 1048"
                width="5.314"
                height="2.317"
                rx="1.158"
                transform="translate(6.187 0)"
                fill="#87ba3b"
            />
            <rect
                id="Rectangle_1049"
                data-name="Rectangle 1049"
                width="2.765"
                height="2.151"
                rx="1.076"
                transform="matrix(0.817, 0.577, -0.577, 0.817, 15.034, 1.868)"
                fill="#87ba3b"
            />
        </svg>
    );
};

export default DetectTime;
