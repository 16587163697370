import React, { Fragment } from 'react';
import moment from 'moment';
import {
    COLOR_SCHEME,
    DEFAULT_BACKEND_DATE_FORMAT,
    EMPTY_STRING,
    FORMS,
} from '../../utils/constants';
import {
    isAbilitiesTag,
    isCapturedMomentsTag,
    isCelebrationsTag,
    isCreativePerformancesTag,
    isExistingSticker,
    isProphylacticsTag,
    isReadOnlyRoute,
    isWorriesTag,
    parseDate,
} from '../../utils/commonUtils';
import { Button, Col } from 'reactstrap';
import NotificationIcon from '../common/svgComponents/NotificationIcon';
import * as i18n from 'i18next';
import { Fields } from '../common/Fields';
import { connect } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';
import { formUtils } from '../../utils/formUtils';
import {
    addStickerToJournal,
    addStickerWithPhotoToJournal,
    getAddedSticker,
    updateAddedSticker,
} from '../../actions';
import AchievementIcon from '../common/svgComponents/AchievementIcon';
import navigationMethods from '../../utils/navigationUtils';
import StickerDescription from './StickerDescription';

class StickerDateAndDescription extends React.Component {
    renderAchievementIcon(
        worriesTag,
        isStickerExpired,
        activeIconColor,
        notificationColor
    ) {
        if (isStickerExpired) {
            return (
                <AchievementIcon
                    width={70}
                    height={70}
                    fill={activeIconColor}
                />
            );
        } else {
            return worriesTag ? (
                <NotificationIcon
                    width={70}
                    height={70}
                    fill={notificationColor}
                />
            ) : (
                <AchievementIcon
                    width={70}
                    height={70}
                    fill={activeIconColor}
                />
            );
        }
    }

    renderStickerDescription() {
        return <StickerDescription />;
    }

    renderAddToJournalBtn() {
        if (isReadOnlyRoute()) {
            return;
        }
        const { unActiveColor, borderColor } = this.props;
        const formValues = { ...this.props.formValues };
        const enteredDate = formValues.entered;

        const activeButtonColor = enteredDate ? borderColor : unActiveColor;
        const buttonName = isExistingSticker()
            ? i18n.t('buttons:save')
            : i18n.t('addStickerScreen:buttons.addToJournal');

        return (
            <Col xs={12} className="flexContainerColumn centred">
                <Button
                    className="header addToJournalBtn"
                    style={{ backgroundColor: activeButtonColor }}
                    onClick={
                        isExistingSticker()
                            ? this.props.handleSubmit(this.updateSticker)
                            : this.props.handleSubmit(this.addStickerToJournal)
                    }
                >
                    {buttonName}
                </Button>
            </Col>
        );
    }

    getTagName = () => {
        const { selectedTag, selectedStickerTemplate } = this.props;

        if (selectedTag && selectedTag.name) {
            return selectedTag.name;
        } else {
            return selectedStickerTemplate &&
                selectedStickerTemplate.area &&
                selectedStickerTemplate.area.tag
                ? selectedStickerTemplate.area.tag.name
                : EMPTY_STRING;
        }
    };

    addStickerToJournal = (formValues) => {
        const enteredDate = formValues.entered;
        const stickerTemplateId = this.props.selectedStickerTemplate.id;
        const userText = formValues.userText;
        if (formValues.uploadPhoto && formValues.uploadPhoto.length > 0) {
            this.props.addStickerWithPhotoToJournal(
                stickerTemplateId,
                enteredDate,
                userText
            );
        } else {
            this.props.addStickerToJournal(
                stickerTemplateId,
                enteredDate,
                userText
            );
        }
    };

    updateSticker = (formValues) => {
        const data = { ...formValues };
        const route = navigationMethods.getRoute().split('/');
        const stickerTemplateId = route[route.length - 1];

        this.props.updateAddedSticker(stickerTemplateId, data);
    };

    renderAchievementTitle() {
        const selectedTagName = this.props.selectedTag.name;
        const isTagFromMemories =
            isCelebrationsTag(selectedTagName) ||
            isCreativePerformancesTag(selectedTagName) ||
            isCapturedMomentsTag(selectedTagName);

        let achievementTitle =
            isProphylacticsTag(selectedTagName) || isTagFromMemories
                ? i18n.t('addStickerScreen:happenedОn')
                : isAbilitiesTag(selectedTagName)
                ? i18n.t('addStickerScreen:achievedОn')
                : i18n.t('addStickerScreen:savedОn');

        return <p className="noMargin stickerDatesText">{achievementTitle}</p>;
    }

    renderDates = () => {
        const formValues = this.props.formValues;
        const { user, selectedAddedSticker, selectedStickerTemplate } =
            this.props;
        const {
            unActiveColor,
            borderColor,
            backgroundColor,
            expiredBorderColor,
            expiredBackgroundColor,
        } = this.props;

        const enteredDate =
            formValues && formValues.entered
                ? formValues.entered
                : selectedAddedSticker.enteredDate;

        let expectedEndDate = null;
        if (selectedStickerTemplate.daysToExpire !== null) {
            expectedEndDate = moment(
                user.childDateOfBirth,
                DEFAULT_BACKEND_DATE_FORMAT
            )
                .add(selectedStickerTemplate.daysToExpire, 'days')
                .format(DEFAULT_BACKEND_DATE_FORMAT);
        }

        if (user.isChildBorn4WeeksBeforeDueDate) {
            const fourWeeksBeforeDueDate = 30;
            expectedEndDate = moment(
                expectedEndDate,
                DEFAULT_BACKEND_DATE_FORMAT
            )
                .add(fourWeeksBeforeDueDate, 'days')
                .format(DEFAULT_BACKEND_DATE_FORMAT);
        }

        const happenedAfterExpirationDate = moment(enteredDate).isAfter(
            expectedEndDate,
            'day'
        );
        const activeIconColor = enteredDate ? borderColor : unActiveColor;

        const worriesTag = isWorriesTag(this.getTagName()); // The notification icon wil be always yellow/orange in
        // case of this TAG

        let notificationColor =
            selectedStickerTemplate.isExpired || worriesTag
                ? expiredBorderColor
                : unActiveColor;

        if (enteredDate) {
            if (happenedAfterExpirationDate || worriesTag) {
                notificationColor = expiredBorderColor;
            } else {
                notificationColor = borderColor;
            }
        }

        const isColumnHidden =
            expectedEndDate && expectedEndDate !== 'Invalid date'
                ? false
                : true;

        const expiredStyle = {
            border: `1px solid ${expiredBorderColor}`,
            boxShadow: `0 0 0 0 rgb(0 0 0 / 0%), 0px 0px 0px 6px ${expiredBackgroundColor}`,
        };

        const expiredBtnStyle = {
            backgroundColor: expiredBorderColor,
        };

        const visibleContainerStyle = {
            backgroundColor: borderColor,
            border: 'none',
        };

        const inVisibleContainerStyle = {
            border: `1px solid ${borderColor}`,
            boxShadow: `0 0 0 0 rgb(0 0 0 / 0%), 0px 0px 0px 6px ${backgroundColor}`,
        };

        const isStickerExpired = selectedStickerTemplate.isExpired
            ? true
            : false;

        let containerStyle = isStickerExpired
            ? expiredBtnStyle
            : visibleContainerStyle;

        if (enteredDate) {
            if (happenedAfterExpirationDate) {
                containerStyle = expiredStyle;
            } else {
                containerStyle = inVisibleContainerStyle;
            }
        }

        const enteredFieldClassName = isColumnHidden
            ? 'visibleContainer full-width-field'
            : 'visibleContainer';

        return (
            <Col
                xs={12}
                className="stickerDatesSection flexContainerCentered centred noPadding"
            >
                <Col
                    xs={6}
                    className="flexColumnCentered noPadding"
                    hidden={isColumnHidden}
                >
                    <NotificationIcon
                        width={70}
                        height={70}
                        fill={
                            notificationColor
                                ? notificationColor
                                : COLOR_SCHEME.GRAY
                        }
                    />
                    <p className="noMargin stickerDatesText">
                        {i18n.t('addStickerScreen:expectedEndDate')}
                    </p>
                    <div className="expectedEndDate centredText">
                        {parseDate(expectedEndDate)}
                    </div>
                </Col>
                <Col
                    xs={isColumnHidden ? 12 : 6}
                    className="flexColumnCentered noPadding"
                >
                    {this.renderAchievementIcon(
                        worriesTag,
                        isStickerExpired,
                        activeIconColor,
                        notificationColor
                    )}
                    {this.renderAchievementTitle()}
                    <div
                        className="flexContainerCentered datePickerContainer"
                        style={containerStyle}
                    >
                        {Fields.commonFields.entered({
                            filterDate: true,
                            className: enteredFieldClassName,
                            placeholder: i18n.t(
                                'addStickerScreen:buttons.choose'
                            ),
                            disabled: isReadOnlyRoute(),
                        })}
                    </div>
                </Col>
            </Col>
        );
    };

    render() {
        return (
            <Fragment>
                {this.renderDates()}
                {this.renderStickerDescription()}
                {this.renderAddToJournalBtn()}
            </Fragment>
        );
    }
}

const parseInitialValues = (values) => {
    const initialValues = {};
    if (values.entered) {
        initialValues.entered = new Date(values.entered);
    }
    if (values.userText) {
        initialValues.userText = values.userText;
    }
    return initialValues;
};

const mapStateToProps = (state) => {
    return {
        selectedTag: isExistingSticker()
            ? state.selectedSticker.selectedAddedSticker.stickerTemplate.area
                  .tag
            : state.selectedSticker.selectedStickerTemplate.area.tag,
        formValues: getFormValues(FORMS.STICKER_FORM)(state),
        selectedStickerTemplate: isExistingSticker()
            ? state.selectedSticker.selectedAddedSticker.stickerTemplate
            : state.selectedSticker.selectedStickerTemplate,
        selectedAddedSticker: isExistingSticker()
            ? state.selectedSticker.selectedAddedSticker
            : {},
        user: state.authentication.userData || {},
        initialValues: parseInitialValues(
            isExistingSticker()
                ? state.selectedSticker.selectedAddedSticker
                : {}
        ),
    };
};

const stickerForm = reduxForm({
    form: FORMS.STICKER_FORM,
    enableReinitialize: true,
    validate: formUtils.validateAddSticker,
})(StickerDateAndDescription);

export default connect(mapStateToProps, {
    addStickerToJournal,
    addStickerWithPhotoToJournal,
    getAddedSticker,
    updateAddedSticker,
})(stickerForm);
