import { useTrackingTimer } from '../../../../context/TrackingTimerProvider';
import { TrackingButtonProps } from '../models';

export const useTrackingButtonProps = (
  buttonSide: string,
  { onClick, selectedSide, isActive, tracker }: TrackingButtonProps
) => {
  const { isActiveTracker } = useTrackingTimer(tracker);
  return {
    onClick: () => {
      onClick && onClick(buttonSide);
    },
    disabled: !isActiveTracker || (selectedSide !== buttonSide && isActive),
    isActive: isActiveTracker && selectedSide === buttonSide && isActive,
  };
};
