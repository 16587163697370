import moment from './moment';
import { DEFAULT_BACKEND_DATE_FORMAT } from './constants';

const convertDateToBackendFormat = (date) =>
    moment(date).format(DEFAULT_BACKEND_DATE_FORMAT);

const getQueryStringAfterFirstEqualsCharacter = (queryString) =>
    queryString.substring(queryString.indexOf('=') + 1);

const appMethods = {
    convertDateToBackendFormat,
    getQueryStringAfterFirstEqualsCharacter,
};

export default appMethods;
