import { FC } from 'react';
import LeftPauseButton from '../../common/svgComponents/LeftPauseButton';
import LeftPlayButton from '../../common/svgComponents/LeftPlayButton';
import LeftPlayButtonDisabled from '../../common/svgComponents/LeftPlayButtonDisabled';
import { StartStopButton } from '../shared/StartStopButton';

interface BreastFeedingLeftButtonProps {
  disabled?: boolean;
  onClick?: () => void;
  isActive?: boolean;
}
export const BreastFeedingLeftButton: FC<BreastFeedingLeftButtonProps> = (
  props
) => {
  return (
    <StartStopButton
      startButton={LeftPlayButton}
      stopButton={LeftPauseButton}
      disabledButton={LeftPlayButtonDisabled}
      color={'#87ba3b'}
      {...props}
    />
  );
};
