import { FC } from 'react';
import useDfpSlot, { useDfpSlotProps } from '../../hooks/useDfpSlot';

const AdSlot: FC<useDfpSlotProps> = ({ id, size, path }) => {
  useDfpSlot({
    id,
    path,
    size,
  });
  return <div id={id} style={{ minWidth: '300px', minHeight: '50px' }} />;
};

export default AdSlot;
