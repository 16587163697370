import React from 'react';

const ForgottenPassword = ({ width, height, svgClicked }) => {
    const viewBoxValues = '0 0 164 166';

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox={viewBoxValues}
        >
            <defs>
                <filter
                    id="Rectangle_1662"
                    x="0"
                    y="0"
                    width="164"
                    height="164"
                    filterUnits="userSpaceOnUse"
                >
                    <feOffset dy="1" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="1" result="blur" />
                    <feFlood floodOpacity="0.157" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g
                id="Квадрат_с_картинка_-_забравена_парола"
                data-name="Квадрат с картинка - забравена парола"
                transform="translate(-99.5 -117)"
            >
                <g
                    transform="matrix(1, 0, 0, 1, 99.5, 117)"
                    filter="url(#Rectangle_1662)"
                >
                    <g
                        id="Rectangle_1662-2"
                        data-name="Rectangle 1662"
                        transform="translate(3 2)"
                        fill="#fff"
                        stroke="#cacaca"
                        strokeWidth="2"
                    >
                        <rect width="158" height="158" rx="11" stroke="none" />
                        <rect
                            x="1"
                            y="1"
                            width="156"
                            height="156"
                            rx="10"
                            fill="none"
                        />
                    </g>
                </g>
                <text
                    id="_"
                    data-name="!"
                    transform="matrix(0.985, -0.174, 0.174, 0.985, 171.312, 237)"
                    fill="#5abae9"
                    fontSize="78"
                    fontFamily="PlainScriptCTT"
                    letterSpacing="0.03em"
                >
                    <tspan x="-16.107" y="0">
                        !
                    </tspan>
                </text>
                <text
                    id="_2"
                    data-name="?"
                    transform="matrix(0.985, 0.174, -0.174, 0.985, 194, 206)"
                    fill="#e95a9c"
                    fontSize="76"
                    fontFamily="PlainScriptCTT"
                    letterSpacing="0.03em"
                >
                    <tspan x="-16.112" y="0">
                        ?
                    </tspan>
                </text>
            </g>
        </svg>
    );
};

export default ForgottenPassword;
