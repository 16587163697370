import {
  LOAD_SELECTED_TAG_STICKERS_SUCCESS,
  CLEAR_REDUCERS,
} from '../../actions/actionTypes';

const initialState = {
  selectedStickers: [],
};

const selectedTagStickersReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case LOAD_SELECTED_TAG_STICKERS_SUCCESS:
      state.selectedStickers = action.payload;
      return { ...state };

    case CLEAR_REDUCERS:
      return { ...initialState };

    default:
      return state;
  }
};

export default selectedTagStickersReducer;
