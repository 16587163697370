import { getAxiosWithToken } from '../utils/webApi';
import {
    GET_USEFUL_INFO
} from './actionTypes';
import { startLoader, stopLoader } from './commonActions';

export const getUsefulInfo = (month) => async (dispatch) => {
    dispatch(startLoader());

    const response = await getAxiosWithToken().get(
        'information/by-month/' + month
    );

    dispatch(stopLoader());

    const returnedData = response.data;

    if (returnedData.success) {
        dispatch({
            type: GET_USEFUL_INFO,
            payload: returnedData.data,
        });
    }
};
