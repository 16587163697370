import React from 'react';

const AddToJournalTitle = ({ width, height }) => {
    const viewBoxValues = '0 0 307 91';

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox={viewBoxValues}
        >
            <defs>
                <filter
                    id="Rectangle_1734"
                    x="0"
                    y="21"
                    width="307"
                    height="70"
                    filterUnits="userSpaceOnUse"
                >
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="2" result="blur" />
                    <feFlood floodColor="#b4b4b4" floodOpacity="0.11" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g
                id="Box_-_Добави_в_дневника"
                data-name="Box - Добави в дневника"
                transform="translate(-33 -78)"
            >
                <g
                    id="Подложка_-_Добави_в_дневник"
                    data-name="Подложка - Добави в дневник"
                    transform="translate(-15.002 6)"
                >
                    <g
                        transform="matrix(1, 0, 0, 1, 48, 72)"
                        filter="url(#Rectangle_1734)"
                    >
                        <rect
                            id="Rectangle_1734-2"
                            data-name="Rectangle 1734"
                            width="295"
                            height="58"
                            rx="11"
                            transform="translate(6 24)"
                            fill="#f4fce5"
                        />
                    </g>
                    <circle
                        id="Ellipse_896"
                        data-name="Ellipse 896"
                        cx="29.5"
                        cy="29.5"
                        r="29.5"
                        transform="translate(175.002 72)"
                        fill="#f4fce5"
                    />
                </g>
                <text
                    id="добави_в_дневника"
                    data-name="добави в дневника"
                    transform="translate(192 140)"
                    fill="#87ba3b"
                    fontSize="16"
                    fontFamily="Nunito-Bold, Nunito"
                    fontWeight="700"
                >
                    <tspan x="-91.592" y="0">
                        ДОБАВИ В ДНЕВНИКА
                    </tspan>
                </text>
                <g
                    id="Звезда_в_кръгче_със_сянка_-_зелена"
                    data-name="Звезда в кръгче със сянка - зелена"
                    transform="translate(-5 3)"
                >
                    <path
                        id="Subtraction_51"
                        data-name="Subtraction 51"
                        d="M154.838,36.551a16.8,16.8,0,0,1-12.312,3.29A16.042,16.042,0,0,1,128.46,24.567a16.629,16.629,0,0,1,2.307-9.142,16.12,16.12,0,0,0,2.681,13.888A16.536,16.536,0,0,0,156.613,32.3a16.931,16.931,0,0,0,4.427-4.946,16.758,16.758,0,0,1-2.259,5.029A17.018,17.018,0,0,1,154.838,36.551Z"
                        transform="translate(49.553 72.022)"
                        fill="#87ba3b"
                        opacity="0.52"
                    />
                    <ellipse
                        id="Ellipse_899"
                        data-name="Ellipse 899"
                        cx="16.5"
                        cy="15.5"
                        rx="16.5"
                        ry="15.5"
                        transform="translate(178 80)"
                        fill="#fff"
                    />
                    <g
                        id="Group_1384"
                        data-name="Group 1384"
                        transform="translate(50.01 72.798)"
                    >
                        <path
                            id="Icon_awesome-star"
                            data-name="Icon awesome-star"
                            d="M143.869,18.712l-1.685,3.416-3.769.549a.826.826,0,0,0-.456,1.409l2.726,2.656-.645,3.753a.825.825,0,0,0,1.2.869l3.371-1.772,3.367,1.773a.825.825,0,0,0,1.2-.869l-.641-3.754,2.726-2.657a.826.826,0,0,0-.456-1.409l-3.768-.549-1.685-3.416a.826.826,0,0,0-1.48,0Z"
                            transform="translate(-0.226 -0.299)"
                            fill="#87ba3b"
                        />
                        <g
                            id="Group_1678"
                            data-name="Group 1678"
                            transform="translate(133.129 12.202)"
                        >
                            <g
                                id="Line_2"
                                data-name="Line 2"
                                transform="translate(17.535 4.077)"
                            >
                                <path
                                    id="Path_1736"
                                    data-name="Path 1736"
                                    d="M152.194,18.785a.618.618,0,0,1-.343-1.133l1.6-1.058a.618.618,0,0,1,.684,1.03l-1.6,1.058A.608.608,0,0,1,152.194,18.785Z"
                                    transform="translate(-151.574 -16.491)"
                                    fill="#87ba3b"
                                />
                            </g>
                            <g
                                id="Line_6"
                                data-name="Line 6"
                                transform="translate(10.636)"
                            >
                                <path
                                    id="Path_1737"
                                    data-name="Path 1737"
                                    d="M144.935,15.426h-.009a.618.618,0,0,1-.609-.626l.027-1.988a.654.654,0,0,1,.626-.609.619.619,0,0,1,.61.626l-.028,1.988A.619.619,0,0,1,144.935,15.426Z"
                                    transform="translate(-144.317 -12.202)"
                                    fill="#87ba3b"
                                />
                            </g>
                            <g
                                id="Line_4"
                                data-name="Line 4"
                                transform="translate(2.509 4.077)"
                            >
                                <path
                                    id="Path_1738"
                                    data-name="Path 1738"
                                    d="M138,19.235a.614.614,0,0,1-.421-.166l-1.619-1.507a.618.618,0,1,1,.842-.905l1.619,1.508a.618.618,0,0,1-.421,1.07Z"
                                    transform="translate(-135.768 -16.491)"
                                    fill="#87ba3b"
                                />
                            </g>
                            <g
                                id="Line_3"
                                data-name="Line 3"
                                transform="translate(20.318 13.025)"
                            >
                                <path
                                    id="Path_1739"
                                    data-name="Path 1739"
                                    d="M157.069,27.6a.609.609,0,0,1-.143-.017l-1.949-.461a.617.617,0,1,1,.284-1.2l1.949.461a.618.618,0,0,1-.142,1.219Z"
                                    transform="translate(-154.502 -25.903)"
                                    fill="#87ba3b"
                                />
                            </g>
                            <g
                                id="Line_5"
                                data-name="Line 5"
                                transform="translate(0 13.136)"
                            >
                                <path
                                    id="Path_1740"
                                    data-name="Path 1740"
                                    d="M133.746,27.6a.618.618,0,0,1-.121-1.223l1.734-.348a.618.618,0,0,1,.243,1.211l-1.734.348A.6.6,0,0,1,133.746,27.6Z"
                                    transform="translate(-133.129 -26.02)"
                                    fill="#87ba3b"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default AddToJournalTitle;
