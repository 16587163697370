import { Box, Stack, Typography } from '@mui/material';
import * as i18n from 'i18next';
import moment from 'moment';
import React, { Fragment, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { deleteTrackingRecord } from '../../actions/trackingRecordsActions';
import { RELATIVE_PAGE_PATHS, TRACKERS_SUB_TYPE } from '../../utils/constants';
import formatMeasurement from '../../utils/trackerMeasurement';
import DateDivider from './DateDivider';
import TrackerHistoryItem from './TrackerHistoryItem';
import { useTranslation } from 'react-i18next';

const TrackerHistory = ({
  historyRecords = [],
  hideInterval,
  isBreastFeeding,
  onEdit,
  disableEdit,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation('trackingScreen');

  const HistoryTime = ({ record }) => {
    return (
      <div>
        <p className="date">{moment(record.date).format('HH:mm')}</p>
      </div>
    );
  };

  const HistoryIndicator = () => {
    return (
      <div className="indicator pr-2 pl-2">
        <div className="line"></div>
        <div className="point"></div>
      </div>
    );
  };

  const getInterval = (record, nextRecord) => {
    if (!nextRecord) return 0;
    if (record.subType === TRACKERS_SUB_TYPE.SLEEP) {
      return moment(record.date).from(
        moment(nextRecord.date).add(nextRecord.quantity, 'seconds'),
        true
      );
    } else {
      return moment(record.date).from(nextRecord.date, true);
    }
  };

  const HistoryDetails = ({ record, nextRecord }) => {
    const intervalKey =
      record.subType === TRACKERS_SUB_TYPE.SLEEP
        ? TRACKERS_SUB_TYPE.SLEEP
        : 'interval';

    return (
      <div className="pl-1 pr-1">
        <Stack>
          <span>
            {formatMeasurement(
              record.quantity,
              record.symbol,
              record.type,
              record.subType,
              record.additionalInfo
            )}
          </span>
          {!hideInterval && nextRecord && (
            <Typography component="span" fontSize={'12px'}>
              {t(`history.${intervalKey}`, {
                interval: getInterval(record, nextRecord),
              })}
            </Typography>
          )}
        </Stack>
      </div>
    );
  };

  const handleDelete = useCallback(
    (id) => {
      dispatch(deleteTrackingRecord(id));
    },
    [dispatch]
  );

  const handleEdit = (id, subTypeId) => {
    if (isBreastFeeding) {
      onEdit && onEdit(id);
    } else {
      history.push(`${RELATIVE_PAGE_PATHS.TRACKING}/${subTypeId}/${id}`);
    }
  };

  return (
    <>
      <Typography variant={'h5'}>
        {i18n.t('breastfeedingScreen:history')}
      </Typography>
      <div className="history container">
        {historyRecords.map((record, index) => (
          <Fragment key={index}>
            <DateDivider
              current={record}
              previous={historyRecords[index - 1]}
            />
            <TrackerHistoryItem
              record={record}
              onDelete={handleDelete}
              onEdit={handleEdit}
              disableEdit={disableEdit}
              disableLink={isBreastFeeding}
            >
              <HistoryTime record={record} />
              <HistoryIndicator />
              <HistoryDetails
                record={record}
                nextRecord={historyRecords[index + 1]}
              />
            </TrackerHistoryItem>
          </Fragment>
        ))}
      </div>
    </>
  );
};

export default TrackerHistory;
