import React from 'react';
import * as i18n from 'i18next';
import { Fields } from '../common/Fields';
import { Button, Col, Container } from 'reactstrap';
import { reduxForm } from 'redux-form';
import {
    DEFAULT_BACKEND_DATE_FORMAT,
    FORMS,
    RELATIVE_PAGE_PATHS,
} from '../../utils/constants';
import { formUtils } from '../../utils/formUtils';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    editProfile,
    navigateToHomeScreen,
    showInfoModal,
    showConfirmModal,
    closeModal,
    checkForNewVersion,
} from '../../actions';
import moment from 'moment';

class ProfileScreen extends React.Component {
    componentDidMount() {
        // Avoiding device keyboard
        const datePickers = document.getElementsByClassName(
            'react-datepicker__input-container'
        );
        Array.from(datePickers).forEach((el) =>
            el.childNodes[0].setAttribute('readOnly', true)
        );

        this.props.checkForNewVersion();
    }

    handleSubmit = (formValues) => {
        const data = { ...formValues };
        data.id = this.props.user.id;
        data.isChildGirl = !!data.isChildGirl;
        if (
            moment(data.childDateOfBirth).format(
                DEFAULT_BACKEND_DATE_FORMAT
            ) !==
            moment(this.props.initialValues.childDateOfBirth).format(
                DEFAULT_BACKEND_DATE_FORMAT
            )
        ) {
            this.props.showConfirmModal({
                message: i18n.t('profileScreen:confirmEditingUser'),
                onConfirm: () => {
                    this.props.closeModal();
                    this.props.editProfile(
                        data,
                        this.props.user.id,
                        formValues.isChildBorn4WeeksBeforeDueDate
                    );
                },
            });
        } else {
            this.props.editProfile(
                data,
                this.props.user.id,
                formValues.isChildBorn4WeeksBeforeDueDate
            );
        }
    };

    render() {
        return (
            <Container className="noPadding">
                <Col
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    className="centred noPadding"
                >
                    <form
                        onSubmit={this.props.handleSubmit(this.handleSubmit)}
                        className="flexContainerColumn flexContainerCentered profile-form fix-padding"
                    >
                        <div className="flexContainerCentered">
                            <p className="header mainGreenColor header-small uppercase">
                                {i18n.t('profileScreen:forParents')}
                            </p>
                        </div>

                        <div className="flexContainerColumn flexContainerCentered">
                            {Fields.commonFields.email({ disabled: true })}
                            {Fields.commonFields.parentName({
                                placeholder: i18n.t(
                                    'profileScreen:fields.name'
                                ),
                            })}
                            {Fields.commonFields.city()}
                        </div>
                        <div className="flexContainerCentered">
                            <p className="header mainGreenColor uppercase">
                                {i18n.t('profileScreen:forChild')}
                            </p>
                        </div>
                        <div className="flexContainerColumn flexContainerCentered">
                            {Fields.commonFields.childName({
                                placeholder: i18n.t(
                                    'profileScreen:fields.name'
                                ),
                            })}
                            {Fields.commonFields.childBirth()}
                            {Fields.commonFields.isChildBorn4WeeksBeforeDueDate()}
                            {Fields.commonFields.isChildGirl()}
                        </div>
                        {/*<p style={ {fontSize: 12} }>Имате профил? <span style={ {color: '#87ba3b'} }>Влезте.</span></p>*/}
                        <Col
                            xs={10}
                            sm={10}
                            md={10}
                            lg={8}
                            className="flexContainerColumn centred"
                        >
                            <Button
                                onClick={this.props.handleSubmit(
                                    this.handleSubmit
                                )}
                                className="greenBtn header next uppercase"
                            >
                                {i18n.t('buttons:saveChanges')}
                            </Button>
                            <div className="text-buttons">
                                <Link
                                    className="text-btn"
                                    to={RELATIVE_PAGE_PATHS.CHANGE_PASSWORD}
                                >
                                    {i18n.t('profileScreen:changePassword')}
                                </Link>
                                <Link
                                    className="text-btn danger d-block"
                                    to={RELATIVE_PAGE_PATHS.DELETE_PROFILE}
                                >
                                    {i18n.t('profileScreen:deleteProfile')}
                                </Link>
                            </div>
                        </Col>
                    </form>
                </Col>
            </Container>
        );
    }
}

const profileForm = reduxForm({
    form: FORMS.PROFILE_FORM,
    validate: formUtils.validateProfile,
})(ProfileScreen);

const parseInitialValues = (values) => {
    const initialValues = { ...values };
    initialValues.childDateOfBirth = new Date(values.childDateOfBirth);

    initialValues.isChildGirl = values.isChildGirl ? '1' : '';
    return initialValues;
};

const mapStateToProps = (state) => {
    return {
        initialValues: parseInitialValues(state.authentication.userData),
        user: state.authentication.userData,
    };
};

export default connect(mapStateToProps, {
    editProfile,
    navigateToHomeScreen,
    showInfoModal,
    showConfirmModal,
    closeModal,
    checkForNewVersion,
})(profileForm);
