import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { compose } from 'redux';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

import reducers from './reducers';
import App from './App';
import DmtThemeProvider from './theme/DmtThemeProvider';
import { AdProvider } from './context/AdProvider';
import { TrackingTimerProvider } from './context/TrackingTimerProvider';

// TODO: Remove the use of history object in favor of react router hooks.
export const history = createBrowserHistory();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// TODO: switch to @reduxjs/toolkit
export const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(thunk))
);

// window.googletag.cmd.push(() => {
//   window.googletag.openConsole();
// });

// export const store = createStore(reducers, applyMiddleware(thunk));
const version = process.env.REACT_APP_BUILD_VERSION;
console.log(version || 'dev');
ReactDOM.render(
  <Provider store={store}>
    <DmtThemeProvider>
      <AdProvider>
        <TrackingTimerProvider>
          <App history={history} />
        </TrackingTimerProvider>
      </AdProvider>
    </DmtThemeProvider>
  </Provider>,
  document.getElementById('root')
);
