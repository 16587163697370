import React from 'react';

const TermsAndConditions = ({ width, height }) => {
    const viewBox = '0 0 164 164';

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox={viewBox}
        >
            <defs>
                <filter
                    id="Rectangle_1662"
                    x="0"
                    y="0"
                    width="164"
                    height="164"
                    filterUnits="userSpaceOnUse"
                >
                    <feOffset dy="1" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="1" result="blur" />
                    <feFlood floodOpacity="0.157" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g
                id="Квадрат_с_картинка_важни_съгласия"
                data-name="Квадрат с картинка важни съгласия"
                transform="translate(-106 -166)"
            >
                <g
                    transform="matrix(1, 0, 0, 1, 106, 166)"
                    filter="url(#Rectangle_1662)"
                >
                    <g
                        id="Rectangle_1662-2"
                        data-name="Rectangle 1662"
                        transform="translate(3 2)"
                        fill="#fff"
                        stroke="#cacaca"
                        strokeWidth="2"
                    >
                        <rect width="158" height="158" rx="11" stroke="none" />
                        <rect
                            x="1"
                            y="1"
                            width="156"
                            height="156"
                            rx="10"
                            fill="none"
                        />
                    </g>
                </g>
                <g
                    id="Group_1832"
                    data-name="Group 1832"
                    transform="translate(-117.729 -27.831)"
                >
                    <path
                        id="Path_1722"
                        data-name="Path 1722"
                        d="M4056.593,961.766A13.548,13.548,0,0,0,4043.06,975.3v9.858h27.066V975.3A13.548,13.548,0,0,0,4056.593,961.766Z"
                        transform="translate(-3748.193 -720.049)"
                        fill="none"
                    />
                    <path
                        id="Path_1723"
                        data-name="Path 1723"
                        d="M4034.372,973.278V963.42a13.533,13.533,0,0,1,27.067,0v9.858h5.668a7.252,7.252,0,0,1,1.026.081V963.42a20.228,20.228,0,0,0-20.228-20.228h0a20.228,20.228,0,0,0-20.228,20.228v9.892c.225-.021.453-.034.683-.034Z"
                        transform="translate(-3739.676 -709.766)"
                        fill="#87ba3b"
                    />
                    <path
                        id="Path_1724"
                        data-name="Path 1724"
                        d="M4060.458,1014.252a7.221,7.221,0,0,0-1.026-.082h-38.747c-.231,0-.458.013-.684.035a7.179,7.179,0,0,0-6.5,7.145v30.77a7.18,7.18,0,0,0,7.18,7.179h38.747a7.18,7.18,0,0,0,7.18-7.179v-30.77A7.175,7.175,0,0,0,4060.458,1014.252Zm-16.525,24.95v5.054a3.7,3.7,0,1,1-7.407,0V1039.2a7.351,7.351,0,1,1,7.407,0Z"
                        transform="translate(-3731.83 -749.063)"
                        fill="#87ba3b"
                    />
                </g>
            </g>
        </svg>
    );
};

export default TermsAndConditions;
