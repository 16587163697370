import React from 'react';
import navigationMethods from '../../../utils/navigationUtils';
import { COLOR_SCHEME, RELATIVE_PAGE_PATHS } from '../../../utils/constants';

const navigateToExpiredStickers = (lightbulbColor, insideNavigationBar) => {
    if (lightbulbColor === COLOR_SCHEME.YELLOW && insideNavigationBar) {
        navigationMethods.navigate(RELATIVE_PAGE_PATHS.EXPIRED_STICKERS);
    }
};

const NotificationIcon = ({ width, height, fill, insideNavigationBar }) => {
    const viewBoxValues = '0 0 33.909 47.9575';

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox={viewBoxValues}
        >
            <g
                id="Крушка_-_Обърни_внимание"
                onClick={() =>
                    navigateToExpiredStickers(fill, insideNavigationBar)
                }
                data-name="Крушка - Обърни внимание"
                transform="translate(0 3)"
            >
                <g
                    id="Group_1"
                    data-name="Group 1"
                    transform="translate(10.959 -3)"
                >
                    <rect
                        id="Rectangle_1"
                        data-name="Rectangle 1"
                        width="0.624"
                        height="9.616"
                        transform="translate(5.81)"
                        fill={fill ? fill : '#87ba3b'}
                    />
                    <rect
                        id="Rectangle_2"
                        data-name="Rectangle 2"
                        width="10.124"
                        height="1.461"
                        rx="0.73"
                        transform="translate(1.112 11.621)"
                        fill={fill ? fill : '#87ba3b'}
                    />
                    <rect
                        id="Rectangle_3"
                        data-name="Rectangle 3"
                        width="12.348"
                        height="1.461"
                        rx="0.73"
                        transform="translate(0 13.682)"
                        fill={fill ? fill : '#87ba3b'}
                    />
                    <rect
                        id="Rectangle_4"
                        data-name="Rectangle 4"
                        width="11.635"
                        height="1.461"
                        rx="0.73"
                        transform="translate(0.357 15.734)"
                        fill={fill ? fill : '#87ba3b'}
                    />
                    <path
                        id="Path_1"
                        data-name="Path 1"
                        d="M530.107-122.124a3.735,3.735,0,0,0-3.578,2.474.185.185,0,0,0,.173.249h6.811a.185.185,0,0,0,.172-.249A3.734,3.734,0,0,0,530.107-122.124Z"
                        transform="translate(-523.935 130.379)"
                        fill={fill ? fill : '#87ba3b'}
                    />
                </g>
                <g
                    id="Group_4"
                    data-name="Group 4"
                    transform="translate(0 14.82)"
                >
                    <path
                        id="Path_2"
                        data-name="Path 2"
                        d="M530.325-96.378c-1.167-1.769-2.692-3.345-2.784-5.669l-11.627.058c.047,1.688-.813,2.9-1.6,4.164a38.021,38.021,0,0,0-2.585,4.382,10.169,10.169,0,0,0,.064,9.122A11.556,11.556,0,0,0,522.549-78.1a11.51,11.51,0,0,0,9.923-7.735C533.686-89.728,532.448-93.158,530.325-96.378Z"
                        transform="translate(-504.684 102.047)"
                        fill={fill ? fill : '#87ba3b'}
                    />
                    <g
                        id="Group_3"
                        data-name="Group 3"
                        transform="translate(0 0.13)"
                    >
                        <g
                            id="Group_2"
                            data-name="Group 2"
                            transform="translate(0 11.132)"
                        >
                            <rect
                                id="Rectangle_5"
                                data-name="Rectangle 5"
                                width="4.296"
                                height="1.45"
                                rx="0.725"
                                transform="translate(29.613)"
                                fill={fill ? fill : '#87ba3b'}
                            />
                            <rect
                                id="Rectangle_6"
                                data-name="Rectangle 6"
                                width="4.296"
                                height="1.45"
                                rx="0.725"
                                fill={fill ? fill : '#87ba3b'}
                            />
                        </g>
                        <rect
                            id="Rectangle_7"
                            data-name="Rectangle 7"
                            width="4.296"
                            height="1.45"
                            rx="0.725"
                            transform="translate(26.728 21.55) rotate(45)"
                            fill={fill ? fill : '#87ba3b'}
                        />
                        <rect
                            id="Rectangle_8"
                            data-name="Rectangle 8"
                            width="4.296"
                            height="1.45"
                            rx="0.725"
                            transform="translate(5.626 0.26) rotate(45)"
                            fill={fill ? fill : '#87ba3b'}
                        />
                        <rect
                            id="Rectangle_9"
                            data-name="Rectangle 9"
                            width="4.296"
                            height="1.45"
                            rx="0.725"
                            transform="translate(25.281 3.037) rotate(-45)"
                            fill={fill ? fill : '#87ba3b'}
                        />
                        <rect
                            id="Rectangle_10"
                            data-name="Rectangle 10"
                            width="4.296"
                            height="1.45"
                            rx="0.725"
                            transform="translate(3.99 24.139) rotate(-45)"
                            fill={fill ? fill : '#87ba3b'}
                        />
                        <rect
                            id="Rectangle_11"
                            data-name="Rectangle 11"
                            width="4.296"
                            height="1.45"
                            rx="0.725"
                            transform="translate(17.859 25.711) rotate(90)"
                            fill={fill ? fill : '#87ba3b'}
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default NotificationIcon;
