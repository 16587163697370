import {
  HIDE_HAMBURGER_MENU,
  SHOW_HAMBURGER_MENU,
} from '../actions/actionTypes';

const initialState = {
  showHamburgerMenu: 'DISABLED',
};

const commonReducer = (state = initialState, action) => {
  state = {
    ...state,
  };

  switch (action.type) {
    case SHOW_HAMBURGER_MENU:
      state.showHamburgerMenu = 'SHOW';
      return {
        ...state,
      };

    case HIDE_HAMBURGER_MENU:
      state.showHamburgerMenu = 'HIDE';
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default commonReducer;
